bat-card-gloitmulticategorycarousel.multicategory-card-top-carousel{
    max-width: 1240px !important;
    @include media-breakpoint-down(md) {
        max-width: 343px !important;
        margin-inline: auto;
    }
    .bat-multicategorycarousel-gloit--card {
        border-radius: 16px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
        background: #fff;
        padding: 23px;
        @include media-breakpoint-down(md) {
            padding: 16px;
        }
        &-main {
            width: 100%;
            display: flex;
            gap: 23px;
            align-items: center;
            @include media-breakpoint-down(md) {
                flex-direction: column-reverse;
                gap: none;
                justify-content: space-between;
                min-height: 378px;
            }
            &-left-logo {
                height: 232px;
                @include media-breakpoint-down(md) {
                    height: 210px;
                }
            }

            &-right {
                display: block;
                color: var(--Glo---Black, #000);
                font-family: Mont;
                line-height: normal;
                font-style: normal;
                p {
                    margin-bottom: 0;
                }
                .heading {
                    font-size: 12px;
                    font-weight: 400;
                }

                .title {
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 16px;
                    margin-top: 8px;
                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        text-align: center;
                        margin-top: 0px;
                    }
                }

                .description {
                    font-size: 16px;
                    font-weight: 400;
                    @include media-breakpoint-down(md) {
                        font-size: 14px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

bat-card-gloitmulticategorycarousel.multicategory-small-card-discover {
    max-width: 440px !important;
    margin-bottom: 32px !important;
    @include media-breakpoint-down(md) {
        max-width: 343px !important;
        margin-inline: auto;
        margin-bottom: 24px !important;
    }
    .bat-multicategorycarousel-gloit--card {
        padding: 0px 24px 0px 0px;
        border-radius: 16px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
        background: #fff;
        padding: 24px;
        height: 213px;
        overflow: hidden;
        @media (min-width: 1086px) and (max-width: 1280px){
            height: 253px;
        }
        @include media-breakpoint-down(md) {
            border-radius: 18px;
            padding: 16px;
            height: 200px;
        }
        &-main {
            width: 100%;
            height: 100%;
            display: flex;
            gap: 23px;
            align-items: flex-start;
            @include media-breakpoint-down(md) {
                gap: 16px;
            }

            &-left{
                &-logo{
                    @include media-breakpoint-down(md) {
                        width: 70px;
                        margin-top: 8%;
                    }
                }
            }

            &-right {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                color: var(--Glo---Black, #000);
                font-family: Mont;
                font-style: normal;
                gap: 16px;
                height: 100%;
                p {
                    margin-bottom: 0;
                }
                .title {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: -13px;
                    line-height: normal;
                    letter-spacing: normal;
                }
    
                .description {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: normal;
                    @include media-breakpoint-down(md) {
                        font-size: 12px;
                        line-height: normal;
                    }
                }
    
                .ctalist {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: flex-end;
                    width: 100%;
                    .bat-cta-style.button-dark {
                        background: #000 !important;
                        height: 40px;
                    }
                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }

    }
}

bat-card-gloitmulticategorycarousel.multicategory-small-card-discover.multicategory-small-card-discover-full-with {
    max-width: none !important;
    margin-bottom: 64px !important;
    @include media-breakpoint-down(md) {
        max-width: 343px !important;
        margin-bottom: 48px !important;
    }
    .bat-multicategorycarousel-gloit--card {
        padding: 24px;
        border-radius: 16px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
        background: #fff;
        @include media-breakpoint-down(md) {
           padding: 16px;
           border-radius: 18px;
           height: 242px !important;
        }
        &-main {
            align-items: flex-start;
            width: 100%;
            display: flex;
            gap: 23px;
            @include media-breakpoint-down(md) {
                gap: 16px;
             }
            &-left-logo {
                width: 186px;
                height: auto;
                @include media-breakpoint-down(md) {
                    height: auto;
                    width: 86px;
                    margin-top: 0px;
                 }
            }
            &-right {
                display: block;
                color: var(--Glo---Black, #000);
                font-family: Mont;
                font-style: normal;

                .title-logo{
                    @include media-breakpoint-down(md) {
                        width: 52px;
                     }
                }
                p {
                    margin-bottom: 0;
                }
                .title {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 4px;
                    margin-top: 24px;
                    line-height: normal;
                    @include media-breakpoint-down(md) {
                        margin-top: 12px;
                        margin-bottom: 3px;
                        line-height: 18px;
                     }
                }
    
                .description {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    @include media-breakpoint-down(md) {
                        color: var(--Glo---Charcoal, #2A2A2A);
                        font-family: Mont;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                     }
                }
    
                .ctalist {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    margin-top: 32px;
                    @include media-breakpoint-down(md) {
                        margin-top: 16px;
                     }
                    a span{
                        font-size: 14px;
                        letter-spacing: 0.7px;
                    }

                    .bat-cta-style.button-dark {
                        background: #000 !important;
                    }
                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }

    }
}

bat-carousel-gloitmulticategory.multicategory-carousel-steps {

    &.multicategory-carousel-steps-black-card{
        @include media-breakpoint-down(md) {
            margin-top: -16px !important;
        } 
    }
    margin-top: -1.5%;
    @include media-breakpoint-down(md) {
        margin-top: 24px;
    }
    .bat-carousel {
        
        .slick-dots{
            @include media-breakpoint-down(md) {
                display: none !important;
            }
        }

        .slick-next{
            @include media-breakpoint-down(md) {
                top: -36px;
                right: 16px;

                .bat-icon img {
                    content: url('/content/dam/glo-it/images/multicategory/Navigation_CTA-right.png');
                    width: 30px; 
                    height: auto;
                }
            }
        }
    
        .slick-prev{
            @include media-breakpoint-down(md) {
                top: -36px;
                right: 62px;
                left: unset;

                .bat-icon img {
                    content:  url('/content/dam/glo-it/images/multicategory/Navigation_CTA-left.png');
                    width: 30px; 
                    height: auto; 
                }
            }
        }

        .slick-prev,
        .slick-next {
            display: block !important;
            z-index: 1;
            .bat-icon {
                width: 40px;
                height: 40px;
            }

            &.slick-disabled {
                opacity: 0.2;
            }
        }
    }
}

bat-section-default.multicategory-background-gradient{
    background: linear-gradient(180deg, rgba(229, 225, 230, 0.40) 0%, #FFF 100%);
    @include media-breakpoint-down(md) {
        background: var(--glo-warm-grey-40, rgba(229, 225, 230, 0.40));
    }
}
