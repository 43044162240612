.image-container{
	max-width: 980px;
	img{
		max-width: 370px;
		@include media-breakpoint-down(md) {
			margin-left: 10px;
			max-width: 190px;
		}
	}
}
.alternativa-video{
	max-width: 980px;
	.video-container{
		position:relative;
		.white-rectangle{
			position: absolute;
			left: 0;
			top: 0;
			height: 20px;
			width: 225px;
			z-index: 1;
			background-color: $white;
			@include media-breakpoint-up(md) {
				height: 20px;
				width: 225px;
			}
		}
	}
}


.text-alternativa-landing{
	max-width: 980px;
	padding: 30px 20px 70px;
	font-size: 14px;
	.second-par{
		margin-top: 15px;
		a{
			color:$orange;
		}
	}
}