bat-carousel-gloitaudiomistery {
	.bat-carousel {
		width: fit-content;
		max-width: 100%;
		.slick-dots {
			display: flex;
			flex-direction: row;
			position: absolute;
			bottom: 0%;
			left: 47%;
			height: fit-content;
			margin-left: 0;
			@include media-breakpoint-down(md) {
				margin: 0;
				width: 100%;
				bottom: 0;
				left: 0;
				justify-content: center;
			}
			li {
				padding: 5px 9px;
				@include media-breakpoint-down(md) {
					margin: 0;
					padding: 0px 7px;
				}
				button {
					@include media-breakpoint-down(md) {
						width: 8px !important;
						height: 8px !important;
					}
					width: 10px !important;
					height: 10px !important;
					background-color: #0941be !important;
					border: 1px solid #0941be !important;
					border-radius: 50%;
					cursor: pointer;
					transition: background-color 0.3s ease, opacity 0.3s ease;
					overflow: hidden;

					&:focus {
						outline: none;
					}
				}
			}
		}
		.slick-track {
			@include media-breakpoint-down(md) {
				height: 522px;
			}
		}
	}
}
