bat-carousel-gloitminibox{
    &.pdp-hyper-core{
        .bat-carousel.bat-carousel-minibox-card-gloit {
            .slick-play-stop {
                background-color: #E5E1E6; 
                color: #2A2A2A; 
                
                .icon-play, .icon-stop {
                    fill: #2A2A2A;
                    path{
                        stroke: #2A2A2A;
                    }
                }
            }
        
            .slick-dots {
                background-color: #E5E1E6;
                li {
                    opacity: 0.4;
                    background: #2A2A2A;
        
                    &.slick-active {
                        opacity: 1;
                        background: #2A2A2A;
                    }
                }
            }
        }
    }
    padding-bottom: 82px;
    margin-top: 36px;
    .bat-carousel.bat-carousel-minibox-card-gloit {

        .slides-container{
            .navigation-arrows{
                .bat-carousel-slides{
                    .slick-slide{
                        min-height: 239px;
                        margin-right: 38px;
                        height: 100%;
                        &:last-child{
                            margin-right: 0px;
                        }
                    } 
                }
            }
        }
        .slick-dots-container {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            margin-top: 24px; 
            gap: 24px;
            width: 100%;
        }
    
        .slick-play-stop {
            background-color: #2A2A2A; 
            color: white; 
            border: none;
            border-radius: 50%; 
            padding: 7px;
            cursor: pointer; 
            display: flex;
            align-items: center;
            justify-content: center;
            
            .icon-play, .icon-stop {
                width: 26px;
                height: 26px;
                fill: white;
            }
    
            .icon-play {
                display: none;
            }
    
            &.paused {
                .icon-stop {
                    display: none;
                }
    
                .icon-play {
                    display: block;
                }
            }
    
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    
        .slick-dots {
            background-color: #2A2A2A;
            border-radius: 30px; 
            padding: 10px; 
            width: fit-content;
            margin: 0;
            bottom: 0;
            position: relative;
            left: unset;
            right: unset;
            
            li {
                width: 10px; 
                height: 10px; 
                display: inline-block;
                background: #E5E1E6;
                margin: 0 5px;
                opacity: 0.3;
                cursor: pointer;
                -webkit-border-radius: 50%;
                        border-radius: 50%;
                -webkit-transition: opacity .5s, background-color .5s, width .5s;
                -o-transition: opacity .5s, background-color .5s, width .5s;
                transition: opacity .5s, background-color .5s, width .5s;
                -webkit-transition-delay: .5s, .5s, 0s;
                     -o-transition-delay: .5s, .5s, 0s;
                        transition-delay: .5s, .5s, 0s;
                
                button {
                  display: none;
                }
    
                &.slick-active {
                    opacity: 1;
                    background: #ffffff;
                    width: 40px;
                    border-radius: 30px;
                    -webkit-transition-delay: 0s;
                     -o-transition-delay: 0s;
                        transition-delay: 0s;
                }
            }
        }
    
        .slick-dots-container {
            display: flex;
            justify-content: center;
            margin-top: 10px; 
        }
    }
}

.text-opacity-six{
    .bat-text{
        p{
            color: var(--glo-2024-Warm-Grey, #E5E1E6);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Mont;
            font-size: 18px !important;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 140% !important; /* 25.2px */
            opacity: 0.8;
        
            @include media-breakpoint-down(md) {
                font-size: 16px !important;
                font-style: normal;
            }
        }
    }
}

.text-opacity-six.pdp-hyper-core{
    .bat-text{
        p{
            color: var(--glo-2024-glo-Charcoal, #2A2A2A);
            opacity: 0.7;
        }
    }
}

