bat-card-gloitminibox{
    .bat-card{
        &--minibox{
            border-radius: 40px;
            background: var(--glo-2024-Cool-Grey, #DDE5ED);
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            overflow: hidden;

            &-text{
                margin-left: 20px;
                padding-block: 20px;
                height: 20%;
                position: absolute;
            }

            &-img{
                width: 100%;
                height: auto;
                img{
                    width: 312px;
                    @include media-breakpoint-down(sm) {
                        width: 335px;
                        height: 247px;
                    }
                    object-fit: fill;
                    border-radius: 40px;
                }
            }
        }
    }
}