.gloit-consenti-posizione-modal-body{
	padding: 0 !important;
}

#gloit-consenti-posizione-modal-close-button{
	background: #fff;
	top: 24px;
	left: 80%;
	height: 40px;
	width: 40px;
	padding: 5px;
	position: absolute;
	border-radius: 100%;
}
.gloit-consenti-posizione-modal{
	background: #fff;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &-image{
    	width: 50%;
        margin: auto;
        margin-top: 30%;
        margin-bottom: 0;
    }
    &-text{
    	text-align: center;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: .7px;
        margin: 5%;
    }
    &-bottom-buttons{
    	text-align: center;
        width: 90%;
        height: 50%;
        margin: 5%;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &-consenti{
        		border-radius: 100px;
                border: solid 2px #ff5216;
                background: linear-gradient(267.63deg, #cd4a04 .65%, #ed7d30 100%);
                &-text{
                	margin: 3%;
                	color: #fff;
                	font-size: 16px;
					font-weight: 600;
                }
        }
        &-close{
        		border-radius: 100px;
                border: solid 2px #ff5216;
                background: #fff;
            	margin-top:5%;
            	&-text{
            		margin: 3%;
                    color: #000;
                    font-weight: 700;
					font-size: 16px;
            	}
        }
    }
}
