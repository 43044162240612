// sass-lint:disable class-name-format, no-qualifying-elements, force-element-nesting, force-pseudo-nesting

.bat-quiz {
	background-color: $white;
	margin: 0 auto;
	max-width: 1000px;
	padding: 30px 20px 0;

	@include media-breakpoint-up(md) {
		padding: 50px;
	}
	
	&__headline > * {
		border-bottom: 2px solid $light-gray;
		margin-bottom: 30px;
		padding-bottom: 30px;
		@include media-breakpoint-down(sm) {
			font-size: 24px;
			font-weight: 600;
			letter-spacing: -1px;
			line-height: 1;
			margin-bottom: 15px;
			padding-bottom: 25px;
		}
	}
	
	&__copy {
		p {
			color: $dark-gray;
			font-size: 15px;
			font-weight: 500;
			
			@include media-breakpoint-down(sm) {
				font-size: 13.5px;
				line-height: 1.6;
			}
		}
		> *:last-child {
			margin-bottom: 30px;
		}
	}
	
	&__questions {
		padding: 0 0 30px;
		
		@include media-breakpoint-up(md) {
			padding: 50px 0;
		}
		
		.aem-GridColumn {
			padding: 0;
		}
	}
	
	&__product-rec {
		animation: fade-in .5s ease-in-out;
		display: none;
		
		&--visible {
			display: block;
		}
		
		&-cta.bat-cta-style.button-dark {
			animation: fade-in .5s ease-in-out;
			display: none;
			
			&.bat-quiz__product-rec-cta--visible {
				display: inline-block;
			}
		}
		
		&-headline {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-bottom: 20px;
			@include media-breakpoint-up(md) {
				flex-direction: row;
			}
			
			& > * {
				margin-bottom: 20px;
				text-align: center;
				@include media-breakpoint-up(md) {
					margin-bottom: 0;
					text-align: left;
				}
			}
		}
		
		&-more-link.bat-cta-style.arrow-link-dark {
			font-size: 14px;
			margin-right: 25px;
			
			&:after {
				font-size: 22px;
				margin-bottom: 4px;
			}
		}
		
		&-copy {
			text-align: center;
			@include media-breakpoint-up(md) {
				text-align: left;
			}
			
			p {
				color: $dark-gray;
				font-size: 15px;
				font-weight: 500;
			}
			> *:last-child {
				margin-bottom: 30px;
			}
		}
		
		&-container {
			display: flex;
			justify-content: center;
			
			@include media-breakpoint-up(md) {
				justify-content: flex-start;
			}
			
			&.slick-initialized {
				width: calc(100% + 20px);
				
				@include media-breakpoint-up(md) {
					width: calc(100% + 70px);
				}
				
				@include media-breakpoint-up(lg) {
					width: 100%;
				}
			}
		}
		
		.slick-list {
			padding: 0 20% 0 0;
			width: 100%;
			
			.slick-track {
				margin-left: 0;
			}
			
			@include media-breakpoint-up(lg) {
				margin-right: -40px;
				padding: 0;
			}
			
			.bat-productcard {
				max-width: 100%;
			}
			
			bat-productcard-velo,
			bat-productcard-default {
				margin-right: 40px;
				
				@media (min-width: 450px) {
					margin-right: 120px;
				}
				
				@include media-breakpoint-up(sm) {
					margin-right: 40px;
				}
				
				@include media-breakpoint-up(md) {
					margin-right: 60px;
				}
				
				@include media-breakpoint-up(lg) {
					margin-right: 40px;
				}
			}
		}
	}
	
	&__carousel-controls {
		display: flex;
		justify-content: space-between;
		margin: 0 16px;
		
		&.hidden {
			display: none;
		}
		
		.slick-arrow  {
			.bat-icon::before {
				font-size: 19px;
			}
		}
		
		.slick-dots {
			display: flex;
			list-style: none;
			margin: 0;
			padding: 0;
			
			li {
				margin: 0;
			}
			
			button {
				font-size: 0;
				height: 33px;
				overflow: hidden;
				padding: 10px;
				position: relative;
				width: 33px;
				
				&:after {
					background-color: $light-gray;
					border-radius: 50%;
					content: '';
					display: block;
					height: 13px;
					left: 10px;
					position: absolute;
					top: 10px;
					width: 13px;
				}
			}
			.slick-active {
				button::after {
					background-color: $light-blue;
				}
			}
		}
	}
	
	&__error {
		color: $red;
		font-family: monospace;
		margin-bottom: 20px;
		
		& > * {
			text-transform: none;
		}
	}
}

bat-quiz-velo,
bat-quiz-default {
	display: block;
	position: relative;
}

.bat-quiz--overlap-mobile {
	bat-quiz-velo,
	bat-quiz-default {
		margin-top: -200px;
		
		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
	}
}
.bat-quiz--overlap-desktop {
	bat-quiz-velo,
	bat-quiz-default {
		@include media-breakpoint-up(md) {
			margin-top: -200px;
		}
	}
}
.bat-quiz--overlap-always {
	bat-quiz-velo,
	bat-quiz-default {
		margin-top: -200px;
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
