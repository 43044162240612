.registrazione-completata-modal-body {
	display: none;
	position: fixed;
	z-index: 1000000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0px !important;
}

.registrazione-completata-modal-content {
	background: #fff;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	overflow-wrap: break-word;
}

#confirmed-registration-qr-code-container{
	display: flex;
	justify-content: center;
	margin-bottom: 10%;
    margin-top: 7%;
}

#registrazione-completata-modal-close-button {
	background: #fff;
	top: 24px;
	right: 6%;
	height: 40px;
	width: 40px;
	padding: 5px;
	position: absolute;
	border-radius: 100%;
}

.gloit-registrazione-completata-title {
	padding-top: 20%;
	text-align: center;
	font-size: 28px;
	font-weight: bold;
	color: #000;
	line-height: 36px;
	margin: 5%;
	margin-top: 0;
	margin-bottom: 0;
}

.gloit-registrazione-completata-text {
	text-align: center;
	margin: 8%;
	margin-top: 3%;
	margin-bottom: 5%;
	color: #000;
	font-size: 18px;
	line-height: 24px;
}

.gloit-registrazione-completata-image {
	width: 60%;
	margin: auto;
	margin-top: 0;
	margin-bottom: 0;
}

.gloit-aggiungi-al-calendario {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	height: 10%;

	&-text {
		font-family: Montserrat;
		text-align: center;
		font-size: 14px;
		line-height: 21px;
		color: #CD4A04;
		letter-spacing: 0.7px;
		font-weight: 700;
		margin: 5%;
	}

	&-image {
		width: 7%;
		padding-bottom: 2%;
        margin-left: 2%;
	}
}

.gloit-dettagli-evento {
	display: flex;
	flex-direction: column;
	margin: 7%;
	margin-top: 0;
	height: 10%;
	margin-bottom: 15%;

	&-pretitle {
		font-size: 12px;
	}

	&-title {
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 5%;
	}
}

.gloit-registrazione-completata-cta-container {
	text-align: center;
    width: 90%;
    margin: 5%;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.registration-complete-continue-cta{
	width: 100%;
}

.event-date, .event-location{
	line-height: 28px;
}