// File:          _productfeatures.scss
// Description:   Productfeatures Styles
.bat-productfeatures {
	
	&-description {
		
		&-headline {
			margin-bottom: 20px;
		}
		
		&-text {
			
			@include media-breakpoint-up(lg) {
				max-width: 440px;
			}
		}
	}
	
	&-list {
		max-width: 360px;
		
		@include media-breakpoint-up(lg) {
			max-width: none;
			padding: 0;
		}
		
		&-item {
			
			
			@include media-breakpoint-up(lg) {
				padding-right: 16px;
			}
			
			picture {
				position: relative;
				
				img {
					margin: 0 auto;
					width: 80%;
				}
				
				&:before {
					background-color: $teal;
					border-radius: 40px;
					content: '';
					height: 75px;
					left: 0;
					position: absolute;
					top: 0;
					width: 75px;
					z-index: -1;
				}
				
				&:after {
					background-color: $dark-gray;
				}
			}
			
			&-description {
				
				h5 {
					@include font-rem(20px, 20px);
					font-style: normal;
					font-weight: 700;
					margin-bottom: 10px;
					position: relative;
					text-transform: capitalize;
					
					@include media-breakpoint-up(lg) {
						@include font-rem(20px, 25px);
						margin-bottom: 20px;
						
						&:before {
							border-bottom: 3px solid $teal;
							bottom: -10px;
							content: '';
							display: block;
							left: 0;
							position: absolute;
							right: 0;
						}
						
						&:after {
							border: 3px solid $teal;
							border-radius: 50%;
							bottom: -16px;
							box-sizing: border-box;
							content: '';
							display: block;
							height: 16px;
							position: absolute;
							right: -15px;
							width: 16px;
						}
					}
				}
				
				p {
					@include font-rem(13px, 15px);
				}
			}
			
			//Waypoint for viewport in-view event
			&.bat-waypoint {
				left: -600px;
				opacity: 0;
				transition: left 1s ease-in calc(var(--animation-order) * 50ms), opacity 1s ease-in calc(var(--animation-order) * 50ms);
				
				@include media-breakpoint-up(lg) {
					left: -400px;
					transition: left 1s ease-in calc(var(--animation-order) * 50ms), opacity 2s ease-in calc(var(--animation-order) * 50ms);
					
					.authorEditMode & { // sass-lint:disable-line class-name-format
						left: 0;
						transition: none;
					}
				}
			}
			
			&.bat-waypoint--in-view {
				left: 0;
				opacity: 1;
			}
			
			@include media-breakpoint-up(lg) {
				max-width: 220px;
			}
			
			@include media-breakpoint-up(xl) {
				margin-left: calc(var(--animation-order) * 15%);
				max-width: 240px;
			}
		}
	}
}
