.registrazione-completata-modal-body {
	display: none;
	position: fixed;
	z-index: 1000000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0px !important;
}

.registrazione-completata-modal-content {
	background: #fff;
	height: 100%;
	position: relative;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	overflow-wrap: break-word;
    }

@media screen and(max-height: 670px){
	.gloit-registrazione-completata-continue-button-container {
	padding-top: 10%;
    }
}

#registrazione-completata-modal-close-button {
	background: #fff;
	top: 24px;
	right: 6%;
	height: 40px;
	width: 40px;
	padding: 5px;
	position: absolute;
	border-radius: 100%;
}

.gloit-registrazione-completata-title {
	padding-top: 20%;
	text-align: center;
	font-size: 28px;
	font-weight: bold;
	color: #000;
	line-height: 36px;
	margin: 5%;
	margin-top: 0;
	margin-bottom: 0;
}

.gloit-registrazione-completata-text {
	text-align: center;
	margin: 8%;
	margin-top: 3%;
	margin-bottom: 5%;
	color: #000;
	font-size: 18px;
	line-height: 24px;
}

.gloit-registrazione-completata-image {
	width: 60%;
	margin: auto;
	margin-top: 0;
	margin-bottom: 0;
}

.gloit-aggiungi-al-calendario {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	height: 10%;

	&-text {
		text-align: center;
		font-size: 14px;
		line-height: 21px;
		color: #CD4A04;
		letter-spacing: 0.7px;
		font-weight: 700;
		margin: 5%;
	}

	&-image {
		width: 7%;
		padding-bottom: 2%;
        margin-left: 2%;
	}
}

.gloit-dettagli-evento {
	display: flex;
	flex-direction: column;
	margin: 7%;
	margin-top: 0;
	height: 10%;
	margin-bottom: 15%;

	&-pretitle {
		font-size: 12px;
	}

	&-title {
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 5%;
		line-height: 25px;
	}

	&-container {
		display: flex;
		flex-direction: column;

		&-data {
			display: flex;

			&-image {
				margin-right: 2%;
                height: 50%;
			}
		}

		&-luogo {
			display: flex;
			margin-top: -3%;

			&-image {
				margin-left: 0.5%;
				margin-right: 2%;
				height: 50%;
			}
		}
	}
}


.gloit-registrazione-completata-continue-button-container {
	text-align: center;
	width: 90%;
	height: 20%;
	margin: 5%;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.gloit-registrazione-completata-continue-button {
	border-radius: 100px;
	background: linear-gradient(to right, #fd612c 0, #ce3a07 100%);

	&-text {
		margin: 3%;
		font-weight: bold;
	}
}