bat-card-gloitaudiomysterynumbers{
    width: fit-content;
    margin: 0 !important;
    cursor: pointer;
    .gloit-audiomysterynumbers-card{
        width: fit-content;
        margin-right: 20px !important;
        .number-container{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 2px solid #FFF;
            width: 36px;
            height: 36px;
            .number{
                color: #FFF;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 111.111% */
            }
        }
    }
}