.aem-GridColumn{
	&.webseries-card{
		padding: 70px 60px;

		@include media-breakpoint-down(sm){
			padding: 0px;
		}
    }
	.aem-GridColumn{
		@include media-breakpoint-down(sm){
			padding: 0px;
		}
	}

	.margin-small{
		margin-bottom: 10px
	}
}


.bat-carousel.bat-carousel-card-gloit{
	.slides-container{
		padding: 0px;
	}
	.slick-track:first-child {
		margin-left: 5px;
	}
}

.bat-carousel-slides{
	.slick-dots li{
		display: none;
	}
}
.bat-carousel-entraincontatto-card-gloit{
	.bat-carousel-slides{
		.slick-dots li{
			display: block;
			button{
				background-color:#C4DBF2;
				border:0px;
			}
			&.slick-active {
					
				button {
					background-color: white;
					border:0px;
				}
			}
		}
		.slick-slide{
			padding-left:10px;
		}
		
	}
}	




#webseriesSection{
	background-color: #58A6E4;
}


.bat-webseries-gloit{
	margin-right: 10px;

    &--webseries {
		overflow: hidden;
		position: relative;
		@media (max-width: 1023px) {
			height: 65vw;
		}

		&-text {
			max-width: 70%;
			@include media-breakpoint-down(sm){
				max-width: none;
			}

			.bat-card--webseries-title {
				@include font-rem(22px, 28px);
                font-weight: 800;
			}

			.bat-card--webseries-andmore{
				color: $black;
				@include font-rem(16px, 20px);
				h3 {
					font-size: 22px;
    				line-height: 28px;
    				font-weight: 800;
				}
			}

			.bat-card--webseries-price,.price{
				@include font-rem(30px, 32px);
				font-weight: 800;
				color: $white;
				background-color: $orange;
				padding: 5px
			}

			.bat-card--webseries-price-full,.price-full{
				@include font-rem(22px, 28px);
				font-weight: 600;
				color: $black;
				text-decoration: line-through;
				padding-left: 15px;
				padding-top: 5px;

			}

			.price-full-white{
				@include font-rem(22px, 28px);
				font-weight: 600;
				color: $black;
				text-decoration: line-through;
				padding-left: 15px;
				padding-top: 5px;
				filter: invert(100%);
			}

			.card-price {
				display: block;
				background: $orange;
				width: fit-content;
				padding-top: 6px;
				padding-bottom: 6px;
				padding-left: 9px;
				padding-right: 13px;
				margin-bottom: 20px;
				@include font-rem(30px, 32px);
				font-weight: bold;
				font-family: $primary-font;
			}
			.card-\&more {
				content: url('/content/dam/glo/images/glo_and-more-branding.svg');
			}

			.bat-headline-style {
				.headline2-color1 {
					@include font-rem(22px, 28px);
				}
			}

			@include media-breakpoint-down(sm) {
				h2, h3, h4 {
					@include font-rem(22px, 28px);
				}
			}

			p {
				@include font-rem(16px, 20px);
				margin-top: 0px;
				@include media-breakpoint-down(sm){
					margin-top: 10px;
				}
			}
		}

		&-img{
			position: static
		}
		

		&-main {
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 20px;
			color: $black;
			pointer-events: none;
			a,button {
				pointer-events: all;
			}
			@include media-breakpoint-down(md){
				display: none;
			}
		}

		&-connector {
			width: 50%;
			height: 50%;
			margin-left: auto;
			margin-right: auto;
		}

		&-img {
			bottom: 0;
			left: 0;
			right: 0;

			> a {
				display: block;
			}

			picture,
			img {
				height: 100%;
				object-fit: cover;
			}
			@include media-breakpoint-down(sm) {
				picture,
				img {
					height: 100%;
					object-fit: cover;
					width: 100%;
				}
			}
		}

		&-ctalist {
			margin-top: 20px;
			@include media-breakpoint-down(sm) {
				margin-top: 10px;
			}
			.button-secondary-light {
				border-color: $white;
				color: $white;
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left: 20px;
				padding-right: 20px;
			}
			.button-secondary-dark {
				border-color:#58A6E4;
				color: $black;
				background-color: #58A6E4;
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left: 20px;
				padding-right: 20px;
			}
			.button-secondary-dark:hover {
				border-color:#58A6E4;
				color: $black;
				background-color: #58A6E4;
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left: 20px;
				padding-right: 20px;
			}
			/*a:hover{
				span{
					color: $black;
				}
				
			}*/
		}

		.zoom-animation {
			transition: transform 2s;
			&:hover {
				transform: scale(1.2);
			}
		}
	}
}