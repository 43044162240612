
bat-section-gloitneostick{
	max-width: 1300px !important;
	border-radius: 35px;
	@include media-breakpoint-down(sm) {
		width: 80%;
		margin-left: 40px;
	}
	.neo-stick{
		.bat-section-content{
			max-width: 1300px !important;
			.text{
				padding-right: 0px;
				padding-left: 0px;
				@include media-breakpoint-down(sm) {
					width: 100% !important;
					margin-left: 0px !important;
				}
			}
			.image{
				padding-top: 30px;
				@include media-breakpoint-down(sm) {
					width: 100% !important;
					padding: 30px;
					margin-left: 0px !important;
				}
			}
		}
	}
}

.gradient{
	background-image: linear-gradient(#C8A874, #FFFFFF);
}

.broder-radius-full{
	border-radius: 35px;
}


