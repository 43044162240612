.glo-analisi-it{
	background-color: $concrete;
	.last-section-container{
			max-width: 980px;
			padding: 20px;
		.analisi-container{
			@include media-breakpoint-down(md) {
				flex-direction: column-reverse;
			}
			.description{
				margin-top: 40px;
				margin-bottom: 20px;
				font-size: 16px;
   				line-height: 24px;
			}
		
		.analisi-button{
			@include media-breakpoint-down(md) {
				justify-content: center;
			}
		}
			
		}
		.logo{
			max-width: 260px;
			margin-top: 20px;
			margin-bottom: 20px;
		}
		.underLineT{
			font-size: 38px;
			   line-height: 36px;
			font-weight: 800;
			&::after{
				content: '';
				display: block;
				width: 150px;
				height: 3px;
				background-color: #2a2c2c;
				position: relative;
				top: 15px;
			
			}
		}
	}
	
}
