.bat-carousel-hyperfeatures-gloit {
	color: $white;
	font-size: 20px;
	line-height: 24px;
	text-align: center;

    .slick-track {
        gap: 77px;
    }

    .arrows-container {
        position: relative;
        display: flex;
        top: 102px;
        height: 0;
        gap: 219px;
        margin: auto;
        width: fit-content;

        .slick-arrow {
            position: relative;
            top: auto;
            transform: none;
        }
    }

	.bat-carousel-slides {
		opacity: 1;

		.slick-slide {
			visibility: visible;
		}

		.slide {
			display: flex !important;
			flex-direction: column;
			align-items: center;
			gap: 36px;
			max-width: 350px;

            .mbl-only{
                display: none;
                @media (max-width: 850px){
                    display: block;
                }
            }
            .desktop-only{
                display: block;
                @media (max-width: 850px){
                    display: none;
                }
            }
		}

	}

    .buttons-container {
        display: none;

        a{
            padding: 12px;
            justify-content: center !important;
        }
    }
}

@media (max-width: 1200px) {
    .bat-carousel-hyperfeatures-gloit {
        .slick-track {
            gap: 20px;
        }
    }
}

@media (min-width: 850px) {
    .bat-carousel-hyperfeatures-gloit {
        .arrows-container {
            display: none;
        }

        .slick-list {
            padding: 0 !important;

            .slick-track {
                display: flex;
                width: 100% !important;
            }
        }
    }
}

@media (max-width: 850px) {
    .bat-carousel-hyperfeatures-gloit {
        .arrows-container {
            gap: 259px;
        }
        .slick-slide:not(.slick-current) {
            opacity: 0 !important;
        }

        .slide {
            margin: auto;
        }

        .buttons-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-bottom: 35px;
            padding-top: 45px;
            margin: auto;
            width: 172px;

            .bat-cta-style.button-secondary-light{
                border: solid 2px white;
            }
        }
    }
}
