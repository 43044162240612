.prediction-modal{
	width: 585px;
    background: linear-gradient(45deg, #5482c2 0, #353174 100%);
    box-shadow: 16px 16px 0px #D5DDEC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
	
	.uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

	.first-section-modal {		
        margin-top: 60px;
        width: max-content;

        @include media-breakpoint-down(md) {
            margin-top: 80px;
        }
        
        .survey-code-box {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 24px;
            gap: 8px;
            background: #0B1047;
            border-radius: 24px;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: $white;

            .icon-prediction{
                height: 48px;
                width: 48px;

                &::before {
                    height: 50px;
                }
            }
            .icon-prediction-modal::before{
                height: 50px !important;
                transform: unset !important;
            }
        }
	}

    .second-section-modal {

        .survey-modal-list {
            &-item {
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                text-align: center;

                &-button-current {
                    width: 47px;
                    height: 47px;
                    color: $white;
                    border-radius: 100%;
                    border: 3px solid $white;
                    font: inherit;

                    &.active {
                        background: white;
                        color: #18207F;
                    }
                } 

                &-button-voted {
                    width: 47px;
                    height: 47px;
                    background: #53CC85;
                    border-radius: 100%;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    font: inherit;
                    color: #18207F;

                    i::before {
                        font-size: 18px;
                    }

                    &.active {
                        display: flex;

                        + .survey-modal-list-item-button-current {
                            display: none;
                        }
                    }
                }

            }
        }
    }

	.third-section-modal {
		display: flex;
        flex-direction: column;
        justify-content: center;

        .modal-title {
            font-weight: 700;
            font-size: 26px;
            line-height: 30px;
            text-align: center;
            color: $white;
        }

        .description-modal {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: $white;
            margin-bottom: 24px;
        }

	}

    .survey-step-form {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        display: none;

        &.active {
            display: block;
        }
    }

    .fourth-section-modal {
        margin: 0;

        .bat-form--survey-question {
            margin: 0;
        }

        .bat-form-field-set {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            label {
                display: flex;
                flex-direction: row;
                padding: 16px 24px;
                gap: 16px;
                width: 457px;
                min-height: 60px;
                background: #D5DDEC;
                align-items: center;
                margin: 0;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                > input[type="checkbox"] {
                    height: 24px;
                    margin-right: 16px;

                    &::before {
                        background: #D5DDEC;
                        font-size: 24px;
                        height: 24px;
                        width: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #0B1047;
                    }

                    + span {
                        font-size: 16px;
                        line-height: 18px;
                        color: #0B1047;
                        padding: 0;
                    }

                    &:checked + span {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                        color: #0B1047;
                    }

                    &:checked::before {
                        color: $white;
                        content: "\e96d";
                        background: #0B1047;
                        font-family: "icomoon";
                        font-size: 30px;
                    }
                }

                &.selected {
                    background: $white;
                    border: 4px solid #18207F
                }
            }

            .text-area {
                max-width: 457px;
            }

            .number-field {
                max-width: 100px;
                font-size: 24px;
                font-weight: 600;
                height: 60px;
            }

            .date-field {
                max-width: 457px;
                height: 60px;
            }
        }
    } 

    .fifth-section-modal {
        display: flex;
        flex-direction: column;
        align-items: center;

        .cta-proceed,
        .cta-send {
            padding: 10px 32px;
            background: $white;
            border-radius: 24px;
            font-family: inherit;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: #2A2C2C;
            margin-bottom: 40px;

            @include media-breakpoint-down(md) {

                margin-bottom: 24px;
                span {
                    width: max-content;
                }
            }

            &:hover {
                opacity: 0.9;
            }

            &:disabled {
                opacity: 0.8;
                pointer-events: none;
            }
        }

        .mgm-error-message {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 24px;
            gap: 16px;
            background: #FDF3F5;
            border-radius: 8px;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.2px;
            color: #E00830;
            margin-bottom: 40px;
            width: 420px;

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-bottom: 24px;
            }

            .icon-warning {
                &::before {
                    content: url('/content/dam/glo-it/images/newloyalty/Warning.svg');
                }
            }
        }
    }
}