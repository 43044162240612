.glo-it-puntivendita{
	background-color: $concrete;
	padding-top: 10px;
	padding-bottom: 20px;
	.uk-container{
		flex-direction: column;
		.description{
			@include media-breakpoint-down(md){
				justify-content: center;
			}
		}
		.img-info-button{
			@include media-breakpoint-down(md){
				margin-top: 15px;
			}
		}
	}
}