.bat-paymentmethod-gloit{
    width: 75%;
    margin: auto 70px;

    @include media-breakpoint-down(md){
        width: auto;
        margin: auto 20px;
    }

    &-title{
        padding-bottom: 40px;

        @include media-breakpoint-down(md){
            padding-bottom: 30px;
        }

        .title{
            @include font-rem(30px, 34px);
            text-transform: uppercase;
            margin-bottom: 30px;
    
            @include media-breakpoint-down(md){
                text-align: center;
                @include font-rem(24px, 28px);
                margin-bottom: 20px;
            }
        }

        .subtitle{
            @include font-rem(20px, 20px);
            font-weight: 500;
            margin-bottom: 30px;
    
            @include media-breakpoint-down(md){
                text-align: center;
                @include font-rem(18px, 24px);
                margin-bottom: 20px;
            }
        }

        .addPayment{
            font-size: 13px;
            color: white;
            width: 250px;

            @include media-breakpoint-down(md){
                max-width: 100%;
                width: 100%;
            }
        }
    }

    &-saved-payment-methods{
        padding-top: 40px;
        border-top: 2px solid #DEDEDE;

        @include media-breakpoint-down(md){
            padding-bottom: 30px;
        }

        .card{
            padding: 20px;
            border: 2px solid #DEDEDE;
            margin-bottom: 10px;
        
            @include media-breakpoint-down(md){
                flex-direction: column;
            }

            .card-information,
            .card-actions{
                width: 50%;

                @include media-breakpoint-down(md){
                    width: 100%;
                }
            }

            .card-information{
                justify-content: space-between;
                align-items: center;

                .card-info{
                    .card-ending{
                        @include font-rem(14px, 20px);
                        padding-left: 5px;
                    }
                }

                .card-expire-date{
                    padding-top: 5px;
                    width: 100px;
                    
                    @include media-breakpoint-down(md){
                        width: auto;
                        padding-top: 0;
                    }

                    .card-expired,
                    .card-date{
                        vertical-align: text-top;
                        @include font-rem(14px, 20px);
                    }

                    .card-expired{
                        color: red;
                    }
                }
            }

            .card-actions{
                text-align: end;

                @include media-breakpoint-down(md){
                    display: flex;
                    justify-content: space-between;
                    margin-top: 30px;
                }

                .edit-action,
                .remove-action{
                    width: 150px;

                    @include media-breakpoint-down(md){
                        width: 49%;
                        max-width: 49%;
                    }
                }

                .edit-action{
                    font-size: 13px;
                    color: white;
                }

                .remove-action{
                    &:hover{
                        color: $brand-black;
                    }
                }
            }
        }
    }
}