// For AEM
.expander {
	bat-expander-default {
		.bat-expander {
			border-top: solid 1px $light-gray;
		}
	}

	~ .expander {
		bat-expander-default {
			.bat-expander {
				border-top: 0;
			}
		}
	}
}

bat-expander-default {
	.bat-expander {
		border-top: solid 1px $light-gray;
	}

	~ bat-expander-default {
		.bat-expander {
			border-top: 0;
		}
	}
}

.bat-expander {
	border-bottom: 1px solid $light-gray;
	margin-top: -2px;

	@keyframes expanderOut {
		0% {
			height: auto;
			opacity: 1;
			transform: scale(1);
		}
		100% {
			opacity: 0;
		}
	}

	@keyframes expanderIn {
		0% {
			opacity: 0;
			transform-origin: 50% 0;
		}
		100% {
			height: auto;
			opacity: 1;
		}
	}

	&-button {
		align-items: center;
		border: 0;
		outline: none;
		padding: 20px 0;
		text-align: left;

		&-label {
			@include font-rem(15px, 15px);
			color: $dark-blue;
			font-family: $primary-font;
			font-weight: 500;
		}

		.chat & {
			padding: 14px 0;

			&:before {
				color: $dark-blue;
				content: "\e91e";
				font-family: "icomoon";
				font-size: 24px;
			}
		}

		.phone & {
			padding: 14px 0;

			&:before {
				color: $dark-blue;
				content: "\e957";
				font-family: "icomoon";
				font-size: 24px;
			}
		}

		.email & {
			&:before {
				color: $dark-blue;
				content: "\f0e0";
				font-family: "Font Awesome 5 Free";
				font-size: 14px;
				padding: 0 4px;
			}
		}

		&-icon {
			&-close,
			&-open {
				i {
					height: 14px;
					padding: 0 8px;
					width: 22px;
				}
			}

			&-close {
				i {
					&::before {
						color: $dark-blue;
						content: "\e959";
						font-family: "icomoon";
						font-weight: 400;
						@include font-rem(20px, 20px);
					}
				}
			}

			&-open {
				i {
					&::before {
						color: $dark-blue;
						content: "\e94d";
						font-family: "icomoon";
						font-weight: 400;
						@include font-rem(20px, 20px);
					}
				}
			}
		}
	}

	&-content {
		animation: expanderOut 0.5s ease-in-out 1 alternate both;

		.authorEditMode & {
			// sass-lint:disable-line class-name-format
			animation: none;
		}

		.active & {
			animation: expanderIn 0.5s ease-in-out 1 normal both;
			border-bottom: 0;
		}

		& > div {
			padding: 0 0 20px;

			.below & {
				padding: 20px 0 0;
			}
		}
	}
}

bat-expander-gloitinsidersaccount {
	.bat-expander {
		border-bottom: unset;
		width: 87%;
	}
}
