.gloit-micro-events{
	width: 100%;
    margin-top: 5%;
	h3{
		font-size:24px;
	}
	.gloit-micro-events-container{
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		padding: 5%;
		gap: 20px;

		.gloit-micro-event{
			&-container{
				border: 2px;
				border-radius: 15px;
				border-color: #aaa;
				border-style: solid;
				display: flex;
				flex-direction: column;
				height: auto;
				padding-bottom: 4%;
				.bat-dettaglio-microeventi-cta-alreadyregistered-container{
					.gloit-eventi-prenotati-button{
						margin-top: 0%;
						display: flex;
						justify-content: center;
						button{
							height: 45px;
							padding: 0px;
							width: 100%;
						}
					}
				}
			}
			&-details-container{
				border: 2px;
				border-radius: 15px;
				display: flex;
				flex-direction: row;
				min-height: 130px;
			}
			&-date{
				width: 20%;
				margin: 5% 5% 0 5%;
				border: 2px;
				border-radius: 6px;
				border-color: #EFEFEF;
				border-style: solid;
				height: 50%;
				&-day-container{
					text-align: center;
					height: 70%;
					background-color: #EFEFEF;
					border-radius: 6px 6px 0 0;
				}
			}
			&-day{
				font-size: 26px;
				font-weight: 700;
				padding-top: 20%;
			}
			&-month{
				font-weight: 600;
				font-size: 18px;
			}
			&-year{
				text-align: center;
			}
			&-details{
				margin: 5% 5% 5% 0;
				width: 80%;
				display: flex;
				flex-direction: column;
				&-name{
					font-weight: 600;
					font-size: 20px;
					margin-bottom: 5%;
				}
			}
			&-place-container{
				display: flex;
				flex-direction: row;
			}
			&-image-container{
				margin-right: 2%;
			}
		}
	}
	
	.gloit-micro-event-waitinglist-text{
		margin: 3% 0 0 3%;
		font-weight: 600;
	}
	
}

.gloit-micro-event-place-container span{
     text-decoration: underline;
     text-decoration-color: #6B6B6B;
     color: #6B6B6B;
	 width: 100%;
}
.gloit-micro-event-cta span{
     font-size: 14px;
}
