.neo-sticks-page{
    .glo-it-veo .veo-card-container-image picture{
        @include media-breakpoint-down(sm) {
            width: 22%;
        }
    }
}

.glo-it-veo{
    .veo-card-container{
        color: white;
         display: flex; 
         flex-direction: column;
        align-items: center;

        &-image{
            align-content: center;
            width: 100%;
            display: flex;
            justify-content: center;
            height: 100px;
            align-items: center;

             picture{
                align-content: center; 
                width:30%;
                @include media-breakpoint-down(sm) {
                    width: 35%;
                }
             }
        }

        &-description{
            width: 85%;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        h2{
            color: #fff;
            font-size: 20px;
            line-height: 28px;
            @include media-breakpoint-down(sm) {
                margin-top:0px;
            }
        }
    }
}