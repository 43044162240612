.bat-reviewslist-gloit{
    background-color: $brand-black;

    &-container{
        @include media-breakpoint-up(sm){
            .bat-modal{
                &.active{
                    display: contents;
                } 
                
                .bat-modal-content{
                    width: inherit;
                    background-color: $charcoal;
                    top: 0px;

                    i::before{
                        color: $white;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm){
            display: block;
            padding: 30px 20px;

            .add-review-cta{
                display: block;
                max-width: fit-content;
                margin: auto;
                padding: 17px 100px;
            }

            .bat-message{
                display: none;

                &.show{
                    display: block;
                    background-color: $red;
                    margin: 20px 0px;
                    
                    .md{
                        color: $white;
                        font-weight: 600;
                        a {
                            color: $white;
                            font-weight: 600;
                        }
                    }
                }    
            }
        }

        .container-survey{
            display: flex;
            flex-direction: column;
            width: 30%;
            @include media-breakpoint-down(md){
                width: 40%;
            }

            @include media-breakpoint-down(sm){
                width: 60%;
            }

            .bat-message{

                @include media-breakpoint-up(sm){
                    padding: 20px 40px;
                }

                @include media-breakpoint-down(sm){
                    width: auto;
                    text-align: center;
                    margin-bottom: 40px;
                }

                display: none;
                
                &.show{
                    display: block;
                    background-color: $red;
                    margin-top: 0;
                    margin-right: 40px;
                    margin-left: 60px;
                    margin-bottom: 55px;
                    @include media-breakpoint-down(sm){
                        margin-right: 0px;
                        margin-top: 45px;
                        margin-bottom: 20px;
                    }

                    &::after {
                        content: '';
                    }
    
                    .md{
                        color: $white;
                        @include font-rem(12px, 18px);
                        font-weight: 600;
                        a {
                            color: $white;
                            @include font-rem(12px, 18px);
                            font-weight: 600;
                            text-decoration: underline;
                        }
                    }
                }  
            }
            .bat-reviewslist-gloit-coin{

                @include media-breakpoint-down(sm){
                    position: relative;
                    margin-top: 25px;
                    margin-bottom: 30px;
                    margin-left: 0px;
                }
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 8px;
                gap: 8px;

                position: absolute;
                width: 87px;
                height: 30px;
                margin-left: 60px;
                margin-top: 25px;

                /* Primary/Light blue */

                background: #D5DDEC;
                border-radius: 40px;
                .number-of-coin{
                    width: 71px;
                    height: 14px;

                    /* Desktop/Heading 5 */

                    //font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 14px;
                    /* identical to box height, or 117% */

                    letter-spacing: 1.2px;
                    text-transform: uppercase;

                    /* Loyalty/Dark loyalty blue */

                    color: #0B1047;


                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;

                
                }
            }

            .bat-reviewslist-gloit-container-title{
    
                @include media-breakpoint-up(sm){
                    margin-top: 20px;
                    padding: 60px 60px;
                    padding-right: 0px;
                }
               
    
                @include media-breakpoint-down(sm){
                    width: auto;
                    text-align: left;
                    margin-bottom: 40px;
                }
    
                .title{
                    color: $white;
                    @include font-rem(30px, 34px);
                    font-family: $primary-font;
                    font-weight: 800;
                    margin-bottom: 10px;
                }
    
                .reviewsNumber{
                    color: $white;
                    @include font-rem(12px, 14px);
                    font-family: $primary-font;
                    font-weight: 500; 
                    margin-bottom: 40px;
                    letter-spacing: 1px;
                }
    
                .add-review-cta{
                    display: block;
                    margin-top: 40px;
                    padding: 17px 50px;
    
                    @include media-breakpoint-down(sm){
                        max-width: fit-content;
                        margin: auto;
                        padding: 17px 100px;
                    }
                }
    
            }
            .container-coin{
                width: 270px;
                display: flex;
                flex-direction: column;
                .image-banner{
                    align-self: center;
                    margin-top: -25px;
                    margin-left: -165px;
                    position: relative;
                    min-width: 60px;
                    top: -145px;
                }
        
                .coin{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-right: -37px;
                    padding: 32px 16px 16px 32px;
                    gap: 8px;
                    border-radius: 0px 8px 8px 0px;
                    text-align: center;

                
                    //position: absolute;
                    //width: 62px;
                    //height: 280px;
                    left: 0px;
                    //top: 1321px;
                    
                    /* Gradient/Secondary */
                    
                    background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
        
                    .text-survey{
                        height: 15px;
        
                        //font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 15px;
                        /* identical to box height, or 107% */
        
                        text-transform: uppercase;
        
                        color: #FFFFFF;
        
        
                        /* Inside auto layout */
        
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }
        
                    .second-text-survey{
                        //height: 15px;
        
                        //font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 107% */
        
        
                        color: #FFFFFF;
        
        
                        /* Inside auto layout */
        
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }    

                    .sign-up-insiders-club{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        //padding: 10px 32px;

                        width: 265px;
                        height: 37px;

                        /* Neutral/White */

                        background: #FFFFFF;
                        border-radius: 24px;

                        /* Inside auto layout */

                        margin-top: 8px;
                        flex: none;
                        order: 1;
                        align-self: stretch;
                        flex-grow: 0;
                        z-index: 1;
                        
                    }

                    .sign-up-insiders-club-text{
                        width: 260px;
                        height: 40px;

                        /* Button/CTA */

                        //font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 17px;
                        /* identical to box height, or 120% */

                        text-align: center;
                        letter-spacing: 0.7px;
                        text-transform: uppercase;

                        /* Neutral/Brand black */

                        color: #2A2C2C;


                        /* Inside auto layout */

                        flex: none;
                        //order: 0;
                        flex-grow: 0;
                    }
                }
            }
        }

        &-reviews{
            @include media-breakpoint-up(sm){
                padding: 60px 60px;
                padding-left: 0px;
            }

            &.hidden{
                display: none;
            }

            width: 50%;
            @include media-breakpoint-down(sm){
                width: auto;
            }

            .review-container{
                &.hidden{
                    display: none;
                }

                &-upper {
                    margin-bottom: 10px;
                }

                &-separator {
                    height: 1px;
                    width: 100%;
                    background: #6B6B6B;
                    margin: 27px 0;
                }
            }

            .review-by{
                @include font-rem(13px, 20px);
                font-family: $primary-font;
                color: $white;
                margin-left: 0;

                img {
                    height: 18px;
                    margin-top: -3px;
                }
            }

            .title{
                @include font-rem(20px, 24px);
                font-family: $primary-font;
                color: $white;
                font-weight: 800;
                margin-top: 0;
                margin-left: 20px;
            }

            .text{
                @include font-rem(14px, 20px);
                font-family: $primary-font;
                color: $white;
                margin-top: 10px;
                margin-bottom: 0;
            }

            .page-selector{
                color: $white;

                .pages{
                    list-style: none;
                    padding-right: 16px;
                }

                .page{   
                    padding: 4px 11px;
                    background: orangered;
                    border-radius: 100%;
                    font-size: 14px;
                    width: 27px;
                    height: 25px;
                }
            }

            .bat-pagination {
                margin-top: 50px;
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @include media-breakpoint-down(sm){
                    margin-bottom: 50px;
                }
            
                &-label {
                    @include font-rem(14px, 14px);
                    color: $brand-black;
                    margin: 10px 0;
                }
                
                &-buttons {
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                }
                
                &-button-wrapper{
                
                    button {
                        @include font-rem(14px, 14px);
                        border-radius: 52px;
                        color: $white;
                        height: 30px;
                        transition: color .1s ease-in;
                        width: 30px;
                        
                        &.active {
                            background-color: $orange;
                            color: $white;
                        }
                        
                        &:hover {
                            color: $white;
                            
                            i {
                                
                                &:before {
                                    color: $white;
                                }
                            }
                        }
                        
                        i {
                            height: 30px;
                            width: 30px;
                            
                            &:before {
                                color: $white;
                                font-size: 20px;
                                transition: color .1s ease-in;
                            }
                        }
                    }
                }

                .button-pages-container{
                    display: flex;

                    .bat-pagination-button-wrapper-page{
                        button {
                            @include font-rem(14px, 14px);
                            border-radius: 52px;
                            color: $white;
                            height: 30px;
                            transition: color .1s ease-in;
                            width: 30px;
                            margin-left: 5px;
                            margin-right: 5px;
                            font-family: $primary-font;
                            
                            &.active {
                                background-color: $orange;
                                color: $white;
                            }
                            
                            &:hover {
                                color: $white;
                                
                                i {
                                    
                                    &:before {
                                        color: $white;
                                    }
                                }
                            }
                            
                            i {
                                height: 30px;
                                width: 30px;
                                
                                &:before {
                                    color: $white;
                                    font-size: 20px;
                                    transition: color .1s ease-in;
                                }
                            }
                        }

                        &.hidden{
                            display: none;
                        }
                    }

                    .more-pages{
                        font-family: $primary-font;
                        color: $white;
                        font-size: 16px;
                        letter-spacing: 3px;
                        margin-left: 10px;

                        &.hidden{
                            display: none;
                        }
                    }
                }

                &.hidden{
                    display: none;
                }
            }

            .noReviewMessage{
                @include font-rem(20px, 20px);
                font-family: $primary-font;
                color: $white;
            }
        }
    }
    
    .rating-white {
        display: inline-block;
        unicode-bidi: bidi-override;
        color: #FFCA43;
        font-size: 13px;
        letter-spacing: 5px;
        height: 23px;
        width: auto;
        margin: 0;
        position: relative;
        padding: 0;
        font-family: FontAwesome;
    }
      
    .rating-upper {
        color: #FFCA43;
        padding: 0;
        position: absolute;
        z-index: 1;
        display: flex;
        top: 0;
        left: 0;
        overflow: hidden;
        &::before {
            content: "\f005 \f005 \f005 \f005 \f005";
        }
    }
      
    .rating-lower {
        padding: 0;
        display: flex;
        z-index: 0;
        &::before {
            content: "\f006 \f006 \f006 \f006 \f006";
        }
    }
    
}