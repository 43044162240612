.bat-carousel-card-gloit {
	.slides-container{
		padding: 20px;
		@include media-breakpoint-up(md) {
			padding:60px;
		}

		.title-section{
			@include media-breakpoint-down(sm){
				display: block;
				text-align: center;
				padding-top: 30px;
			}
			padding-bottom: 30px;
			.product-section-title{
				font-weight: 800;
				font-size: 26px;
				line-height: 30px;
			}
			.product-section-link{
				span{
					margin-right: 10px;
				}
				
				font-weight: 800;
				font-size: 16px;
				line-height: 28px;
			}
		}
	}
	
	max-width: 100%;
	margin-bottom: 50px;
	@include media-breakpoint-down(sm){
		margin-bottom: 0px;
	}

		
	.slick-dots {
		display: flex;
		justify-content: center;
		margin: 0 auto;
	}

	.navigation-arrows {
		position: absolute;
    	width: calc(100% - 42px);
		@include media-breakpoint-up(md) {
			width: calc(100% - 122px);
		}
    	margin-top: 5px;

		
		.slick-next, .slick-prev {
			.bat-icon:before {
				font-size: 18px !important;
			}
		}
	}


	bat-productcard-gloithome {
		padding-left: 17px;
		padding-right: 17px;
	}

	bat-productcard-gloiblackfriday {
		padding-left: 17px;
		padding-right: 17px;
	}
}

.bat-carousel .slick-next, .bat-carousel .slick-prev {
	display: block;
}