bat-section-default.upper-continue-core {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: -20%;
		left: 0;
		right: 0;
		height: 20%;
		background: rgba(229, 225, 230, 0.6);
		z-index: 0;
	}
}

bat-form-gloitconfrontodevices {
	&.pdp-hyper-core {
		background: transparent;

		#comparison-table-container {
			background: transparent;
			color: black;

			.comparison-cta {
				border: 2px solid var(--glo-2024-glo-Charcoal, #2a2a2a) !important;

				div {
					color: var(--glo-2024-glo-Charcoal, #2a2a2a) !important;
				}
			}

			.comparison-title-mobile {
				.comparison-title-text {
					text-align: left;
					color: #2a2a2a;
				}

				.device-header {
					display: flex;
					justify-content: space-around;
					flex-grow: 1;
					text-align: center;
					align-items: center;

					#device-select {
						background: var(--glo-2024-glo-Charcoal, #2a2a2a);
						color: white;
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.64645 7.14645C2.84171 6.95118 3.15829 6.95118 3.35355 7.14645L10 13.7929L16.6464 7.14645C16.8417 6.95118 17.1583 6.95118 17.3536 7.14645C17.5488 7.34171 17.5488 7.65829 17.3536 7.85355L10.3536 14.8536C10.1583 15.0488 9.84171 15.0488 9.64645 14.8536L2.64645 7.85355C2.45118 7.65829 2.45118 7.34171 2.64645 7.14645Z' fill='white' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
						background-repeat: no-repeat;
						background-position: right 0.7rem top 50%;
						background-size: 20px 20px;
						option {
							color: white;
							text-align: left;
							font-size: 12px;
							font-family: Mont;
							font-weight: 700;
							line-height: 120%; /* 14.4px */
							letter-spacing: 0.7px;

							&:hover {
								background-color: #717171;
							}
						}
					}

					img {
						max-height: 135px;
						display: block;
						margin-bottom: 12.54px;
					}

					p {
						color: var(--glo-2024-glo-Charcoal, #fff);
						text-align: center;
						font-size: 12px;
						font-weight: 700;
						line-height: 120%; /* 14.4px */
						letter-spacing: 0.7px;

						&.pro {
							color: #2a2a2a;
						}
					}
				}
			}

			.comparison-title {
				.comparison-title-text {
					color: black;
				}
			}

			table {
				min-width: 100%;
				border-collapse: separate;
				border-spacing: 0 16px;
				margin-top: 10px;
				@media screen and (max-width: 780px) {
					display: flex;
				}

				th,
				td {
					padding: 40px 35px;
					background: var(--glo-2024-White, #fff);
					border: none;
					color: var(--glo-2024-glo-Charcoal, #2a2a2a);
					font-size: 20px;
					font-weight: 600;
					line-height: 120%;

					@media screen and (max-width: 780px) {
						padding: 0px;
						padding-left: 24px;
						background: var(--glo-2024-White, #fff);
						border: none;
						color: var(--glo-2024-glo-Charcoal, #2a2a2a);
						font-size: 20px;
						font-weight: 600;
						line-height: 120%;
					}

					&.data-text {
						color: var(--glo-2024-glo-Charcoal, #2a2a2a);
						font-size: 18px;
						font-weight: 500;
						line-height: 100%;
					}

					.category-elements {
						display: flex;
						align-items: flex-start;
						justify-content: space-between;
						gap: 36px;

						p.data-text {
							font-weight: 500;
							font-size: 18px;
							line-height: 120%;
							min-width: 120px;
						}
					}
				}

				/* Bordo arrotondato per la prima e l'ultima colonna */
				td {
					&:first-child {
						border-top-left-radius: 24px;
						border-bottom-left-radius: 24px;
					}

					&:last-child {
						border-top-right-radius: 24px;
						border-bottom-right-radius: 24px;
					}
				}

				tbody {
					@media screen and (max-width: 780px) {
						display: flex;
						width: 100% !important;
						flex-direction: column;
					}
					tr {
						background-color: var(
							--glo-2024-White,
							#fff
						); // Stesso colore per tutte le righe
						@media screen and (max-width: 780px) {
							border-radius: 24px;
						}
						& + tr {
							margin-top: 10px; // Spazio tra le righe
						}
					}
				}

				/* Dimensioni */
				.dimension-title {
					text-align: left;
					p {
						color: black;
						font-feature-settings: "liga" off, "clig" off;
						font-size: 20px;
						font-weight: 600;
						line-height: 120%; /* 24px */
						margin-bottom: 8px;

						&.light-gray {
							color: var(--glo-2024-glo-Charcoal, #2a2a2a);
							font-size: 16px;
							font-weight: 500;
							line-height: 120%; /* 19.2px */
							opacity: 0.6;
						}
					}
				}
				/* Pallini dei colori */
				.color-palette {
					.color-circle {
						border: none;
					}
				}

				/* Stile per i dati di Peso e Misure */
				.dimension-data {
					p {
						color: var(--glo-2024-glo-Charcoal, #2a2a2a);
						font-size: 18px;
						font-weight: 500;
						line-height: 120%; /* 21.6px */
						margin: 5px 0; // Spaziatura tra Peso e Misure
					}
				}
			}
		}
	}
  &.confronto-black-friday{
    background-color: #232321;
    .comparison-cta{
      display: none;
    }
    #comparison-table-container {
      background-color: #232321;
	  max-width: 1257px;
      table {
    
        th, td, tr {
          background-color: #2A2A2A;
        }
    }
  }
}

	background-color: black;

  #comparison-table-container {
    margin-inline: auto;
    width: 100%;
    background-color: black;
    border-radius: 30px;
    padding: 20px;
    color: #fff;
    font-family: Mont;
    font-style: normal;
  
    /* Visualizzazione a tabella per schermi grandi */
    .comparison-title {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 20px;
      margin-left: 3%;
  
      .comparison-title-text {
        text-align: left;
        flex-basis: 40%;
        color: var(--glo-2024-White, #FFF);
        font-size: 30px;
        font-weight: 700;
        line-height: 140%; /* 42px */
      }

			.device-header {
				display: flex;
				justify-content: space-between;
				flex-grow: 1;
				padding-right: 17%;

				@media screen and (max-width: 1300px) {
					gap: 33%;
				}

				div {
					display: flex;
					flex-direction: column;
					align-items: center;
					text-align: center;
					img {
						max-height: 201px;
						display: block;
						width: 100%;
						height: auto;
					}

					p {
						margin-top: 10px;
						font-weight: bold;
						font-size: 16px;
						text-align: left;
					}
				}
			}
		}

		.comparison-title-mobile {
			display: block;
			.comparison-title-text {
				text-align: left;
				color: var(--glo-2024-White, #fff);
				font-size: 28px;
				font-weight: 700;
				line-height: 120%;
				margin-bottom: 22px;
			}
			.device-header {
				display: flex;
				justify-content: space-around;
				flex-grow: 1;
				text-align: center;
				align-items: center;

				#device-select {
					border-radius: 100px;
					min-width: 156px;
					background: var(--glo-2024-White, #fff);
					padding: 12px 10px 12px 16px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: none;
					color: var(--glo-2024-glo-Charcoal, #2a2a2a);
					font-family: Mont;
					text-align: center;
					font-size: 12px;
					font-weight: 800;
					line-height: 120%; /* 14.4px */
					letter-spacing: 0.6px;
					appearance: none;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.64645 7.14645C2.84171 6.95118 3.15829 6.95118 3.35355 7.14645L10 13.7929L16.6464 7.14645C16.8417 6.95118 17.1583 6.95118 17.3536 7.14645C17.5488 7.34171 17.5488 7.65829 17.3536 7.85355L10.3536 14.8536C10.1583 15.0488 9.84171 15.0488 9.64645 14.8536L2.64645 7.85355C2.45118 7.65829 2.45118 7.34171 2.64645 7.14645Z' fill='%232A2A2A' stroke='%232A2A2A' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
					background-repeat: no-repeat;
					background-position: right 0.7rem top 50%;
					background-size: 20px 20px;

					option {
						color: var(--glo-2024-glo-Charcoal, #2a2a2a);
						text-align: left;
						font-size: 12px;
						font-family: Mont;
						font-weight: 700;
						line-height: 120%; /* 14.4px */
						letter-spacing: 0.7px;

						&:hover {
							background-color: #717171;
						}
					}
				}

				img {
					max-height: 135px;
					display: block;
					margin-bottom: 12.54px;
				}

				p {
					color: var(--glo-2024-glo-Charcoal, #fff);
					text-align: center;
					font-size: 12px;
					font-weight: 700;
					line-height: 120%; /* 14.4px */
					letter-spacing: 0.7px;

					&.pro {
						color: var(--glo-2024-White, #fff);
						font-size: 16px;
						font-weight: 600;
						line-height: 120%; /* 19.2px */
						padding: 12px 10px 12px 0px;
						margin-bottom: 0px;
					}
				}
			}
		}

		/* Stile per la tabella */
		table {
			min-width: 100%;
			border-collapse: separate;
			border-spacing: 0 16px;
			margin-top: 10px;
			@media screen and (max-width: 780px) {
				display: flex;
			}

			th,
			td {
				padding: 40px 35px;
				background-color: #2d2d2d;
				border: none;
				color: var(--glo-2024-White, #fff);
				font-size: 20px;
				font-weight: 600;
				line-height: 120%;

				@media screen and (max-width: 780px) {
					padding: 0px;
					padding-left: 24px;
					background-color: #2d2d2d;
					border: none;
					color: var(--glo-2024-White, #fff);
					font-size: 20px;
					font-weight: 600;
					line-height: 120%;
				}

				&.data-text {
					color: var(--glo-2024-White, #fff);
					font-size: 18px;
					font-weight: 500;
					line-height: 100%;
				}

				.category-elements {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					gap: 36px;

					p.data-text {
						font-weight: 500;
						font-size: 18px;
						line-height: 120%;
						min-width: 120px;
					}
				}
			}

			/* Bordo arrotondato per la prima e l'ultima colonna */
			td {
				&:first-child {
					border-top-left-radius: 24px;
					border-bottom-left-radius: 24px;
				}

				&:last-child {
					border-top-right-radius: 24px;
					border-bottom-right-radius: 24px;
				}
			}

			tbody {
				@media screen and (max-width: 780px) {
					display: flex;
					width: 100% !important;
					flex-direction: column;
				}
				tr {
					background-color: #2d2d2d; // Stesso colore per tutte le righe
					@media screen and (max-width: 780px) {
						border-radius: 24px;
					}
					& + tr {
						margin-top: 10px; // Spazio tra le righe
					}
				}
			}

			/* Dimensioni */
			.dimension-title {
				text-align: left;
				p {
					color: var(--glo-2024-White, #fff);
					font-feature-settings: "liga" off, "clig" off;
					font-size: 20px;
					font-weight: 600;
					line-height: 120%; /* 24px */
					margin-bottom: 8px;

					&.light-gray {
						color: var(--glo-2024-Warm-Grey, #e5e1e6);
						font-size: 16px;
						font-weight: 500;
						line-height: 120%; /* 19.2px */
						opacity: 0.6;
					}
				}
			}

			/* Stile per i dati di Peso e Misure */
			.dimension-data {
				p {
					color: var(--glo-2024-White, #fff);
					font-size: 18px;
					font-weight: 500;
					line-height: 120%; /* 21.6px */
					margin: 5px 0; // Spaziatura tra Peso e Misure

					&.space {
						min-height: 11.59px;
					}
					&.space-heat {
						min-height: 11.59px;
						@media screen and (max-width: 1129px) {
							min-height: 34.59px;
						}
					}
				}
			}

			/* Pallini dei colori */
			.color-palette {
				display: flex;
				justify-content: flex-start;
				width: fit-content;
				@media screen and (max-width: 920px) {
					flex-wrap: wrap;
				}
				.color-circle {
					width: 20px;
					height: 20px;
					border-radius: 50%;
					border: 2px solid black;
					margin: 0 5px;
					display: inline-block;
					@media screen and (max-width: 920px) {
						margin: 0 8px 8px 0px;
					}
				}
			}
		}
	}

	.comparison-cta {
		background: transparent;
		border: solid 2px #fff;
		min-height: 42px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-inline: 34px;
		border-radius: 100px;
		width: fit-content;
		margin-left: auto;
		@media screen and (max-width: 780px) {
			margin-top: 16px;
		}

		div {
			color: var(--glo-2024-White, #fff);
			font-family: Mont;
			font-size: 13px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	}
}

bat-cta-default.border-white {
	.bat-cta {
		.bat-cta-style.button-secondary-dark {
			border: solid 2px #fff;
			color: #fff;
		}
	}
}

bat-cta-default.border-black {
	.bat-cta {
		.bat-cta-style.button-secondary-dark {
			border: 2px solid var(--glo-2024-glo-Charcoal, #2a2a2a);
			color: #2a2a2a;
		}
		.bat-cta-style.button-secondary-dark:hover {
			border-color: rgba(42,42,42,0.6) !important; 
		}
		.bat-cta-style.button-secondary-dark:hover span {
			opacity: 0.8 !important;
			color: #2a2a2a !important;
		}
	}
}
