bat-carousel-gloitpopup{
	background-color: black;
	padding-top: 32px;
	.bat-carousel-popup-gloit {
		color: $white;
		padding-bottom: 81px;
		max-width: 1440px;
		.slick-track {
			gap: 14px;
		}

		@media (max-width: 850px) {
			padding-bottom: 20px;
		}
	
		.bat-carousel-slides {
			.slide {
				width: 265px !important;
				padding: 30px 20px 20px 30px;
				border-radius: 40px;
				height: 360px;
				position: relative;
				cursor: pointer;

				@media only screen and (max-width: 1380px) and (min-width: 1229px){
					width: 235px !important;
					height: 330px;
				}

				@media only screen and (max-width: 1228px) and (min-width: 1077px){
					width: 205px !important;
					height: 300px;
				}

				@media only screen and (max-width: 1076px) and (min-width: 1001px) {
					width: 185px !important;
					height: 280px;
				}

	
				&.one{
					background-image: url("/content/dam/glo-it/images/pdp-redesign-pro/ksp_10_1_5x.webp");
					background-size: cover;
					background-repeat: no-repeat;
				}
	
				&.two{
					background-image: url("/content/dam/glo-it/images/pdp-redesign-pro/ksp_8_1_5x.webp");
					background-size: cover;
					background-repeat: no-repeat;
				}
	
				&.three{
					background-image: url("/content/dam/glo-it/images/pdp-redesign-pro/ksp_7_1_5x.webp");
					background-size: cover;
					background-repeat: no-repeat;
				}
	
				&.four{
					background-image: url("/content/dam/glo-it/images/pdp-redesign-pro/spec_8_1_5x.webp");
					background-size: cover;
					background-repeat: no-repeat;
				}
	
				&.five{
					background-image: url("/content/dam/glo-it/images/pdp-redesign-pro/ksp_9_1_5x.webp");
					background-size: cover;
					background-repeat: no-repeat;
				}
				.slide-title {
					font-size: 12px;
					font-weight: 500;
					line-height: 20px;
					text-transform: uppercase;
					padding-bottom: 5px;
				}
	
				.slide-text {
					font-size: 20px;
					font-weight: 600;
					line-height: 24px;
				}
	
				.plus-icon{
					position: absolute;
					bottom: 8px;
					right: 13px;
					pointer-events: all;
					cursor: pointer; 
					z-index: 1000; 
					img{
						border-radius: 50%;
					}
				}
			}
		}
	}

	

@media (min-width: 850px) {
	.bat-carousel-popup-gloit {
		.slick-list {
			padding: 0 !important;

			.slick-track {
				width: 100% !important;
				margin: 0;
				display: flex;
				justify-content: center;

				&::before,
				&::after {
					display: none !important;
				}

				.slick-slide {
					width: fit-content !important;
				}
			}
		}

        .arrows-container {
            display: none;
        }
	}
}

@media (max-width: 850px) {
	.bat-carousel-popup-gloit {
		.slick-slide {
			margin-left: 12px;
		}
		.slide {
            margin-left: 12px;
		}

		.slick-dots {
			position: initial;
			height: fit-content !important;
			padding-top: 39px;
			overflow: visible !important;
			-webkit-slign-items: center;

			li {
				margin-top: 0 !important;
				padding-top: 0 !important;
			}

			button {
				overflow: hidden;
				background-color: #e5e1e6 !important;
				opacity: 0.6;
				border: 1px solid #e5e1e6 !important;
			}

			.slick-active {
				button {
					opacity: 1;
					width: 8px !important;
				}
			}
		}

		.arrows-container {
			position: absolute;
			right: 3%;
			bottom: 2%;

			button {
				position: relative !important;
				transform: none !important;
				margin-right: 18px;
			}
		}

		.navigation-arrows {
			position: relative;
		}
	}
}

#overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); 
    z-index: 998; 
    display: none; 
	opacity: 0;
    transition: opacity 0.3s ease; 
}

.overlay-visible {
    display: block !important;
	opacity: 1 !important;
    transition: opacity 0.3s ease;
}


#popup {
	z-index: 999;
    position: fixed;
	height: 42%;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 40px 40px 0px 0px;
	background: var(--glo-2024-glo-Charcoal, #2A2A2A);
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.55);		
    color: #e5e1e6; 
    padding: 0px 45px 0px 25px; 
    transform: translateY(100%);
    transition: transform 0.6s ease-in-out;
    display: none;
    max-width: 648px; 
    margin: 0 auto;
    font-family: Arial, sans-serif; 
	@include media-breakpoint-down(sm) {
		max-width: 355px; 
		height: 60%;
	}

    &.popup-visible {
        transform: translateY(0); 
        display: block !important; 
		z-index: 999;
    }


    &.popup-hidden {
        display: none;
    }

    .popup-content {
        position: relative; 
		margin-top: 56px;
		height: 90%;
		.title{
			color: var(--glo-2024-Warm-Grey, #E5E1E6);
			font-feature-settings: 'liga' off, 'clig' off;
			font-family: Mont;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 111.111% */
			text-transform: uppercase;
			opacity: 0.4;
			width: fit-content;
		}

		.subtitle{
			color: var(--glo-2024-White, #FFF);
			font-family: Mont;
			font-size: 28px;
			font-style: normal;
			font-weight: 600;
			line-height: 120%; /* 33.6px */
			margin-top: 13px;
		}

        p {
			color: var(--glo-2024-Warm-Grey, #E5E1E6);
			font-family: Mont;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 140%; /* 22.4px */
			opacity: 0.8;
            margin-top: 10px; 
			margin-top: 22px;

			&.small{
				color: var(--glo-2024-Warm-Grey, #E5E1E6);
				font-family: Mont;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 18.2px */
				opacity: 0.6;
				position: absolute;
				bottom: 50px;
			}
        }
    }

    #close-popup {
        background: transparent; 
        color: #ffffff; 
        border: none; 
        padding: 5px; 
        border-radius: 50%; 
        cursor: pointer; 
        position: absolute; 
        top: -34px; 
        right: -12px;
        font-size: 18px; 
        line-height: 1; 
		@include media-breakpoint-down(sm) {
			right: -26px;
		}

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
}



}
