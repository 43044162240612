bat-card-gloitinsiders {
	@include media-breakpoint-down(md) {
		width: 100vw;
		overflow: scroll;
	}
}

.iscrizione-container{
	padding-top: 30px;
	padding-bottom: 20px;
	margin-top:20px;
	max-width: 980px;
	background: url('/content/dam/glo-it/images/newloyalty/card-insiders-background.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 20%;	
    padding-left: 26px;
    padding-right: 26px;
	width: 750px;

	/*
	@include media-breakpoint-down(md){
		margin-top:0px;
		padding-left: 0;
		padding-right: 0;
		background: unset;
	}
	*/

	.list-container{
		
		.element-list{
			/*
			@include media-breakpoint-down(md){
				padding-top: 10px;
				padding-bottom: 10px;
				flex-direction: column;
			}
			*/
			.single-element{
				max-width: 250px;
				width: 33%;
				/*
				@include media-breakpoint-down(md){
					margin-top: 20px;
					max-width: 180px;
				}
				*/
			}
			.icona{
				max-width: 100px;
				height: 100px;
				margin-bottom: 10px;

				picture {
					height: 100%;
					img {
						height: 100%;
						object-fit: contain;
					}
				}
			}
			.titolo{
				display: flex;
				gap: 12px;
				justify-content: center;
				padding-left: 10px;
    			padding-right: 10px;
				
				.index-element {
					flex: none;
					display: flex;
					width: 24px;
					height: 24px;
					background: #D5DDEC;
					border-radius: 100%;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					line-height: 24px;
				}
				h4 {
					margin: 0;
				}
				margin-bottom: 10px;
			}
			.descrizione{
				font-size: 14px;
				padding-left: 10px;
    			padding-right: 10px;
			}
		}
	}
	.iscrizione-button{
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.accedi-container{
		margin-bottom: 20px;
		a{

			margin-top: -5px;
			margin-left: 5px;
			color: $orange;
		}
	}
}