.bat-carousel-blackfriday-gloit{
    border-radius: 30px;

    @include media-breakpoint-down(sm){
        height: auto;
    }

    h2{
        text-align: left;
        margin-top: 6%;
        margin-left: 5%;
        color: white;
        padding-bottom: 35px;
        font-family: Mont;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        text-transform: uppercase;
        @include media-breakpoint-down(sm){
            margin-top:10%;
        }
    }
    .arrows-container{
        position: absolute;
        display: flex;
        margin-top: -2%;
        left: 77%;
        .next-arrow{
            margin-left: 50px; 
            @include media-breakpoint-down(sm){
                margin-left: 20px; 
            }
            .icon-chevron-right{
                margin-left: 15px;
            }
            .bat-icon::before{
                color: white;
                content: url('/content/dam/glo-it/images/Glo-Arrow-right.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }  
        }
        .prev-arrow{
            .bat-icon::before{
                color: white;
                content: url('/content/dam/glo-it/images/Glo-Arrow-left.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }  
        }
        @include media-breakpoint-down(sm){
            left: 74%;
        }


        
    }

    @include media-breakpoint-down(sm) {
        .slick-track {
            margin-left: -33%;
        }
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list
    {
        transition-delay: 300ms;
    }

    .slick-dots{
        justify-content: flex-start;
        margin: 0 auto;
        display: flex;
        margin-left: 7%;
        overflow: visible;
    
        li{
            display: block !important;
            overflow: hidden;
            @include media-breakpoint-down(sm){
                margin-top: -10% !important;
            }
            button{
                background-color: #7e9c9f !important;
                border: 1px solid #7e9c9f!important;
                height: 6px;
                width: 6px;
            }
        }
        li.slick-active{
            button{
                width: 6px;
                height: 6px;
                background-color: #3A3A3A!important;
                border: 1px solid #3A3A3A !important;
            }
        }
        
    }

    
}


#black-friday-section{
    border-radius: 30px;
    padding: 40px;
    background-color: white;
    .slick-dots{
        margin-left: 1%;
    }
    .arrows-container{
        left: 94%;
        margin-top: 1%;

        .icon-arrow-right{
            align-items: flex-end;
        }
    }
    @include media-breakpoint-down(sm){
        background-color: white;
        margin-bottom: 300px;
        padding-bottom: 70px;
        padding: 0px;
        margin: 5%;
        height: 610px;
        .slick-dots{
            margin-left: 5%;
            li{
                margin-top: 0% !important;
            }   
        }
        .arrows-container{
            left: 77%;
            margin-top: 3%;  

            .icon-arrow-right{
                margin-left: 15px;
                margin-top: 1px;
            }
        }
    }
}

#second-black-friday-section{
    border-radius: 30px;
    padding: 40px;
    background-color: white;
    .slick-dots{
        margin-left: 1%;
    }
    .arrows-container{
        left: 94%;
        margin-top: 20px;
        .icon-arrow-right{
            align-items: flex-end;
        }
    }
    @include media-breakpoint-down(sm){
        background-color: white;
        margin-bottom: 300px;
        padding-bottom: 70px;
        padding: 0px;
        margin: 5%;
        height: 772px;
        .slick-dots{
            margin-left: 5%;
            li{
                margin-top: 0% !important;
            }   
        }
        .arrows-container{
            left: 77%;
            margin-top: 0%;     
        }
    }
}



