bat-carousel-gloitvertical{
    &.pdp-hyper-core{
        background-color: white;

        .bat-carousel-vertical {
            background-image: url("/content/dam/glo-it/images/hyper-core-pdp/hyper-come-si-usa-background.webp");

            
            @media screen and (max-width: 700px) {
                background-image: url("/content/dam/glo-it/images/hyper-core-pdp/glo-hyper-come-si-usa-background-mob.webp");
            }
        }
    }
    max-width: none;
    padding-top: 40px;
    padding-bottom: 78px;
    background-color: black;
    @media screen and (max-width: 700px) {
        padding-inline: 20px;
    }
    .bat-carousel-vertical {

        background-image: url("/content/dam/glo-it/images/pdp-redesign-pro/five-steps/glo-hyper-pro-come-si-usa-background-desk.webp");
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 40px;
        position: relative;
        max-width: 1400px;
        margin-inline: auto;
    
        @media screen and (max-width: 700px) {
            background-image: url("/content/dam/glo-it/images/pdp-redesign-pro/five-steps/glo-hyper-pro-come-si-usa-background-mob.webp");
        }

            .slickvertical-dots-container{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column-reverse;
                gap: 73.8px;
                position: absolute;
                bottom: 5%;
                right: 2%;

                @media screen and (max-width: 700px) {
                    flex-direction: row;
                    justify-content: space-between;
                    right: unset;
                    width: 100%;
                    padding-inline: 20px;
                }

                .buttons-container{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: fit-content;
                    justify-content: center;
                    gap: 28px;
                }
            }
        }

        .slick-slide {
            transition: opacity 0.5s ease-in-out !important;
            opacity: 0;
            max-height: 535px;

            .slick-current {
                z-index: 1;
            }
        }

        .slick-active {
            opacity: 1;
        }

        .bat-card {
            .bat-card--default {
                margin-right: 20px;
                max-width: none;
                
                @include media-breakpoint-up(sm) {
                    margin-right: 40px;
                }
            }
        }
        
        &-slides {
            opacity: 0;
            transition: opacity .5s ease-in;
            
            //AEM author mode 
            .authorEditMode & { // sass-lint:disable-line class-name-format
                opacity: 1;
            }
            
            &.loaded {
                opacity: 1;
            }
        }
        
        .slick-dots {
            width: fit-content;
            gap: 22px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 0px;

            @include media-breakpoint-down(sm) {
                gap: 0px;
                margin-left: 6px;
            }

            li{
                list-style: none;
                button{
                    font-size: 0;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: #ccc;
                    opacity: 0.4;
                    @include media-breakpoint-down(sm) {
                        width: 8px;
                        height: 8px;
                    }
                }

                &.slick-active button{
                    opacity: 1;
                }
            }
        }
        
 
        
        .slick-arrow {
            
            &.slick-prev {
                
                &.slick-hidden {
                    display: none;
                }
            }
            
            &.slick-next {
                
                &.slick-hidden {
                    display: none;
                }
            }
        }
        
    
        .slick-prev,
        .slick-next {
            z-index: 1;
            width: 44px;
            height: 44px;
            display: inline-block !important;
            .bat-icon {
                color: $dark-blue;
                width: 44px;
                height: 44px;
            }
        }
    }

.vertical-carousel-card-pro{
    display: flex !important;
    align-items: center;
    justify-content: center;
    max-height: 535px;

    @media screen and (max-width: 700px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding-inline: 12px;
        padding-top: 50px;
    }

    bat-image-default{
        margin-bottom: 0px !important;
        width: 411px;

        .slide-title-text-pro{
            @include media-breakpoint-down(sm) {
                font-size: 24px;
                font-style: normal;
                line-height: normal;
            }
        }

        .slide-description-text-pro{
            @include media-breakpoint-down(sm) {
                font-size: 16px;
                font-style: normal;
                line-height: 140%;
            }
        }

        .bat-image{
            position: relative;
            bottom: -140px;
            left: 80px;
            @include media-breakpoint-down(sm) {
                width: 60%;
                bottom: -100px;
                left: 40px;
            }
        }
    }
}

