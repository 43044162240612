.bat-engraving-gloit{
    padding-bottom: 120px;

    .invert-engraving-color & {
        .collection-selected,
        .collection-selected-x2,
        .frontText-selected,
        .backText-selected {
            filter: invert(100%);
        }
    } 

    &-container{
        @include media-breakpoint-down(lg){
            display: block;
            margin-left: 0px;
        }

        .collection-selected{
            display: none;
            &.active{
                display: block;
                width: 325px;
                height: 500px;
                position: absolute;
                top: 27%;
                picture{
                    max-width: initial !important;
                    padding: 0;
                    height: 100%;
    
                    @include media-breakpoint-down(sm){
                        width: 70% !important;
                        padding-left: 18px;
                        padding-top: 15px;
                    }

                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
                @include media-breakpoint-down(sm) {
                    width: 150px;
                    top: 15%;
                    height: 270px;
                }
            } 
            
            &.hidden{
                display: none;
            }
        }

        .collection-selected-x2{
            display: none;
            &.active{
                display: block;
                width: 100px;
                height: 255px;
                position: absolute;
                top: 42%;
                picture{
                    max-width: initial !important;
                    padding: 0;
                    height: 100%;
    
                    @include media-breakpoint-down(sm){
                        width: 70% !important;
                    }

                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
                @include media-breakpoint-down(sm) {
                    width: 95px;
                    top: 38%;
                    height: 125px;
                
                }
            } 
            
            &.hidden{
                display: none;
            }
        }

        .frontText-selected{
            display: none;

            &.active{
                display: block;
                position: absolute;
                top: 55%;
                font-size: 45px;

                @include media-breakpoint-down(sm){
                    top: 50%;
                    font-size: 25px;
                }
            }

            &.horizontal{
                transform: rotate(0deg);
            }
            &.vertical{
                transform: rotate(-90deg);
            }

            &.hidden{
                display: none;
            }
        }

        .backText-selected{
            display: none;

            &.active{
                display: block;
                position: absolute;
                padding-left: 30px;
                top: 46%;
                font-size: 45px;

                @include media-breakpoint-down(sm){
                    top: 45%;
                    font-size: 25px;
                }
            }

            &.horizontal{
                transform: rotate(0deg);
            }
            &.vertical{
                transform: rotate(-90deg);
            }

            &.hidden{
                display: none;
            }
        }
    }

    .product-image{
        background-color: #E5E5E5;
        padding-left: 0px;
        height: 980px;
        
        @include media-breakpoint-up(sm) {
            min-width: 640px;
        }

        @include media-breakpoint-down(sm) {
            height: 440px;
        }

        &-container{
            align-items: center;
            position: relative;
            height: 90%;
        }

        &-list {
            height: 100%;
            &-item {
                height: 100%;
                picture{ 
                    height: 100%;
                    width: 260px;
                    @include media-breakpoint-up(md) {
                        width: 585px;
                    }
                    img {
                        height: 100%;
                        object-fit: cover;
                        padding: 30px;

                        @include media-breakpoint-down(sm) {
                            padding: 0;
                        }
                    }
                }
            }
        }
       

        @include media-breakpoint-down(sm){
            width: 100%;
            padding-left: 0px;
        }

        &-prevArrow,
        &-nextArrow{
            border-radius: 100%;
            background-color: $brand-black;
            opacity: 0.3;

            i::before{
                font-size: 20px;
                color: $white;
            }

            @include media-breakpoint-down(sm){
                display: none;
            }

            &.hidden{
                display: none;
            }
        }

        .product-image-list{
            list-style: none;
            padding: 0px;

            @include media-breakpoint-down(sm){
                max-width: 100%;
            }
            
            &-item{
                display: none;

                &.active{
                    display: block;
                }
            }
        }

        picture{
            padding-top: 60px;
            margin: auto;

            @include media-breakpoint-down(sm){
                padding-top: 0px;
            }
        }

        &-buttons{
            max-width: fit-content;
            margin: auto;
            padding-bottom: 30px;

            .front-button,
            .back-button{
                padding: 13px 40px;
                background-color: $white;
                @include font-rem(12px, 14px);
                font-family: $primary-font;
                font-weight: 600;
                letter-spacing: 0.5px;
                color: #6B6B6B;

                &.selected{
                    background-color: $black;
                    color: $white;
                }
            }

            &.hidden{
                display: none;
            }
        }

        &-colors{
            max-width: fit-content;
            margin: auto;
            padding-bottom: 30px;
            text-align: center;

            .colors-container{
                margin-bottom: 10px;
                i::before{
                    font-size: 20px;
                }
            }

            .color{
                padding: 15px;
                border-radius: 100%;
                margin-left: 5px;
                margin-right: 5px;
            }

            .label{
                @include font-rem(14px, 34px);
                padding-top: 5px;

                @include media-breakpoint-down(sm){
                    display: none
                }
            }

            &.hidden{
                display: none;
            }
        }
        
    }

    .product-engraving{
        padding-left: 0px;

        @include media-breakpoint-down(sm){
            width: 100%;
            padding-left: 0px;
        }

        &-info{
            padding: 20px 100px 0px;
            align-items: center;

            @include media-breakpoint-down(sm){
                padding: 20px 20px;
            }

            &-name {
                max-width: 85%;
            }

            &-name,
            &-price{
                font-family: $primary-font;
                @include font-rem(20px, 34px);
                font-weight: 600;

                @include media-breakpoint-down(sm){
                    @include font-rem(18px, 27px);
                    width: 70%;
                }
            }

            &-price{
                margin-right: 10px;

                @include media-breakpoint-down(sm){
                    margin-right: 0px;
                }
            }

            .bat-icon{
                &::before{
                    font-size: 20px;

                    @include media-breakpoint-down(sm){
                        font-size: 16px;
                    }
                }

                @include media-breakpoint-down(sm){
                    padding-bottom: 4px;
                }
            }
        }

    }

    &-hyper-pro {
        max-width: $site-max-width;
        margin: 0 auto;
        padding-bottom: 0;

        .bat-tabs-gloit-list-item-button {
            cursor: default;
        }

        .bat-engraving-gloit-container {
            overflow: hidden;
        }

        .product-image {
            background-color: unset;
            height: 800px;
            position: relative;
            transition: transform ease-out 0.8s;
        
            @include media-breakpoint-up(sm) {
                min-width: 640px;
            }

            @include media-breakpoint-down(sm) {
                height: 440px;
            }

            &.started {
                .frontText-selected-buttons,
                .backText-selected-buttons,
                .product-image-colors,
                .product-image-colors-accordion {
                    display: none;
                }

                .frontText-selected-value,
                .backText-selected-value {
                    display: flex;
                }

                &.front-selected {
                    transform: scale(2) translate(0, 200px);

                    @include media-breakpoint-down(sm) {
                        transform: scale(2) translate(0, 105px);
                    }
                }

                &.back-selected {
                    transform: scale(1.7) translate(0, 115px);

                    @include media-breakpoint-down(sm) {
                        transform: scale(1.4) translate(0, 40px);
                    }
                }

                &.recap {
                    transform: scale(1.5) translate(0, 115px);

                    @include media-breakpoint-down(sm) {
                        transform: scale(1.3) translate(0, 35px);
                    }
                }
            }

            &-list {
                &-item {
                    justify-content: center;
                    overflow: hidden;

                    &.active {
                        display: flex !important;
                    }

                    &-video {
                        height: 100%;
                        margin-top: -70px;

                        @include media-breakpoint-down(sm) {
                            margin-top: -40px;
                        }
                    }
                }
            }

            &-colors {
                margin-top: -120px;
                z-index: 1;
                position: relative;
                padding: 0;
                
                &-box {
                    background-color: $white;
                    border-radius: 18px;
                    padding: 15px;
                }

                @include media-breakpoint-down(sm) {
                    transform: rotate(-90deg);
                    left: -30%;
                    bottom: 32%;

                    &-box {
                        padding: 10px 0;
                        overflow: hidden;
                        transition: all ease-out 0.4s;
                        width: 0;

                        &.opened {
                            width: 100%;
                            padding: 10px 10px;

                            .bat-producthero-configurator-configurable-options-variants-button {
                                transition: all ease-out 0.4s;
                                transform: translate(0, 0);
                            }
                        }

                        .bat-producthero-configurator-configurable-options-variants-button {
                            transition: all ease-out 0.7s;
                            transform: translate(calc(var(--n) * -45px), 0);
                        }
                    }

                }

                &-label {
                    display: block;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 1.2px;
                    margin-bottom: 8px;

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                &-accordion {
                    width: 34px;
                    height: 34px;
                    position: relative;
                    bottom: 26%;
                    left: -30%;
                    z-index: 0;
                    margin: auto;
                    display: none;
                    opacity: 1;
                    transition: opacity ease-out 0.4s;

                    @include media-breakpoint-down(sm) {
                        display: block;
                    }

                    &.opened {
                        opacity: 0;
                    }
                }

                .colors-container {
                    margin: 0;

                    .colors-list-container {
                        display: flex;
                        .bat-producthero-configurator-configurable-options-variants-button {
                            padding: 4px;
                            height: 40px;
                            width: 40px;

                            @include media-breakpoint-down(sm) {
                                margin-left: 2px;
                                margin-right: 2px;
                                height: 34px;
                                width: 34px;
                            }

                            &.active {
                                border: 1px solid $brand-black;
                            }

                            &.disabled {
                                pointer-events: none;

                                &::after {
                                    opacity: 0.6;
                                }
                            }

                            &::after {
                                content: "";
                                background-color: var(--button-variant-color);
                                display: block;
                                width: 100%;
                                height: 100%;
                                border-radius: 99999999px;
                            }
                        }
                    }

                    .visible-on-mobile {
                        display: none;
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;

                        &::before {
                            font-size: 30px;
                        }

                        @include media-breakpoint-down(sm) {
                            display: block;
                        }
                    }
                }
            }

            &-container {
                .frontText-selected {
                    position: absolute;
                    top: 15%;
                    display: block;

                    @include media-breakpoint-down(sm) {
                        top: 13%;
                    }
        
                    &-buttons {
                        gap: 10px;
                        position: relative;
                        left: 70%;
                        cursor: pointer;

                        @include media-breakpoint-down(sm) {
                            left: 55%;
                        }
        
                        &-plus {
                            background-color: white;
                            border-radius: 100%;
                            width: 28px;
                            height: 28px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        
                            .icon-plus::before {
                                font-size: 25px;
                                line-height: 55px;
                            }
                        }
        
                        &-text {
                            background-color: white;
                            border-radius: 24px;
                            font-size: 13px;
                            line-height: 20px;
                            font-weight: 600;
                            width: 110px;
                            height: 28px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                        }
                    }

                    &-value {
                        transform: rotate(2deg);
                        width: 110px;
                        height: 26px;
                        border-radius: 5px;
                        position: relative;
                        top: 12px;
                        right: 5px;
                        font-size: 16px;
                        font-weight: 400;
                        color: $white;
                        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
                        align-items: center;
                        justify-content: center;
                        display: none;

                        @include media-breakpoint-down(sm) {
                            width: 65px;
                            height: 15px;
                            font-size: 10px;
                        }

                        &.selected {     
                            border: 1px dashed white;
                        }

                        &.typing {
                            border: unset;
                        }
                    }
                }
        
                .backText-selected {
                    display: block;
                    position: absolute;

                    @include media-breakpoint-down(sm) {
                        top: 45%;
                    }
        
                    &-buttons {
                        gap: 10px;
                        position: relative;
                        left: 35%;
                        cursor: pointer;

                        @include media-breakpoint-down(sm) {
                            left: 37%
                        }
        
                        &-plus {
                            background-color: white;
                            border-radius: 100%;
                            width: 28px;
                            height: 28px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        
                            .icon-plus::before {
                                font-size: 25px;
                                line-height: 55px;
                            }
                        }
        
                        &-text {
                            background-color: white;
                            border-radius: 24px;
                            font-size: 13px;
                            line-height: 20px;
                            font-weight: 600;
                            width: 110px;
                            height: 28px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                        }
                    }

                    &-value {
                        transform: rotate(-91deg);
                        width: 290px;
                        height: 26px;
                        border-radius: 5px;
                        position: relative;
                        right: 65px;
                        bottom: 55px;
                        font-size: 16px;
                        font-weight: 400;
                        color: white;
                        align-items: center;
                        justify-content: center;
                        display: none;
                        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);

                        @include media-breakpoint-down(sm) {
                            width: 165px;
                            height: 15px;
                            right: 35px;
                            bottom: 15px;
                            font-size: 10px;
                        }

                        &.selected {     
                            border: 1px dashed white;
                        }

                        &.typing {
                            border: unset;
                        }
                    }
                }
            }
        }

        .product-engraving {
            display: flex;
            align-items: center;

            @include media-breakpoint-down(sm) {
                padding-bottom: 50px;
                padding-left: 10px;
                padding-right: 10px;
            }

            &-container {
                max-width: 600px;
                width: 100%;
                background-color: $white;
                border-radius: 24px;
                padding: 30px 35px;
                z-index: 1;

                @include media-breakpoint-down(lg) {
                    margin-top: -120px;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 20px;
                    margin-bottom: 10px;
                }

                @include media-breakpoint-down(sm) {
                    max-width: 100%;
                    margin-bottom: 0;
                }
            }

            &-info {
                padding: 0;
            }

            bat-tabs-gloit.bat {
                height: auto;
                min-height: unset;
                padding: 0;
            }

            .bat-sectionengraving-gloit {

                .back-cta {
                    color: $brand-black;
                }

                .front-section {
                    .front-title {
                        font-weight: 600;
                    }

                    .front-text {
                        font-weight: 400;
                        font-size: 13px;
                    }

                    &-engraving-select {
                        &-container {
                            flex-direction: column;
                            gap: 30px;
                            margin: 40px 0 0 5px;
                        }

                        &-cta {
                            gap: 10px;
                            cursor: pointer;
                            
                            &-circle {
                                width: 26px;
                                height: 26px;
                                border-radius: 100%;
                                background: linear-gradient(#CD4A04, #ED7D30);

                                .bat-icon {
                                    width: 26px;
                                    height: 26px;

                                    &::before {
                                        font-size: 25px;
                                        color: $white;
                                        line-height: 27px;
                                    }
                                }
                            }

                            &-text {
                                font-size: 13px;
                                font-weight: 600;
                                letter-spacing: 0.7px;
                            }
                        }

                        &-input {
                            display: none;

                            form {
                                position: relative;
                            }

                            .input-counter {
                                display: none;
                            }

                            .input-button-add {
                                font-size: 14px;
                                letter-spacing: 1px;
                                font-weight: 600;
                                position: absolute;
                                right: 10px;
                                top: 35px;
                                color: $brand-black;
                                display: none;

                                &:disabled {
                                    opacity: 0.6;
                                    pointer-events: none;
                                }
                            }

                            .input-button-remove {
                                font-size: 14px;
                                letter-spacing: 1px;
                                font-weight: 600;
                                position: absolute;
                                right: 10px;
                                top: 30px;
                                color: $brand-black;
                                display: none;
                            }

                            .error-msg {
                                gap: 5px;
                            }
                        }

                        &-top,
                        &-side {
                            &.opened {
                                .front-section-engraving-select-cta {
                                    display: none;
                                }

                                .front-section-engraving-select-input {
                                    display: block;
                                }
                            }

                            &.typing {
                                .input-standard-text {
                                    display: none;
                                }

                                .input-counter {
                                    display: block;
                                }

                                .input-button-add {
                                    display: block;
                                }
                            }

                            &.confirmed {
                                .frontText-input {
                                    background-color: $white;
                                    border: 2px solid #dedede;
                                }

                                .backText-input {
                                    background-color: $white;
                                    border: 2px solid #dedede;
                                }

                                .input-standard-text {
                                    display: none;
                                }

                                .input-counter {
                                    display: none;
                                }

                                .input-button-add {
                                    display: none;
                                }

                                .input-button-remove {
                                    display: flex;
                                }
                            }
                        }
                    }

                    .cta-container {
                        flex-wrap: wrap;

                        @include media-breakpoint-down(sm) {
                            flex-direction: column;
                            margin-left: 0;
                        }

                        .bat-cta-style {
                            width: 198px;
                            padding: 13px;
                        }

                        .returnCta {
                            border: solid 2px $brand-black;
                            margin-top: 10px;

                            @include media-breakpoint-down(sm) {
                                margin-bottom: 10px;
                            }

                            &:hover {
                                color: $brand-black;
                            }
                        }
                    }
                }

                .summary-section {

                    .summary-table-heading {
                        text-transform: none;
                    }

                    .cta-container {
                        flex-wrap: wrap;

                        @include media-breakpoint-down(sm) {
                            flex-direction: column;
                            margin-left: 0;
                        }
    
                        .bat-cta-style {
                            width: 218px;
                            padding: 13px;
                        }
    
                        .returnCta {
                            border: solid 2px $brand-black;
                            margin-top: 10px;

                            @include media-breakpoint-down(sm) {
                                margin-bottom: 10px;
                            }
    
                            &:hover {
                                color: $brand-black;
                            }
                        }
                    }

                    .data-cta {
                        gap: 10px;
                    }
                }
            }
        }
    }
}

bat-producthero-gloitproengraving {
    background-color: #79A59E;
}