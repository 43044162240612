.digital-card-modal-body {
    display: none; 
    position: fixed; 
    z-index: 1000000; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: hidden; 
    padding: 0px !important;

    .digital-card-modal-content {
        background: linear-gradient(45deg, #5482C2 0, #353174 100%);
        height: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        
        .digital-card-modal-close-button {
            background: #fff;
            top: 24px;
            left: 80%;
            height: 40px;
            width: 40px;
            padding: 5px;
            position: absolute;
            border-radius: 100%;
        }
        #gloit-card-title {
            padding-top: 25%;
            margin-bottom: 10%;
            text-align: center;
            font-size: 26px;
            font-weight: bold;
            color: #fff;
        }
        .gloit-digital-card{
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            picture{
                width: 90%;
                position: relative;
            }
            .gloit-img-container{
                position: absolute;
                right: 20%;
                top: 15%;
                color: #fff;
            }

            .gloit-usercardinfo{
            	position: absolute;
                bottom: 15%;
                left: 17%;
            	
                &-generalità {
                    margin-top: 20%;
                    font-size: 13px;
                    color: #fff;
                    font-weight: bold;
                }
                
                &-number-date-container{
                	display: flex;
					flex-direction: row;
					justify-content: space-between;
                    gap: 40%;
                }
                
                &-number {
                    color: #fff;
                    font-size: 13px;
                    }
                    
                &-date{
                    color: #fff;
                    font-size: 13px;
                    margin-right: 40%;
                }
            }
            
        }
        .gloit-bottom-buttons{
        	display: none !important;
            text-align: center;
            width: 90%;
            height: 30%;
            margin: 5%;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            
            &-share-button{
                border-radius: 100px;
                border: solid 2px #fff;
                color:#fff;
                &-text{
                    margin: 3%;
                }
                
                &-image{
                    margin-left: 2%;
                    margin-bottom: 2%;
                }
            }
            
            &-modal-close-button{
                margin-top: 5%;
            }   
        }

        #qrcode{
            height: 100%;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 45%;
                border: 15px solid white;
                border-radius: 12px;
            }
        }
    }
}