.glo-it-discoverglohitech {
	padding-top: 20px;
	padding-bottom: 50px;
	background: #F4F4F4;
    border-radius: 30px;

	.bat-hitech-gloit {
		&-discover {
			&-list {
				min-height: 520px !important;

				@include media-breakpoint-down(md) {
					min-height: 500px !important;
				}

				&-item {
					justify-content: flex-end;

					@include media-breakpoint-down(md) {
						flex-direction: column;
						justify-content: flex-start;
						position: relative;
					}

					&.first {

						@include media-breakpoint-down(md) {
							text-align: center;

							.instruction-image {
								img {
									margin-bottom: 20px;
									max-width: 100%;
								}

								.previous,
								.next {
									display: none;
								}
							}
						}

						.instructions {
							.title {
								@include media-breakpoint-down(md) {
									text-align: center;
								}

								&::after {
									display: none;
								}
							}
						}
					}

					&.last {
						.button {
							filter: invert(100%);
						}

						.buttons {
							.button-container {
								.button {
									filter: invert(0%);
								}
							}
						}
					}

					&.first,
					&.last {
						@include media-breakpoint-down(md) {
							.instructions {
								.button {
									width: 250px;
									display: block;
									margin-inline: auto;
								}
							}
						}
					}
					
					.instruction-image {
						

						@include media-breakpoint-down(md) {
							margin-inline: auto;
							display: flex;
							img {
								max-width: 100%;
							}
						}

						@include media-breakpoint-up(md) {
							margin-right: 100px;
							width: 474px;
							height: 567px;
						}

						.previous,
						.next {
							display: none;

							@include media-breakpoint-down(md) {
								display: contents;
								width: fit-content;

								svg {
									margin-top: 80px;
									width: 18px;

									polyline {
										stroke-width: 3;
										color: #ff8900;
									}
								}
							}
						}
					}

					.instructions {
						width: 600px;
						margin: auto;
						margin-inline: 0px;

						@include media-breakpoint-down(md) {
							width: 100%;
							margin: 0px;
							margin-inline: 0px;

							.instructions-container{
								margin-top: 30px;
							}
						}

						.title {
							@include font-rem(38px, 36px);
							font-weight: 800;
							margin: 0;
							text-transform: uppercase;
							width: 50%;


							@include media-breakpoint-down(md) {
								@include font-rem(24px, 24px);
								width: 100%;
							}
						}

						.buttons {

							@include media-breakpoint-down(md) {
								display: flex;
								margin: auto;
							}

							.button-container {
								@include media-breakpoint-down(md) {
									width: 50%;
								}

								.button {
									width: 150px;

									&.bat-button--dark {
										z-index: 1;
										pointer-events: none;

										@include media-breakpoint-down(md) {
											z-index: 0;
										}
									}

									&.bat-button--secondary--dark {
										z-index: 0;

										&:hover {
											color: $brand-black;
										}
									}

									&.boost {
										margin-inline: -50px
									}

									@include media-breakpoint-down(md) {
										display: block;
										width: 50%;

										&.boost {
											margin-left: 2px
										}
									}
								}
							}
						}

						.list {
							margin-top: 50px;
							list-style: none;
							padding-left: 0px;

							@include media-breakpoint-down(md) {
								margin-top: 30px;
							}

							.item {
								font-size: 14px;

								&::before {
									content: '';
									background-color: #FF8900;
									display: inline-block;
									height: 8px;
									border-radius: 50%;
									-moz-border-radius: 50%;
									-webkit-border-radius: 50%;
									width: 8px;
									margin-right: 10px;
									position: relative;
									top: -1px;
								}

								&.uk-active {
									display: block;
								}
							}
						}

						.disclaimer {
							display: flex;
							width: 50%;
							color: #2A2C2C;
							text-transform: uppercase;
							font-weight: 800;
							margin-top: 20px;
							line-height: 14px;

							img {
								margin-right: 5px;
							}

							@include media-breakpoint-down(md) {
								width: 100%;
							}
						}

						.button {
							margin-top: 35px;
							width: 210px;

							svg {
								display: none;
							}

							&.uk-slidenav:hover {
								color: $white;
							}

						}

						.step-buttons{
							margin-top: 38px;
							justify-content: space-between;
							margin-right: 15%;
							display: flex;
							align-items: center;
							.bat-cta-style{
								&.button-dark {
									height: 45px;
									width: 0px;
									font-size: 20px;
									color: white;
									border-radius: 100%;
									box-shadow: inset 0px 0px 0px 9px #F4F4F4;
									pointer-events: none;
									background: #F57F1E;
									border: 4px solid #F57F1E;
									
									@include media-breakpoint-down(md) {
										padding: 9px 32px;
										margin-right: 10px;
									}
								}
								&.button-dark-disabled{
									padding: 7px 32px;
									background: #D9D9D9;
									font-size: 20px;
									color: black;
									height: 45px;
									width: 0px;
									font-size: 17px;
									border-radius: 100%;
									pointer-events: all;
									cursor: pointer;
									
									@include media-breakpoint-down(md) {
										padding: 2px 26px;
										margin-right: 10px;
									}
									
								}
								
							}
							
						}

						.progress-bar {
							width: 180px;
							height: 4px;
							border-radius: 8px;
							border: 1px solid #ff8900;
							margin-top: 38px;

							&.progress-coral::-webkit-progress-value {
								background-color: #ff8900;
							}

							&.progress-coral::-moz-progress-bar {
								background-color: #ff8900;
							}

							&.progress-coral::-ms-fill {
								background-color: #ff8900;
							}

							@include media-breakpoint-down(md) {
								margin-inline: auto;
							}
						}
					}
				}
			}
		}
	}
}