bat-productcard-gloitblackfriday24{
    .bat-productcard-gloitblackfriday24{
        min-height: 447px;
        width: 100%;
        position: relative;
        border-radius: 40px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
        background: #FFF;
        padding: 20px;
        margin-top: unset;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include media-breakpoint-down(sm) {
            min-height: 447px;
        }
        .img-wrapper{
            picture{
                img{
                    height: 225px !important;
                    width: auto !important;
                }
            }
        }

        .button-cart-card{
            width: 100%;

            button.add-to-cart-cta{
                margin-top: 10px;
                background: #FE5000;
                border-radius: 100px;
                max-height: 42px;
                display: flex;
                justify-content: center;
                padding-inline: 34px;
                width: 100%;
                color: #000;
                text-align: center;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%; /* 15.6px */
                letter-spacing: 0.7px;
                text-transform: none !important;
                @include media-breakpoint-down(md) {
                    padding-inline: 20px;
                }
                &:hover{
                    opacity: 0.7;
                    span{
                        color: black;
                    }
                }
            }
            
            a{
                width: 100%;
                padding: 10px;
                justify-content: center !important;
            }
            
        }
        .price-mobile{
            font-weight: 700;
        }
        .price-arrow{
            margin-top: 5px;
            margin-left: 10px;
        }
        .label {
            top: 0;
            background-color: $charcoal;
            color: $white;
            padding: 5px 10px;
            font-size: 10px;
            line-height: 10.5px;
            font-weight: 800;
        }
        .promo {
            position: absolute;
            bottom: 0;
            background-color: rgba($orange, 0.2);
            text-align: center;
            width: 100%;
            font-weight: 800;
            line-height: 13.75px;
            font-size: 11px;
            padding: 7px 0;
        }
        .logo {
            top: 0;
            right: 0;
        }
    
        .text {
            margin-top: 5px;
            font-size: 16px;
            .flex-title-price{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                gap: 20px;
                max-width: 100%;
            }
            .title-card{
                color: #2A2C2C;
                font-family: Mont;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%; 
                width: 170px;
            }
        }
        .cart-link-container {
            /*
            .button-cart{
                @include media-breakpoint-up(sm){
                    &:hover {
                        transform: translateX(50px);
                    }
                    transition: all .5s ease-out;
                }
            }
            
            margin-top: 10px;
            padding-top: 10px;
            border-top: 2px $light-gray solid;
            a {
                text-decoration: none;
                color: $brand-black;
                &:hover,
                a:active,
                a:focus {
                    text-decoration: none;
                    color: $brand-black;
                }
                span {
                    @include font-rem(16px, 28px);
                    letter-spacing: 1px;
                    text-decoration: none;
                    color: $brand-black;
                    font-weight: 700;
                    margin-right: 10px;
                }
            }
            */
        }
    
        .review-stars{
            width: 100%;
            flex-direction: column;
        }
        .price {
            color: #2A2C2C;
            text-align: right;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            img {
                margin-left: 10px;
            }
        }
        .price-sale {
            color: #FC4A1F;
            font-size: 16px;
            font-weight: 500;
            line-height: 9.286px; /* 58.036% */
            margin-top: 8px;
            justify-content: flex-end;
            @include media-breakpoint-down(md) {
                margin-left: 6px;
                margin-right: 5px;
            }
            img {
                margin-left: 10px;
            }
        }
    
        .rating {
            display: inline-block;
            unicode-bidi: bidi-override;
            color: #dedede;
            font-size: 14px;
            letter-spacing: 2px;
            height: 23px;
            width: auto;
            margin: 0;
            position: relative;
            padding: 0;
            font-family: FontAwesome;
        }
        .totalReviews{
            color: var(--glo-brand-black, #2A2C2C);
            text-align: right;
            font-family: Mont;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-left: 5px;
        }
          
        .rating-upper {
            color: #ffd518;
            padding: 0;
            position: absolute;
            z-index: 1;
            display: flex;
            top: 0;
            left: 0;
            overflow: hidden;
            font-size: 14px;
            &::before {
                content: "\f005 \f005 \f005 \f005 \f005";
            }
        }
          
        .rating-lower {
            padding: 0;
            display: flex;
            z-index: 0;
            &::before {
                content: "\f005 \f005 \f005 \f005 \f005";
            }
        }
    
        .bat-producthero-configurator {
            display: flex;
            flex-direction: column;
            padding: 0;
            width: 100%;
    
            & > * {
                padding-left: 20px;
                padding-right: 20px;
            }
            .bat-producthero-strengths-indicator-line, .bat-producthero-strengths-group {
                margin-left: 20px;
                margin-right: 20px;
            }
    
            &-bottom {
                flex-direction: column;
                justify-content: flex-start;
                margin-bottom: 43px;
                padding: 0 20px;
                text-align: left;
            }
    
            &-cta-list {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                margin-left: auto;
                align-items: center;
                width: 100%;
                button, a {
                    max-width: 300px;
                    padding: 11px 50px;
                    width: fit-content;
                }
                button {
                    display: none;
                    &.active {
                        display: flex;
                    }
                }
                a {
                    display: flex;
                }
                button, a {
                    &:not(:last-child) {
                        margin-bottom: 0.5em;
                    }
                }
            }
    
            &-price {
                flex: 0 1 auto;
                margin-top: 10px;
                margin-bottom: 10px;
                text-align: left;
            }
    
            &-subheading {
                @include font-rem(20px, 28px);
                font-weight: 500;
                letter-spacing: 1.5px;
                margin-bottom: 8px;
                padding-bottom: 0;
                padding-top: 0;
                text-align: left;
                text-transform: capitalize;
            }
    
            &-short-description {
                h6 {
                    @include font-rem(14px, 14px);
                    font-weight: 700;
                    margin-bottom: 15px;
                }
            }
    
            &-description {
                @include font-rem(14px, 20px);
                color: $dark-gray;
    
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: inherit;
                    font-size: inherit;
                    line-height: inherit;
                    margin-bottom: 7px;
                }
    
                p {
                    color: inherit;
                    font-size: inherit;
                    line-height: inherit;
                    margin-bottom: 0;
                    &:not(:last-of-type) {
                        margin-bottom: 5px;
                    }
                }
    
                ul {
                    margin-bottom: 15px;
    
                    li {
                        color: inherit;
                        font-size: inherit;
                        line-height: inherit;
                    }
                }
            }
    
            &-stock {
                display: block;
                font-weight: 700;
                margin-bottom: 20px;
            }
    
            &-subscription {
                background-color: $concrete;
                margin: 0 auto;
                max-width: 325px;
    
                &-text {
                    @include font-rem(12px, 12px);
                    margin-top: 2px;
    
                    .formatted-price {
                        font-weight: normal;
                    }
    
                    &-note {
                        @include font-rem(12px, 12px);
                        display: inline-block;
                        font-weight: normal;
                        margin: 7px 0 0;
                        text-transform: none;
                    }
    
                    .discount-savings {
                        @include font-rem(12px, 12px);
                        background-color: $dark-blue;
                        color: $white;
                        display: inline-block;
                        padding: 2px 5px;
                    }
                }
    
                &-max-message {
                    margin-top: 0;
                }
    
                &-details {
                    button {
                        color: $dark-blue;
                        font-weight: 700;
                    }
                }
    
                fieldset {
                    padding: 0;
    
                    label {
                        align-content: flex-start;
                        align-items: flex-start;
                        height: auto;
                        min-height: 29px;
    
                        input {
                            margin-top: 2px;
                        }
                    }
                }
            }
            &-configurable-options {
                display: flex;
                flex-direction: row;
                text-align: left;
                width: auto;
    
                .size-container{
                    border-radius: 100%;
                    margin-right: 2px;
                    margin-top: 5px;
                    border: 1px solid transparent;
    
    
                    
    
                    .size{
                        border-radius: 100%;
                        width: 30px;
                        position: relative;
                        height: 30px;
                        background-color: $concrete;
                        color: $dark-gray;
                        font-weight: 600;
                        font-size: 12px;
                        font-family: $primary-font;
                        margin: 2px;
        
                        &:hover{
                            border: 1px solid $brand-black;
                            color: $brand-black;
                        }
            
                        &:active{
                            background-color: $white;
                            box-shadow: 0 0 3px #1d1d1d;
                            border: 1px solid $white;
                            background-color: $white;
                            color: $brand-black;
                            
                        }
                        &:disabled{
                            &:hover{
                                border: 0;
                                color: $dark-gray;
                            }
                            &:active{
                                background-color: $concrete;
                                color: $brand-black;
                                box-shadow: 0 0 0 #1d1d1d;
                                color: $dark-gray;
                            }
                            &:after {
                                height:23px;
                                width:26px;
                                background: linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
                                background: -moz-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
                                background: -ms-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
                                background: -o-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
                                background: -webkit-gradient(linear,left top,right bottom,color-stop(0%,rgba(255,255,255,0)),color-stop(42%,rgba(255,255,255,0)),color-stop(43%,#fff),color-stop(46%,#fff),color-stop(47%,#ff5216),color-stop(53%,#ff5216),color-stop(54%,#fff),color-stop(57%,#fff),color-stop(58%,rgba(255,255,255,0)),color-stop(100%,rgba(255,255,255,0)));
                                background: -webkit-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
                                bottom: 0;
                                content: '';
                                
                                left: 0;
                                position: absolute;
                                right: 0;
                                top: 0;
                            }
                        }
                    }
    
                    &.active{
                        .size{
                            border: 1px solid $brand-black;
                            color: $brand-black;
                        }
                    }
    
                }
    
                &-title {
                    @include font-rem(12px, 16px);
                    font-weight: 700;
                    letter-spacing: 0;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
                
                &-variants-button {
                    $button-size: 28px;
                    height: $button-size;
                    width: $button-size;
                    border-radius: 99999999px;
                    padding: 4px;
                    position:relative;
                    &:after {
                        content: "";
                        background-color: var(--button-variant-color);
                        display: block;
                        width: 100%;
                        height: 100%;
                        border-radius: 99999999px;
                    }
                    &:active,
                    &.active {
                        border: 1px solid $brand-black;
                    }
                    &:disabled{
                        
                        background-color: var(--button-variant-color);
                        border: 4px solid $white;
                        &:active{
                            border: 0;
                        }
                        &:after {
                            background: linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
                            background: -moz-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
                            background: -ms-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
                            background: -o-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
                            background: -webkit-gradient(linear,left top,right bottom,color-stop(0%,rgba(255,255,255,0)),color-stop(42%,rgba(255,255,255,0)),color-stop(43%,#fff),color-stop(46%,#fff),color-stop(47%,#ff5216),color-stop(53%,#ff5216),color-stop(54%,#fff),color-stop(57%,#fff),color-stop(58%,rgba(255,255,255,0)),color-stop(100%,rgba(255,255,255,0)));
                            background: -webkit-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
                            bottom: 0;
                            content: '';
                            
                            left: 0;
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                    }
                }
    
                p {
                    display: none;
                }
            }
    
            &-title {
                @extend h3;
                @include font-rem(32px, 32px);
                letter-spacing: -1.25px;
                margin-bottom: 6px;
                max-width: unset;
                padding-bottom: 0;
                padding-top: 0;
            }
    
            &-options {
                z-index: 1;
    
                &-option {
                    flex: 0 1 auto;
                    margin-right: 32px;
                    padding: 0 0 10px;
                    text-align: center;
    
                    p {
                        @include font-rem(14px, 14px);
                        font-weight: 700;
                        margin-bottom: 5px;
                    }
    
                    &-input {
                        .select-wrapper {
                            display: inline-block;
    
                            select {
                                @include font-rem(13px, 13px);
                                font-weight: 700;
                            }
                        }
    
                        fieldset {
                            label {
                                cursor: pointer;
                                display: inline-flex;
                                margin-bottom: 0;
                                margin-right: 5px;
    
                                input {
                                    &[type="radio"] {
                                        display: none;
    
                                        + span {
                                            @include font-rem(13px, 13px);
                                            background-color: $concrete;
                                            color: $dark-blue;
                                            display: block;
                                            padding: 14px;
                                        }
    
                                        &:checked {
                                            + span {
                                                @include font-rem(
                                                    13px,
                                                    13px
                                                );
                                                background: $dark-blue;
                                                color: $white;
                                                display: block;
                                                font-weight: 700;
                                                padding: 14px;
                                            }
                                        }
    
                                        &:disabled {
                                            + span {
                                                position: relative;
    
                                                &::after {
                                                    background: $dark-blue;
                                                    content: "";
                                                    height: 2px;
                                                    left: -5px;
                                                    position: absolute;
                                                    top: 46%;
                                                    transform: rotate(
                                                        -34deg
                                                    );
                                                    width: 114%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
    
            .bat-quantity {
                margin-bottom: 26px;
                padding-bottom: 0;
                padding-top: 0;
                &-label {
                    @extend h5;
                    @extend .headline5-small;
                    @include font-rem(12px, 15px);
                    flex: 0 1 140px;
                    letter-spacing: 1px;
                }
                .bat-quantity-button {
                    flex: 0 0 32;
                    height: 32px;
                    min-width: 32px;
                }
            }
    
            .bat-producthero-deliveryinfo {
                align-items: center;
                background-color: $white;
                display: flex;
                padding: 24px 20px;
                margin-top: auto;
                margin-bottom: 23px;
                &-icon {
                    padding-left: 4px;
                    padding-right: 14px;
                    .bat-icon {
                        height: 34px;
                        width: 34px;
                        &::before {
                            font-size: 34px;
                        }
                    }
                }
                &-text {
                    .heading {
                        @extend h5;
                        @extend .headline5-small;
                        @include font-rem(12px, 15px);
                        display: block;
                        letter-spacing: 1px;
                        margin-bottom: 4px;
                    }
                    p {
                        @include font-rem(12px, 14.4px);
                        color: $dark-gray;
                        letter-spacing: 0.25px;
                        margin: 0;
                    }
                }
            }
    
            .bat-producthero-desktop {
                display: none;
                padding-bottom: 0;
                padding-top: 0;
            }
    
            .bat-producthero-strengths {
                &-group {
                    margin-left: 10%;
                    margin-right: 10%;
                    margin-bottom: 12px;
                    padding: 0;
                    &-indicators {
                        display: flex;
                        flex-direction: column;
                        > .bat-producthero-strengths-indicator-line:not(:last-child) {
                            margin-right: 40px;
                        }
                    }
                    .bat-producthero-strengths-indicator-line {
                        margin: 0;
                    }
                    &-top-bar {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 12px;
    
                        span {
                            margin-right: 13px;
                            font-family: $primary-font;
                            @include font-rem(12px, 115%);
                            letter-spacing: 1.2px;
                            font-weight: bold;
                            width: 14ch;
                            flex-shrink: 0;
                        }
    
                        hr {
                            width: 100%;
                            height: 0;
                            background-color: $light-gray;
                            border: none;
                            border-top: 2px solid $light-gray;
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                    }
                }
                
                &-indicator-line {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 25px;
                    margin-left: 10%;
                    margin-right: 10%;
                    padding: 0;
                }
    
                &-container {
                    display: flex;
                    flex-wrap: wrap;
                    
                    .bat-indicator {
                        @include variable(--indicator-active-color, $brand-black);
                        @include variable(--indicator-active-last-color, $brand-black);
                        border: none;
                        align-items: baseline;
                        flex-direction: row;
                        font-family: $primary-font;
                        justify-content: center;
                        margin: 0;
                        padding: 0;
                        width: auto;
                        $size: 13px;
                        ul {
                            li {
                                width: $size;
                                height: $size;
                                line-height: $size;
                                font-size: $size;
                                border-color: $brand-black;
                            }
                        }
    
                        &.strength-4mg,
                        &.strength-mild,
                        &.strength-1 {
                            li {
                                &:nth-child(1) {
                                    background: var(--indicator-active-color);
                                }
                            }
    
                            &.active {
                                li {
                                    &:nth-child(1) {
                                        background: $white;
                                    }
                                }
                            }
                        }
    
                        &.strength-6mg,
                        &.strength-medium,
                        &.strength-2 {
                            li {
                                &:nth-child(-n + 2) {
                                    background: var(--indicator-active-color);
                                }
                                &:nth-child(2) {
                                    background: var(--indicator-active-last-color);
                                    border: none;
                                }
                            }
    
                            &.active {
                                li {
                                    &:nth-child(-n + 2) {
                                        background: $white;
                                    }
                                }
                            }
                        }
    
                        &.strength-10mg,
                        &.strength-strong,
                        &.strength-3 {
                            li {
                                &:nth-child(-n + 3) {
                                    background: var(--indicator-active-color);
                                }
                                &:nth-child(3) {
                                    background: var(--indicator-active-last-color);
                                    border: none;
                                }
                            }
    
                            &.active {
                                li {
                                    &:nth-child(-n + 3) {
                                        background: $white;
                                    }
                                }
                            }
                        }
    
                        &.strength-16mg,
                        &.strength-x-strong,
                        &.strength-4 {
                            li {
                                &:nth-child(-n + 4) {
                                    background: var(--indicator-active-color);
                                }
                                &:nth-child(4) {
                                    background: var(--indicator-active-last-color);
                                    border: none;
                                }
                            }
    
                            &.active {
                                li {
                                    &:nth-child(-n + 4) {
                                        background: $white;
                                    }
                                }
                            }
                        }
    
                        &.strength-18mg,
                        &.strength-ultra,
                        &.strength-5 {
                            li {
                                &:nth-child(-n + 5) {
                                    background: var(--indicator-active-color);
                                }
                                &:nth-child(5) {
                                    background: var(--indicator-active-last-color);
                                    border: none;
                                }
                            }
    
                            &.active {
                                li {
                                    &:nth-child(-n + 5) {
                                        background: $white;
                                    }
                                }
                            }
                        }
    
                        &.strength-20mg,
                        &.strength-max,
                        &.strength-6 {
                            li {
                                &:nth-child(-n + 6) {
                                    background: var(--indicator-active-color);
                                }
                                &:nth-child(6) {
                                    background: var(--indicator-active-color);
                                    border: none;
                                }
                            }
    
                            &.active {
                                li {
                                    &:nth-child(-n + 6) {
                                        background: $white;
                                    }
                                }
                            }
                        }
                        span {
                            @include font-rem(12px, 12px);
                            color: $brand-black;
                            font-weight: 700;
                            margin-left: 10px;
                            margin-bottom: 0;
                        }
                    }
                }
                &-label {
                    display: inline-flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 0;
                    margin-inline-end: 10px;
                    font-weight: normal;
                    @include font-rem(13px, 185%);
                    width: fit-content;
                    &--bold {
                        font-weight: bold;
                    }
                    &-popover-button {
                        .bat-icon:before {
                            @include font-rem(19px, 100%);
                        } 
                    }
                    &-popover-content {
                        position: absolute;
                        background-color: $charcoal;
                        color: $white;
                        padding: 15px;
                        transform: translateX(50%);
                        width: 200px;
                        $triangle-size: 10px;
                        display: none;
                        margin-top: $triangle-size;
                        z-index: 999;
                        font-family: $primary-font;
                        font-weight: normal;
                        @include font-rem(10px, 150%);
    
                        &[data-show] {
                            display: block;
                        }
    
                        &-arrow {
                            visibility: hidden;
    
                            &, &::before {
                                position: absolute;
                                width: $triangle-size;
                                height: $triangle-size;
                                background: inherit;
                            }
    
                            &::before {
                                visibility: visible;
                                content: '';
                                transform: rotate(45deg);
                            }
                        }
    
                        &[data-popper-placement^='top'] > .bat-producthero-strengths-label-popover-content-arrow {
                            bottom: #{-($triangle-size / 2)};
                        }
                    
                        &[data-popper-placement^='bottom'] > .bat-producthero-strengths-label-popover-content-arrow {
                            top: #{-($triangle-size / 2)};
                        }
                
                        &[data-popper-placement^='left'] > .bat-producthero-strengths-label-popover-content-arrow {
                            right: #{-($triangle-size / 2)};
                        }
                
                        &[data-popper-placement^='right'] > .bat-producthero-strengths-label-popover-content-arrow {
                            left: #{-($triangle-size / 2)};
                        }
    
                        p {
                            display: block;
                            font-size: inherit;
                            line-height: inherit;
                            font-family: inherit;
                            font-weight: inherit;
                        }
                    }
                }
            }
        }
    }
}