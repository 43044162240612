.bat-searchstorelocator-gloit {
	margin: 0 auto;
	max-width: $max-width-lg;
	min-height: 300px;
	min-width: 500px;
	padding: 53px 50px 0px;

    @include media-breakpoint-down(md){
        padding: 0px 0px 20px;
		min-width: unset;
    }
	
	&-input {
		position: relative;
		width: 100%;

		@include media-breakpoint-down(md){
			display: none;
		}

		&.mobile{
			display: block;
			padding: 20px;
		}
		
		@include media-breakpoint-up(lg) {
			width: 100%;
		}

        .search-title{
            text-transform: uppercase;
            @include font-rem(30px, 34px);
            margin-bottom: 30px;

            @include media-breakpoint-down(md) {
                @include font-rem(24px, 28px);
            }
        }

        .current-location-cta{
            margin-top: 10px;
            text-transform: uppercase;
            @include font-rem(14px, 28px);
            font-weight: 700;
            font-family: $primary-font;
            width: auto;
        }
		
		form {
			align-items: center;
			display: flex;
			position: relative;
			
			input {
				height: 40px;
				padding: 5px 30px 5px 10px;
				width: 100%;
			}
		}
		
		&-button {
			background: none;
			border: 0;
			cursor: pointer;
			height: 24px;
			padding: 2px;
			position: absolute;
			right: 10px;
			top: 4px;
			width: 24px;
			
			i {
				font-size: 20px;
				&:before {
					font-size: 20px;
					color: $brand-black
				}
			}
		}

		&-filter{
			@include media-breakpoint-down(md){
				display: flex;
			}

			.filter-select{
				display: block;

				@include media-breakpoint-down(md){
					width: 100%;
				}

				.select-wrapper{
					&::after{
						font-size: 20px;
					}
				}
			}

			.glo-corners & {
				display: none !important;
			}
		}
		
		&-popup-info{
			background: #D3E3F3;
			height: 60px;
			margin-top: 20px;
			display: flex;
			padding: 8px;
			gap: 10px;
			p{
				margin:5px;
			}

			@include media-breakpoint-down(md){
				display: none;
			}

			&.mobile{
				display: flex;
				margin-bottom: 20px;
			}

			.glo-corners & {
				display: none !important;
			}
		}

		&-popup-info-orange{
			background: #faede6;
			margin-top: 20px;
			margin-bottom: 20px;
			display: flex;
			padding: 8px;
			justify-content: space-between;

			p{
				margin:5px;
			}

			&.mobile{
				display: flex;
				margin-bottom: 20px;
			}

			.glo-corners & {
				display: none !important;
			}
			img{
				object-fit: contain;
			}
			a{
				margin-top: 10px;
			}
		}
	}
	
	&-results,
	&-no-results {
		margin-top: 30px;

		@include media-breakpoint-down(md){
			margin-top: 20px;
		}
	}
	
	&-results {
        overflow-y: auto;
        height: 575px;

        @include media-breakpoint-down(md){
            height: 100%;
            overflow-y: hidden;
        }

		&-container{
			border: 1px solid #DEDEDE;
			padding: 20px;
			
			@include media-breakpoint-down(md){
				text-align: center;
				margin-inline: 20px;
			}

			&.selected{
				border: 2px solid $charcoal;

				@include media-breakpoint-down(md){
					border: 1px solid #DEDEDE;
				}
			}

			&.hidden{
				display: none;
			}
	
			.title-container{
				
				.title{
					font-weight: 800;
					@include font-rem(18px, 20px);
					color: $charcoal;
	
					@include media-breakpoint-down(md){
						@include font-rem(16px, 20px);
					}
				}
	
				.distance{
					text-transform: uppercase;
					font-weight: 700;
					@include font-rem(12px, 15px);
					color: $dark-gray
				}
	
				margin-bottom: 15px;
				gap: 10px;
	
				@include media-breakpoint-down(md){
					margin-bottom: 10px;
				}
			}

			.info-container{
				display: flex;
				height: 15px;
				gap: 10px;
				.info{

					font-family: 'Mont';
					font-style: normal;
					font-weight: 500;
    				font-size: 15px;
					line-height: 15px;
					display: flex;
					align-items: center;

					color: #2A2C2C;

					opacity: 0.7;
					margin: 0;
				}
		
				margin-bottom: 20px;
	
				@include media-breakpoint-down(md){
					margin-bottom: 20px;
				}
			}
			.company-name-container{
				margin-bottom: 20px;
   				display: flex;
				.company-name{
					font-size: 18px;
    				line-height: 20px;
				}
			}
	
			.address{
				@include font-rem(14px, 15px);
				color: #2A2C2C;
				font-family: 'Mont';
				font-style: normal;
				font-weight: 500;
    			font-size: 19px;
				line-height: 23px;
				margin-bottom: 15px;
				text-align: left;
				
	
				@include media-breakpoint-down(md){
					@include font-rem(13px, 20px);
				}
			}
	
			.get-directions-cta{
				@include font-rem(13px, 16px);
	
				@include media-breakpoint-down(md){
					max-width: 100%;
					width: 100%;
				}
				
				&:hover{
					color: #2A2A2A;
				}
			}
		}
	}
	
	&-no-results {
		display: none;
		
		&.active {
			display: block;
		}
	}

	&-back{
		width: 100%;
		text-align: left;
		background-color: $concrete;

		button{
			width: 100%;
			padding: 20px;

			&::after{
				right: auto !important;
				left: 40px;
			}
		}

		&.hidden{
			display: none;
		}

		@media screen and (min-width: 991px) {
			display: none;
		}
	}
}
