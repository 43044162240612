.confronto-container{
	margin-top:25px;
	background-color: $brand-black;
	.black-section{
		max-width: $site-max-width;
		justify-content: center;
		padding-top: 50px;
		.title1,.title2{
			color: $white;
		}
		.middle{
			margin: 0;
			color: $orange;
		}
		h2{
			font-size: 38px;
			line-height: 36px;
			font-weight: 800;
			display: inline;
			@include media-breakpoint-down(md) {
				font-size: 20px;
    			line-height: 20px;
			}
		}
		.title1-container, .title2-container{
			display: inline;
			
		}
	}
	.confronto-button{
		padding-top: 20px;
		padding-bottom: 20px;
		@include media-breakpoint-down(md) {
			justify-content: center;
		}
	}
	.first-pic{
		max-width: $site-max-width;
	}
}
.white-section{
	max-width: $site-max-width;
	justify-content: center;
	.second-pic, .third-pic{
		max-width: $site-max-width;
	}
}