.section-content{
    &.social{
        margin: 70px 60px;
        @include media-breakpoint-down(sm){
            margin: 30px 20px;
        }

        .header-wrapper{
            @include media-breakpoint-down(sm){
                display: block;
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
            } 
        }
        
        .socialgrid-title{
            font-family: $primary-font;
            font-weight: 800;
            @include font-rem(22px, 28px);
            color: $brand-black;

            @include media-breakpoint-down(sm){
                @include font-rem(20px, 24px);
                text-align: center;
            }
        }

        .socialgrid-link{
            color: $charcoal;
            font-family: $primary-font;
            font-weight: 800;
            @include font-rem(14px, 28px);

            @include media-breakpoint-down(sm){
                margin-left: 0px;
            }
        }

        .social-logo{
            margin-left: 20px;
            text-decoration: none;
            color: $charcoal;
        }

        .social-logo:hover{
            color: $charcoal;
        }
    }
}
.bat-socialgrid-gloit{
    h1{
        font-weight: 800;
        font-family: $primary-font;
    }
}


.animation-container {
	overflow: hidden; 
	width: fit-content;
  }
  
 