// File:           _video.scss
// Description:   Video Styles
.bat-video {
	
	& ~ .bat-modal {
	
		.bat-modal-content {
			max-width: $max-width-xl;
			padding: 45px;	
		}
	}
	
	&-content {
		padding: 45px;
	
		&-video {
	
			picture {
				
				&:after {
					color: transparent !important;
					content: '';
					font-family: 'Font Awesome 5 Free';
					font-weight: 700;
					left: 50%;
					padding: 34px;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					@include font-rem(20px, 20px);
				}
				&:before {
					background-color: transparent !important;
					border-radius: 50%;
					color: transparent;
					content: '';
					font-family: 'Font Awesome 5 Free';
					font-weight: 700;
					left: 50%;
					padding: 34px;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					@include font-rem(20px, 20px);
				}
				
				&:hover {
					cursor: pointer;
					
					&:after {
						color: $black;
					}
					
					&:before {
						background-color: $white;
					}
				}
				
			}
        }
	}
}

.overlapping-video {
	margin-top: -130px;
}
