.aem-GridColumn{
    

	&.news-card{
		padding: 70px 50px;
        background-color: $concrete;

		@include media-breakpoint-down(sm){
			padding: 0px;
		}
    }

    .title-section{
        @include media-breakpoint-down(sm){
            display: block;
			text-align: center;
            padding-top: 30px;
		}
        
    }

    @include media-breakpoint-down(xl){
        .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--tablet--6 {
            width: 100%;
        }
    }

    .news-section-title{
        @include font-rem(22px, 28px);
        font-weight: 800;

        @include media-breakpoint-down(sm){
            margin-bottom: 10px;
		}
    }

    .news-section-link{
        text-decoration: none;
        color: $charcoal;
    
        @include font-rem(14px, 28px);
        font-weight: 800;

        i{
            margin-top: 2px;
            margin-left: 10px;
        }
    }
}
.bat-loyaltyinfosimple-gloit{

    &--news {

        &-main {
            padding: 16px 24px;
            gap: 12px;
            background: $white;
            border: 2px solid #5F73BA;
            box-shadow: 8px 8px 0px #D5DDEC;
        }

        &-image {
            width: 40px;
            img {
                width: 40px;
                height: 40px;
                object-fit: contain;
            }
        }

        &-box-coins {
            padding: 6px 10px;
            text-align: center;
            background: #18207F;
            border-radius: 40px;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: $white;
        }

        &-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-title{
            @include font-rem(20px, 28px);
            font-weight: 600;
            color: #2A2C2C;
            margin-top: 0;
            margin-bottom: 12px;
        }

        &-body {
            margin-top: 16px;

            .uk-accordion-title::before{
                background-image: url("/content/dam/glo-it/images/chevron-down-arrow.svg");
                }
            .uk-open > .uk-accordion-title::before{
            background-image: url("/content/dam/glo-it/images/chevron-up-arrow.svg");
            }

        }

        &-content{
            p {
                @include font-rem(14px, 20px);
                font-weight: 400;
                color: #2A2C2C;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
	}
}