bat-card-gloitaudiomisteryhog {
	.bat-card-gloitaudiomisteryhog.teaser{
		margin-left: 0% !important;
	}
	.bat-card-gloitaudiomisteryhog{
		margin-left: -5% !important;
	}
	width: 102%;
	height: auto;
	margin-top: -12px;
	@include media-breakpoint-down(md) {
		margin-left: auto !important;
		width: 100%;
		margin-top: 0px;
	}

	.card-registrated {
		width: 100%;
		height: 100%;
		min-height: 393px;
		position: relative;
		@include media-breakpoint-down(md) {
			width: 100%;
			height: 450px;
		}

		.second-section-registrated {
			position: relative;
			z-index: 2;
			color: #fff;
			padding: 7% 0 0 24%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			gap: 29px;

			@include media-breakpoint-down(md) {
				padding-bottom: 8% !important;
				padding: unset;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-end;
				width: 100%;
				gap: 25px;
			}

			.title-second-section-registrated {
				font-size: 34px;
				font-weight: 600;
				line-height: 41px;

				@include media-breakpoint-down(md) {
					font-size: 26px;
					line-height: 28px;
					text-align: center;
				}
			}

			.text-second-section-registrated {
				p {
					font-size: 16px;
					line-height: 18px;
					font-weight: 400;
				}

				@include media-breakpoint-down(md) {
					p {
						font-size: 13px;
						text-align: center;
					}
				}
			}

			.cta-second-section-registrated {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				@include media-breakpoint-down(md) {
					justify-content: center;
				}
				.button-secondary-dark {
					@include media-breakpoint-down(md) {
						padding: 0 5%;
					}
					text-transform: none;
					height: 42px;
					font-size: 13px;
					background-color: #fe5000;
					border: 0;
					border-radius: 50px;
					width: fit-content;
					padding: 0 3%;
					color: #000;
					font-weight: 600;
					text-align: center;
					cursor: pointer;
					transition: all 0.3s ease;

					&:hover {
						opacity: 0.6;
					}
				}
			}
		}
	}

	.card {
		width: 100%;
		height: 250px;
		position: relative;
		@include media-breakpoint-down(md) {
			width: 100%;
			height: 267px;
		}

		.second-section {
			position: relative;
			z-index: 2;
			color: #fff;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 5%;
			align-items: center;

			@include media-breakpoint-down(md) {
				padding-bottom: 8% !important;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				gap: 0;
			}
			.title-second-section {
				font-size: 34px;
				font-weight: 600;
				line-height: 41px;
				margin-bottom: 10px;

				@include media-breakpoint-down(md) {
					font-size: 26px;
					line-height: 28px;
					font-weight: 600;
					text-align: center;
				}
			}
			.text-second-section {
				p {
					font-size: 16px;
					line-height: 18px;
					font-weight: 400;
					margin-bottom: 15px;
				}

				@include media-breakpoint-down(md) {
					p {
						font-size: 13px;
						text-align: center;
					}
				}
			}
			.cta-second-section {
				width: 100%;
				display: flex;
				justify-content: center;
				.button-secondary-dark {
					@include media-breakpoint-down(md) {
						padding: 0 5%;
					}
					text-transform: none;
					height: 42px;
					font-size: 13px;
					background-color: #fe5000;
					border: 0;
					border-radius: 50px;
					width: fit-content;
					padding: 0% 2%;
					color: #000;
					font-weight: 600;
					text-align: center;
					cursor: pointer;
					transition: all 0.3s ease;

					&:hover {
						background-color: #fe5000;
						color: black;
					}
				}
			}
		}
	}
}
