.text-and-picture-discover {
	padding-bottom: 120px;

	@include media-breakpoint-down(sm){
		padding-bottom: 15px;
	}

	.content-section{
		@include media-breakpoint-down(sm){
			flex-direction: column-reverse;
		}
		img{
			width: unset;
			max-width: 470px;
		}
	}
	.content-description{
		@include media-breakpoint-down(sm){
			padding: 20px;
		}
		.title{
			font-size: 38px;
			line-height: 36px;
			font-weight: 800;
			margin-bottom: 0;
		}
		.description{
			max-width: 470px;
			font-size: 16px;
			line-height: 24px;
		}
		@include media-breakpoint-down(sm){
			.title{
				font-size: 24px;
    			line-height: 24px;
    			font-weight: 800;
			}
		}
	}
}

.last-section{
	@include media-breakpoint-up(md){
		position: relative;
		.glo-it-puntivendita{
			position: absolute;
			top: 0%;
			left: 15%;
			z-index: 1;
		}
	}

	.glo-it-infotabacco {
		.uk-container {
			max-width: $site-max-width;
		}
	}

	.glo-it-puntivendita {
		.uk-container {
			max-width: $site-max-width;
		}
	}
}