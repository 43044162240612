bat-cta-gloittoiletpaperslider,
bat-card-gloittoiletpaper {
    max-width: unset !important;
}

.bat-gloit-toiletpaperslider {

    background-image: url("/content/dam/glo-it/images/landingtp/SKY-TP-1-2.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    &-silder {
        max-width: 1000px;
        margin: 0 auto;
        padding: 82px 0 106px 0;

        @include media-breakpoint-down(md) {
            max-width: 100%;
            padding: 120px 0 0 0;
        }
    }

    &-silder-slides-item {
        cursor: pointer;
        margin-left: 50px;
        margin-right: 50px;
        opacity: 0.7;
        transition: all 300ms ease;

        &.slick-current,
        &.slick-center {
            opacity: 1;
            transform: translateY(-40px);
        }

        @include media-breakpoint-down(md) {
            margin-left: 10px;
            margin-right: 10px;
            transform: translateY(40px);
        }
    }

    &-pointer {
        position: absolute;
        height: 140px;
        width: 300px;
        overflow: hidden;
        bottom: 0;
        transition: all 300ms ease;

        @include media-breakpoint-down(md) {
            height: 110px;
            width: 265px;
            position: relative;
            margin: 0 auto;
            padding-left: 40px;
        }
    }

    .slick-list.draggable {
        overflow: visible;
    }

    .slick-prev {
        position: absolute;
        left: 20px;
        top: 40px;
        
        img {
            height: 20px;
        }
    }

    .slick-next {
        position: absolute;
        right: 20px;
        top: 40px;

        img {
            height: 20px;
        }
    }
}

.bat-toiletpapercard-gloit {

    position: absolute;
    left: -99999px;

    &.active {
        position: relative;
        left: 0;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: -45px;
        z-index: 1;
    }

    &--blurb {
        position: relative;
        &-img {
            width: 100%;
            height: 100%;
        }

        &-main {
            position: absolute;
            top: 35%;
            left: 50%;
            padding-right: 20%;

            @include media-breakpoint-down(md) {
                top: 60%;
                left: 0;
                text-align: center;
                padding: 0 20px;
            }

            p,
            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 10px;
                
                @include media-breakpoint-down(md) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @include media-breakpoint-down(xxs) {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
            

            img {
                height: 58px;
                object-fit: contain;

                @include media-breakpoint-down(md) {
                    height: 48px;
                }
            }
        }
    }
}