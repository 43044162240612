.aem-GridColumn{
	&.review-card{
		padding: 70px 50px;
        background-color: $concrete;

		@include media-breakpoint-down(sm){
			padding: 0px;
		}
    }

    .title-section{
        @include media-breakpoint-down(sm){
            display: block;
			text-align: center;
            padding-top: 30px;
		}
        
    }

    @include media-breakpoint-down(xl){
        .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--tablet--6 {
            width: 100%;
        }
    }

    .review-section-title{
        @include font-rem(22px, 28px);
        font-weight: 800;

        @include media-breakpoint-down(sm){
            margin-bottom: 10px;
		}
    }

    .review-section-link{
        text-decoration: none;
        color: $charcoal;
    
        @include font-rem(14px, 28px);
        font-weight: 800;

        i{
            margin-top: 2px;
            margin-left: 10px;
        }
    }
}

bat-card-gloitreview {
    width: 487px;
    height: 238px;    
    
    @include media-breakpoint-down(sm) {
        width: 276px;
        height: 200px;
    }
}

.bat-cardreview-gloit{

    height: 100%;
    padding: 20px;

    @include media-breakpoint-down(sm) {
        padding: 10px;
    }

    &--review {

        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

		@include media-breakpoint-down(xs) {
			margin-bottom: 0;
			margin-top: 0;
			padding: 40px 10px 30px;
		}

		&-img {
			height: 100%;
            min-height: 20px;
			max-height: 240px;
			overflow: hidden;
            display: flex;
            align-items: center;

            picture {
                width: 30%;

                @include media-breakpoint-down(sm) {
                    width: 40%;
                }
            }

			a {
				display: block;
			}
		}

        &-title{
            @include font-rem(24px, 34px);
            font-weight: 800;
            margin: 15px 0;

			@include media-breakpoint-down(xs) {
                @include font-rem(19px, 28px);
				margin: 0 0 25px;
			}
        }

        &-content{
            @include font-rem(14px, 20px);
        }

		&-text {
			margin: 15px 0;

			@include media-breakpoint-down(xs) {
				margin: 8px 0 5px;
			}

			h3 {
				font-size: 20px;
				line-height: 150%;
				padding-top: 15px;

				@include media-breakpoint-down(xs) {
					padding-top: 4px;
				}
			}
			p {
				@include font-rem(18px, 28px);
				margin-top: 5px;

				@include media-breakpoint-down(xs) {
					color: $dark-gray;
                    @include font-rem(16px, 28px);
				}
			}
		}
		&-ctalist {
			.bat-cta-style {
				font-size: 15px;
				line-height: 150%;
				margin-top: 5px;
                text-transform: uppercase;
                @include font-rem(15px, 28px);
                font-weight: 800;
                color: $charcoal;

				@include media-breakpoint-down(sm) {
					margin-top: 0;
				}
				
			}
			
			.button-review{
				@include media-breakpoint-up(sm){
					&:hover {
						transform: translateX(50px);
					}
					transition: all .5s ease-out;
				}
				
			}

            a{
                text-decoration: none;
                color: $charcoal;
            
                @include font-rem(14px, 28px);
                font-weight: 800;
        
                i{
                    margin-top: 2px;
                    margin-left: 10px;
                }
            }
            a:hover{
                text-decoration: none;
                color: $charcoal;

                span{
                    text-decoration: none;
                    color: $charcoal;
                }
            }

            span{
                font-weight: 800;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 10px;
            }
		}
	}
}

.artandmore-review{
    .aem-GridColumn{
        background-color: transparent;

        &.aem-GridColumn--default--4{
            margin-top: 20px;
            padding-right: 5px;
            padding-left: 5px;

            @include media-breakpoint-down(sm){
                margin-top: 5px;
            }

            &:last-of-type{ 
                .bat-cardreview-gloit--review{
                    border-bottom: none;
                }
            }
        }
        
    }

    .artandmore-review-title{
        text-align: center;
        @include font-rem(30px, 34px);
        margin-top: 40px;
        margin-bottom: 30px;

        @include media-breakpoint-down(sm){
            @include font-rem(24px, 28px);
            margin-bottom: 0px;
            margin-left: 20px;
            margin-right: 20px;
            text-align: left;
        }
    }

    .bat-cardreview-gloit--review{
        @include media-breakpoint-down(sm){
            margin-left: 20px;
            margin-right: 20px;
            padding: 20px 0px;
            border-bottom: 2px solid #DEDEDE;
        }   

        .bat-cardreview-gloit--review-date{
            @include font-rem(14px, 20px);
        }

        .bat-cardreview-gloit--review-title{
            margin-top: 0px;
        }
    }
}

.gloitmclaren-section {
    .bat-cardreview-gloit--review{
        @include media-breakpoint-down(sm){
            padding-top: 5px;
        }
    }
}

#review{
    .bat-cardreview-gloit{
        &--review {
            margin-top: 8px;
    
            @include media-breakpoint-down(xs) {
                margin-bottom: 0;
                margin-top: 0;
                padding: 20px 10px 30px;
                border-bottom: 2px solid #DEDEDE;
            }
        }
    }
}