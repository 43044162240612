.gloitmclaren-title-text{
    font-size: 30px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 20px;
    
    img{
        margin-top: -5px;
    }
}
