.bat-productfeatures-gloitspecifications{

    margin: 60px;

    @include media-breakpoint-down(sm){
        margin: 20px;
    }

    &-container{
        @include media-breakpoint-down(sm){
            display: block
        }
    }
    &-title{
        @include font-rem(30px, 34px);
        font-weight: 800;
        font-family: $primary-font;
        color: $charcoal;

        @include media-breakpoint-down(sm){
            @include font-rem(20px, 24px);
        }
    }

    &-text{
        margin: 70px;
        justify-content: space-around;

        @include media-breakpoint-up(sm){
            .first-column,
            .second-column{
                max-width: 30%;
            }
        }

        @include media-breakpoint-down(sm){
            display: block;
            margin: 0px;
        }

        .title{
            @include font-rem(20px, 20px);
            font-weight: 600;
            font-family: $primary-font; 
            color: $charcoal;
        }
        .full-specifications-cta{
            @include media-breakpoint-up(sm){
                display: none
            }

            .text{
                @include font-rem(16px, 27px);
                font-weight: 800;
                letter-spacing: 1px;
                font-family: $primary-font;
                color: $charcoal; 
            }

            &.hidden{
                display: none;
            }
        }

        .border{
            border-bottom: 2px solid $light-gray;
            margin-top: 30px;
            width: 40% 
        }

        .subtitle{
            @include font-rem(14px, 20px);
            font-weight: 500;
            font-family: $primary-font;
            color: $dark-gray;
            padding-top: 5px;
        }

        .first-column{
            div:last-of-type{
                border-bottom: none;
            }
        }
        .second-column{
            div:last-of-type{
                border-bottom: none;
            }
        }

        .first-column{
            .border{
                &.show{
                    border-bottom: 2px solid $light-gray;
                }
            }
        }

        .second-column{
            @include media-breakpoint-down(sm){
                display: none;
                margin-top: 30px
            }

            &.show{
                display: block;
            }
        }
    }

    &-image {
        display: flex;
        align-items: center;
    }
}

bat-productfeatures-gloitspecifications.hyper-pdp-only{
    .bat-productfeatures-gloitspecifications{
        &-text{
            margin-left: 70px;
            margin-top: -30px;
            margin-bottom: 70px;
            width: 100%;
            justify-content: space-around;
    
            @include media-breakpoint-up(sm){
                .first-column,
                .second-column{
                    max-width: 70%;
                }
            }
    
            @include media-breakpoint-down(sm){
                display: block;
                margin: 0px;
            }
    
            .title{
                @include font-rem(20px, 20px);
                font-weight: 600;
                font-family: $primary-font; 
                color: $charcoal;
                margin-bottom: 10px;
            }
            .full-specifications-cta{
                @include media-breakpoint-up(sm){
                    display: none
                }
    
                .text{
                    @include font-rem(16px, 27px);
                    font-weight: 800;
                    letter-spacing: 1px;
                    font-family: $primary-font;
                    color: $charcoal; 
                }
    
                &.hidden{
                    display: none;
                }
            }
    
            .border{
                border-bottom: 2px solid $light-gray;
                margin-top: 30px;
                width: 40% 
            }
    
            .subtitle{
                @include font-rem(14px, 20px);
                font-weight: 500;
                font-family: $primary-font;
                color: $dark-gray;
                padding-top: 5px;
            }
    
            .first-column{
                div:last-of-type{
                    border-bottom: none;
                }
            }
            .second-column{
                div:last-of-type{
                    border-bottom: none;
                }
            }
    
            .first-column{
                .border{
                    &.show{
                        border-bottom: 2px solid $light-gray;
                    }
                }
            }
    
            .second-column{
                @include media-breakpoint-down(sm){
                    display: none;
                    margin-top: 30px
                }
    
                &.show{
                    display: block;
                }
            }
        }
        &-image{
            max-width: 500px;
        }
    }
}
