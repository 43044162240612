.bat-insidersshopcard-gloit{
    background-color: $brand-black;
    color: white;
    padding: 16px;
    margin: 10px 10px;

    &-image{
        a {
            width: 100%;
        }

        .price{
            padding: 8px 16px;
            background-color: $brand-black;
            width: fit-content;
            position: relative;
            top: -40px;
            margin-right: 0px;
            margin-left: auto;

            .price-label{
                font-size: 12px;
                line-height: 15px;
                color: $ligth-orange;
                font-weight: 700;
            }

            .price-value{
                font-size: 18px;
                line-height: 28px;
                font-weight: 700;
            }
        }
    }

    &-content{
        flex-direction: column;
        position: relative;
        top: -20px;

        a:hover {
            span {
                text-decoration: none;
                color: $white;
            }
        }

        .category{
            text-transform: uppercase;
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 10px;
            font-weight: 700;
        }

        .product-name{
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
        }
    }

    .bat-cta-style{
        font-size: 14px;
        line-height: 17px;

        @media (max-width: $breakpoint-md) {
            max-width: 100%;
            width: 100%;
        }
    }

    &.mobile-app {
        background-color: $white;
        color: $brand-black;
        padding: 10px;

        .bat-insidersshopcard-gloit-image{
            position: relative;
            .image {
                img {
                    @include media-breakpoint-down(md) {
                        height: 236px;
                        object-fit: cover;
                        object-position: left top;
                        box-shadow: 0px 4px 6px -1px #D5DDEC, 0px 2px 4px -1px rgba(213, 221, 236, 0.06);
                        border-radius: 12px;
                    }
                }
            }

            .price{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px;
                gap: 5px;
                width: max-content;
                background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
                border-radius: 8px;
                position: absolute;
                bottom: 12px;
                right: 12px;
                color: $white;
                top: unset;
                margin: 0;
    
                .price-label{
                    font-size: 12px;
                    line-height: 17px;
                    color: $white;
                }
    
                .price-value{
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    text-align: center;
                    letter-spacing: 0.2px;
                    display: flex;
                }
            }
        }

        .bat-insidersshopcard-gloit-content {
            top: 7px;
        }
    }
}