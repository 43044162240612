bat-form-gloitaudiomystery {
    color: #fff;
    font-style: normal;

    .audio-mystery-container {
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 26px;
        @include media-breakpoint-down(sm) {
            gap: 30px;
        }

        .highlight-gradient{
            display: none;
        }

        &-episode.highlighted {
            .highlight-gradient {
                border-radius: 7px;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 150px; 
                background: linear-gradient(190deg, #FF4B0F -11.67%, rgba(0, 35, 111, 0.00) 50.24%);
                pointer-events: none;
                z-index: 0;
                @include media-breakpoint-down(sm) {
                    background: linear-gradient(22deg, #FF4B0F -11.67%, rgba(0, 35, 111, 0.00) 50.24%);
                    top: unset;
                    bottom: 0;
                }
            }
        }
        &-episode.blocked {
            position: relative;
            overflow: hidden; 

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-position: center;
                z-index: 1;
                border-radius: 7px;
                background: rgba(0, 21, 64, 0.48);
                box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
                backdrop-filter: blur(4px);
            }
        

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 14%;
                transform: translate(-50%, -50%);
                width: 69px; 
                height: 95px;
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="86" height="119" viewBox="0 0 86 119" fill="none"><g clip-path="url(%23clip0_2067_4857)"><path d="M74.2732 119H11.7277C5.26087 119 0 113.661 0 107.1V56.3269C0 49.7667 5.25998 44.4265 11.7277 44.4265H74.2732C80.7382 44.4265 86.0009 49.7657 86.0009 56.3269V107.1C86.0009 113.66 80.7391 119 74.2732 119ZM11.7277 49.1867C7.84779 49.1867 4.69109 52.3907 4.69109 56.3269V107.1C4.69109 111.035 7.84779 114.24 11.7277 114.24H74.2732C78.1513 114.24 81.3098 111.036 81.3098 107.1V56.3269C81.3098 52.3917 78.1522 49.1867 74.2732 49.1867H11.7277Z" fill="white"/><path d="M43.0007 87.2661C36.5338 87.2661 31.2729 81.9268 31.2729 75.3657C31.2729 68.8046 36.5329 63.4653 43.0007 63.4653C49.4684 63.4653 54.7284 68.8046 54.7284 75.3657C54.7284 81.9268 49.4666 87.2661 43.0007 87.2661ZM43.0007 68.2264C39.1207 68.2264 35.964 71.4305 35.964 75.3666C35.964 79.3027 39.1207 82.5068 43.0007 82.5068C46.8806 82.5068 50.0373 79.3027 50.0373 75.3666C50.0373 71.4305 46.8797 68.2264 43.0007 68.2264Z" fill="white"/><path d="M43.0003 103.134C41.7055 103.134 40.6548 102.067 40.6548 100.753V84.8869C40.6548 83.573 41.7055 82.5068 43.0003 82.5068C44.2951 82.5068 45.3459 83.573 45.3459 84.8869V100.753C45.3459 102.067 44.2951 103.134 43.0003 103.134Z" fill="white"/><path d="M68.0179 49.1867C66.7231 49.1867 65.6723 48.1205 65.6723 46.8066V27.7669C65.6723 15.0799 55.5023 4.76015 42.9993 4.76015C30.4963 4.76015 20.3263 15.0799 20.3263 27.7669V46.8066C20.3263 48.1205 19.2756 49.1867 17.9808 49.1867C16.686 49.1867 15.6353 48.1205 15.6353 46.8066V27.7669C15.6362 12.4549 27.9121 0 43.0002 0C58.0883 0 70.3643 12.4549 70.3643 27.7669V46.8066C70.3643 48.1205 69.3136 49.1867 68.0188 49.1867H68.0179Z" fill="white"/></g><defs><clipPath id="clip0_2067_4857"><rect width="86" height="119" fill="white"/></clipPath></defs></svg>');
                background-repeat: no-repeat;
                background-size: contain;
                z-index: 2;
                @include media-breakpoint-down(sm) {
                    top: 30%;
                    left: 50%;
                    width: 86px; 
                    height: 119px;
                }
            }

            .audio-mystery-container-episode-content{
                position: relative;
                z-index: 2;
                .audio-controls{
                    display: none;
                }
                .gioca-cta{
                    opacity: 0.8;
                    filter: blur(4px);
                    cursor: none;
                    &:hover{
                        opacity: 0.8;
                    }
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                .bottom .login-cta{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 34px;
                    width: 177px;
                    border-radius: 16px;
                    background: #FE5000; 
                    text-align: center;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; 
                    margin-top: 10px;
                    color: #000;
                    text-align: center;
                    font-size: 13px;
                    &:hover{
                        opacity: 0.6;
                    }
                    @include media-breakpoint-down(sm) {
                        margin-top: 15px;
                        color: #000;
                        font-size: 14px;
                        margin-inline: auto;
                        text-transform: none;
                    }
                }
            }
        }
        
        &-episode {
            position: relative;
            width: 962px;
            height: 148px;
            border-radius: 7px;
            box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
            background-size: cover;
            background-repeat: no-repeat;
            margin-inline: auto;
            padding-inline: 16px;
            @include media-breakpoint-down(sm) {
                width: 320px;
                height: 358px;
                padding-bottom: 16px;
            }
            &-content {
                padding-left: 30%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                height: 100%;
                position: relative;
                z-index: 2;
                @include media-breakpoint-down(sm) {
                    padding-left: 0%;
                    justify-content: flex-end;
                }
                .top {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 600;
                    min-width: 100%;
                    z-index: 2;
                    margin-top: 6px;
                    @include media-breakpoint-down(sm) {
                        align-items: center;
                    }
                    .top-text {
                        display: block;
                        .episode-index {
                            color: white;
                            margin-bottom: 0px;
                            font-size: 12px;
                            line-height: 20px;
                            @include media-breakpoint-down(sm) {
                                font-size: 13px;
                                margin-bottom: 0px;
                            }
                        }
                        .title {
                            font-size: 25px;
                            line-height: 23px;
                            color: #fff;
                            margin-top: 2px;
                            margin-bottom: 10px;
                            @include media-breakpoint-down(sm) {
                                font-size: 23px;
                            }
                        }
                    }

                    .gioca-cta {
                        text-align: center;
                        font-size: 10px;
                        line-height: 20px;
                        min-height: 23px;
                        width: 77px;
                        border-radius: 11.5px;
                        border: 1px solid #fff;
                        justify-content: center;
                        cursor: pointer;
                        &:hover {
                            opacity: 0.6;
                        }
                    }
                }

                .bottom {
                    display: block;
                    width: 100%;
                   
                    .synopsis {
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 15px;
                        margin-top: 0px;
                        margin-bottom: 8px !important;
                    }
                    .login-cta{
                        display: none;
                    }
                }

                .audio-controls {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    @include media-breakpoint-down(sm) {
                        margin-top: -1%;
                    }
                    .audio-progression{
                        display: flex;
                        align-items: center;
                        gap: 7px;
                        width: 100%;
                    }
                    .play-pause-btn{
                        z-index: 2;
                        svg{
                            width: 48px;
                            height: 49px;
                        }
                    }
                    .play-pause-btn,
                    .rewind-btn,
                    .fast-forward-btn {
                        cursor: pointer;
                        &:hover{
                            opacity: 0.8;
                        }
                    }

                    .progress-bar {
                        width: 100%;
                        max-width: 355px;
                        height: 2px;
                        cursor: pointer;
                        border-radius: 7px;
                        @include media-breakpoint-down(sm) {
                            max-width: 148px;
                        }
                    }
                    input[type="range"] {
                        -webkit-appearance: none;
                        appearance: none; 
                        width: 100%;
                        cursor: pointer;
                        outline: none;
                        border-radius: 7px;
                        background: linear-gradient(to right, #FF4B0F 0%, #FFFFFF 0%);

                        &::-webkit-slider-thumb {
                            -webkit-appearance: none;
                            appearance: none;
                            height: 6px;
                            width: 6px;
                            background-color: #FFF;
                            border-radius: 50%;
                            cursor: pointer;
                        }
                    
                        &::-moz-range-thumb {
                            height: 6px;
                            width: 6px;
                            background-color: #FFF;
                            border-radius: 50%;
                            cursor: pointer;
                        }
                    
                        &::-ms-thumb {
                            height: 6px;
                            width: 6px;
                            background-color: #FFF;
                            border-radius: 50%;
                            cursor: pointer;
                        }
                      }


                    .time-display {
                        text-align: center;
                        font-size: 10px;
                        font-weight: 400;
                        text-transform: capitalize;
                        margin-left: 4px;
                    }
                }
            }
        }
    }
}
