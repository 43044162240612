.blackfriday24-section{
    .responsivegrid{
        max-width: 1138px !important;
    }

}

bat-carousel-gloitblackfriday24{
    background-color: transparent;
    padding-top: 60px;
    max-width: 1200px;
    .background-container{
        padding-bottom: 50px;
        padding-top: 25px;
        position: relative;
        background-color: #000;
        max-width: 1200px;
        margin-inline: auto;
        border-radius: 40px;
        @include media-breakpoint-down(md) {
            max-width: 355px;
            height: 664px;
            margin-inline-end: unset;
            margin-inline: auto;
            padding-bottom: 35px;
        }
        .text-container{
            display: block;
            position: absolute;
            bottom: 14.6%;
            left: 0%;
            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: row;
                gap: 26px;
                align-items: flex-start;
                bottom: unset;
                top: 3.7%;
                width: 100%;
            }

            img{
                @include media-breakpoint-down(md) {
                    max-width: 130px;
                }
            }

            .text-cta-cnt{
                @include media-breakpoint-down(md) {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    justify-content: flex-start;
                    width: 100%;
                }
                .text{
                    color: var(--Glo-White, #FFF);
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 130%; /* 31.2px */
                    margin-left: 12%;
                    margin-top: 15px;
                    @include media-breakpoint-down(md) {
                        font-size: 20px;
                        margin-left: 0px;
                        margin-top: 0px;
                    }
                }
                .cta-confronto{
                    margin-top: 32px;
                    color: var(--glo-2024-White, #FFF);
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-decoration-line: underline;
                    margin-left: 12%;
                    max-width: fit-content;
                    @include media-breakpoint-down(md) {
                        margin-left: 0px;
                        margin-top: 0px;
                    }
    
                    &::before {
                        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none"><path d="M8.74365 16.8477H14.9024C15.427 16.8477 15.8478 16.4215 15.8478 15.9023V3.5848C15.8478 3.06019 15.4216 2.6394 14.9024 2.6394H8.74365" stroke="white" stroke-width="1.19231" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2.91 2.91"/><path d="M8.74357 2.6394H2.5848C2.06019 2.6394 1.6394 3.06565 1.6394 3.58481V15.8969C1.6394 16.4215 2.06565 16.8423 2.5848 16.8423H8.74357" stroke="white" stroke-width="1.19231" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.74365 17.9407V1.54651" stroke="white" stroke-width="1.19231" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 8px; 
                    }
                }
            }
        }
        .bat-carousel-gloitblackfriday24-container {
            left: 24%;
            margin-top: 40px;
            color: $white;
            max-width: 100% !important;
            padding-left: 20px;
            @include media-breakpoint-down(md) {
                left: 0.5%;
                bottom: 0%;
                position: absolute;
                margin-top: 0px;
                padding-bottom: 20px;
            }
            @media (max-width: 850px) {
                margin-top: 20px;
             }
        
            .slick-track {
                margin-bottom: 20px;
                gap: 20px;
            }
        
            .bat-carousel-slides {
                margin-inline: auto;
                .slick-slide {
                    height: 100%;
                    background: transparent;
                    position: relative;
        
                    @media (max-width: 850px) {
                        margin-right: 15px;
                        max-width: 303px !important;
                    }
        
                    &:last-child{
                        margin-right: 0px;
                    }
        
        
                    .img-wrapper{
                        max-width: 225px;
                        height: auto;
                        margin-inline: auto;
                    }
        
                    .cart-link-container{
                        border-top: none;
        
                        a{
                            span{
                                color: var(--glo-2024-Black, #000);
                                font-family: Mont;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                margin-right: 0px;
                                margin-inline: auto;
                            }
        
                            &::after{
                                display: none;
                            }
                        }
                        .button-cart{
                            min-height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-inline: 34px;
                            border-radius: 100px;
                            border: 2px solid var(--glo-2024-Black, #000);
                            background-color: transparent;
                            &:hover{
                                transform: none !important;
                                opacity: 0.8;
                            }
                        }
                    }
        
                    .text{
                        color: var(--glo-2024-Black, #000);
                        font-family: Mont;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 110%; /* 22px */
                    }
        
                    .price{
                        color: var(--glo-2024-Black, #000);
                        font-family: Mont;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 20px; /* 83.333% */
                    }
        
                    .slide-text {
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 24px;
                    }
        
                    .plus-icon {
                        right: 11px;
                        border-radius: 50%;
                        position: absolute;
                        bottom: 5px;
                    }
                }
            }
        }
    }


    @media (min-width: 850px) {
        .bat-carousel-gloitblackfriday24-container {
    
            .uk-flex.uk-flex-middle{
                display: none !important;
            }
            .slick-list {
                padding: 0 !important;
    
                .slick-track {
                    width: 100% !important;
                    margin: 0;
                    display: flex;
                    //justify-content: space-between;
    
                    &::before,
                    &::after {
                        display: none !important;
                    }
    
                    .slick-slide {
                        width: fit-content !important;
                    }
                }
            }
    
            .arrows-container {
                display: none;
            }
        }
    }
    
    @media (max-width: 850px) {
        .bat-carousel-gloitblackfriday24-container {
    
            .uk-flex.uk-flex-middle{
                display: none !important;
            }
    
            .slide {
                margin-right: 12px;
            }
    
            .slick-dots {
                position: initial;
                height: fit-content !important;
                margin: 0 !important;
                margin-top: 30px;
    
                li {
                    margin-top: 0 !important;
                    padding-top: 0 !important;
    
                    button {
                        background-color: #e5e1e6 !important;
                        border: 1px solid #e5e1e6 !important;
                        width: 12px !important;
                        height: 12px !important;
                        opacity: 0.6;
                        overflow: hidden;
                    }
                }
    
                .slick-active {
                    button {
                        width: 12px !important;
                        opacity: 1;
                    }
                }
            }
    
            .arrows-container {
                position: absolute;
                bottom: 0;
                right: 12px;
                button {
                    position: relative !important;
                    transform: none !important;
                    margin-right: 18px;

                    .bat-icon{
                        width: 38px !important;
                        height: 38px !important;
                    }

                    &.slick-disabled{
                        opacity: 0.5;
                    }

                }
            }
    
            .navigation-arrows {
                position: relative;
            }
        }
    }
}



