.redeem-modal{
	width: 585px;
    background: linear-gradient(45deg, #5482c2 0, #353174 100%);
    box-shadow: 16px 16px 0px #D5DDEC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
	
	.uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

	.first-section-modal {		
        margin-top: 60px;
        width: max-content;

        @include media-breakpoint-down(md) {
            margin-top: 80px;
        }
        
        .redeem-code-box {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 24px;
            gap: 8px;
            background: #0B1047;
            border-radius: 24px;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: $white;

            .icon-redeem {
                height: 48px;
                width: 48px;

                &::before {
                    transform: scale(2.4);
                    height: 32px;
                }
            }
        }
	}

	.second-section-modal {
		display: flex;
        flex-direction: column;
        justify-content: center;

        .modal-title {
            font-weight: 700;
            font-size: 26px;
            line-height: 30px;
            text-align: center;
            color: $white;
        }

        .description-modal {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: $white;
        }

	}

    .third-section-modal {

        width: 100%;
        padding-left: 24px;
        padding-right: 24px;

        @include media-breakpoint-down(md) {
            padding-left: 10px;
            padding-right: 10px;
        }
        
        .digits {
            display: flex;
            gap: 10px;
            justify-content: center;
            width: 100%;

            @include media-breakpoint-down(md) {
                gap: 3px;
            }

            input {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px;
                background: #fff;
                font-weight: 800;
                text-align: center;
                font-size: 26px;
                line-height: 38px;
                color: #2A2C2C;
                width: 100%;
                max-width: 320px;
                font-family: 'Mont';

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    line-height: 35px;
                    padding: 0;
                }

                &:placeholder-shown {
                    font-size: 15px;
                    font-weight: 600;
                }
            }
        }
    }

    .fourth-section-modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 24px;
        margin-right: 24px;

        .cta-verify {
            padding: 10px 32px;
            background: $white;
            border-radius: 24px;
            font-family: inherit;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: #2A2C2C;
            margin-bottom: 40px;

            @include media-breakpoint-down(md) {

                margin-bottom: 24px;
                span {
                    width: max-content;
                }
            }

            &:hover {
                opacity: 0.9;
            }

            &:disabled {
                opacity: 0.8;
                pointer-events: none;
            }
        }

        .redeem-error-message {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 24px;
            gap: 16px;
            background: #FDF3F5;
            border-radius: 8px;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.2px;
            color: #E00830;
            margin-bottom: 40px;
            max-width: 420px;

            @include media-breakpoint-down(md) {
                margin-bottom: 24px;
                width: 100%;
            }

            .icon-warning {
                &::before {
                    content: url('/content/dam/glo-it/images/newloyalty/Warning.svg');
                }
            }
        }
    }
}