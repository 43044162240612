.bat-mentholban-gloit{
    &-mail-image{
        max-width: fit-content;
        margin: 40px auto;
    }

    &-content{
        padding: 0px 180px;

        @include media-breakpoint-down(sm){
            padding: 0px 10px;
        }
        .content-title{
            @include font-rem(42px, 46px);
            font-weight: bold;
            margin-bottom: 40px;
        }

        .content-container{
            .title{
                @include font-rem(24px, 26px);
                margin-bottom: 40px;
            }

            .text{
                @include font-rem(16px, 24px);
                margin-bottom: 40px;
            }

            .list{
                margin-bottom: 40px;

                .list-item{
                    @include font-rem(16px, 24px);
                }
            }
        }
    }

    &-trova-lo-store{
        padding: 0px 180px;
        margin-top: 60px;
        margin-bottom: 60px;
        justify-content: space-between;

        @include media-breakpoint-down(sm){
            padding: 0px 10px;
            display: block;
        }

        .trova-lo-store-container{
            margin: auto 120px;

            @include media-breakpoint-down(sm){
                margin: 60px 40px 0px;
            }

            .title{
                font-size: 26px;
                margin-bottom: 10px;
            }

            .subtitle{
                font-size: 18px;
            }

            form{
                .input-container{
                    margin-top: 10px;
                    margin-bottom: 30px;

                    .input{
                        margin: auto;
                        font-size: 13px;
                        height: 45px;
                        border-radius: 0px;
                    }

                    .mapicon-button{
                        width: 55px;

                        .mapicon{
                            width: 45px;
                        }
                    }
                }

                .cta-container{
                    .cerca-cta{
                        background-color: $black;
                        color: $white;
                        height: 45px;
                        border-radius: 0px;
                    }
                }
            }
        }
    }
}