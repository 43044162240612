.glo-it-neotobacco{
	background-color: $concrete;
	.logo{
		max-width:265px;
	}
	.uk-container{
		max-width: $site-max-width;
	}
	.neotobacco-discover{
		@include media-breakpoint-down(sm){
			flex-direction: column-reverse;
		}
		.text{
			max-width: 495px;
			@include media-breakpoint-up(sm){
				margin-right: 20px;
			}
		}
		.title{
			margin-top: 15px;
			margin-bottom: 15px;
			@include media-breakpoint-up(sm){
				font-size: 36px;
				line-height: 36px;
				font-weight: 800;
			}
			@include media-breakpoint-down(sm){
				font-size: 24px;
				line-height: 24px;
			}
		}
		.description{
			font-size: 16px;
			line-height: 24px;
			font-weight: 500;
		}
		.description-bold{
			font-size: 16px;
   			line-height: 24px;
    		font-weight: 700;
			margin-top: 15px;
		}
		.img-info-button{
			margin-top:15px;
			margin-bottom: 15px;
			@include media-breakpoint-down(lg){
				justify-content: center;
			}
		}
		.overlapping-img{
			@include media-breakpoint-up(md){
				margin-top: -150px;
			}
			@include media-breakpoint-down(sm){
				margin-top: -60px;
			}

			img{
				border-radius: 35px;
			}
		}
	}
	.uk-card{
		max-width: 170px;
		@include media-breakpoint-up(md){
			max-width: 270px;
		}
		&-body{
			padding: 0;
		}

		.textlabel{
			text-align: left;
			margin-top: 20px;
			font-size: 15px;
		}
	}
	
}

.neo-text{
	.bat-headline-style{
		color: var(--Glo-Brand-Black, #2A2C2C);
		text-align: center;
		font-size: 40px !important;
		font-style: normal;
		font-weight: 800;
		line-height: 32px !important; /* 80% */
		text-transform: uppercase;

		@include media-breakpoint-down(md){
			color: #3A3A3A;
			font-size: 28px !important;
			font-style: normal;
			line-height: 110%; /* 30.8px */
		}
	}
}

.neo-sticks-page{

	.glo-it-neotobacco-slider{
		.card-item .uk-card-body{
			margin-bottom: 50px;
		}

		.uk-container {
			justify-content: center;
	
			.card-container-desktop{
				flex-wrap: wrap;
				justify-content: center;
			}
		}
	}
	
}

.glo-it-neotobacco-slider{
	background-color: $concrete;
	.logo{
		max-width:265px;
	}
	.uk-container{
		max-width: $site-max-width;
		display: flex;
		@include media-breakpoint-down(sm){
			display: block;
		}
		.card-container-desktop{
			gap: 25px;
			display: flex;
			flex-direction: row;
		}
	}

	.slider-container{
		@include media-breakpoint-down(sm){
			overflow: visible;
		}
	}
	
	.neotobacco-discover{
		@include media-breakpoint-down(sm){
			flex-direction: column-reverse;
		}
		.text{
			max-width: 495px;
			@include media-breakpoint-up(sm){
				margin-right: 20px;
			}
		}
		.title{
			margin-top: 15px;
			margin-bottom: 15px;
			@include media-breakpoint-up(sm){
				font-size: 36px;
				line-height: 36px;
				font-weight: 800;
			}
			@include media-breakpoint-down(sm){
				font-size: 24px;
				line-height: 24px;
			}
		}
		.description{
			font-size: 16px;
			line-height: 24px;
			font-weight: 500;
		}
		.description-bold{
			font-size: 16px;
   			line-height: 24px;
    		font-weight: 700;
			margin-top: 15px;
		}
		.img-info-button{
			margin-top:15px;
			margin-bottom: 15px;
			@include media-breakpoint-down(lg){
				justify-content: center;
			}
		}
		.overlapping-img{
			@include media-breakpoint-up(lg){
				margin-top: -50px;
			}
		}
	}

	.card-item{
		
		.uk-card{
			max-width: 170px;
			@include media-breakpoint-up(md){
				max-width: 270px;
			}
			@include media-breakpoint-down(sm){
				max-width: 135px;
			}
			&-body{
				padding: 0;
			}

			.textlabel{
				text-align: left;
				margin-top: 20px;
				font-size: 15px;
			}
		}

		.picture{
			position:relative
		}
		.veo-card{
			.veo-card-seo-h3{
				position: absolute;
				height: 75%;
				width: 100%;
				@include media-breakpoint-down(sm){
					height: 62%;
				}
				h3{
					opacity: 0;
				}
			}
		}
	}

	.card-item:not(:first-child) {
		@include media-breakpoint-down(sm){
			padding-left: 10px;
		}
	}
	
}

