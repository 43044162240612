.gloit-selectdateslounge{

	.gloit-selectdateslounge-content{
		height: 100%;
		background: #fff;
		position: relative;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		overflow-wrap: break-word;

		.gloit-selectdateslounge-close-button{
			background: #fff;
			top: 24px;
			right: 6%;
			height: 40px;
			width: 40px;
			padding: 5px;
			position: absolute;
			border-radius: 100%;
		}
		.gloit-selectdateslounge-title-container{
			margin: 5%;
			.gloit-selectdateslounge-title{
				text-align: center;
				font-size: 30px;
				font-weight: 700;
				color: #000;
				line-height: 36px;
				margin-top: 20%;
				
			}
		}

		.gloit-selectdateslounge-event-details{
			display: flex;
			flex-direction: column;
			margin: 7%;
			height: auto;

			&-title{
				font-size: 22px;
				font-weight: 700;
				margin-bottom: 5%;
				line-height: 25px;
			}

			.gloit-selectdateslounge-event-date,.gloit-selectdateslounge-event-location{
				display: flex;
				flex-direction: row;
				gap: 1%;

				&-container{
					width: 5%;
				}
			}

			.gloit-event-qrcode-dettagli-evento-info-details{
				span{
					width: 90%;
				}
			}
		}
		
		.gloit-selectdateslounge-dates-containter{
			padding: 0 3%;
	
			.gloit-selectdateslounge-appuntamenti-title,.gloit-selectdateslounge-morning-title,.gloit-selectdateslounge-afternoon-title{
				font-family: Mont;
				font-size: 12px;
				font-weight: 400;
				line-height: 18px;
				text-align: left;
				color: #6b6b6b;
				display: flex;
				padding: 0 2%;align-items: center;
				gap: 2%;
				margin-top: 5%;
				margin-bottom: 5%;
			}
			.gloit-selectdateslounge-appuntamenti-container,.gloit-selectdateslounge-morning-container,.gloit-selectdateslounge-afternoon-container{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				gap: 5px;
				padding: 0 2%;
				align-items: center;
	
				.gloit-lounge-available-morning-hours-btn,.gloit-lounge-available-afternoon-hours-btn{
					border-radius: 30px;
					height: 40px;
					padding: 0px;
					width: 60px;
					border: 2px solid #CD4A04;

					&.button-dark{
						color: white !important;
						font-weight: 700 !important;
					}

					&.button-secondary-light{
						color:black !important;
						font-weight: 700 !important;
					}

					&.disabled{
						border: none;
						background: #DEDEDE;
					}
				}
				.gloit-lounge-date-btn{
					display: flex;
					flex-direction: column;
					
					border-radius: 24px;
					height: 60px;
					padding: 0px;
					width: 65px;
					border: 2px solid #CD4A04;

					span{
						pointer-events: none;
					}

					.gloit-lounge-day-text{
						text-transform: capitalize !important;
					}

					&.button-dark{
						color: white !important;
						font-weight: 700 !important;
					}

					&.button-secondary-light{
						color:black !important;
						font-weight: 700 !important;
						
					}
				}
			}
			.gloit-selectdateslounge-title{
				text-align: center;
				font-size: 30px;
				font-weight: 700;
				color: black;
				line-height: 36px;
				margin-top: 20%;
			}
	
			.gloit-selectdateslounge-close-button{
				background: #fff;
				top: 24px;
				right: 6%;
				height: 40px;
				width: 40px;
				padding: 5px;
				position: absolute;
				border-radius: 100%;
			}
		}

		.gloit-selectdateslounge-btn-container{
			display: flex;
			height: 100%;
			align-items: flex-end;
			justify-content: center;
			margin-top: 5%;
			.gloit-selectdateslounge-confirm-btn{
				height: 40px;
				bottom: 5%;
			}
		}
	}
	
}