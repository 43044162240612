bat-carousel-gloitredesignhyperpro{
    .bat-icon{
        height: 38px !important;
        width: 38px !important;
    }
    &.pdp-hyper-core{

        @media (max-width: 850px) {
            .bat-carousel-gloitredesignhyperpro-container {
        
                .uk-flex.uk-flex-middle{
                    display: none !important;
                }
        
                .slide {
                    margin-right: 12px;
                }
        
                .slick-dots {
                    li {
        
                        button {
                            background-color: var(--glo-2024-glo-Charcoal, #2A2A2A) !important;
                            border: 1px solid #2A2A2A !important;
                        }
                    }
        
                    .slick-active {
                        button {
                            width: 8px !important;
                            opacity: 1;
                            -webkit-transform: translateZ(0);
                        }
                    }
                }
        
                .arrows-container {
                    position: absolute;
                    bottom: 0;
                    right: 12px;
                    margin-right: -18px;
        
                    button {
                        position: relative !important;
                        transform: none !important;
                        margin-right: 18px;
                    }
                }
        
                .navigation-arrows {
                    position: relative;
                }
            }
        }
    }
    &.black-background{
        background-color: black;
    }

    &.productcard-carousel-pro{
        &.pdp-hyper-core{
            .slick-dots{
                display: none !important;
            }
            .arrows-container{
                display: none !important;
            }
        }
        .slick-slide {
            padding: 0px !important;
            background: none !important;
            position: relative !important;
            margin-right: 0px !important;

            @media (max-width: 850px) {
               margin-right: 20px; 
               min-width: 280px !important;
            }

            &:last-child{
                margin-right: 0px;
            }


            .img-wrapper{
                max-width: 199px;
                height: auto;
                margin-inline: auto;
            }

            .cart-link-container{
                border-top: none;

                a{
                    span{
                        color: var(--glo-2024-Black, #000);
                        font-family: Mont;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin-right: 0px;
                        margin-inline: auto;
                    }

                    &::after{
                        display: none;
                    }
                }
                .button-cart{
                    min-height: 42px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-inline: 34px;
                    border-radius: 100px;
                    border: 2px solid var(--glo-2024-Black, #000);
                    background-color: transparent;
                    &:hover{
                        transform: none !important;
                        opacity: 0.8;
                    }
                }
            }

            .text{
                color: var(--glo-2024-Black, #000);
                font-family: Mont;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%; /* 22px */
            }

            .price{
                color: var(--glo-2024-Black, #000);
                font-family: Mont;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px; /* 83.333% */
            }

            .slide-text {
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
            }

            .plus-icon {
                right: 11px;
                border-radius: 50%;
                position: absolute;
                bottom: 5px;
            }
        }

        .bat-carousel-gloitredesignhyperpro-container {
            @media (max-width: 850px) {
                margin-top: 0px;
             }
        }
    }

    .bat-carousel-gloitredesignhyperpro-container {
        margin-top: 40px;
        color: $white;
        max-width: 100% !important;
        padding-left: 20px;
    
        @media (max-width: 850px) {
            margin-top: 20px;
         }
    
        .slick-track {
            gap: 25px;
        }
    
        .bat-carousel-slides {
            margin-inline: auto;
            .slick-slide {
                padding: 20px;
                border-radius: 40px;
                height: 100%;
                background: white;
                position: relative;
                margin-right: 40px;
    
                @media (max-width: 850px) {
                   margin-right: 20px; 
                   max-width: 230px !important;
                }
    
                &:last-child{
                    margin-right: 0px;
                }
    
    
                .img-wrapper{
                    max-width: 199px;
                    height: auto;
                    margin-inline: auto;
                }
    
                .cart-link-container{
                    border-top: none;
    
                    a{
                        span{
                            color: var(--glo-2024-Black, #000);
                            font-family: Mont;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin-right: 0px;
                            margin-inline: auto;
                        }
    
                        &::after{
                            display: none;
                        }
                    }
                    .button-cart{
                        min-height: 42px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-inline: 34px;
                        border-radius: 100px;
                        border: 2px solid var(--glo-2024-Black, #000);
                        background-color: transparent;
                        &:hover{
                            transform: none !important;
                            opacity: 0.8;
                        }
                    }
                }
    
                .text{
                    color: var(--glo-2024-Black, #000);
                    font-family: Mont;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 110%; /* 22px */
                }
    
                .price{
                    color: var(--glo-2024-Black, #000);
                    font-family: Mont;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px; /* 83.333% */
                }
    
                .slide-text {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                }
    
                .plus-icon {
                    right: 11px;
                    border-radius: 50%;
                    position: absolute;
                    bottom: 5px;
                }
            }
        }
    }

    @media (min-width: 850px) {
        .bat-carousel-gloitredesignhyperpro-container {
    
            .uk-flex.uk-flex-middle{
                display: none !important;
            }
            .slick-list {
                padding: 0 !important;
    
                .slick-track {
                    width: 100% !important;
                    margin: 0;
                    display: flex;
                    justify-content: space-between;
    
                    &::before,
                    &::after {
                        display: none !important;
                    }
    
                    .slick-slide {
                        width: fit-content !important;
                    }
                }
            }
    
            .arrows-container {
                display: none;
            }
        }
    }
    
    @media (max-width: 850px) {
        .bat-carousel-gloitredesignhyperpro-container {
    
            .uk-flex.uk-flex-middle{
                display: none !important;
            }
    
            .slide {
                margin-right: 12px;
            }
    
            .slick-dots {
                position: initial;
                height: fit-content !important;
                padding-top: 39px;
                margin: 0 !important;
                -webkit-display: flex;
                -webkit-transform: translateZ(0);
                -webkit-justify-content: flex-start;
                -webkit-slign-items: center;
    
                li {
                    margin-top: 0 !important;
                    padding-top: 0 !important;
                    -webkit-transform: translateZ(0);
    
                    button {
                        background-color: #e5e1e6 !important;
                        border: 1px solid #e5e1e6 !important;
                        width: 8px !important;
                        height: 8px !important;
                        opacity: 0.6;
                        -webkit-transform: translateZ(0);
                        overflow: hidden;
                    }
                }
    
                .slick-active {
                    button {
                        width: 8px !important;
                        opacity: 1;
                        -webkit-transform: translateZ(0);
                    }
                }
            }
    
            .arrows-container {
                position: absolute;
                bottom: 0;
                right: 12px;
                margin-right: -18px;
    
                button {
                    position: relative !important;
                    transform: none !important;
                    margin-right: 18px;
                }
            }
    
            .navigation-arrows {
                position: relative;
            }
        }
    }
}



bat-carousel-gloitredesignhyperpro.pdp-hyperpro-news-carousel{

    &.pdp-hyper-core{
        @media (max-width: 850px) {
            .bat-carousel-gloitredesignhyperpro-container {
                .slides-container .bat-carousel-slides .slick-slide{
                    background: transparent;
                    margin-left: 10px !important;
                    box-shadow: none;
                }
                .slick-dots {
                    li {
        
                        button {
                            background-color: #2A2A2A !important;
                            border: 1px solid #2A2A2A !important;
                        }
                    }
                }
   
            }
        }
    }
    .bat-carousel-gloitredesignhyperpro-container {
        margin-top: 40px;
        color: $white;
        max-width: 100% !important;
        padding-left: 20px;
    
        @media (max-width: 850px) {
            margin-top: 20px;
         }
    
        .slick-track {
            gap: 25px;
        }
    
        .bat-carousel-slides {
            margin-inline: auto;
            margin-top: 14px;
            .slick-slide {
                padding: 0px 0px 26px 0px;
                border-radius: 30px;
                height: 100%;
                background: #2A2A2A;
                position: relative;
                box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.16);
                margin-right: 40px;
    
                @media (max-width: 850px) {
                   margin-right: 16px; 
                   max-width: 272px !important;
                   padding: 0px;
                }
    
                &:last-child{
                    margin-right: 0px;
                }

            }
        }
    }

    @media (min-width: 850px) {
        .bat-carousel-gloitredesignhyperpro-container {
    
            .uk-flex.uk-flex-middle{
                display: none !important;
            }
            .slick-list {
                padding: 0 !important;
    
                .slick-track {
                    width: 100% !important;
                    margin: 0;
                    display: flex;
                    justify-content: space-between;
    
                    &::before,
                    &::after {
                        display: none !important;
                    }
    
                    .slick-slide {
                        width: fit-content !important;
                    }
                }
            }
    
            .arrows-container {
                display: none;
            }
        }
    }
    
    @media (max-width: 850px) {
        .bat-carousel-gloitredesignhyperpro-container {
    
            .uk-flex.uk-flex-middle{
                display: none !important;
            }
    
            .slide {
                margin-right: 12px;
            }
    
            .slick-dots {
                position: initial;
                height: fit-content !important;
                padding-top: 39px;
                margin: 0 !important;
                gap: 6px;
                -webkit-display: flex;
                -webkit-transform: translateZ(0);
                -webkit-justify-content: flex-start;
                -webkit-slign-items: center;
    
                li {
                    margin-top: 0 !important;
                    padding-top: 0 !important;
                    -webkit-transform: translateZ(0);
    
                    button {
                        background-color: #2A2A2A !important;
                        border: 1px solid #2A2A2A !important;
                        width: 8px !important;
                        height: 8px !important;
                        overflow: hidden;
                        opacity: 0.6;
                        -webkit-transform: translateZ(0);
                    }
                }
    
                .slick-active {
                    button {
                        width: 8px !important;
                        height: 8px !important;
                        opacity: 1;
                        -webkit-transform: translateZ(0);
                    }
                }
            }
    
            .arrows-container {
                position: absolute;
                bottom: 0;
                right: 0;
                padding-right: 20px;
    
                button {
                    position: relative !important;
                    transform: none !important;
                    margin-right: 12px;
                }
            }
    
            .navigation-arrows {
                position: relative;
            }
        }
    }
}


bat-carousel-gloitredesignhyperpro.video-carousel-pro{

    &.pdp-hyper-core{
        @media (max-width: 850px) {
            .bat-carousel-gloitredesignhyperpro-container {
                .slick-dots { 
                    li {
        
                        button {
                            background-color: #2A2A2A !important;
                            border: 1px solid #2A2A2A !important;
                        }
                    }
                }
            }
        }
    }
    &.black-background{
        background-color: black;
    }

    &.productcard-carousel-pro{
        .slick-slide {
            padding: 0px !important;
            background: none !important;
            position: relative !important;
            margin-right: 0px !important;

            @media (max-width: 850px) {
               margin-right: 20px; 
               min-width: 280px !important;
            }

            &:last-child{
                margin-right: 0px;
            }


            .img-wrapper{
                max-width: 199px;
                height: auto;
                margin-inline: auto;
            }

            .cart-link-container{
                border-top: none;

                a{
                    span{
                        color: var(--glo-2024-Black, #000);
                        font-family: Mont;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin-right: 0px;
                        margin-inline: auto;
                    }

                    &::after{
                        display: none;
                    }
                }
                .button-cart{
                    min-height: 42px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-inline: 34px;
                    border-radius: 100px;
                    border: 2px solid var(--glo-2024-Black, #000);
                    background-color: transparent;
                    &:hover{
                        transform: none !important;
                        opacity: 0.8;
                    }
                }
            }

            .text{
                color: var(--glo-2024-Black, #000);
                font-family: Mont;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%; /* 22px */
            }

            .price{
                color: var(--glo-2024-Black, #000);
                font-family: Mont;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px; /* 83.333% */
            }

            .slide-text {
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
            }

            .plus-icon {
                right: 11px;
                border-radius: 50%;
                position: absolute;
                bottom: 5px;
            }
        }

        .bat-carousel-gloitredesignhyperpro-container {
            @media (max-width: 850px) {
                margin-top: 0px;
             }
        }
    }

    .bat-carousel-gloitredesignhyperpro-container {
        margin-top: 40px;
        color: $white;
        max-width: 100% !important;
        padding-left: 20px;
    
        @media (max-width: 850px) {
            margin-top: -20px;
         }
    
        .slick-track {
            gap: 25px;
        }
    
        .bat-carousel-slides {
            margin-inline: auto;
            .slick-slide {
                padding: 0px;
                border-radius: 40px;
                height: 100%;
                background: transparent;
                position: relative;
                margin-right: 40px;
    
                @media (max-width: 850px) {
                   max-width: none !important;
                }
    
                &:last-child{
                    margin-right: 0px;
                }
    
    
                .img-wrapper{
                    max-width: 199px;
                    height: auto;
                    margin-inline: auto;
                }
    
                .cart-link-container{
                    border-top: none;
    
                    a{
                        span{
                            color: var(--glo-2024-Black, #000);
                            font-family: Mont;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin-right: 0px;
                            margin-inline: auto;
                        }
    
                        &::after{
                            display: none;
                        }
                    }
                    .button-cart{
                        min-height: 42px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-inline: 34px;
                        border-radius: 100px;
                        border: 2px solid var(--glo-2024-Black, #000);
                        background-color: transparent;
                        &:hover{
                            transform: none !important;
                            opacity: 0.8;
                        }
                    }
                }
    
                .text{
                    color: var(--glo-2024-Black, #000);
                    font-family: Mont;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 110%; /* 22px */
                }
    
                .price{
                    color: var(--glo-2024-Black, #000);
                    font-family: Mont;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px; /* 83.333% */
                }
    
                .slide-text {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                }
    
                .plus-icon {
                    right: 11px;
                    border-radius: 50%;
                    position: absolute;
                    bottom: 5px;
                }
            }
        }
    }

    @media (min-width: 850px) {
        .bat-carousel-gloitredesignhyperpro-container {
    
            .uk-flex.uk-flex-middle{
                display: none !important;
            }
            .slick-list {
                padding: 0 !important;
    
                .slick-track {
                    width: 100% !important;
                    margin: 0;
                    display: flex;
                    justify-content: space-between;
    
                    &::before,
                    &::after {
                        display: none !important;
                    }
    
                    .slick-slide {
                        width: fit-content !important;
                    }
                }
            }
    
            .arrows-container {
                display: none;
            }
        }
    }
    
    @media (max-width: 850px) {
        .bat-carousel-gloitredesignhyperpro-container {
    
            .uk-flex.uk-flex-middle{
                display: none !important;
            }
    
            .slide {
                margin-right: 12px;
            }
    
            .slick-dots {
                position: initial;
                height: fit-content !important;
                padding-top: 39px;
                margin: 0 !important;
                -webkit-display: flex;
                -webkit-transform: translateZ(0);
                -webkit-justify-content: flex-start;
                -webkit-slign-items: center;
    
                li {
                    margin-top: 0 !important;
                    padding-top: 0 !important;
                    -webkit-transform: translateZ(0);
    
                    button {
                        background-color: #e5e1e6 !important;
                        border: 1px solid #e5e1e6 !important;
                        width: 8px !important;
                        height: 8px !important;
                        overflow: hidden;
                        opacity: 0.6;
                        -webkit-transform: translateZ(0);
                    }
                }
    
                .slick-active {
                    button {
                        width: 8px !important;
                        opacity: 1;
                        -webkit-transform: translateZ(0);
                    }
                }
            }
    
            .arrows-container {
                position: absolute;
                bottom: 0;
                right: 0;
                padding-right: 20px;
    
                button {
                    position: relative !important;
                    transform: none !important;
                    margin-right: 12px;
                }
            }
    
            .navigation-arrows {
                position: relative;
            }
        }
    }
}



