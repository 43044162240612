.entraclub-modal{
	max-width: 700px;
	
	.uk-close{
		color: white !important;
	}
	.first-section-modal{
		position: relative;
		text-align: center;
  		
		.modal-text{
			position: absolute;
			top: 50%;
  			left: 50%;
			width: 100%;
  			transform: translate(-50%, -50%);
			text-align: center;

			.modal-title{
				margin-top:20px;
				font-weight: 600;
				font-size: 36px;
				line-height: 40px;
				color: $white;
				@include media-breakpoint-down(lg) {
					margin-top:20px;
					font-size: 32px;
					line-height: 35px;
				}
			}
		}
	}
	.second-section-modal{
		padding: 18px;
		background-color: $brand-black;
		.description-modal{
			text-align: center;
			font-weight: 400;
			font-size: 14px;
			line-height: 145%;
			color: $white;
		}

		.bat-form---phone-number {
			label {
				font-weight: 400;
				font-size: 14px;
				line-height: 145%;
				color: $white;
				margin: 20px 0 10px 0;
			}

			input {
				max-width: 40%;
			}

			button {
				padding: 10px 28px;
			}

			.uk-flex {
				align-items: center;
				gap: 20px;
			}
		}

		.terms-checkbox-container {
			text-align: center;
			margin-top: 20px;

			.terms-checkbox-label {
				color: #45b7fc;
				font-size: 14px;

				a:hover {
					text-decoration: underline;
				}

				> input[type="checkbox"] {
                    height: 14px;
                    margin-right: 16px;

                    &::before {
                        background: $white;
                        font-size: 18px;
                        height: 17px;
                        width: 17px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #1d1d1b;
						content: "\e977";
    					font-family: icomoon;
                    }

                    &:checked::before {
                        color: $white;
						content: "\e96d";
						background: #6c6c6c;
						font-family: "icomoon";
						font-size: 18px;
                    }
                }
			}
		}

		.button-container{
			text-align: center;
			margin-top: 30px;
			margin-bottom: 10px;
		}
	}
}