.gloit-cancel-subscription-modal-body {
	display: none;
	position: fixed;
	z-index: 1000000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0px !important;
}

.gloit-cancel-subscription-modal-content {
	height: 100%;
	background: #fff;
	position: relative;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	overflow-wrap: break-word;
	
	.event-date, .event-location{
		line-height: 28px;
	}
}

#gloit-cancel-subscription-modal-close-button {
	background: #fff;
	top: 24px;
	right: 6%;
	height: 40px;
	width: 40px;
	padding: 5px;
	position: absolute;
	border-radius: 100%;
}

.gloit-cancel-subscription-title-container{
	margin: 5%;
}

.gloit-cancel-subscription-title {
	text-align: center;
	font-size: 30px;
	font-weight: bold;
	color: #000;
	line-height: 36px;
	margin-top: 30%;
}

.gloit-cancel-subscription-text {
	text-align: center;
	margin: 5%;
	margin-top: 0;
	font-size: 18px;
	line-height: 26px;
}

.gloit-cancel-subscription-image {
	width: 70%;
	margin: auto;
	margin-top: 35%;
	margin-bottom: 0;
}

.gloit-cancel-subscription-confirm-button-container {
	text-align: center;
	width: 90%;
	margin: 5%;
	color: #fff;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	gap: 15px;
}

.gloit-cancel-subscription-cancel-button-container {
	text-align: center;
	width: 90%;
	margin: 5%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.gloit-cancel-subscription-button{
	width: 100%;
}

.gloit-cancel-subscription-cancel-button{
	width: 100%;
}

.gloit-cancel-subscription-cancel-button-text {
	color: black;
}



.gloit-cancel-subscription-dettagli-evento {
	display: flex;
	flex-direction: column;
	margin: 7%;
	height: auto;
	margin-bottom: 25%;

	&-pretitle {
		font-size: 12px;
	}

	&-title {
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 5%;
		line-height: 25px;
	}
	&-info-details{
    		display: flex;
    		flex-direction: row;
    		margin-bottom: 1%;
    	}
    
}

.loading-wrapper {
	margin: 50px auto;
	align-items: center;
	display: flex;
	height: 110px;
	width: 100px;
	position: relative;

	p {
		text-align: center;
		width: 100%;
		font-size: 14px;
		margin: 0;
	}

	&:after {
		-webkit-animation: spin 2s linear infinite; /* Safari */
		animation: spin 2s linear infinite;
		border: 10px solid $light-gray;
		border-radius: 50%;
		border-top: 10px solid $purple;
		bottom: 0;
		content: "";
		height: 60px;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top: 0;
		width: 60px;
		z-index: 1;
	}
}