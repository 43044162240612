.plainpage-content,
.myaccount-content{
	background-color: #e5e5e5;

	@include media-breakpoint-down(sm){
		background-color: $white;
	}
}
bat-cta-gloitverticalnav {
	background-color: #e5e5e5;
	font-size: 2rem;
	height: 100%;
	.bat-cta-list--vertical {
		ul {
			list-style: none;
			padding: 40px 60px;
			width: 100%;

			@include media-breakpoint-down(sm){
				padding: 0px;
				margin-bottom: 0px;
			}

			li {
				text-transform: uppercase;
				align-items: center;
				border-bottom: 2px solid $light-gray;
				display: none;
				justify-content: space-between;
				margin: 0;
				min-height: 60px;
				padding-left: 25px;
				padding-right: 25px;

				span{
					@include font-rem(12px, 15px);
				}
				
				@include media-breakpoint-up(lg) {
					display: flex;
				}

				&:last-child {
					border-bottom: 0;
				}

				&.selected {
					display: flex;

					@include media-breakpoint-up(lg) {
						background-color: $brand-black;

						span{
							color: $white;
						}

						a:hover{
							span{
								color: $white;
							}
						}
					}
				}

				a {
					@extend .cta-link;
					align-items: center;
					height: 100%;
					margin-bottom: 0;
					@include media-breakpoint-down(md) {
						width: 90%;
					}
				}

				.icon-plus {
					cursor: pointer;

					@include media-breakpoint-up(lg) {
						display: none;
					}
				}

				.icon-minus{
					cursor: pointer;

					@include media-breakpoint-up(lg) {
						display: none;
					}
				}
			}
		}
	}

	// when plus is clicked all items become visible in mobile mode
	&.expanded {
		.bat-cta-list--vertical {
			ul {
				li {
					display: flex;

					.icon-plus{
						display: none;
					}

					.icon-minus{
						display: none;
					}

				}

				li:first-child{
					.icon-plus{
						display: block;
					}
					.icon-minus {
						display: block;
					}
				}
			}
		}
	}
}

.cq-dd-paragraph {
	height: 100%;

	& > .cta {
		height: 100%;
	}
}

bat-cta-gloitverticalmyaccount {
	background-color: #e5e5e5;
	font-size: 2rem;
	height: 100%;
	
	.bat-cta-list--vertical {
		min-height: 600px;
		ul {
			list-style: none;
			padding: 40px 60px;
			width: 100%;

			@include media-breakpoint-down(md){
				padding: 0px;
				margin-bottom: 0px;
			}

			li {
				text-transform: uppercase;
				align-items: center;
				border-bottom: 2px solid $light-gray;
				display: none;
				justify-content: space-between;
				margin: 0;
				min-height: 50px;
				padding-left: 25px;
				padding-right: 25px;

				span{
					@include font-rem(12px, 15px);
				}
				
				@include media-breakpoint-up(lg) {
					display: flex;
				}

				&:last-child {
					border-bottom: 0;
				}

				&.selected {
					display: flex;

					@include media-breakpoint-up(lg) {
						background-color: $brand-black;

						span{
							color: $white;
						}

						a:hover{
							span{
								color: $white;
							}
						}
					}
				}

				a {
					@extend .cta-link;
					align-items: center;
					height: 100%;
					margin-bottom: 0;
					@include media-breakpoint-down(md) {
						width: 90%;
					}
				}

				.icon-chevron-up {
					cursor: pointer;
					height: 14px;

					@include media-breakpoint-up(lg) {
						display: none;
					}

					&::before {
						font-size: 14px;
					}
				}

				.icon-chevron-down {
					cursor: pointer;
					height: 14px;

					@include media-breakpoint-up(lg) {
						display: none;
					}

					&::before {
						font-size: 14px;
					}
				}

				&.index-element {
					padding-left: 0;
					border-bottom: 1px solid #2A2C2C;

					@include media-breakpoint-down(md) {
						padding-left: 25px;
					}

					span {
						font-weight: 600;
						font-size: 20px;
						line-height: 28px;
						display: flex;
						align-items: center;
						color: #CD4A04;
						text-transform: none;

						@include media-breakpoint-down(md) {
							@include font-rem(12px, 15px);
						}
					}
				}
			}
		}
		@include media-breakpoint-down(sm){
			min-height: 50px;
		}
	}

	// when plus is clicked all items become visible in mobile mode
	&.expanded {
		@include media-breakpoint-down(md) {
			.bat-cta-list--vertical {
				ul {
					li {
						display: flex;

						.icon-chevron-down{
							display: none;
						}

						.icon-chevron-up{
							display: none;
						}

					}

					li:first-child{
						.icon-chevron-down{
							display: flex;
						}
						.icon-chevron-up {
							display: flex;
						}
					}
				}
			}
		}
	}
}

bat-cta-gloitmyaccountmobile {
	.bat-cta-list--vertical {
		padding: 32px 20px;
		display: flex;
		flex-direction: column;
		gap: 32px;
		ul {
			list-style: none;
			padding: 0;
			width: 100%;
			margin: 0;

			li {
				padding: 12px 0px;
				border-bottom: 1px solid #DEDEDE;
				margin: 0;

				a {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 8px;
					font: inherit;
					margin: 0

				}

				span{
					@include font-rem(16px, 24px);
				}

				&.title-element {
					padding: 0;
					@include font-rem(20px, 26px);
					font-weight: 700;
					border-bottom: unset;

				}
			}
		}
	}
}
