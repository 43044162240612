.bat-insiderstab-gloit{
    &-buttons{
        justify-content: flex-end;
        border-bottom: 1px solid #b0b0b0;
        
        &-button{
            padding: 20px 40px;
            width: 225px;
            margin: 20px 10px 50px;
            justify-content: center;

            .title{
                font-family: $primary-font;
                @include font-rem(12px, 14px);
                font-weight: 700;
            }

            .stars{
                padding-left: 5px;

                .star{
                    padding-bottom: 5px;
                }
            }
        }
        
        &-button{
            &.trendsetter{
                border: 1px solid #FAA61A;

                .title{
                    color: #FAA61A;
                }
            }

            &.hero{
                border: 1px solid #C7006B;

                .title{
                    color: #C7006B;
                }
            }

            &.leader{
                border: 1px solid #7100C3;
                margin-right: 0px;
                width: 234px;

                .title{
                    color: #7100C3;
                }
            }
        }
    }

    &-table-container{

        &.hidden{
            display: none;
        }
        
        padding: 15px 0px;
        border-bottom: 1px solid #b0b0b0;

        table{
            caption{
                @include font-rem(18px, 16px);
                font-family: $primary-font;
                font-weight: 700;
                color: $brand-black;
                text-transform: uppercase;
            }

            tbody{
                tr{
                    td{
                        text-transform: uppercase;
                        width: 100px;
                        text-align: center;
                        padding: 0px;
                        border: none;

                        &:first-of-type{
                            padding-left: 0px;
                            text-align: left;

                            p{
                                border: none;
                                margin: 0px;
                                padding: 0px;
                                @include font-rem(14px, 16px);
                                font-weight: 600;
                                color: #757575;
                                padding-top: 30px;
                            }
                        }

                        &:last-of-type{
                            p{
                                margin-right: 0px;
                            }
                        }

                        p{
                            border: 1px solid #B0B0B0;
                            padding: 10px;
                            margin: 10px;
                            @include font-rem(12px, 16px);
                            height: 55px;
                        }
                    } 
                }
            }
        }
    }
}

p.note-become{
    margin-top: 20px;
}