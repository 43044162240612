bat-tabs-gloitloyaltyeventi {
	.bat-tabs {
		border-top: 0;

		@include media-breakpoint-up(lg) {
			border-top: solid 12px $teal;
		}

		&-indicator {
			border-color: $teal transparent transparent;
			top: 39px;

			@include media-breakpoint-up(lg) {
				top: 0;
			}
		}

		&-list {
			background-color: $gray;

			@include media-breakpoint-up(lg) {
				background-color: transparent;
			}

			&-item {
				&:not(:last-of-type) {
					border-right: 2px solid $white;
				}

				@include media-breakpoint-up(lg) {
					&:not(:last-of-type) {
						border-right: 0;
					}
				}

				&-button {
					box-shadow: inset 0 2px 6px 0 $dark-gray-30;
					padding: 12px 30px;

					@include media-breakpoint-up(lg) {
						box-shadow: none;
					}

					span {
						color: $dark-gray;
						font-family: $primary-font;
						font-size: 16px;
						font-weight: 700;
						letter-spacing: -0.7px;
						line-height: 16px;
						position: relative;

						@include media-breakpoint-up(lg) {
							font-size: 24px;
							line-height: 24px;

							&:after {
								border-bottom: 2px solid $dark-gray;
								bottom: 0;
								content: "";
								left: 0;
								position: absolute;
								transition: width 0.25s ease-in;
								width: 0;
							}
						}
					}

					.active & {
						background-color: $teal;
						border-bottom: 0;

						@include media-breakpoint-up(lg) {
							background-color: transparent;
							color: $dark-gray;
						}

						span {
							color: $white;

							@include media-breakpoint-up(lg) {
								color: $dark-gray;

								&:after {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
		&-cta{
			display: flex;
			justify-content: flex-end;
			padding-top: 8%;
			margin-bottom: 6%;
			margin-right: 10%;
		}
	}
	&.mobile-app {

		.bat-tabs {
			&-indicator {
				display: none !important;
			}

			&-list {
				background-color: $white;
				left: 0;
				width: 100%;
				z-index: 1;

				&-item {
					width: 50%;
					border-right: none !important;
					border-bottom: 1px solid #DEDEDE;

					&-button {
						justify-content: center;
						text-transform: uppercase;
						box-shadow: none;
						border-bottom: unset;

						span {
							font-weight: 700;
							font-size: 16px;
							line-height: 24px;
							text-align: center;
							letter-spacing: 0.7px;
							color: #AAAAAA;
						}
					}

					&.active {
						border-bottom: 2px solid #E97129;

						span {
							color: #2A2C2C;
						}
					}
				}
			}

			&-panels {
				margin-top: 35px;
			}
		}
	}
}