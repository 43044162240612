.subscription-to-event-modal-body{
	background: white;
	display: none;
	.subscription-to-event-modal-content{
		padding:5%;
		.subscription-to-event-modal-closeButton-container{
			display: flex;
			justify-content: flex-end;
			padding: 2%;
			padding-bottom: 10%;	
		}

		.bat-form-heading{
			p{
				font-size: 24px;
				margin-bottom: 8%;
			}
		}
		.bat-form--subscription-to-loyalty-event-date,.bat-form--subscription-to-loyalty-event-date-guest{
			.error-msg{
				display: none;
			}
	
			.bat-icon{
				margin-right: 10px;
				margin-left: auto;
				margin-top: -38px;
				cursor: pointer;
			}
	
			input[type="date"]::-webkit-calendar-picker-indicator{
				display: none;
			}
	
			input::-webkit-date-and-time-value {
				text-align: left;
				vertical-align: middle;
			}
	
			.bat-icon{
				&::before{
					content: url('/content/dam/glo-it/images/icon-calendar.svg');
				}
			}
			@include media-breakpoint-down(sm){
				div:first-of-type{
					margin-right: 10px;
				}

				div:last-of-type{
					margin-left: 10px;
				}

				div:only-child {
					margin-right: 0;
					margin-left: 0;
				}

				input{
					-webkit-appearance: none;
				}      
			} 
		}
		.bat-form--subscription-to-loyalty-event-identity-document{
			input[name="upload-identity-document"]{
				display: none;
			}
		}
		.bat-form--subscription-to-loyalty-event-identity-document-guest{
			input[name="upload-identity-document-guest"]{
				display: none;
			}
		}
		.bat-form--subscription-to-loyalty-event-upload-btn,.bat-form--subscription-to-loyalty-event-upload-btn-guest{
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			.button-secondary-light{
				width: 100%;
				color:black;
				&:hover span{
					color: black;
				} 
			}
			.bat-message-text{
				text-align: center;
				font-size: 14px;
				margin-top: 4%;
			}
		}

		.bat-form--subscription-to-loyalty-event-submit {
			display: flex;
    		justify-content: center;
			align-items: center;
			button{
				width: 100%;
			}
		}
		.bat-form--subscription-to-loyalty-event-add-guest-btn{
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			button{
				padding-right: 0px;
				padding-left: 0px;
				width: 100%;
				display: flex;
    			justify-content: flex-end;

				span{
					font-size: 18px;
				}

				.icon-addGuest {
					&::before {
						content: url('/content/dam/glo-it/images/mvp2-loyalty-app/addGuest.svg');
						transform: scale(0.9);
					}
				}
			}
		}

		.bat-form--subscription-to-loyalty-event-policy-checkbox-1{
			a{
				color: black;
			}
		}

		.bat-form--subscription-to-loyalty-event-remove-guest{
			margin-top:10%;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			button{
				padding-right: 0px;
				width: 100%;
				display: flex;
    			justify-content: flex-end;

				.icon-removeGuest{
					&::before {
						content: url('/content/dam/glo-it/images/mvp2-loyalty-app/removeGuest.svg');
						transform: scale(0.9);
					}
				}
			}
			.guest-text{
				font-size: 20px;
    			font-weight: 700;
			}
		}

		.user-document-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
			
			.user-document-text-container {
				width: 80%;
				.user-document-text {
					padding-left: 5%;
					color: #18207F;
				}
			}
			.remove-button-container {
				width: 20%;
				display: flex;
				justify-content: flex-end;
				
				button{
					.bat-icon{
						&::before{
							content: url('/content/dam/glo-it/images/mvp2-loyalty-app/removeFile.svg');
						}
					}
				}
			}
        }
		.guest-document-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
			
			.guest-document-text-container {
				width: 80%;
				.guest-document-text {
					padding-left: 5%;
					color: #18207F;
				}
			}
			.remove-button-container {
				width: 20%;
				display: flex;
				justify-content: flex-end;
				
				button{
					.bat-icon{
						&::before{
							content: url('/content/dam/glo-it/images/mvp2-loyalty-app/removeFile.svg');
						}
					}
				}
			}
        }

		.loading-wrapper {
			margin: 0 auto;
			align-items: center;
			display: flex;
			height: 110px;
			position: relative;
			width: 100px;
	
			p {
				text-align: center;
				width: 100%;
				font-size: 14px;
				margin: 0;
			}
	
			&:after {
				-webkit-animation: spin 2s linear infinite; /* Safari */
				animation: spin 2s linear infinite;
				border: 10px solid $light-gray;
				border-radius: 50%;
				border-top: 10px solid $purple;
				bottom: 0;
				content: "";
				height: 60px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 60px;
				z-index: 1;
			}
		}

		.bat-form--subscription-to-loyalty-event-policy-checkbox-1{
			margin-bottom: 0;
			
			fieldset{
				margin-bottom: 0;
				height: 20%;
			}

			.error-msg{
				display: block;
			}
		}
	}

	
}