.quiz-modal{
	width: 585px;
    background: linear-gradient(45deg, #5482c2 0, #353174 100%);
    box-shadow: 16px 16px 0px #D5DDEC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
	
	.uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

	.first-section-modal {		
        margin-top: 60px;
        width: max-content;

        @include media-breakpoint-down(md) {
            margin-top: 80px;
        }
        
        .survey-code-box {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 24px;
            gap: 8px;
            background: #0B1047;
            border-radius: 24px;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: $white;

            .icon-quiz {
                height: 48px;
                width: 48px;

                &::before {
                    height: 32px;
                }
            }
            .icon-quiz-modal::before{
                height: 32px !important;
                transform: scale(1.3);
            }
        }
	}

    .second-section-modal {

        .survey-modal-list {
            &-item {
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                text-align: center;

                &-button-current {
                    width: 47px;
                    height: 47px;
                    color: $white;
                    border-radius: 100%;
                    border: 3px solid $white;
                    font: inherit;

                    &.active {
                        background: white;
                        color: #18207F;
                    }
                } 

                &-button-voted {
                    width: 47px;
                    height: 47px;
                    background: #53CC85;
                    border-radius: 100%;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    font: inherit;
                    color: #18207F;

                    i::before {
                        font-size: 18px;
                    }

                    &.active {
                        display: flex;

                        + .survey-modal-list-item-button-current {
                            display: none;
                        }
                    }
                }

                &-button-wrong {
                    width: 47px;
                    height: 47px;
                    background: #0B1047;
                    border-radius: 100%;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    font: inherit;
                    color: #FFFFFF;

                    i::before {
                        font-size: 18px;
                    }

                    .icon-wrong{
                        margin-top: 12px;
                    }

                    &.active {
                        display: flex;

                        + .survey-modal-list-item-button-current {
                            display: none;
                        }
                    }
                }

            }
        }
    }

	.third-section-modal {
		display: flex;
        flex-direction: column;
        justify-content: center;

        .modal-title {
            font-weight: 700;
            font-size: 26px;
            line-height: 30px;
            text-align: center;
            color: $white;
            margin-top: 20px;
        }

        .description-modal {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: $white;
            margin-bottom: 24px;
        }

	}

    .timer{
      /* Neutral/Brand black */
      background: #2A2C2C;
      max-width: 300px;
      margin: 0 auto;
      margin-bottom: 25px;
      /* justify-content: center; */
      /* align-items: center; */
      /* padding: 8px; */
      /* gap: 8px; */
      /* display: flex; */
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 24px;
      gap: 8px;
      color: #FFFFFF;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */
        .time_left_txt{
            display: flex;
            flex-direction: row;
            .timer_sec{
                margin-left: 5px;
                margin-right: 5px;
                font-weight: 800;
                line-height: 150%;
                letter-spacing: 0.2px;
            }
        }
    }

    .survey-step-form {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        display: none;

        &.active {
            display: block;
        }
    }

    .fourth-section-modal {
        margin: 0;

        .bat-form--survey-question {
            margin: 0;

            &-number,
            &-time,
            &-points {
                color: $white;
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 65px;

                .question-circle {
                    width: 60px;
                    height: 60px;
                    background: #18207F;
                    border-radius: 100%;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .number {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 27px;
                    }

                    .text {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }

                .question-info {
                    position: absolute;
                    top: 65px;
                    text-align: center;
                    margin: 0 auto;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }

        .bat-form-field-set {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            label {
                display: flex;
                flex-direction: row;
                padding: 16px 24px;
                gap: 16px;
                width: 335px;
                min-height: 60px;
                background: #D5DDEC;
                align-items: center;
                margin: 0;
                justify-content: space-between;


                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                > input[type="checkbox"] {
                    height: 24px;
                    margin-right: 16px;
                    display: none;

                    &::before {
                        background: #D5DDEC;
                        font-size: 24px;
                        height: 24px;
                        width: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #0B1047;
                    }

                    + span {
                        font-size: 16px;
                        line-height: 18px;
                        color: #0B1047;
                        padding: 0;
                    }
                    /*
                    &:checked + span {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                        color: #0B1047;
                    }
                    */

                    &:checked::before {
                        color: $white;
                        content: "\e96d";
                        background: #0B1047;
                        font-family: "icomoon";
                        font-size: 30px;
                    }
                }

                &.selected {
                    background: $white;
                    //border: 4px solid #B4C7E7
                }
            }

            .text-area {
                max-width: 457px;
            }

            .number-field {
                max-width: 100px;
                font-size: 24px;
                font-weight: 600;
                height: 60px;
            }

            .date-field {
                max-width: 457px;
                height: 60px;
            }
        }
    } 

    .fifth-section-modal {
        display: none;
        flex-direction: column;
        align-items: center;

        &.active {
            display: flex;
        }

        .cta-proceed,
        .cta-send {
            padding: 10px 32px;
            background: $white;
            border-radius: 24px;
            font-family: inherit;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: #2A2C2C;
            margin-bottom: 40px;

            @include media-breakpoint-down(md) {

                margin-bottom: 24px;
                span {
                    width: max-content;
                }
            }

            &:hover {
                opacity: 0.9;
            }

            &:disabled {
                opacity: 0.8;
                pointer-events: none;
            }
        }

        .mgm-error-message {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 24px;
            gap: 16px;
            background: #FDF3F5;
            border-radius: 8px;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.2px;
            color: #E00830;
            margin-bottom: 40px;
            width: 420px;

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-bottom: 24px;
            }

            .icon-warning {
                &::before {
                    content: url('/content/dam/glo-it/images/newloyalty/Warning.svg');
                }
            }
        }
    }
}

#modal-quiz-runner.mobile-app{
    .quiz-audiomystery.quiz-modal{
        .timer{
            background: transparent;
            max-width: 335px;
            margin-inline: auto;
            margin-top: -32px;
            margin-bottom: 0px;
            /* justify-content: center; */
            /* align-items: center; */
            /* padding: 8px; */
            /* gap: 8px; */
            /* display: flex; */
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px;
            color: var(--Neutral-White, #FFF);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 24px */
            text-align: center;
            .time_left_txt{
                display: flex;
                flex-direction: row;
                .timer_sec{
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
        }
    }
}
.quiz-audiomystery.quiz-modal{
    width: 585px;
    min-height: 520px;
    background-image: url("/content/dam/glo-it/images/audiomystery/quiz-modal/mobile.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    @include media-breakpoint-up(md) {
        background-image: url("/content/dam/glo-it/images/audiomystery/quiz-modal/desktop.webp");
        gap: 24px;
    }
	.uk-close{
		background: white;
        color: #000 !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 16px;
            width: 16px;
        }
        @include media-breakpoint-up(md) {
            height: 40px;
            width: 40px;
            svg {
                height: 14px;
                width: 14px;
            }
        }
	}

	.first-section-modal {		
        margin-top: 40px !important;
        width: max-content;

        @include media-breakpoint-down(md) {
            margin-top: 32px !important;
        }
        
        .survey-code-box {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 0px;
            background: transparent;
            border-radius: 24px;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: $white;

            span{
                display: none;
            }

            .icon-quiz {
             height: 55px;
             width:292px;
             margin-left: 5%;

                &::before {
                    height: 32px;
                }
            }
            .icon-quiz-modal::before{
                height: 32px !important;
                transform: scale(1.3);
            }
        }
	}

    .second-section-modal {

        .survey-modal-list {
            &-item {
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                text-align: center;
                padding-left: 5px !important;

                &-button-current {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    border: 2px solid white;
                    color: var(--White, #FFF);
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px; /* 175% */

                    &.active {
                        background: white;
                        color: #18207F;
                    }
                } 

                &-button-voted {
                    width: 40px;
                    height: 40px;
                    background: #53CC85;
                    border-radius: 100%;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    font: inherit;
                    color: #18207F;

                    i::before {
                        font-size: 18px;
                    }

                    &.active {
                        display: flex;

                        + .survey-modal-list-item-button-current {
                            display: none;
                        }
                    }
                }

                &-button-wrong {
                    width: 40px;
                    height: 40px;
                    background: #0B1047;
                    border-radius: 100%;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    font: inherit;
                    color: #FFFFFF;
                    border: 2px solid #0B1047;

                    i::before {
                        font-size: 18px;
                    }

                    .icon-wrong{
                        margin-top: 12px;
                    }

                    &.active {
                        display: flex;

                        + .survey-modal-list-item-button-current {
                            display: none;
                        }
                    }
                }

            }
        }
    }

	.third-section-modal {
		display: flex;
        flex-direction: column;
        justify-content: center;

        .modal-title {
            color: var(--Neutral-White, #FFF);
            text-align: center;
            font-size: 26px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px; /* 115.385% */
            margin-top: 0px;
        }

        .description-modal {
            color: var(--Neutral-White, #FFF);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 24px */
            margin-top: 8px;
            margin-bottom: 10px;
        }

	}

    .timer{
        /* Neutral/Brand black */
        background: #2A2C2C;
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 25px;
        /* justify-content: center; */
        /* align-items: center; */
        /* padding: 8px; */
        /* gap: 8px; */
        /* display: flex; */
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 24px;
        gap: 8px;
        color: #FFFFFF;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
          .time_left_txt{
              display: flex;
              flex-direction: row;
              .timer_sec{
                  margin-left: 5px;
                  margin-right: 5px;
                  font-weight: 800;
                  line-height: 150%;
                  letter-spacing: 0.2px;
              }
          }
      }


    .survey-step-form {
        width: 100%;
        padding-left: 28px;
        padding-right: 28px;
        padding-bottom: 0px !important;
        display: none;

        form{
            margin-top: -32px;
        }

        &.active {
            display: block;
            gap: 32px;
            flex-direction: column;
            display: flex;

            .modal-title{
                color: var(--Neutral-White, #FFF);
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%; /* 31.2px */
                letter-spacing: -0.48px;
            }
        }
    }

    .fourth-section-modal {
        margin: 0;

        .bat-form--survey-question {
            margin: 0;

            &-number{
                margin-bottom: 45px !important;
            }

            &-number,
            &-time,
            &-points {
                color: $white;
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 42px;
                margin-top: 32px !important;

                .question-circle {
                    width: 60px;
                    height: 60px;
                    background: #18207F;
                    border-radius: 100%;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .number {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 27px;
                    }

                    .text {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }

                .question-info {
                    position: absolute;
                    top: 65px;
                    margin: 0 auto;
                    color: var(--Neutral-White, #FFF);
                    text-align: center;
                    font-family: Mont;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 21px */
                }
            }
        }

        .bat-form-field-set {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            margin: 0 0 10% 0;
            @include media-breakpoint-up(md) {
                margin: 0;
            }
            label {
                display: flex;
                flex-direction: row;
                padding: 15px 24px;
                gap: 16px;
                width: 335px;
                min-height: 54px;
                background: #D5DDEC;
                align-items: flex-start;
                margin: 0;
                justify-content: space-between;


                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                > input[type="checkbox"] {
                    height: 24px;
                    margin-right: 16px;
                    display: none;

                    &::before {
                        background: #D5DDEC;
                        font-size: 24px;
                        height: 24px;
                        width: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #0B1047;
                    }

                    + span {
                        color: var(--Loyalty-Dark-loyalty-blue, #0B1047);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%; /* 24px */
                        padding: 0;
                    }
                    /*
                    &:checked + span {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                        color: #0B1047;
                    }
                    */

                    &:checked::before {
                        color: $white;
                        content: "\e96d";
                        background: #0B1047;
                        font-family: "icomoon";
                        font-size: 30px;
                    }
                }

                &.selected {
                    background: $white;
                    //border: 4px solid #B4C7E7
                }
            }

            .text-area {
                max-width: 457px;
            }

            .number-field {
                max-width: 100px;
                font-size: 24px;
                font-weight: 600;
                height: 60px;
            }

            .date-field {
                max-width: 457px;
                height: 60px;
            }
        }
    } 

    .fifth-section-modal {
        display: none;
        flex-direction: column;
        align-items: center;

        &.active {
            display: flex;
        }

        .cta-proceed,
        .cta-send {
            @include media-breakpoint-up(md) {
                height: 40px;
            }
            height: 48px;
            width: 100%;
            min-width: 239px;
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $white;
            border-radius: 100px;
            font-family: inherit;
            color: var(--Neutral-Brand-black, #2A2C2C);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; /* 100% */
            letter-spacing: 0.7px;
            text-transform: uppercase;
            margin-bottom: 32px;

            @include media-breakpoint-down(md) {

                margin-bottom: 32px;
                span {
                    width: max-content;
                }
            }

            &:hover {
                opacity: 0.7;
            }

            &:disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        .mgm-error-message {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 24px;
            gap: 16px;
            background: #FDF3F5;
            border-radius: 8px;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.2px;
            color: #E00830;
            margin-bottom: 40px;
            width: 420px;

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-bottom: 24px;
            }

            .icon-warning {
                &::before {
                    content: url('/content/dam/glo-it/images/newloyalty/Warning.svg');
                }
            }
        }
    }
}