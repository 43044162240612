bat-card-gloitverticalcarousel{
    &.pdp-hyper-core{
        .bat-card-vertical {
            .bat-card--vertical-main {
                .bat-card--vertical-text {
                    .description{
                        color: white;
                    }
                }
            }
        }
    }
    max-height: 535px;
    @media screen and (max-width: 700px) {
        padding-inline: 26px;
        width: 355px;
    }
    .bat-card-vertical {
        padding-right: 300px;
        padding-left: 54%;
        height: 553px;
        border-radius: 40px; 
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 1400px; 
        gap: 160px;
        background: transparent;
        @media screen and (max-width: 700px) {
            padding-right: 0px;
            padding-left: unset;
            flex-direction: column-reverse;
            gap: 60px;
            justify-content: flex-end;
            align-items: flex-start;
        }

        .bat-card--vertical-img{
            position: absolute;
            bottom: -20px;
            width: 416px;
            left: 220px;
            height: 100%;
            @media screen and (max-width: 700px) {
                position: absolute;
                width: 275px;
                left: 40px;
                height: 306px;
            }

            img{
                width: 416px;
                height: auto;
            }

        }

        .bat-card--vertical-main {
            .bat-card--vertical-text {
                position: relative;
                top: 28px;
                .title{
                    color: var(--glo-2024-White, #FFF);
                    font-feature-settings: 'liga' off, 'clig' off;
                    font-family: Mont;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 16px;
        
                    @media screen and (max-width: 700px) {
                        color: var(--glo-2024-White, #FFF);
                        font-feature-settings: 'liga' off, 'clig' off;
                        font-family: Mont;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }
        
                .description{
                    color: var(--glo-2024-Black, #000);
                    font-feature-settings: 'liga' off, 'clig' off;
                    font-family: Mont;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 25.2px */
        
                    @media screen and (max-width: 700px) {
                        color: var(--glo-2024-Black, #000);
                        font-feature-settings: 'liga' off, 'clig' off;
                        font-family: Mont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 22.4px */
                    }
                }
            }
        }
    }
}



