.bat-carousel-gloitaudiomisterypersonages {
	height: 100%;
	width: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	@include media-breakpoint-down(md) {
		display: block;
		background-image: none;
		padding: 0;
	}

	.navigation-arrows {
		position: relative;

		.bat-carousel-slides {
			max-width: 802px;
			@include media-breakpoint-down(md) {
				max-width: 100%;
			}
			.slick-list {
				margin: 0;
				//margin-bottom: 40px !important;
				.slick-track {
					display: flex;
					flex-direction: row;
				}
			}
		}

		.arrows-container {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			display: flex;
			justify-content: space-between;
			transform: translateY(-50%);
			z-index: 10;
			max-width: 794px;
			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		.prev-arrow,
		.next-arrow {
			width: 40px;
			height: 40px;
			color: white;
			border: none;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			z-index: 11;
		}

		.prev-arrow {
			left: -47px;
		}

		.next-arrow {
			right: -35px;
		}
	}

	.slick-dots {
		padding-top: 35px !important;
		margin: 0;
		display: flex;
		justify-content: center;
		flex-direction: row;

		@include media-breakpoint-down(md) {
			margin: 0;
			width: 100%;
			bottom: 5%;
			padding: 0% 0% 0% 0% !important;
		}

		li {
			padding: 0 8px !important;
			margin-bottom: 0 !important;
			button {
				width: 10px !important;
				height: 10px !important;
				background-color: #0941be !important;
				border: 1px solid #0941be !important;
				border-radius: 50%;
				cursor: pointer;
				transition: background-color 0.3s ease, opacity 0.3s ease;
				overflow: hidden;

				&:focus {
					outline: none;
				}

				@include media-breakpoint-down(md) {
					width: 8px !important;
					height: 8px !important;
				}
			}

			&.slick-active button {
				background-color: #ffffff !important;
				border-color: #ffffff !important;
			}
		}
	}
	.slick-slide {
		@include media-breakpoint-down(md) {
			margin-right: 0;
			width: 100% !important;
		}
		width: fit-content !important;
		margin-right: 22px;
	}
}

bat-section-default.characters-carousel-audiomystery{
		background: #010C57;
}

bat-section-default.gadgets-audiomystery{
	margin-bottom: 0px !important;
	background: rgb(1,12,87);
	background: -moz-linear-gradient(180deg, rgba(1,12,87,1) 30%, rgba(43,86,141,1) 69%, rgba(110,206,229,1) 110%);
	background: -webkit-linear-gradient(180deg, rgba(1,12,87,1) 30%, rgba(43,86,141,1) 69%, rgba(110,206,229,1) 110%);
	background: linear-gradient(180deg, rgba(1,12,87,1) 30%, rgba(43,86,141,1) 69%, rgba(110,206,229,1) 110%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#010c57",endColorstr="#6ecee5",GradientType=1);

	bat-hero-gloitbackground{
		//min-height: 448px;
		.bat-hero .bat-hero-content{
			padding-left: 24.2% !important;
			@include media-breakpoint-down(md) {
				padding-left: 0px !important;
				padding: 0px;
				width: 100%;
				top: 13%;
				bottom: unset;
			}
		}
		@include media-breakpoint-down(md) {
			//min-height: 620px;
			padding-left: 0% !important;
			text-align: center;
		}
		h3{
			color: #FFF;
			font-size: 34px;
			font-style: normal;
			font-weight: 600;
			line-height: 41px; 
			@include media-breakpoint-down(md) {
				color: #FFF;
				font-size: 26px;
				font-style: normal;
				font-weight: 600;
				line-height: 28px; 
				max-width: 355px;
				margin-inline: auto;
				top: -14px;
				position: relative;
			}
		}
		p{
			margin-top:  18px;
			@include media-breakpoint-down(md) {
				margin-top: 75%;
			}
		}
		img{
			//min-height: 428px;
			object-fit: contain;
			@include media-breakpoint-down(md) {
				//min-height: 680px;
			}
		}

		.bat-cta-style.button-dark{
			max-width: 146px;
			padding: 0px;
			border-radius: 21px;
			background: #FE5000;
			height: 42px;
			&:hover{
				opacity: 0.8;
			}
			@include media-breakpoint-down(md) {
				margin-inline: auto;
			}
			span{
				color: black;
				text-transform: none;

			}
		}
	}
}


bat-text-default.personaggi-audiomystery-text{
	.bat-text h2{
		color: #FFF;
		text-align: center;
		font-feature-settings: 'liga' off, 'clig' off;
		font-family: Mont;
		font-size: 34px;
		font-style: normal;
		font-weight: 700;
		line-height: 44px; /* 129.412% */

		@include media-breakpoint-down(md) {
			font-size: 27px;
			line-height: 23px;
		}
	}
}
