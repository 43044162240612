.become-insider-text{
	max-width: 980px;
	.title-become{
		@include font-rem(36px, 45px);
		margin-bottom: 20px;
		padding-left: 20px;
		padding-right: 20px;

		@include media-breakpoint-down(md){
			@include font-rem(20px, 24px);
		}
	}
	.par-become{
		margin-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;

		@include media-breakpoint-down(md){
			@include font-rem(18px, 22px);
		}
	}
	.note-become{
		font-size: 12px;
		a{
			text-decoration: none;
		}

		@include media-breakpoint-down(md){
			padding: 0px 20px;
		}
	}
}