.bat-usp-gloit{
	.banner {
		background-color: $brand-black;
		color: $white;
		.uk-container {
			max-width: 1400px;
			padding-left: 0;

			padding-top: 40px;
            @include media-breakpoint-up(lg){
                padding-bottom: 25px;
				padding-top: 25px;
            }
		}
		.title-and-subtitle {
            max-width: 318px;
            @include media-breakpoint-up(lg){
                max-width: 410px;
            }
			
		}
		.big-title {
			color: $white;
			text-transform: uppercase;
			font-size: 24px;
			
            font-family: $primary-font;
			@include media-breakpoint-up(xl) {
				font-size: 28px;
				max-width: 200px;
			}
            @include media-breakpoint-down(sm){
                text-align: center;
				margin-bottom: 20px;
            }
		}
		.subtitle {
			color: $white;
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			font-size: 13px;
			margin-bottom: 10px;
			margin-top: 0;
			@include media-breakpoint-up(lg) {
				font-size: 16px;
			}
		}
		.banner-container {
			padding-right:0;
			@include media-breakpoint-up(xl) {
				flex-direction: row;
			}
            @include media-breakpoint-down(lg){
                padding-right: auto;
            }
		}
		.banner-number-list {
			flex-direction: row;
    		margin-left: 30px;
    		margin-right: 0;

            @include media-breakpoint-down(xl){
                display: block;
                margin-right: auto;
            }
			@include media-breakpoint-down(xl){
                margin-left: auto;
            }

		}
		.list-element {
			margin-left: 20px;
            max-width: 255px;
			@include media-breakpoint-down(xl){
                margin-bottom: 40px;
            }
			@include media-breakpoint-up(xl){
                margin-left: 18px;
            }
			@include media-breakpoint-up(xxl){
                margin-left: 20px;
            }

			.list-title {
				color: $white;
				font-size: 18px;
				margin-bottom: 5px;
				@include media-breakpoint-up(xl) {
					font-size: 20px;
				}
			}
				.list-icon {
					margin-right: 10px;
					
				}
				
		}
		.element-and-image{
			
			img{
				max-width: 60px;
			}
			@include media-breakpoint-down(xl){
                margin-bottom: 40px;
            }
		
		}
	}
}
