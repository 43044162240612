.gloitcountdownam{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    margin-inline: auto;
    margin-top: 42px;
    
    h3{
        color: #FFF;
        text-align: center;
        font-family: Mont;
        font-size: 29px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px; /* 93.103% */
        margin-bottom: 8px;
        @include media-breakpoint-down(md) {
            font-size: 20px;
        }
    }

    .countdown-container{
        display: flex;
        width:100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: normal;
        color: #FFF;
        #countdown{
            color: #FFF;
            text-align: center;
            font-size: 54px;
            line-height: 27px; /* 60% */
            background-image: url("/content/dam/glo-it/images/audiomystery/Box-Countdown.png");
            background-size: cover;
            min-height: 76px;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-inline: 34px;
            border-radius: 7px;
            font-weight: 700;
            width: 100%;
            letter-spacing: -0.5px;
            @include media-breakpoint-down(md) {
                font-size: 45px;
                min-height: 62px;
            }
        }

        .text{
            display: flex;
            justify-content: space-around;
            align-items: center;
            text-align: center;
            width: 100%;
            padding-right: 41px;
            padding-left: 57px;
            p{
                font-size: 15px;
                font-style: normal;
                line-height: 27px; /* 180% */
                font-weight: 800;
                @include media-breakpoint-down(md) {
                    font-size: 13px;
                }
            }
        }
    }
   
}