.bat-herobannernew-gloit{
    @include media-breakpoint-down(sm){
       width: 100% !important;
       //margin: 20px;
       //margin-top: 40px;
    }
    @include media-breakpoint-up(md){
        width: 100%;
        padding-right: 160px;
        margin-left: 0px;
     }

    .carousel-element{
        div{
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat no-repeat;
            min-height: 370px;
            width: 650px;
            margin: auto;

            @include media-breakpoint-down(sm){
                min-height: 250px;
                width: auto;
            }
        }
    }

    .bat-carousel-slides{
        .slick-dots{
            display: none !important;
        }
    }

    .bat-carousel-buttons {
        .slick-prev{
            left: 70% !important;
            top: 43% !important;
            z-index: 1 !important;
    
            @include media-breakpoint-down(sm){
                left: 76% !important;
                top: 45% !important;
            }
        }
    
        .slick-next{
            right: 12% !important;
            top: 42% !important;
            z-index: 1 !important;
    
            @include media-breakpoint-down(sm){
                right: 5% !important;
                top: 48% !important;
            }


            @media (max-width: 1800px) {
                right: 0% !important;
                top: 45% !important;
            }

            @media (max-width: 1020px) {
                right: 12% !important;
                top: 44.5% !important;
            }

            @media (min-width: 1500px) {
                right: 12% !important;
                top: 45% !important;
            }

            @media (min-width: 1800px) {
                right: 12% !important;
                top: 46% !important;
            }

            @media (max-width: 670px){
                right: 5% !important;
                top: 48% !important;
            }
                

    
        }
    }

    .info-container{
        margin-top:2%;
        .text{
            font-size: 13px;
            width: max-content;
            @include media-breakpoint-down(sm){
                font-size: 11px;
            }
        }
    }


}



.bat-hero-gloit-content-container .left:has(.button-secondary-dark) {
@include media-breakpoint-down(sm){
    display: flex;
    width: max-content;
    margin-bottom: 0px !important;
    margin-top: -45px !important;
}
.button-secondary-dark{
    @include media-breakpoint-down(sm){
        padding: 12px !important;
        margin-left: 20px !important;
    }
}
.button-dark{
    @include media-breakpoint-down(sm){
        margin-top: 22px !important;
        margin-bottom: 0px !important;
    }
}
}