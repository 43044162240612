//YOTI styling
@mixin root-prop($prop: null, $value: null) {
	@if ($prop and $value) {
		#{$prop}: $value;
	}
}

:root {
    @include root-prop(--fcm-primary-button-background-color, #fe5000);
    @include root-prop(--fcm-primary-button-background-color-hover, #eb4a00);
    @include root-prop(--fcm-font-family, 'Mont');
}


bat-card-gloitageverification {

    margin-top: 20px;

    span {
        font-family: 'Mont', sans-serif;
        font-size: 14px;
        color: #1d1d1b;
        text-align: center;

        &.error {
            color: #991f39;
        }

        button {
            color: #ed7d30;
            font-weight: 700;
            font-family: 'Mont', sans-serif;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: #cd4a04;
            }
        }

        &.disclaimer {
            font-size: 12px;
            color: #505050;
        }
    }

    a {
        font-weight: 700;
    }

    label {
        font-size: 14px;
        color: #1d1d1b;
    }

    .bat-cta-style.button-dark,
    .bat-cta-style.button-secondary-dark {
        padding: 8px 38px;
    }

    .uk-container-small {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        padding: 0 10px;

        &.first-container {
            .content-section {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;

                .title {
                    font-size: 24px;
                    font-weight: 800;
                    text-align: center;
                }

                .content-section-advices {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    border-bottom: 2px solid #dedede;

                    .content-section-advice {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 33%;
                        padding: 30px;

                        @media (max-width: 768px) {
                            padding: 30px 10px 10px 10px;
                        }

                        img {
                            width: 40px;
                            height: 40px;
                        }

                        p {
                            text-align: center;
                            margin: 20px 0;
                            font-size: 12px;
                            color: #1d1d1b;
                        }
                    }
                }

                .agreement-section {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                }
            }
        }

        &.second-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .buttons-section {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: stretch;

                .button-secondary-dark {
                    &:hover {
                        color: #2a2a2c;
                    }
                }
            }

            #another-snap-button {
                margin-top: 15px;
            }
        }

        &.third-container {

            @media (max-width: 1199px) {
                height: 700px;
            }
            
            @media (min-width: 1200px) {
                margin-top: 100px;
                height: 600px;
            }

            .loading-spinner {
                color: #F3651A;
            }
        }

        &.fourth-container,
        &.fifth-container,
        &.sixth-container,
        &.seventh-container {
            .title {
                font-size: 24px;
                font-weight: 800;
                text-align: center;
                width: 100%;
                border-bottom: 2px solid #dedede;
                padding-bottom: 20px;
            }

            .subtitle {
                padding: 20px 0;
                text-align: center;
            }
        }
    }

    .deletion-reminder {
        display: block !important;

        .deletion-inner-div {
            text-align: center;
            color: #991f39;
            background: #fdf4f5;
            padding: 25px 40px;
            margin-top: 30px;

            .underlined-text {
                color: #991f39;
                text-decoration: underline;
                font-weight: 800;
            }
        }

    }

    .face-capture-module-parent {
        max-width: 880px;
        margin: auto;

        // @media (max-width: 400px) {
        //     width: 350px;
        //     height: 250px;
        // }

        // @media (min-width: 401px) and (max-width: 600px) {
        //     width: 400px;
        //     height: 300px;
        // }

        // @media (min-width: 601px) and (max-width: 768px) {
        //     width: 600px;
        //     height: 450px;
        // }

        // @media (min-width: 769px) and (max-width: 1199px) {
        //     width: 700px;
        //     height: 500px;
        // }

        // @media (min-width: 1200px) {
        //     width: 800px;
        //     height: 600px;
        // }
        
    }
}