.aem-Grid {
    &.registerdeviceglocard {
        padding: 0px 60px;

        @include media-breakpoint-down(sm) {
            padding: 0px;
        }
    }
}

.bat-registerdeviceglocard-gloit {

    

    .uk-grid {
        background-color: #dedede;
        margin-left: 0px;
        margin-top: 115px;

        .bat-registerdeviceglocard-gloit-text {
            display: flex;
            justify-content: center;
            flex-direction: column;

        }

    }

    &-text {
        background-color: $light-gray;
        padding-left: 75px;

        @include media-breakpoint-down(xl) {
            padding: 40px 0;
            margin-top: 0 !important;
        }
    }

    &-image {
        padding: 0;
        margin-top: -9.87%;
        display: flex;
        align-items: flex-end;
    }

    &-myglonumber {
        width: 100%;
        margin-right: 40px;
        flex-wrap: wrap;

        @include media-breakpoint-down(xl) {
            width: unset;
            margin-left: 20px;
            margin-top: 20px;
            margin-right: 20px;
        }

        &-container {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        &-input {
            height: 46px;
            font-size: 15px;
            line-height: 15px;
            border: 0;
            border-bottom: solid 2px transparent;
            color: #1d1d1b;
            font-family: "Mont", sans-serif;
            padding: 8px 20px;
        }

        &-button {
            max-height: 46px;
            margin-right: 53px;
            margin-left: 24px;

            @include media-breakpoint-down(xl) {
                margin-left: 0px;
                margin-top: 20px;
            }
        }
    }

    font-family: 'Mont';
    font-style: normal;
    color: #2A2C2C;

    &-title,
    &-subtitle {
        margin: 0px;
    }

    @include media-breakpoint-down(xl) {

        .uk-grid {
            margin-top: 0px;
        }

        &-label,
        &-disclaimer {
            margin-left: 20px;
        }
    }

    &-title {
        font-family: 'Mont';
        font-style: normal;
        color: #2A2C2C;
        font-weight: 800;
        font-size: 30px;
        line-height: 34px;
        margin-right: 40px;
    }

    &-subtitle {
        @include font-rem(14px, 20px);
        width: 75%;
        margin-top: 10px;

        p {
            font-family: 'Mont';
            font-style: normal;
            color: #2A2C2C;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;

            @include media-breakpoint-up(xl) {
                font-size: 16px;
                line-height: 18px;
            }

            b {
                font-weight: 800;
            }
        }

    }

    &-label {
        font-family: 'Mont';
        font-style: normal;
        color: #2A2C2C;
        font-weight: 800;
        font-size: 20px;
        line-height: 140%;
        margin-top: 10px;

        @include media-breakpoint-up(xl) {
            font-size: 17px;
            line-height: 100%;
        }

    }

    &-disclaimer {
        font-family: 'Mont';
        font-style: normal;
        color: #6B6B6B;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        margin-top: 15px;
        width: 75%;

        @include media-breakpoint-down(xl) {
            margin-bottom: 75px !important;
        }

        @include media-breakpoint-up(xl) {
            font-size: 11px;
            line-height: 140%;
        }

    }

    @include media-breakpoint-down(xl) {

        .uk-flex {
            flex-direction: column;
        }

        .uk-width-1-2 {
            width: 100%;

            .bat-registerdeviceglocard-gloit {
                &-title {
                    @include font-rem(24px, 28px);
                    padding-left: 20px;
                    padding-top: 0px;
                }

                &-subtitle {
                    padding-top: 0px;
                    padding-left: 20px;
                    padding-right: 20px;
                    width: 95%;
                }

                &-cta {
                    padding-left: 0;
                }
            }
        }

    }

    h1 {
        font-size: 20px;
        font-family: $primary-font;
    }

    &.mobile-app {
        .bat-registerdeviceglocard-gloit-text {
            background-color: $white;
            padding: 18px 0;
        }

        .bat-registerdeviceglocard-gloit-title {
            margin: 0 20px;
            font-weight: 700;
            font-size: 28px;
            line-height: 36px;
        }

        .bat-registerdeviceglocard-gloit-myglonumber {
            margin-top: 0;
        }

        .bat-registerdeviceglocard-gloit-myglonumber-button {
            width: 100%;
            max-width: 100%;
        }

        .bat-registerdeviceglocard-gloit-disclaimer {
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            margin: 10px 0 0 0 !important;
            width: 100%;
        }

        .bat-registerdeviceglocard-gloit-label {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-transform: uppercase;
        }

    }

    &.new-register {
        
        .uk-grid {
            background-color: #EFEFEF;
            margin-left: 0;
            margin-top: 0;
            padding: 20px;

            @include media-breakpoint-down(xl) {
                padding: 0;
            }
        }

        .bat-registerdeviceglocard-gloit-text {
            justify-content: flex-start;
            background-color: #EFEFEF;
            padding-right: 30px;
            
            @include media-breakpoint-down(xl) {
                padding-bottom: 0;
                padding-right: 0;

                .bat-registerdevice-gloit {
                    input {
                        background-color: $white !important;
                        box-shadow: 0 0 0 30px $white inset !important;
                        -webkit-box-shadow: 0 0 0 30px $white inset !important;
                    }

                    select {
                        background-color: $white;
                    }
                }
            }
        }

        .bat-registerdeviceglocard-gloit-label {
            margin-bottom: 10px;
        }

        .bat-registerdeviceglocard-gloit-myglonumber {
            &-container {
                margin-bottom: 10px;
                width: 100%;
                input {
                    background-color: $white;
                }
            }

            &-button {
                margin-left: 0;

                @include media-breakpoint-down(md) {
                    margin-right: 0;
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        .bat-registerdeviceglocard-gloit-title {
            font-size: 34px;
            margin-bottom: 15px;

            @include media-breakpoint-down(xl) {
                font-size: 26px;
            }
        }

        .bat-registerdeviceglocard-gloit-image {
            margin-top: 0;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            picture {
                width: 80%;
                align-self: center;

                @include media-breakpoint-down(xl) {
                    width: 100%;
                }
            }

            @include media-breakpoint-down(xl) {
                background-color: white;
                padding-top: 30px;

                .bat-headline-style,
                .subtitle-device {
                    padding: 0 20px;
                }
            }
        }
    }
}



.bat-registerdevice-gloit {
    align-self: center;
    margin: auto 70px;
    margin-top: 40px;

    @include media-breakpoint-down(xl) {
        width: auto;
        margin: auto 20px;
        margin-top: 20px;
    }

    .bat-form-step {

        .bat-form-heading {
            h1 {
                @include font-rem(30px, 34px);
                margin-bottom: 25px;

                @include media-breakpoint-down(xl) {
                    @include font-rem(24px, 28px);
                    text-align: center;
                }
            }
        }

        .bat-form-text {
            @include font-rem(14px, 20px);
            margin-bottom: 40px;
            padding-bottom: 40px;
            border-bottom: 2px solid #DEDEDE;

            @include media-breakpoint-down(xl) {
                text-align: center;
                padding-bottom: 30px;
                margin-bottom: 30px;
            }
        }

        .bat-form-block {
            .bat-form-field {
                label {
                    text-transform: uppercase;
                }

                input {
                    -webkit-appearance: none;
                    border-radius: 0px;
                    -webkit-border-radius: 0px
                }
            }

            .bat-form--registerdevice-form-device,
            .bat-form--registerdevice-form-serial-number,
            .bat-form--registerdevice-form-optional-field-1,
            .bat-form--registerdevice-form-optional-field-2 {
                // width: 60%;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            .bat-form--registerdevice-form-serial-number {
                margin-bottom: 10px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 30px;
                }
            }

            .bat-form--registerdevice-form-bat-cta-style {
                text-transform: uppercase;
                margin-bottom: 30px;
            }

            .bat-form--registerdevice-form-warranty-checkbox {
                padding: 20px;
                border: 1px solid #DEDEDE;
                margin-bottom: 40px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 30px;
                }

                .bat-form-field-set {
                    margin: 0px;

                    label {
                        margin: 0px;

                        input {
                            background: white;

                            @include media-breakpoint-down(xl) {
                                background: #dedede;
                            }

                            margin: 0px;

                            &:focus {
                                border: none;
                            }
                        }

                        span {
                            padding: 0px;
                        }
                    }
                }
            }

            .bat-form--registerdevice-form-optional-field-title {
                .bat-form-messages {
                    .bat-message {
                        .bat-message-text {
                            @include font-rem(20px, 20px);
                        }
                    }
                }
            }

            .bat-form--registerdevice-form-optional-field-3 {
                input {
                    width: 250px;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }

                .error-msg {
                    display: none;
                }

                .bat-icon {
                    margin-right: 10px;
                    margin-left: 215px;
                    margin-top: -38px;
                    cursor: pointer;
                    background: none !important;

                    @include media-breakpoint-down(md) {
                        margin-left: auto;
                    }
                }

                input[type="date"]::-webkit-calendar-picker-indicator {
                    display: none;
                }

                input::-webkit-date-and-time-value {
                    text-align: left;
                    vertical-align: middle;
                }

                .bat-icon {
                    &::before {
                        content: url('/content/dam/glo-it/images/icon-calendar.svg');
                    }
                }

                input {
                    color: transparent;
                }
            }

            .bat-form--registerdevice-form-submit {

                @include media-breakpoint-down(md) {
                    margin-bottom: 75px;
                }

                .bat-button {
                    width: 250px;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                        width: 100%;
                    }
                }

                .bat-message-text{
                    p{
                        font-size: 15px;
                    }
                }
            }
        }
    }

    &-devices {
        border-top: 2px solid #DEDEDE;

        .title {
            @include font-rem(20px, 20px);
            font-weight: 500;
            margin-top: 40px;
            margin-bottom: 30px;

            @include media-breakpoint-down(md) {
                margin-top: 30px;
            }
        }

        .devices-table {
            @include media-breakpoint-down(md) {
                display: none;
            }

            thead {
                background: $concrete;

                tr {
                    border-bottom: 2px solid $brand-black;

                    th {
                        text-transform: inherit;
                        @include font-rem(14px, 20px);
                        font-weight: 700;
                        font-family: $primary-font;
                        color: $brand-black;
                        width: 40%;

                        &:first-of-type {
                            width: 30%;
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid $concrete;

                    td {
                        border: none;
                        @include font-rem(14px, 20px);
                        font-family: $primary-font;
                        color: $brand-black;
                        width: 40%;

                        &:first-of-type {
                            width: 30%;
                        }
                    }
                }
            }
        }

        .devices-container {
            display: none;
            padding: 20px;
            border: 1px solid #DEDEDE;
            margin-bottom: 20px;

            .header {
                width: 45%;

                p {
                    @include font-rem(14px, 20px);
                    font-weight: 700;
                    margin-bottom: 10px;

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }

            .device {
                width: 55%;

                p {
                    @include font-rem(14px, 20px);
                    margin-bottom: 10px;

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                display: flex;
            }
        }
    }

    .mobile-app & {
        .bat-form-heading,
        .bat-form-text {
            display: none;
        }

        form {
            margin-bottom: 60px;
        }

        .bat-form--registerdevice-form-submit {
            position: fixed;
            bottom: 0;
            left: 0;
            margin: 0 !important;
            padding: 20px;
            background: white;
            z-index: 1;
        }
    }
}