.glo-faqcoins-it{
	.bat-listaccordion-gloit{
		margin-top: 70px;

		.uk-container{
			max-width: 980px;

			.title-container{
				.title{
					text-align: center;
				}
			}

			.list{
				li:first-of-type{
					border-top: solid 1px #DEDEDE;
				}
			}
		}
	}

	.faq-accordion-container{
		max-width: 980px;
		padding: 20px 10px;
		.faq-accordion{
			li:first-of-type{
				border-top: solid 1px #DEDEDE;
			}

			.faq-title{
				padding-top: 10px;
				padding-bottom: 10px;
			}
			.accordion-element{
				border-bottom: 	solid 1px #DEDEDE;
				
			}
		}
		.uk-accordion{
			&-title{
				display: block;
				font-size: 14px;
				font-weight: 600;
    			line-height: 1.1;
				&:hover{
					color: #4d91d2;
				}
				&::before {
					content: "";
					width: 1.4em;
					height: 1.4em;
					margin-right: 20px;
					float: right;
					background-image: url('/icons/global/chevron-up.svg');
					background-repeat: no-repeat;
					background-position: 50% 50%;
				}
				
			}
			&-content{
				margin-bottom: 20px;
				padding-right: 10px;
				font-size: 14px;
			}
		}
		.uk-open{
			.uk-accordion-title{
				display: block;
				&::before {
					content: "";
					width: 1.4em;
					height: 1.4em;
					margin-right: 20px;
					float: right;
					background-image: url('/icons/global/chevron-down.svg');
					background-repeat: no-repeat;
					background-position: 50% 50%;
				}
			}
		}
	}
}