.loyalty-success-modal.success-modal-audiomystery{
	width: 585px;
    background: var(--RECO-Final, linear-gradient(45deg, #5482C2 0%, #353174 99.26%));
    box-shadow: 16px 16px 0px #D5DDEC;
    display: flex;
    flex-direction: column;
    justify-content: center;
	
	.uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

	.first-section-modal{
		position: relative;
		text-align: center;
        width: 167px;
        margin: 0 auto;
        margin-top: 60px;
        margin-bottom: 30px;

	}
	.second-section-modal{
		display: flex;
        flex-direction: column;
        justify-content: center;

        .modal-title {
            color: var(--Neutral-White, #FFF);
            text-align: center;
            font-size: 34px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px; /* 117.647% */
            text-transform: uppercase;
        }

        .description-modal {
            color: var(--Neutral-White, #FFF);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 140% */
            padding: 8px 16px;
            margin-bottom: 0px !important;
        }

        .coin-gained-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px 32px;
            background: #0B1047;
            border-radius: 40px;
            width: max-content;
            margin: auto;
            margin-bottom: 40px;
            gap: 4px;

            .coin-gain {
                color: var(--Neutral-White, #FFF);
                font-size: 26px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px; /* 115.385% */
                text-transform: uppercase;
                margin: 0;
            }

            .euro-gain {
                color: var(--Primary-Light-blue, #D5DDEC);
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 17px; /* 141.667% */
                letter-spacing: 0.2px;
                margin: 0;
            }
        }

		.button-container{

            text-align: center;
            color: $white;
            margin-bottom: 35px;
            
            p {
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px; /* 140% */
            }

            a{
                margin-left: -10px;
                margin-top: 10px;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16.8px; /* 120% */
                letter-spacing: 0.7px;
                text-transform: uppercase;
            }
		}
	}
}


.loyalty-success-modal{
	width: 585px;
    background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
    box-shadow: 16px 16px 0px #D5DDEC;
    display: flex;
    flex-direction: column;
    justify-content: center;
	
	.uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

	.first-section-modal{
		position: relative;
		text-align: center;
        width: 210px;
        margin: 0 auto;
        margin-top: 60px;
        margin-bottom: 30px;
	}
	.second-section-modal{
		display: flex;
        flex-direction: column;
        justify-content: center;

        .modal-title {
            font-weight: 700;
            font-size: 34px;
            line-height: 40px;
            text-align: center;
            text-transform: uppercase;
            color: $white;
        }

        .description-modal {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            color: $white;
            padding: 16px;
        }

        .coin-gained-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px 32px;
            background: #0B1047;
            border-radius: 40px;
            width: max-content;
            margin: auto;
            margin-bottom: 40px;

            .coin-gain {
                font-weight: 700;
                font-size: 26px;
                line-height: 30px;
                text-transform: uppercase;
                color: $white;
                margin: 0;
            }

            .euro-gain {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                text-transform: uppercase;
                color: #D5DDEC;
                margin: 0;
            }
        }

		.button-container{

            text-align: center;
            color: $white;
            margin-bottom: 35px;
            
            p {
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
            }
		}
	}
}


.mobile-app{
    .contenuto-informativo-modal{
        height: 70% !important;
        bottom: 0;
        position: absolute;
        border-radius: 40px 40px 0 0;

        .first-section-modal{
            width: 165px;
            margin: 0 auto;
            margin-top: 0px;
            margin-bottom: 0px;

        }

        .bat.button-container{
            bottom: -4% !important;
            .cta-proceed{
                height: 45px !important;
            }
        }
    }

    
}


.contenuto-informativo-modal{
	width: 585px;
    background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
    box-shadow: 16px 16px 0px #D5DDEC;
    display: flex;
    flex-direction: column;
    justify-content: center;
	
	.uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

    

	.first-section-modal{
		position: relative;
		text-align: center;
        width: 210px;
        margin: 0 auto;
        margin-top: 60px;
        margin-bottom: 30px;
	}
	.second-section-modal{
        height: 280px;
        overflow-y: scroll;

        .modal-contenuto-informativo-text-container{
            padding: 30px;
            text-align: center;
            color: $white;
            font-size: 17px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
            margin-bottom: 20px;
            
            h1,
            h2,
            h3,
            h4,
            p,
            span,
            a,
            li {
                color: $white;
            }

            a {
                max-width: 100%;
                white-space: pre-wrap;
                white-space: -moz-pre-wrap;
                white-space: -pre-wrap;
                white-space: -o-pre-wrap;
                word-wrap: break-word;
            }
        }
        
        .modal-title {
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
            color: $white;
            margin-bottom: 8px;
        }

        .description-modal {
            margin-left: 10%;
            margin-right: 10%;
            text-align: center;
            color: $white;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
        }

        .coin-gained-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px 32px;
            background: #0B1047;
            border-radius: 40px;
            width: max-content;
            margin: auto;
            margin-bottom: 40px;

            .coin-gain {
                font-weight: 700;
                font-size: 26px;
                line-height: 30px;
                text-transform: uppercase;
                color: $white;
                margin: 0;
            }

            .euro-gain {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                text-transform: uppercase;
                color: #D5DDEC;
                margin: 0;
            }
        }

		.button-container{

            text-align: center;
            color: $white;
            margin-bottom: 35px;
            
            p {
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
            }
            .cta-proceed{
                margin-top: 5%;
                padding: 10px 32px;
                background: $white;
                border-radius: 24px;
                font-family: inherit;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.7px;
                text-transform: uppercase;
                color: #2A2C2C;
                margin-bottom: 40px;

                @include media-breakpoint-down(md) {

                    margin-bottom: 24px;
                    span {
                        width: max-content;
                    }
                }

                &:hover {
                    opacity: 0.9;
                }

                &:disabled {
                    opacity: 0.8;
                    pointer-events: none;
                }
            }
		}
	}
}

#modal-generic-mission {
    &.mobile-app {
        .contenuto-informativo-modal {
            height: 100% !important;
            border-radius: unset;
        }
    }
    
    .modal-contenuto-informativo-text-container {
        text-align: left;
    }
}