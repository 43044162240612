bat-card-gloitblackmulticategory.multicategory-card-info {
    margin-top: 32px;
	margin-bottom: 32px !important;
	@include media-breakpoint-down(md) {
		margin-inline: auto;
		width: fit-content;
		margin-bottom: 24px !important;
	}
	.bat-blackmulticategory-gloit {
		position: relative;
		max-width: 447px;
		@include media-breakpoint-down(md) {
			max-width: 343px;
		}
		&--card {
			width: 100%;
			height: auto;
			max-height: 437px;
			border-radius: 18px;
			background: var(--Glo---Charcoal, #2a2a2a);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 24px;
			@include media-breakpoint-down(md) {
				padding: 16px 8px 16px 16px;
			}
			&-main {
				height: 100%;
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 24px;
				color: var(--Glo---White, #fff);
				font-family: Mont;
				&-content {
					gap: 32px;
					display: flex;
					justify-content: flex-start;
					flex-direction: column;
					height: 325px;
					@include media-breakpoint-down(md) {
						height: 290px;
					}
					&-top {
						@include media-breakpoint-down(md) {
							display: flex;
							align-items: center;
							justify-content: flex-end;
							gap: 8px;
							flex-direction: row-reverse;
						}
						&-title {
							font-size: 16px;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
							margin-bottom: 0px;
						}

						&-logo {
							margin-top: 12px;
							width: 100%;
							height: 100%;
							@include media-breakpoint-down(md) {
								margin-top: 0px;
							}
						}
					}

					&-bottom {
						display: flex;
						flex-direction: column;
						gap: 24px;
						.text-line {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							gap: 10px;
							&-icon{
								width: 34px;
								height: 34px;
							}
							&-copy {
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: normal;
                                margin-bottom: 0px;
								span {
									font-weight: 600;
								}
							}
						}
					}
				}
                &-ctalist {
                    height: 40px;
                    width: 100%;
                    background-color: #fff;
                    border-radius: 500px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
					cursor: pointer;
                    a {
                        span {
                            color: var(--Glo---Black, #000) !important;
                            text-align: center;
                            font-family: Mont;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: normal;
                            letter-spacing: 0.7px;
                            text-decoration: none !important;

                        }

                        &:hover span {
                            color: #000;
                            opacity: 0.6;
                            text-decoration: none;
                        }
                    }
                }
			}
		}
	}

    &.multicategory-card-glo {
		.bat-blackmulticategory-gloit {
			&--card {
				&-main {
					&-content {
						&-top {
							&-logo {
								width: 57.378px;
								height: auto;
								@include media-breakpoint-down(md) {
									margin-top: 0px;
									width: 35px;
									height: auto;
								}
							}
						}
					}
				}
			}
		}
	}

	&.multicategory-card-velo {
		.bat-blackmulticategory-gloit {
			&--card {
				&-main {
					&-content {
						&-top {
							&-logo {
								width: 114px;
								height: auto;
								@include media-breakpoint-down(md) {
									margin-top: 0px;
									width: 67px;
									height: auto;
								}
							}
						}
					}
				}
			}
		}
	}

	&.multicategory-card-vuse {
		.bat-blackmulticategory-gloit {
			&--card {
				&-main {
					&-content {
						&-top {
							&-logo {
								width: 170px;
								height: auto;
								@include media-breakpoint-down(md) {
									margin-top: 0px;
									width: 94.6px;
									height: auto;
								}
							}
						}
					}
				}
			}
		}
	}
}

sup.multicategory-landing-page-small{
	color: var(--Glo---White, #FFF);
	font-family: Mont;
	font-size: 0.6em;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	vertical-align: baseline;
}

sup.multicategory-landing-page-big{
	color: black;
	font-family: Mont;
	font-size: 0.8em;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	vertical-align: middle;
}
bat-card-gloitblackmulticategory.multicategory-black-with-background{
    margin-block: 32px;
	@include media-breakpoint-down(md) {
		margin-inline: auto;
		width: fit-content;
	}
	.bat-blackmulticategory-gloit {
		position: relative;
		max-width: 447px;
		@include media-breakpoint-down(md) {
			max-width: 343px;
		}
		&--card {
			width: 100%;
			height: auto;
			border-radius: 18px;
			background: var(--Glo---Charcoal, #2a2a2a);
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 24px;
			@include media-breakpoint-down(md) {
				padding: 16px;
			}
			&-main {
				height: 100%;
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 24px;
				color: var(--Glo---White, #fff);
				font-family: Mont;
				min-height: 427px;
				justify-content: flex-end;
				&-content {
					display: flex;
					justify-content: space-between;
					flex-direction: column;
					min-height: 333px;

					&-top {
						&-title {
							font-size: 16px;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
							margin-bottom: 0px;
						}

						&-logo {
							margin-top: 0px;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: auto;
						}
					}

					&-bottom {
						display: flex;
						flex-direction: column;
						gap: 16px;
						z-index: 1;

						.title-main-card{
							font-size: 22px;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
							margin-bottom: 0px;
						}

						.description{
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}
						.text-line {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							gap: 10px;

							&-copy {
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: normal;
                                margin-bottom: 0px;
								span {
									font-weight: 600;
								}
							}
						}
					}
				}
                &-ctalist {
                    height: 40px;
                    width: 100%;
                    background-color: #fff;
                    border-radius: 500px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
					z-index: 1;
					cursor: pointer;
                    a {
                        span {
                            color: var(--Glo---Black, #000) !important;
                            text-align: center;
                            font-family: Mont;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            letter-spacing: 0.7px;
                            text-decoration: none !important;

                        }

                        &:hover span {
                            color: #000;
                            opacity: 0.6;
                            text-decoration: none;
                        }
                    }
                }
			}
		}
	}

}
