.bat-dettaglio-eventi{
	

	&.mobile-app {
		@include media-breakpoint-down(lg){
			width: auto;
			margin: 0;
		}

		.loading-wrapper {
			margin: 50px auto;
			align-items: center;
			display: flex;
			height: 110px;
			position: relative;
			width: 100px;

			p {
				text-align: center;
				width: 100%;
				font-size: 14px;
				margin: 0;
			}

			&:after {
				-webkit-animation: spin 2s linear infinite; /* Safari */
				animation: spin 2s linear infinite;
				border: 10px solid $light-gray;
				border-radius: 50%;
				border-top: 10px solid $purple;
				bottom: 0;
				content: "";
				height: 60px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 60px;
				z-index: 1;
			}
		}

		.comefunziona-link {
			@include media-breakpoint-down(md) {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				padding: 20px 20px 0 20px;
			}
		}

		.bat-insidersclub-gloit-userLevel{

			@include media-breakpoint-down(md) {
				border-radius: 0px 0px 20px 20px;
				margin-top: 0;
				padding: 20px;
			}

			.user-initials{
	
				@include media-breakpoint-down(md){
					align-items: center;
				}
	
				.initials{
					@include media-breakpoint-down(md){
						margin-top: 0;
						width: 96px;
						height: 96px;
					}
				}
	
				.username{
	
					@include media-breakpoint-down(md){
						font-size: 20px;
						line-height: 26px;
						text-transform: unset;
					}
				}
			}
	
			.user-information{
				width: 70%;
	
				@include media-breakpoint-down(md){
					width: 100%;
					padding-bottom: 15px;
				}
	
				.username{
					font-size: 26px;
					font-weight: 700;
					line-height: 30px;
					text-transform: uppercase;
	
					@include media-breakpoint-down(md){
						font-size: 24px;
						line-height: 20px;
					}
				}
	
				.level{
	
					&-trendsetter, &-hero, &-leader{
						background: rgba(255, 255, 255, 0.2);
						border: unset;
	
						&-info{
		
							.label{
								@include media-breakpoint-down(md) {
									text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
								}
							}
						}
					}

					&-hero-lock,
					&-leader-lock {
						background: url('/content/dam/glo-it/images/insidersclub/level-lock.svg') no-repeat rgba(255, 255, 255, 0.2) 8px;
					}
				}
			}
	
			.balance{
	
				@include media-breakpoint-down(md){
					border-top: unset;
					padding-top: 0;
					align-items: center;
				}
	
				.label,
				.total-label{
					@include media-breakpoint-down(md){
						width: auto;
						font-size: 12px;
						font-weight: 700;
						line-height: 15px;
						text-align: center;
						color: #D5DDEC;
						letter-spacing: 1.2px;
					}
				}
	
				.balance-value,
				.total-value{
					@include media-breakpoint-down(md){
						width: auto;
						font-size: 28px;
						font-weight: 700;
						line-height: 36px;
						padding: 0;
						background: unset;
						border-radius: 0;
						margin: 0;
						display: flex;
    					justify-content: center;
						gap: 3px;
					}
				}
	
				.balance-separator {
					flex-basis: auto;
					height: 71px;
					opacity: 0.2;
					border: 2px solid #FFFFFF;
				}
	
				.balance-total,
				.balance-available{	
					@include media-breakpoint-down(md){
						width: auto;
						text-align: center;
						margin-top: 0;
					}
				}
			}
		}


		.bat-insidersclub-gloit-userLevel-skeleton{
			background: #EFEFEF;
			
			@include media-breakpoint-down(md) {
				border-radius: 0px 0px 20px 20px;
				margin-top: 0;
				padding: 20px;
			}

			.user-initials{
	
				@include media-breakpoint-down(md){
					align-items: center;
				}
	
				.initials{
					@include media-breakpoint-down(md){
						margin-top: 0;
						width: 96px;
						height: 96px;
					}
				}
	
				.username{
					
					@include media-breakpoint-down(md){
						width: 224.51px;
						height: 16px;
						background: #DEDEDE;
						border-radius: 4px;
					}
				}
			}
	
			.user-information{
				width: 70%;
	
				background: #DEDEDE;
				border-radius: 8px;
				height: 63px;
				margin-bottom: 20px;
				margin-top: 20px;

				@include media-breakpoint-down(md){
					width: 100%;
					padding-bottom: 15px;
				}
	
				.username{
					font-size: 26px;
					font-weight: 700;
					line-height: 30px;
					text-transform: uppercase;
	
					@include media-breakpoint-down(md){
						font-size: 24px;
						line-height: 20px;
					}
				}
	
				.level{
	
					&-trendsetter, &-hero, &-leader{
						background: rgba(255, 255, 255, 0.2);
						border: unset;
	
						&-info{
		
							.label{
								@include media-breakpoint-down(md) {
									text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
								}
							}
						}
					}

					&-hero-lock,
					&-leader-lock {
						background: url('/content/dam/glo-it/images/insidersclub/level-lock.svg') no-repeat rgba(255, 255, 255, 0.2) 8px;
					}
				}
			}
	
			.balance{
				background: #DEDEDE;
				@include media-breakpoint-down(md){
					border-top: unset;
					padding-top: 0;
					align-items: center;
				}
	
				.label,
				.total-label{
					@include media-breakpoint-down(md){
						width: auto;
						font-size: 12px;
						font-weight: 700;
						line-height: 15px;
						text-align: center;
						color: #D5DDEC;
						letter-spacing: 1.2px;
					}
				}
	
				.balance-value,
				.total-value{
					@include media-breakpoint-down(md){
						width: auto;
						font-size: 28px;
						font-weight: 700;
						line-height: 36px;
						padding: 0;
						background: unset;
						border-radius: 0;
						margin: 0;
						display: flex;
    					justify-content: center;
						gap: 3px;
					}
				}
	
				.balance-separator {
					flex-basis: auto;
					height: 71px;
					opacity: 0.2;
					border: 2px solid #FFFFFF;
				}
	
				.balance-total,
				.balance-available{	
					@include media-breakpoint-down(md){
						width: auto;
						text-align: center;
						margin-top: 0;
					}
				}
			}
		}

		.bat-insidersclub-gloit-title {
			padding-right: 40px;
			margin-top: 25px;
		}

		.bat-insidersclub-gloit-title-skeleton{
			padding-right: 20px;
			margin-top: 5px;
		}

		&.uk-slider-container {
			padding-left: 20px;
		}

		.event-container {

			.event-card {

				&-content {
					@include media-breakpoint-down(md) {
						border: unset;
    					filter: none;
						padding: 0;
						gap: 10px;
						width: 100%;
					}

					.event-info-section{
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						padding: 0 20px;
						.event-title {
							font-family: Mont;
							font-size: 18px;
							font-weight: 800;
							line-height: 27px;
							text-align: left;
							color: black;
							margin-top: 2%;
							margin-bottom: 2%;
						}

						.event-date, .event-location{
							font-family: Mont;
							font-size: 14px;
							font-weight: 500;
							line-height: 20px;
							text-align: left;
							color: #6B6B6B;
							line-height: 28px;
						}
						.event-info{
							margin-bottom: 1%;
						}
					}

					.event-cta{
						display: flex;
						flex-direction: row;
						margin-right: 7%;
						margin-top: 5%;
						margin-bottom: 5%;
						justify-content: flex-end;
					}
				
				}
	
				.event-image-section {
	
					.event-image {
						position: relative;
						img:not([src*='ottieniGoldApp.png']) {
							@include media-breakpoint-down(md) {
								border-radius: 12px;

								bat-productlisting-gloitinsidersshopcarousel & {
									object-position: top center;
								}
							}
						}

						img[src*='ottieniGoldApp.png'] {
							aspect-ratio: unset;
						}

						&-category{
							height: 94%;
							position: absolute;
							width: 120px;
							margin: 5px;
							display: flex;/* margin-bottom: 20%; */
							flex-direction: column;
							justify-content: flex-end;
							align-items: flex-end;
							right: 1%;
							margin-right: 0px;
							
							&-background{
								background: linear-gradient(45deg, #5482c2 0, #353174 100%) !important;
								height: 45px;
								border-radius: 9px;
								text-align: center;
								display: flex;
								align-content: center;
								align-items: center;
								font-weight: 700;
								font-size: 18px;
								line-height: 28px;
								text-align: center;
								letter-spacing: .2px;
								display: flex;

								p{
									font-weight: 700;
									font-size: 16px;
									line-height: 28px;
									text-align: center;
									letter-spacing: .2px;
									display: flex;
									color: white;
									margin: 10px;
								}
							}
						}
					}
	
					.event-value {
						@include media-breakpoint-down(md) {
							background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
							border-radius: 8px;
							position: absolute;
							bottom: 12px;
   					 		right: 12px;
						}
					}
	
					.event-type {
						@include media-breakpoint-down(md) {
							border: unset;
						}
					}
				}
				
				.gloit-events-info-details-data-image-container{
					width: 9%;
				}
				.gloit-events-info-details-luogo-image-container{
					width: 9%;
				}
				.gloit-dettaglio-eventi-events-info-details{
					display: flex;
					flex-direction: row;
					width: 100%;
					margin-bottom: 1%;
				}

			}
		}

		
	}
	&-cta{
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}
	&-cta-container p{
		margin: 6%;
		font-weight: bold;
	}
}

.bat-dettaglio-eventi-cta-alreadyregistered-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	.button-secondary-light{
		span{
			color:black;
		}
	}
}

.bat-dettaglio-eventi-container-skeleton{
    display: flex;
    flex-direction: row;
    width: 180%;
}

.bat-dettaglio-eventi-skeleton{
	width: 100%;
}

.bat-dettaglio-eventi-content{
	display: flex;
    flex-direction: column;
    gap: 8px;
}

.dettaglio-eventi-image-section{
	    position: relative;
}

.dettaglio-eventi-image{
	    aspect-ratio: 4 / 3;
    background: #efefef;
    border-radius: 12px;
}

.bat-dettaglio-eventi-title-section-skeleton{
	    display: block;
    background: #efefef;
    border-radius: 4px;
    height: 20px;
    margin-top: 8px;
    width: 80%;
	margin-left: 10%;
}

.bat-dettaglio-eventi-date-section{
	display: block;
    background: #efefef;
    border-radius: 4px;
    height: 12px;
    width: 80%;
	margin-left: 10%;
}

.bat-dettaglio-eventi-location-section{
	display: block;
    background: #efefef;
    border-radius: 4px;
    height: 12px;
    width: 80%;
	margin-left: 10%;
}

.dettaglio-eventi-description-section{
	 position: relative;
	 width: 80%;
	 margin-left: 10%;
}
