bat-cta-gloiteasygloslider,
bat-card-gloittoiletpaper {
    max-width: unset !important;
}

.bat-gloit-easygloslider {
    display: flex;
    flex-direction: row;
    background-image: url("/content/dam/glo-it/immagini/sfondoblu.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    &-heading{
        width: 40%;
        padding: 100px;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-down(md) {
            width: 100%;
            padding: 25px;
        }
        .bat-gloit-easygloslider-img{
            width: 60%;
            margin-bottom: 30px;
        }
    }

    &-silder {
        //max-width: 1000px;
        width: 60%;
        margin: 0 auto;
        padding: 90px 0 90px 0;
        //padding-left: 37px;
        overflow-x: hidden;

        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 100%;
            padding: 0px;
            overflow-x: visible;
            overflow-y: hidden;
            height: 380px;
        }
        .slick-track{
            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: column;
            }
        }
    }
    &-slider-container{
        /* Altri video */
        position: absolute;
        display: flex;
        flex-direction: row;
        width: 73px;
        height: 20px;
        right: 12%;
        top: 40px;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */
        text-transform: uppercase;

        /* Glo/White */
        color: #FFFFFF;
        @include media-breakpoint-down(md) {
            left: 65px;
            top: 81%;
        } 
    }
    &-slider-container-description{
        /* Altri video */
        text-wrap: nowrap;
        width: 73px;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */
        text-transform: uppercase;

        /* Glo/White */
        color: #FFFFFF;
        @include media-breakpoint-down(md) {
            left: 65px;
            top: 509px;
        }
    }
    
    .slick-slider .slick-track,
    .slick-slider .slick-list
    {
        transition-delay: 300ms;
    }

    &-silder-slides {
        @include media-breakpoint-down(md) {
            touch-action: none;
            -ms-touch-action: none;
        }
    }

    &-silder-slides-item {
        cursor: pointer;
        //margin-left: 80px;
        margin-right: 80px;
        opacity: 0.7;
        transition: all 300ms ease;
        @include media-breakpoint-up(md) {
            transform: scale(0.8) !important;
        }
        &.slick-current,
        &.slick-current-clone,
        &.slick-center {
            opacity: 1;
            transform: scale(1.45) !important;
            @include media-breakpoint-down(md) {
                transform: scale(1.3) !important;
            }
        }


        @include media-breakpoint-down(md) {
            margin-left: auto;
            margin-right: auto;
            //transform: translateY(40px);
            margin-bottom: 85px;
            margin-top: 0px;
            width: 70% !important;
        }
    }

    &-pointer {
        position: absolute;
        height: 140px;
        width: 300px;
        overflow: hidden;
        bottom: 0;
        transition: all 300ms ease;

        @include media-breakpoint-down(md) {
            height: 110px;
            width: 265px;
            position: relative;
            margin: 0 auto;
            padding-left: 40px;
        }
    }

    .slick-list.draggable {
        overflow: visible;
    }

    .slick-prev {
        position: relative;
        left: 80px;
        top: -4px;
        @include media-breakpoint-down(md) {
            //right: 90px;
            transform: rotate(90deg);
            //top: 504px;
            left: 180%;
            z-index: 40;
        }
        img {
            height: 20px;
        }
    }

    .slick-next {
        position: relative;
        left: 110px;
        top: -4px;
        @include media-breakpoint-down(md) {
            //right: 55px;
            transform: rotate(90deg);
            //top: 504px;
            z-index: 40;
            left: 180%;
        }
        img {
            height: 20px;
        }
    }
}

.bat-toiletpapercard-gloit {

    position: absolute;
    left: -99999px;

    &.active {
        position: relative;
        left: 0;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: -45px;
        z-index: 1;
    }

    &--blurb {
        position: relative;
        &-img {
            width: 100%;
            height: 100%;
        }

        &-main {
            position: absolute;
            top: 35%;
            left: 50%;
            padding-right: 20%;

            @include media-breakpoint-down(md) {
                top: 60%;
                left: 0;
                text-align: center;
                padding: 0 20px;
            }

            p,
            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 10px;
                
                @include media-breakpoint-down(md) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @include media-breakpoint-down(xxs) {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
            

            img {
                height: 58px;
                object-fit: contain;

                @include media-breakpoint-down(md) {
                    height: 48px;
                }
            }
        }
    }
}