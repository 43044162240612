// sass-lint:disable class-name-format, no-qualifying-elements, force-element-nesting, force-pseudo-nesting

@mixin headline {
	display: block;
	font-size: 45px;
	font-style: normal;
	font-weight: bold;
	letter-spacing: -1px;
	line-height: 0.9;
}

@mixin button-blue {
	background-color: $blue-light;
	border-radius: 25px;
	color: $white;
	font-family: $primary-font;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.02em;
	line-height: 100%;
	padding: 14px 30px;
	&:hover {
		background-color: $font-color;
	}
}

.cmp-sweepstakes {
	font-family: $primary-font;
	position: relative;
	width: 100%;

	.cmp-button {
		@include button-blue;

		&.hide {
			display: none;
		}
		&__text,
		&:hover {
			color: $white;
			text-decoration: none;
		}
	}

	&__login-wrap {
		display: block;

		.cmp-sweepstakes-login {
			display: inline-flex;
		}

		&.hide {
			display: none;

			body.authorMode & {
				display: block;
			}
		}

		& .aem-GridColumn.loginlinks {
			padding: 0;
		}

		.cmp-button:not(:last-child) {
			margin-right: 20px;
		}
	}

	&__step {
		display: none;

		body.authorMode &,
		&.active {
			display: block;
			position: relative;
		}
	}

	&__step-content {
		display: flex;
		flex-direction: column;
		padding: 20px 20px 0;
		position: relative;
		width: 100%;

		@media only screen and (min-width: $bp-tablet-min) {
			padding: 44px 54px;
		}
	}

	&__disclaimer-link {
		&:not(:last-child) {
			margin-right: 20px;
		}

		a {
			text-decoration: underline;
		}
	}
}

// Start
.cmp-sweepstakes-start {
	color: $white;

	body:not(.authorMode) & {
		height: 100%;
		min-height: 483px;
		overflow: hidden;

		@media only screen and (min-width: $bp-mobile-max) {
			min-height: 674px;
		}
	}

	.cmp-sweepstakes__step-content {
		margin-bottom: 20px;

		@media only screen and (min-width: $bp-tablet-min) {
			margin-bottom: 0;
			width: 75%;
		}

		@media only screen and (min-width: $bp-desktop-min) {
			width: 48%;
		}
	}

	&__played,
	&__play {
		display: none;

		body.authorMode &,
		&.active {
			display: block;
		}
	}

	&__playedBanner {
		border-radius: 5px;

		p {
			margin-top: 0;
		}

		&.hide {
			display: none;
		}
	}

	&__background-picture {
		height: 100%;
		left: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
	}

	&__background-img {
		height: 100%;
		left: 0;
		min-width: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		width: max-content;

		@media only screen and (min-width: $bp-mobile-max) {
			object-fit: unset;
			width: auto;
		}
	}

	&__headline {
		@include headline;
		margin-bottom: 24px;
		max-width: 100%;

		@media only screen and (min-width: $bp-small-mobile-min) {
			max-width: 70%;
		}

		@media only screen and (min-width: $bp-mobile-max) {
			font-size: 58px;
			letter-spacing: -1.5px;
			max-width: 100%;
		}
	}

	&__eyebrow-picture {
		margin-bottom: 6px;
		max-width: 100%;
		padding: 10px;
		width: fit-content;

		@media only screen and (min-width: $bp-mobile-max) {
			margin-bottom: 30px;
			padding: 0;
		}
	}

	&__eyebrow-img {
		display: block;
		max-width: 100%;
	}

	&__headlineEyebrow {
		display: block;
		font-family: $primary-font;
		font-size: 25px;
		font-style: normal;
		font-weight: 700;
	}

	&__description {
		margin-bottom: 24px;
		max-width: 100%;

		@media only screen and (min-width: $bp-small-mobile-min) {
			max-width: 94%;
		}

		@media only screen and (min-width: $bp-mobile-max) {
			max-width: 100%;
		}

		p {
			font-family: $primary-font;
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: 1;

			@media only screen and (min-width: $bp-mobile-max) {
				font-size: 25px;
			}
		}
	}

	&__btn-links-wrap {
		align-items: center;
		display: flex;
		flex-wrap: wrap;

		.cmp-button {
			margin-right: 40px;
		}

		.cmp-sweepstakes__disclaimer {
			padding-top: 16px;
		}
	}

	&__btn {
		display: inline-flex;

		&.hide {
			display: none;

			body.authorMode & {
				display: inline-flex;
			}
		}
	}
}

// Terms
.cmp-sweepstakes-terms {
	color: $font-color;

	&__headline {
		@include headline;
		font-size: 32px;
		letter-spacing: -0.5px;
		margin-bottom: 33px;
		margin-top: 28px;

		@media only screen and (min-width: $bp-mobile-max) {
			font-size: 45px;
		}

		@media only screen and (min-width: $bp-tablet-max) {
			font-size: 75px;
			letter-spacing: -1.8px;
			margin-bottom: 20px;
			margin-top: 35px;
		}
	}

	&__content-container {
		align-items: center;
		display: flex;
		flex-direction: column;
		text-align: center;
	}

	&__content p {
		font-size: 14px;
		letter-spacing: 0.25px;
		line-height: 1.25;

		@media only screen and (min-width: $bp-mobile-max) {
			font-size: 18px;
			letter-spacing: inherit;
			line-height: 1.3;
		}
	}

	&__authoredText {
		@media only screen and (min-width: $bp-mobile-max) {
			max-width: 75%;
		}

		@media only screen and (min-width: $bp-tablet-max) {
			max-width: 49%;
		}
	}

	&__agree-wrap {
		margin-top: 70px;

		@media only screen and (min-width: $bp-mobile-max) {
			margin-top: 0;
		}
	}

	&__agreeContinue {
		margin-top: 25px;

		@media only screen and (min-width: $bp-mobile-max) {
			margin-left: 46px;
			margin-top: 0;
		}

		&[disabled] {
			opacity: 0.25;
		}
	}

	&__agreeLabel {
		display: block;
		font-size: 18px;
		font-weight: 500;

		@media only screen and (min-width: $bp-mobile-max) {
			display: initial;
		}
	}

	&__agreeCheckbox {
		margin-right: 14px;
		position: relative;

		&:before {
			background-color: $white;
			border: 2px solid $font-color;
			content: "";
			display: block;
			height: 24px;
			left: -4px;
			position: absolute;
			top: -5px;
			width: 24px;
		}

		&:checked:after {
			border-bottom: 2px solid $font-color;
			border-right: 2px solid $font-color;
			content: "";
			height: 14px;
			left: 5px;
			position: absolute;
			top: -2px;
			transform: rotate(45deg);
			width: 7px;
		}
	}

	&__agreeCancel {
		font-family: $primary-font;
		font-size: 14px;
		font-weight: 400;
		margin-top: 32px;
		text-decoration: underline;
	}

	.cmp-sweepstakes__disclaimer-links {
		margin-top: 50px;
		padding-bottom: 20px;
		padding-left: 20px;

		@media only screen and (min-width: $bp-mobile-max) {
			margin-top: 100px;
			padding-bottom: 0;
		}
	}
}

// Button
.cmp-sweepstakes-button {
	color: $font-color;

	&[has-background] {
		color: $white;
	}

	body:not(.authorMode) & {
		align-items: center;
		display: none;
		height: 100%;
		min-height: 425px;
		overflow: hidden;

		&.active {
			display: flex;
		}

		@media only screen and (min-width: $bp-mobile-max) {
			min-height: 674px;
		}
	}

	.cmp-sweepstakes__step-content {
		height: 100%;
		justify-content: center;
		margin-bottom: 20px;

		@media only screen and (min-width: $bp-tablet-min) {
			margin-bottom: 0;
		}
	}

	&__content {
		align-items: center;
		display: flex;
		flex-direction: column;
		text-align: center;
	}

	&__background-picture {
		height: 100%;
		left: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
	}

	&__background-img {
		height: 100%;
		left: 0;
		object-fit: cover;
		position: absolute;
		top: 0;

		@media only screen and (min-width: $bp-mobile-max) {
			min-width: 100%;
			width: max-content;
		}
	}

	&__headline {
		@include headline;
		margin-bottom: 10px;

		@media only screen and (min-width: $bp-mobile-max) {
			font-size: 75px;
			letter-spacing: -2px;
			max-width: 90%;
		}

		@media only screen and (min-width: $bp-small-desktop-max) {
			font-size: 100px;
			margin-bottom: 32px;
			max-width: 920px;
		}
	}

	&__btn {
		background: none;
		border: 0;
		max-width: 245px;
		text-align: center;

		@media only screen and (min-width: $bp-mobile-max) {
			max-width: 100%;
		}

		&:hover,
		&:active,
		&focus {
			border-color: transparent;
			outline: 0;
		}

		img {
			max-width: 100%;
		}

		&.pushed {
			transform: scale(1.2);
			transition: transform 0.2s ease;
		}
	}

	&__text {
		font-size: 20px;
		font-weight: 500;
		line-height: 1;
		margin-top: 33px;

		@media only screen and (min-width: $bp-mobile-max) {
			max-width: 50%;
		}

		@media only screen and (min-width: $bp-small-desktop-max) {
			margin-top: 35px;
			max-width: 33%;
		}
	}

	.cmp-sweepstakes__disclaimer-links {
		margin-top: 5px;
		text-align: center;

		@media only screen and (min-width: $bp-mobile-max) {
			margin-top: 20px;
		}
	}
}

// Win
.cmp-sweepstakes-win {
	text-align: center;

	&__updateAddressComplete,
	&__updateAddress,
	&__confirmAddress {
		&.hide {
			display: none;
		}

		body.authorMode &,
		body.xf-web-container & {
			&.hide {
				display: block;
			}
		}
	}

	&__updateAddress {
		margin-bottom: 50px;
		margin-top: 8px;

		@media only screen and (min-width: $bp-mobile-max) {
			margin-bottom: 0;
		}

		a {
			text-decoration: underline;
		}
	}

	&__start,
	&__confirm,
	&__thanks,
	&__addresschanged {
		color: $font-color;
		display: none;
		text-align: center;

		&.active {
			display: block;
		}

		body.authorMode & {
			display: block;
		}

		&-content {
			align-items: center;
			display: flex;
			flex-direction: column;
		}

		&Headline {
			@include headline;
			font-size: 45px;
			letter-spacing: -1px;
			margin-bottom: 16px;
			margin-top: 3px;
			max-width: 75%;

			@media only screen and (min-width: $bp-mobile-max) {
				font-size: 60px;
				letter-spacing: -1.5px;
				margin-top: 14px;
				max-width: 100%;
			}

			@media only screen and (min-width: $bp-small-desktop-max) {
				font-size: 75px;
				letter-spacing: -2px;
				margin-bottom: 20px;
				max-width: 66%;
			}
		}

		&Description {
			font-size: 20px;
			font-weight: 500;
			line-height: 1;
			margin-bottom: 26px;

			@media only screen and (min-width: $bp-mobile-max) {
				font-size: 22px;
				letter-spacing: inherit;
				line-height: 1;
				max-width: 75%;
			}

			@media only screen and (min-width: $bp-small-desktop-max) {
				font-size: 25px;
				line-height: 1.1;
				margin-bottom: 30px;
				max-width: 50%;
			}
		}

		body.authorMode &AuthoredContainer {
			min-width: 400px;
		}
	}

	&__start {
		&Description {
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0.17px;
			line-height: 1.25;
			max-width: 94%;

			@media only screen and (min-width: $bp-mobile-max) {
				font-size: 22px;
				letter-spacing: inherit;
				line-height: 1;
				max-width: 75%;
			}

			@media only screen and (min-width: $bp-small-desktop-max) {
				font-size: 25px;
				line-height: 1.1;
				margin-bottom: 30px;
				max-width: 50%;
			}
		}

		&Headline {
			max-width: 235px;

			@media only screen and (min-width: $bp-mobile-max) {
				max-width: 100%;
			}

			@media only screen and (min-width: $bp-small-desktop-max) {
				max-width: 66%;
			}
		}

		.cmp-sweepstakes-win__prize-image-container img {
			max-width: 100%;
		}

		.cmp-sweepstakes-win__prize-container > p {
			&:last-child {
				margin-bottom: 30px;
			}
		}

		.cmp-sweepstakes-win__prize-name {
			font-size: 22px;
		}

		.cmp-sweepstakes-win__logo {
			margin-bottom: 10px;

			@media only screen and (min-width: $bp-mobile-max) {
				margin-bottom: 30px;
			}

			&-img {
				max-width: 100%;
			}
		}

		.cmp-sweepstakes-win__disclaimer {
			font-size: 18px;
			margin-bottom: 30px;
			max-width: 90%;

			@media only screen and (min-width: $bp-mobile-max) {
				max-width: 75%;
			}

			@media only screen and (min-width: $bp-small-desktop-max) {
				max-width: 45%;
			}
		}
		.cmp-sweepstakes-win__legal-text {
			font-size: 10px;
			margin-bottom: 30px;
			margin-top: 8px;

			@media only screen and (min-width: $bp-mobile-max) {
				margin-bottom: 0;
			}
		}
	}

	&__confirm {
		&Address {
			border: 2px solid $font-color;
			color: $dark-gray;
			font-size: 18px;
			margin-bottom: 30px;
			max-width: 460px;
			padding: 60px 0;
			width: 100%;

			@media only screen and (min-width: $bp-mobile-max) {
				max-width: none;
				padding: 60px 118px;
				width: auto;
			}
		}

		&Headline {
			@media only screen and (min-width: $bp-small-desktop-max) {
				max-width: 55%;
			}
		}
	}

	&__thanks,
	&__addresschanged {
		.cmp-sweepstakes-win__btn {
			margin-bottom: 40px;
		}
	}
}

// Loss
.cmp-sweepstakes-loss {
	color: $font-color;
	text-align: center;

	&__content {
		align-items: center;
		display: flex;
		flex-direction: column;
	}

	&__startHeadline {
		@include headline;
		font-size: 45px;
		letter-spacing: -1px;
		margin-bottom: 15px;
		margin-top: 40px;
		max-width: 75%;

		@media only screen and (min-width: $bp-mobile-max) {
			font-size: 60px;
			letter-spacing: -1.5px;
			max-width: 100%;
		}

		@media only screen and (min-width: $bp-small-desktop-max) {
			font-size: 75px;
			letter-spacing: -2px;
			margin-bottom: 20px;
			margin-top: 14px;
		}
	}

	&__startDescription {
		font-size: 20px;
		font-weight: 500;
		line-height: 1;
		margin-bottom: 26px;

		@media only screen and (min-width: $bp-mobile-max) {
			font-size: 22px;
			max-width: 75%;
		}

		@media only screen and (min-width: $bp-small-desktop-max) {
			font-size: 25px;
			line-height: 1.1;
			margin-bottom: 30px;
			max-width: 50%;
		}
	}

	&__btn {
		margin-bottom: 40px;
	}

	body.authorMode &__additional-components {
		min-width: 400px;
	}
}
