.bat-accountoverview-gloit{
    width: 75%;
    margin: auto 70px;
    min-height: 500px;

    @include media-breakpoint-down(md){
        width: auto;
        margin: auto 20px;
    }

    &-title{
        padding-bottom: 20px;

        @include media-breakpoint-down(md){
            border-bottom: 2px solid #DEDEDE;
            text-align: center;
        }

        .title{
            text-transform: uppercase;
            @include font-rem(30px, 34px);

            @include media-breakpoint-down(md){
                @include font-rem(24px, 28px);
            }
        }
        
        .name{
            @include font-rem(20px, 20px);
            font-weight: 500;
            margin-top: 20px;

            @include media-breakpoint-down(md){
                @include font-rem(18px, 20px);
            }
        }
    }

    &-details{
        padding-top: 20px;
        padding-bottom: 20px;

        @include media-breakpoint-down(md){
            padding-bottom: 30px;
        }

        .title{
            text-transform: uppercase;
            @include font-rem(22px, 28px);
            margin-bottom: 20px;

            @include media-breakpoint-down(md){
                text-align: center;
                @include font-rem(20px, 24px);
            }
        }

        .user{
            @include font-rem(14px, 20px);
            margin-bottom: 10px;
        }

        .email{
            @include font-rem(14px, 20px);
            font-weight: 700;
            margin-bottom: 30px;
        }

        .edit-cta{
            margin-top: 20px;
            font-size: 13px;

            .edit{
                &-details,
                &-password{
                    width: 250px;

                    @include media-breakpoint-down(md){
                        max-width: 100%;
                        width: 100%;
                    }
                }

                &-details{
                    color: $white;
                    margin-right: 10px;

                    @include media-breakpoint-down(md){
                        margin-right: 0px;
                        margin-bottom: 15px;
                    }
                }

                &-password{
                    &:hover{
                        color: $brand-black;
                    }
                }
            }
        }
    }

    &-privacy{
        padding-top: 20px;
        padding-bottom: 40px;
        border-bottom: 2px solid #DEDEDE;

        @include media-breakpoint-down(md){
            padding-bottom: 30px;
        }

        .title{
            text-transform: uppercase;
            @include font-rem(22px, 28px);
            margin-bottom: 20px;

            @include media-breakpoint-down(md){
                text-align: center;
                @include font-rem(20px, 24px);
            }
        }

        .subtitle{
            @include font-rem(20px, 20px);
            font-weight: 500;
            margin-top: 20px;

            @include media-breakpoint-down(md){
                @include font-rem(18px, 20px);
                text-align: center;
            }
        }

        .edit-privacy{
            font-size: 13px;
            width: 250px;
            margin-top: 20px;

            @include media-breakpoint-down(md){
                max-width: 100%;
                width: 100%;
            }

            &:hover{
                color: $brand-black;
            }
        }
    }

    &-address-book{
        margin-top: 40px;

        @include media-breakpoint-down(md){
            margin-top: 30px;
        }

        .title{
            text-transform: uppercase;
            @include font-rem(22px, 28px);
            margin-bottom: 20px;

            @include media-breakpoint-down(md){
                text-align: center;
                @include font-rem(20px, 24px);
            }
        }

        .default-addresses{
            justify-content: space-between;
            margin-bottom: 30px;

            @include media-breakpoint-down(md){
                display: block;
                margin-bottom: 20px;
            }

            .default-delivery-address,
            .default-billing-address{
                width: 45%;

                @include media-breakpoint-down(md){
                    width: auto;
                }

                .title{
                    @include font-rem(20px, 20px);
                    text-transform: inherit;
                    font-weight: 500;

                    @include media-breakpoint-down(md){
                        text-align: left;
                        @include font-rem(18px, 20px);
                    }
                }

                .information{
                    padding: 20px;
                    border: 1px solid #DEDEDE;
                    margin-bottom: 20px;

                    .user{
                        font-weight: 700;
                    }

                    p{
                        margin-bottom: 10px;
                        @include font-rem(14px, 20px);

                        &:last-of-type{
                            margin-bottom: 0px;
                        }
                    }
                }
            }

            .default-delivery-address{
                @include media-breakpoint-down(md){
                    margin-bottom: 20px;
                }
            }
        }

        .edit-address{
            color: $white;
            width: 250px;
            font-size: 13px;

            @include media-breakpoint-down(md){
                max-width: 100%;
                width: 100%;
            }
        }
    }

    &-social-accounts {
        margin-top: 40px;

        .social-buttons {
            margin-top: 20px;
        }

        .bat-button--facebook {
            width: 300px;
            margin-bottom: 20px;

            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: 100%;
            }

            .fa-brands {
                position: absolute;
                left: 15px;
                height: 18px;
                width: 18px;
            }
        }
       
        .bat-button--google {
            width: 300px;
            margin-bottom: 20px;

            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: 100%;
            }

            .fa-brands {
                position: absolute;
                left: 15px;
                height: 18px;
                width: 18px;
            }
        }
    }


    &-title-skeleton{
        min-height: 80px;
        background-color: #EFEFEF;
        border-radius: 25px;
    }
    &-details-skeleton{
        margin-top:15px;
        min-height: 175px;
        background-color: #EFEFEF;
        border-radius: 25px;
    }
    &-privacy-skeleton{
        margin-top:15px;
        min-height: 140px;
        background-color: #EFEFEF;
        border-radius: 25px;
    }
    &-address-book-skeleton{
        margin-top:15px;
        min-height: 250px;
        background-color: #EFEFEF;
        border-radius: 25px;
    }
}