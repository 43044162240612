bat-card-gloitmulticategorysmallbanner{
    .small-banner-multicategory-card{
        width: 100%;
        height: auto;
        background: var(--Glo---Charcoal, #2A2A2A);
        position: relative;
        padding: 16px;
        &-background{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &-content{
            display: block;
            height: 100%;
            position: relative;
            z-index: 2;

            &-flex{
                display: flex;
                align-items: center;
                gap: 10px;
                max-width: fit-content;
                margin-inline: auto;

                .title{
                    color: #FFF;
                    font-family: Mont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    margin-bottom: 0px;
                }
            }

            &-ctalist {
                height: 40px;
                width: 100%;
                background-color: #fff;
                border-radius: 500px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin-top: 24px;
                a {
                    span {
                        color: var(--Glo---Black, #000) !important;
                        text-align: center;
                        font-family: Mont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        letter-spacing: 0.7px;
                        text-decoration: none !important;

                    }

                    &:hover span {
                        color: #000;
                        opacity: 0.6;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}