bat-card-gloitdropzoneapp {

    .dropzone-container{
        margin-top: 40px;
        background: #efefef;
        height: auto;
        padding: 16px;
        border-radius: 16px;
        width: 45vw;
        margin-left: auto;
        margin-right: auto;
        color: #2a2c2c;
        animation: fadeIn 1s ease-in-out;

        @media (max-width: 768px) {
            width: 328px;
    
            .submit-message-success {
                margin: 16px 0px 0px 0px !important;
            }
    
            .submit-submessage-success {
                margin: 16px 0px 24px 0px !important;
            }
        }
    
        @media (min-width: 769px) and (max-width: 1200px) {
            width: 70vw;
        }
    }

	form.dropzone {
		border-radius: 8px;
		border-style: dashed !important;
		background: #efefef;
		display: flex;
		flex-direction: column;
		align-items: center;
		min-height: 332px;
		justify-content: center;
		padding: 32px 16px 24px 16px;

		&.completed {
			border-width: 0px;
            padding: 32px 0px 24px 0px;
            min-height: 400px;
			.dz-message {
				display: none;
			}

			.dz-preview-element {
				display: none;
			}

			.submit-section {
                width: 100%;
				.dz-submit-cta {
					display: none;
				}

				.dz-submit-picture {
					display: block;
				}

				.submit-message {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.dz-discover-cta {
					display: block;
                    border-radius: 100px;
                    background: var(--Gradient-Orange, linear-gradient(90deg, #E97129 0%, #C74206 100%));
                    color: var(--neutral-50-white, #FFF);
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 16px; /* 100% */
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                    height: 48px;
                    width: 100%;
				}
			}
		}

		&.ready {
			background: rgba(47, 159, 85, 0.1);
			border: 2px solid rgba(47, 159, 85, 1);
		}

		&.uploading {
			border: 2px solid rgba(243, 101, 26, 1);
		}

		&.not-loyalty {
			.dz-message {
				display: flex;
				flex-direction: column;
				align-items: center;

				.dz-message-supported-files {
					margin-top: 15px;
					max-width: 60%;
				}

				.dz-message-cta {
					margin-top: 30px;
				}
			}
		}

		.dz-message {
            margin: 0px !important;
            width: 100%;
			.dz-message-picture {
				width: 78px;
				height: 78px;
			}

			.dz-message-title {
				color: var(--Neutral-Brand-black, #2a2c2c);
				text-align: center;
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: 130%;
				margin-top: 24px;
				margin-bottom: 16px;
			}

			.dz-message-supported-files {
				color: var(--Neutral-Brand-black, #2a2c2c);
				text-align: center;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
                margin-bottom: 32px;
			}

			.dz-message-cta {
				height: 48px;
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px; /* 100% */
                letter-spacing: 0.7px;
                text-transform: uppercase;
                border-radius: 100px;
			}

            .dz-clickable.dz-message-cta{
                background: var(--Gradient-Orange, linear-gradient(90deg, #E97129 0%, #C74206 100%));
            }

            .dz-message-max-size, .dz-message-accept-disclaimer{
				color: var(--Neutral-Dark-grey, #6B6B6B);
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
			}

			.dz-message-max-size {
                margin-top: 24px;
                margin-bottom: 0px;
			}

            .dz-message-accept-disclaimer {
                margin-top: 16px;
			}

		}

		.submit-section {
			display: flex;
			flex-direction: column;
			align-items: center;

			.dz-submit-cta {
				display: none;
			}

			.submit-message {
				display: none;

				.submit-message-success,
				.submit-submessage-success {
                    color: var(--Neutral-Brand-black, #2A2C2C);
                    text-align: center;
                    font-style: normal;
				}

				.submit-message-success {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 130%; /* 26px */
				}

				.submit-submessage-success {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 150%; /* 24px */
				}
			}

			.uploading-section {
				display: flex;
				flex-direction: column;
				align-items: center;

                button{
					padding: 0px;
					color: var(--Neutral-Brand-black, #2A2C2C);
					text-align: center;
					font-size: 20px;
					font-style: normal;
					font-weight: 700;
					line-height: 130%; /* 26px */
				}
            }

			.dz-submit-picture {
				display: none;
				width: 84px;
				height: 84px;

				img {
					width: 84px;
                    height: 84px;
				}
			}

			.dz-discover-cta {
				display: none;
			}
		}

		&.dz-started {
			.submit-section {
				.dz-submit-cta {
					display: block;
				}
			}
		}

		.dz-preview-element {
			.dz-image-preview {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				animation: fadeIn 0.3s;

				img {
					width: 84px;
					height: 84px;
                    margin-bottom: 32px;
				}

				label {
					color: var(--Neutral-Brand-black, #2A2C2C);
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 130%; /* 26px */
				}
			}
		}

		&.dz-drag-hover {
			border-color: rgb(243, 101, 26);
			background: rgba(243, 101, 26, 0.1);

			.dz-message {
				opacity: 1 !important;

				.dz-message-or,
				.dz-message-cta,
				.dz-message-max-size {
					display: none !important;
				}
			}
		}
	}

	.dz-agreement {
		background: #ffffff;
		padding: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 20px;
		margin-top: 20px;

		.dz-agreement-text {
            color: var(--Neutral-Brand-black, #2A2C2C);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 21px */

			a {
                font-weight: 700;
                letter-spacing: 0.2px;
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-decoration-skip-ink: none;
                text-decoration-thickness: auto;
                text-underline-offset: auto;
			}
		}

		.dz-agreement-cta {
			height: 48px;
			margin-top: 16px;
            width: 100%;
            color: var(--neutral-50-white, #FFF);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; /* 100% */
            letter-spacing: 0.7px;
            text-transform: uppercase;
            background: var(--Gradient-Orange, linear-gradient(90deg, #E97129 0%, #C74206 100%));
		}
	}

	.submit-section-error {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-height: 400px;

		.submit-message {
			display: flex;
			flex-direction: column;
			align-items: center;

			.submit-message-error{
				margin: 16px 0px;
			}

			.submit-submessage-error {
				margin: 0px;
			}

			.submit-message-error {
                color: var(--Neutral-Brand-black, #2A2C2C);
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%; /* 26px */
			}

			.submit-submessage-error {
				color: var(--Neutral-Brand-black, #2A2C2C);
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 24px */
			}
		}

		.dz-submit-picture {
			width: 82px;
			height: 82px;

			img {
				width: 82px;
			}
		}

		.dz-pdf-agreement-cta{
			margin-top: 24px;
			border-radius: 100px;
			background: var(--Gradient-Orange, linear-gradient(90deg, #E97129 0%, #C74206 100%));
			height: 48px;
			width: 100%;
		}
	}

    .error-generic{
        max-width: 328px;
        gap: 12px;
        margin-inline: auto;
        border-radius: 8px;
        background: var(--Status-Tint-error, #FDF3F5);
        padding: 16px;
        margin-top: 16px;
        color: var(--Neutral-Brand-black, #2A2C2C);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        display: flex;
        align-items: flex-start;
    }

	.submit-section-outside-form {
		display: flex;
		flex-direction: column;
		margin-top: 24px;
        padding: 8px;

		overflow: hidden;

		.submit-or-cancel {
			display: flex;
			flex-direction: column;
            gap: 16px;

			.dz-cancel-cta {
				background: none;
				border-radius: 100px;
                border: 2px solid var(--Gradient-Orange, #E97129);
                height: 48px;
                color: var(--Neutral-Brand-black, #2A2C2C);
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px; /* 100% */
                letter-spacing: 0.7px;
                text-transform: uppercase;
			}

            .dz-submit-cta{
                border-radius: 100px;
                background: var(--Gradient-Orange, linear-gradient(90deg, #E97129 0%, #C74206 100%));
                height: 48px;
                color: var(--neutral-50-white, #FFF);
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px; /* 100% */
                letter-spacing: 0.7px;
                text-transform: uppercase;
            }
		}

		.file-section {
			display: flex;
			flex-direction: column;
			margin-bottom: 24px;

			.file-section-label {
				color: var(--Neutral-Charcoal, #3A3A3A);
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 27px */
                margin-bottom: 12px;
			}

			.file-section-row {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
                align-items: flex-end;

				.file-section-row-left {
					display: flex;
					flex-direction: row;

					.file-section-picture {
						width: 48px;
						height: 48px;
					}

					.file-section-row-filename-size {
						display: flex;
						flex-direction: column;
						margin-left: 20px;

						.file-section-row-filename {
							color: rgba(42, 44, 44, 1);
							font-size: 16px;
							font-weight: 700;
						}
					}
				}
			}
		}
	}

	.hidden-mobile {
		@media (max-width: 768px) {
			display: none !important;
		}
	}

	.hidden-desktop {
		@media (min-width: 769px) {
			display: none !important;
		}
	}
}


bat-headline-default.headline-hog-music-crew {
	.bat-headline {
		margin: 0px 7%;
	}
}