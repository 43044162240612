.carousel{
    &.landing{
        background-color: $concrete;
    }
}

.bat-carousel{
    &.bat-imagecarousel-gloit{
        @include media-breakpoint-down(sm){
           width: 90%;
           margin: 20px;
           margin-top: 40px;
        }

        .for-music & {
            @include media-breakpoint-down(md){
                width: 100%;
                margin: 0 5px;
            }

            @include media-breakpoint-down(sm){
                width: 100%;
                margin: 0 5px;
            }
        }

        .bat-video-content{
            width: 650px;
            position: relative;
            padding: 0%;
            
            @include media-breakpoint-down(sm){
                height: 200px;
                width: 400px;
            }

            iframe{
                width: 100%;
                height: 100%;
                position: absolute;
            }

        }
        

    
        .carousel-element{
            height: 350px;
            //width: 300px;
            position: relative;
            text-align: center;

            @include media-breakpoint-down(sm){
                height: 200px;
            }

            
        }

        .carousel-element{
            div{
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat no-repeat;
                min-height: 370px;
                width: 650px;
                margin: auto;

                @include media-breakpoint-down(sm){
                    min-height: 250px;
                    width: auto;
                }
            }
        }

        .slick-prev{
            left: 20%;

            @include media-breakpoint-down(sm){
                left: 0;
            }
        }

        .slick-next{
            right: 20%;

            @include media-breakpoint-down(sm){
                right: 0;
            }
        }

        .slick-prev,
        .slick-next{
            .bat-icon{
                &::before{
                    font-size: 40px;
                    color: $charcoal;
    
                    @include media-breakpoint-down(sm){
                        font-size: 28px;
                        color: $white;
                    }
                }
            }
        }
    }
}

.carousel-weknow{

    .slick-prev,
    .slick-next{
            .bat-icon{
                &::before{
                    font-size: 28px;
                    color: $white;
                }
            }
        }
}