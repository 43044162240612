bat-productlisting-gloitsimplified {
    max-width: 625px !important;
    display: block;
    margin: 0 auto;
}

.bat-simplifiedshop-gloit{
    &-title{
        padding: 30px 20px;

        .title-info{
            align-items: center;
            padding-bottom: 15px;

            .title{
                text-transform: uppercase;
                font-size: 34px;

                @media (max-width: $breakpoint-md) {
                    font-size: 24px;
                }
            }

            .total-info{
                padding: 15px;
                background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
                border-radius: 8px;
                font-weight: 500;
                font-size: 14px;
                color: $white;

                @media (max-width: $breakpoint-md) {
                    padding: 10px;
                    font-size: 10px;
                }

                .total{
                    font-weight: 700;
                    margin-left: 15px;

                    @media (max-width: $breakpoint-md) {
                        margin-left: 0px;
                    }
                }
            }
        }

        .subtitle{
            font-weight: 500;
            font-size: 20px;
            width: 75%;

            @media (max-width: $breakpoint-md) {
                text-align: center;
                font-size: 14px;
                width: auto;
            }
        }
    }

    &-cards{
        padding: 0px 0px 30px;
    }

    @media (max-width: 320px) { 

        .bat-simplifiedshopcard-gloit-upper-content-description{
            ul{
                display: none;
            }
        }

        .bat-simplifiedshopcard-gloit-upper-content-title{
            font-size: 24px;
        }

        .bat-simplifiedshopcard-gloit-lower{

            .bat-simplifiedshopcard-gloit-lower-price{
                display: flex;
                flex-direction: column-reverse;
            }
            .bat-simplifiedshopcard-gloit-lower-cta{
                padding-left: 0px;

                a{
                    width: 130px;
                }
            }
        }

    }

    @include media-breakpoint-up(xs) {

        .bat-simplifiedshopcard-gloit-upper-content-description{
            ul{
                display: block;
            }
        }

    }
}

.bat-simplifiedshopcard-gloit{
    color: $brand-black;
    margin: 18px 30px 0 30px;
    background-color: #EFEFEF;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
        margin-top: 36px;
    }

    &-upper {

        &-image {
            
            picture {
                padding: 13px 17px;
                max-height: 320px;
            }
            
            picture, img {
                height: 100%;
                object-fit: contain;
            }
        }

        &-content {
            padding: 10px 10px 10px 20px;

            @include media-breakpoint-up(sm) {
                padding: 28px 20px 20px 30px;
            }

            &-title {
                @include font-rem(26px, 32px);
                font-weight: 800;
                text-decoration: underline;
                margin-bottom: 10px;

                @include media-breakpoint-up(sm) {
                    @include font-rem(36px, 45px);
                }
            }

            &-description {
                p span {
                    @include media-breakpoint-up(sm) {
                        font-size: 20px !important;
                        line-height: 25px !important;
                    }
                }

                ul li span {
                    @include media-breakpoint-up(sm) {
                        font-size: 16px !important;
                        line-height: 20px !important;
                    }
                }
            }
        }
    }

    &-lower {
        background-color: #F8F8F8;
        margin-top: 0 !important;
        padding: 13px 17px;
        align-items: center;

        &-price {
            font-weight: 900;
            @include font-rem(22px, 27px);
            color: #CE4B05;
            display: flex;
            align-items: flex-start;
            gap: 8px;

            @include media-breakpoint-up(sm) {
                @include font-rem(27px, 34px);
            }
        }

        .base-price {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-decoration-line: line-through;
            color: #2A2C2C;
            opacity: 0.7;

            @include media-breakpoint-up(sm) {
                @include font-rem(17px, 21px);
            }
        }

        &-cta {
            .bat-cta-style {
                padding: 8px 13px 7px 22px !important;
            }
        }
    }

    &-image{
        a {
            width: 100%;
        }

        .price{
            padding: 8px 16px;
            background-color: $brand-black;
            width: fit-content;
            position: relative;
            top: -40px;
            margin-right: 0px;
            margin-left: auto;

            .price-label{
                font-size: 12px;
                line-height: 15px;
                color: $ligth-orange;
                font-weight: 700;
            }

            .price-value{
                font-size: 18px;
                line-height: 28px;
                font-weight: 700;
            }
        }
    }

    &-content{
        flex-direction: column;
        position: relative;
        top: -20px;

        a:hover {
            span {
                text-decoration: none;
                color: $white;
            }
        }

        .category{
            text-transform: uppercase;
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 10px;
            font-weight: 700;
        }

        .product-name{
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
        }
    }
}