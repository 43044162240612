.gloit-waitinglist-modal-body,
.gloit-waitinglist-bad-news-modal-body,
.gloit-registration-error-modal-body,
.gloit-service-unavailable-modal-body {
	display: none;
	position: fixed;
	z-index: 1000000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0px !important;
}

.gloit-waitinglist-modal-content,
.gloit-waitinglist-bad-news-modal-content,
.gloit-registration-error-modal-content,
.gloit-service-unavailable-modal-content {
	height: 100%;
	background: #fff;
	position: relative;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	overflow-wrap: break-word;
}

#gloit-waitinglist-modal-close-button,
#gloit-waitinglist-bad-news-modal-close-button,
#gloit-registration-error-modal-close-button,
#gloit-service-unavailable-modal-close-button {
	background: #fff;
	top: 24px;
	right: 6%;
	height: 40px;
	width: 40px;
	padding: 5px;
	position: absolute;
	border-radius: 100%;
}

.gloit-waitinglist-title-container,
.gloit-waitinglist-bad-news-title-container,
.gloit-registration-error-title-container,
.gloit-service-unavailable-title-container {
	margin: 5%;
}

.gloit-waitinglist-title,
.gloit-waitinglist-bad-news-title,
.gloit-registration-error-title,
.gloit-service-unavailable-title {
	text-align: center;
	font-size: 30px;
	font-weight: bold;
	color: #000;
	line-height: 25px;
}

.gloit-service-unavailable-text-container {
	display: flex;
	justify-content: center;
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 5%;
	text-align: center;
}

.gloit-waitinglist-bad-news-text-container {
	margin-left: 10%;
	margin-right: 10%;
	text-align: center;
}

.gloit-waitinglist-text,
.gloit-waitinglist-bad-news-text,
.gloit-registration-error-text,
.gloit-service-unavailable-text {
	text-align: center;
	margin: 5%;
	margin-top: 0;
	font-size: 18px;
	line-height: 28px;
}

.gloit-waitinglist-image,
.gloit-waitinglist-bad-news-image,
.gloit-registration-error-image,
.gloit-service-unavailable-image {
	margin: auto;
	margin-top: 30%;
	margin-bottom: 0;
	width: 80%;
}



.gloit-waitinglist-continue-button-container,
.gloit-waitinglist-bad-news-continue-button-container,
.gloit-registration-error-continue-button-container,
.gloit-service-unavailable-continue-button-container {
	text-align: center;
	width: 90%;
	margin: 5%;
	color: #fff;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	
	button{
		width: 100%;
	}
}

.gloit-waitinglist-continue-button,
.gloit-waitinglist-bad-news-continue-button,
.gloit-registration-error-continue-button,
.gloit-service-unavailable-continue-button {
	width: 100%;
}