
.section-parallax-pdp-redesign-pro{
    background-color: black;
}
bat-form-gloitparallax{
    &.pdp-hyper-core{
        background-color: transparent;
        padding-bottom: 30px;
        padding-top: 0px;
        z-index: 1;
        position: relative;
        .content-container {
            background-image: url("/content/dam/glo-it/images/hyper-core-pdp/glo-hyper-vantaggi.webp");
            @include media-breakpoint-down(sm) {
                background-image: url("/content/dam/glo-it/images/hyper-core-pdp/glo-hyper-vantaggi-background-mob.webp");
            }

            .parallax-layer .device-purple{
                visibility: hidden;
                @include media-breakpoint-down(sm) {
                    visibility: visible;
                    max-width: 600px;
                    left: 54%;
                    top: 80%;
                 }
            }
        }
    
    }
    background-color: black;
    padding-bottom: 70px;
    padding-top: 65px;
    @include media-breakpoint-down(sm) {
       padding-top: 10px;
    }
    .content-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        height: 485px;
        max-width: 1400px;
        margin-inline: auto;
        padding: 0 14px;
        background-image: url("/content/dam/glo-it/images/pdp-redesign-pro/glo-hyper-pro-rbackground-parallax-desk.webp");
        background-size: cover;
        border-radius: 40px;
        background-repeat: no-repeat;
        @include media-breakpoint-down(sm) {
            background-image: url("/content/dam/glo-it/images/pdp-redesign-pro/glo-hyper-pro-rbackground-parallax-mob.webp");
            flex-direction: column-reverse;
            height: 361px;
            margin-top: 146px;
            max-width: 355px;
        }
        .text-section {
            flex: 1;
            padding-right: 50px;
            color: var(--Glo-White, #FFF);
            font-family: Mont;
            max-width: 510px;
            z-index: 1;

            @include media-breakpoint-down(sm) {
                padding-right: 0px; 
            }
    
            h2 {
                color: var(--Glo-White, #FFF);
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%; /* 38.4px */
                @include media-breakpoint-down(sm) {
                    font-size: 32px;
                    line-height: 120%; 
                }
                
            }
    
            .subtitle{
                margin-top: 4px;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%; /* 24px */
                margin-bottom: 20px;
                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                    line-height: 140%;
                    margin-bottom: 0px;
                }
                
            }
    
            p {
                font-feature-settings: 'liga' off, 'clig' off;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 25.2px */
                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                    line-height: 140%;
                }
                
            }
    
            .cta-button {
				width:241px;
                margin-top: 39px;
                background-color: transparent;
                min-height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-inline: 74px;
                color: white;
                border: 2px solid white;
                cursor: pointer;
                border-radius: 25px;
                transition: background-color 0.3s ease;
                color: var(--glo-2024-White, #FFF);
                font-family: Mont;
                font-size: 13px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;

                @include media-breakpoint-down(sm) {
					width:100%;
                    min-width: 100%;
                    margin-top: 24px;
                    margin-bottom: 20px;
                }
    
                &:hover {
                    background-color: white;
                    color: #2a1846;
                }
            }
        }
    
        .parallax-container {
            flex: 1;
            position: relative;
            height: 485px;
            // overflow-y: hidden; 
            width: 100vw;
            height: 100%;
        }
    
        .parallax-layer {
            position: absolute;
            top: 24px;
            left: 0;
            width: 100%;
            height: 100%;
    
            img {
                position: absolute;
                transition: none;
            }
    
            .device-blue-left {
                left: 0%;
                top: 57%;
                width: 40%;

                @include media-breakpoint-down(sm) {
                    left: -130px;
                    top: 0%;
                    max-width: 211px;
                    width: unset;
                }
            }
    
            .device-blue-right {
                right: 6%;
                top: 42%;
                width: 30%;

                @include media-breakpoint-down(sm) {
                    right: -68px;
                    top: -30%;
                    max-width: 191px;
                    width: unset;
                }
            }
    
            .device-purple {
                width: 36%;
                left: 48%;
                top: 90%;
                transform: translate(-50%, -50%);

                @include media-breakpoint-down(sm) {
                    top: 92%;
                    left: 44%;
                    max-width: 222px;
                    width: unset;
                }
            }
        }
    }  
}
