.bat-carousel-howtouse-gloit{
    @include media-breakpoint-down(sm){
        height: 240px;
    }

    h2{
        text-align: left;
        margin-top: 6%;
        margin-left: 5%;
        color: white;
        padding-bottom: 35px;
        font-family: Mont;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        text-transform: uppercase;
        @include media-breakpoint-down(sm){
            margin-top:10%;
        }
    }
    .arrows-container{
        position: absolute;
        display: flex;
        left: 75%;
        margin-top: -1%;
        .next-arrow{
            margin-left: 50px; 
            @include media-breakpoint-down(sm){
                margin-left: 20px; 
            }
            .bat-icon::before{
                color: white;
                content: url('/content/dam/glo-it/images/Glo-Arrow-right.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }  
        }
        .prev-arrow{
            .bat-icon::before{
                color: white;
                content: url('/content/dam/glo-it/images/Glo-Arrow-left.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }  
        }
        @include media-breakpoint-down(sm){
            left: 69%;
        }
        
    }

    .slick-track{
        @include media-breakpoint-down(sm){
            height: auto;
        }
    }
    .slick-dots{
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        margin-bottom: 1%;
        width: 45%;
        gap: 5px;

        li{
            display: block !important;
            overflow: hidden;
            @include media-breakpoint-down(sm){
                margin-top:4%
            }
            button{
                background-color: #ADADAD !important;
                border: 1px solid #ADADAD  !important;
                width: 9px;
                height: 9px;    
            }
        }
        li.slick-active{
            button{
                width: 9px;
                height: 9px;
                background-color: #3A3A3A !important;
                border: 1px solid #3A3A3A !important;
            }
        }

        @include media-breakpoint-down(sm){
            width: 100%;
            margin-left: 5%;
            justify-content: flex-start;
        }
        
    }
}

.bat-infoglo-howtouse-card-gloit{

    /*@include media-breakpoint-down(sm){
        width: 91%;
        margin-left: 4%;
    }*/

    p{
        color: black;
    }
    .container{
        
        display: flex;
        justify-content: space-around;
        align-items: center;

        &-info-card{
            
            display: flex;
            flex-direction: row;
            justify-content: center;


            .left{
                height: 100%;
                width: auto;
                .source-review{
                    height: 360px;
                    width: auto;
                    @include media-breakpoint-down(sm){
                        margin: auto;
                        height: 200px;
                    }
                }
                @include media-breakpoint-down(sm){
                    width: auto;
                    min-width: 200px;
                    height: 200px;
                    margin-left: -5%;
                }
            }
            
            .right{
                display: flex;
                flex-direction: column;
                width: 45%;
                justify-content: center;
                
                @include media-breakpoint-up(sm) {
                    max-width: 500px;
                    width: 500px;
                }
                
                .info-text{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 10px;
                }

                @include media-breakpoint-down(sm){
                    width: auto;
                    margin-right: 6%;
                    h3{
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                    }
                    .info-text{
                        p{
                            font-size: 15px;
                            font-style: normal;
                            line-height: 16px;
                        }
                    }
                }
            }

            
        }
            
        
    } 

    .container-slider-dots{
        margin-top: 30px;
        width: 12%; 
        margin-left: 5%;
    }

}

bat-carousel-gloithowtouse.hyper-pro-only{  
    
    .bat-carousel-howtouse-gloit{
        @include media-breakpoint-down(sm){
            height: 280px;
        }

        .slick-dots{
            margin: 0 auto;
            display: flex;
            justify-content: flex-start;
            align-content: center;
            margin-bottom: 1%;
            width: 45%;
            gap: 5px;
    
            li{
                display: block !important;
                overflow: hidden;
                @include media-breakpoint-down(sm){
                    margin-top:4%
                }
                button{
                    background-color: #ADADAD !important;
                    border: 1px solid #ADADAD  !important;
                    width: 9px;
                    height: 9px;    
                }
            }
            li.slick-active{
                button{
                    width: 9px;
                    height: 9px;
                    background-color: #3A3A3A !important;
                    border: 1px solid #3A3A3A !important;
                }
            }
    
            @include media-breakpoint-down(sm){
                width: 100%;
                justify-content: center;
                top: 330px;
            }
            
        }
    }    
    .bat-infoglo-howtouse-card-gloit{
    
        .container{
            
            display: flex;
            justify-content: space-around;
            align-items: center;
    
            &-info-card{
                
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 40px;
                @include media-breakpoint-down(sm){
                    flex-direction: column;
                }
                .left{
                    height: 100%;
                    width: auto;
                    .source-review{
                        height: 360px;
                        width: auto;
                        @include media-breakpoint-down(sm){
                            margin: auto;
                            height: 200px;
                        }
                    }
                    @include media-breakpoint-down(sm){
                        width: auto;
                        min-width: 200px;
                        height: 200px;
                        margin-left: -5%;
                    }
                }
                
                .right{
                    display: flex;
                    flex-direction: column;
                    width: 45%;
                    justify-content: center;
                    
                    @include media-breakpoint-up(sm) {
                        max-width: 500px;
                        width: 500px;
                    }
                    
                    .info-text{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-top: 10px;
                    }
    
                    @include media-breakpoint-down(sm){
                        width: auto;
                        margin-left: 4%;
                        h3{
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: normal;
                        }
                        .info-text{
                            p{
                                font-size: 15px;
                                font-style: normal;
                                line-height: 16px;
                            }
                        }
                    }
                }
    
                
            }
                
            
        } 
    
    }
}

