.bat-insidersshop-gloit {
	&-title {
		padding: 34px 0px 14px;
		title-info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.title {
				text-transform: uppercase;
				font-size: 34px;

				@media (max-width: $breakpoint-md) {
					font-size: 24px;
				}
			}

			.total-info {
				padding: 15px;
				background: linear-gradient(45deg, #5482c2 0, #353174 100%);
				border-radius: 8px;
				font-weight: 500;
				font-size: 14px;
				color: $white;

				display: flex;
				align-items: center;
				width: 250px;
				height: 70px;
				margin-top: 0px;
				justify-content: space-around;

				@media (max-width: $breakpoint-md) {
					padding: 10px;
					font-size: 10px;
				}

				.total-label {
					font-size: 16px;
				}

				.total {
					font-weight: 700;
					margin-left: 15px;

					@media (max-width: $breakpoint-md) {
						margin-left: 0px;
					}
				}
			}
		}

		.subtitle {
			font-weight: 500;
			font-size: 20px;

			@media (max-width: $breakpoint-md) {
				text-align: center;
				font-size: 14px;
				width: auto;
			}
		}
	}

	&-cards {
		padding: 0px 0px 30px;
	}

	&.mobile-app {
		.total-info {
			display: none;
		}

		.subtitle {
			@media (max-width: $breakpoint-md) {
				text-align: left;
				margin-top: 28px;
			}
		}
	}

	&.new-shop {
		padding: 0px 20px;
		&.mobile-app {
			.bat-insidersshop-gloit-cards-hog-accessories {
				.bat-insidersshopcard-gloit {
					justify-content: flex-start;
                    align-items: normal;
					height: auto;
				}
                .review-container{
                    display: none !important;
                }
			}

            .bat-insidersshop-gloit-cards-hog-experiences {
				.bat-insidersshopcard-gloit {
					justify-content: flex-start;
                    align-items: normal;
					height: auto;
                    gap: 10px;
                    .review-container{
                        display: none !important;
                    }
				}
			}
            
			.filter-container {
				@include media-breakpoint-down(md) {
					background: none;
					border-radius: 10px;
					justify-content: center;
					padding: 0px 0px;
					width: 100%;
					min-height: 54px;
					margin-left: 0px;
				}

				&-title {
					display: none;
				}

				&-buttons {
					gap: 12px;
					width: 100%;
					&-button {
						width: 50%;
						min-height: 37px;
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						padding: 7px 16px;
						gap: 10px;
						background: #efefef;
						border-radius: 70px;
						border: none;

						span {
							font-weight: 700;
							font-size: 14px;
							line-height: 21px;
							color: #2a2c2c;
							display: flex;
							align-items: center;
							gap: 5px;

							img {
								height: 18px;
								width: 18px;
							}
						}

						&.selected {
							background: linear-gradient(
								267.63deg,
								#cd4a04 0.65%,
								#ed7d30
							);
							border: solid 2px transparent;
							background-origin: border-box;
							color: #fff;
							box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
							transition: background 0.5s ease;
							span {
								filter: invert(1) brightness(2);
							}
						}
					}
				}
			}

			.switcher-container {
				@include media-breakpoint-down(md) {
					width: 100%;
					min-height: 54px;
					padding: 15px;
				}
			}
		}

		.icon-coin {
			width: 23px;
			height: 23px;
		}

		.bat-insidersshop-gloit-title {
			@include media-breakpoint-down(md) {
				flex-direction: column;
			}
		}

		.bat-insidersshop-gloit-cards-hog-accessories {
			display: flex;
			flex-direction: column;
			h3 {
				margin-left: 1%;
				margin-bottom: 1%;
			}
		}
		.bat-insidersshop-gloit-cards-hog-experiences {
			display: flex;
			flex-direction: column;
			margin-top: 5%;

			&-title {
				display: flex;
				justify-content: space-between;
				margin-bottom: 1%;
				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}
			}
			h3 {
				margin-left: 1%;
				margin-bottom: 1%;
			}
		}

		.switcher-container {
			margin-right: 10px;
		}

		.switcher-container,
		.filter-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: max-content;
			gap: 22px;
			width: fit-content;

			@include media-breakpoint-down(md) {
				background: #efefef;
				border-radius: 10px;
				justify-content: center;
				padding: 0px 12px;
				width: 100%;
				min-height: 54px;
			}

			&-title {
				text-transform: uppercase;
				font-weight: 700;
				font-size: 12px;
				line-height: 20px;
			}

			&-buttons {
				gap: 9px;

				&-button {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: 7px 16px;
					gap: 10px;
					background: transparent;
					border: 1px solid #6b6b6b;
					border-radius: 50px;

					span {
						font-family: "Mont", serif;
						font-weight: 700;
						font-size: 12px;
						line-height: 11px;
						color: #2a2c2c;
						display: flex;
						align-items: center;
						gap: 5px;

						img {
							height: 18px;
							width: 18px;
						}
					}

					&.selected {
						background: #3a3a3a;
						border: 1px solid #3a3a3a;
						box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

						span {
							filter: invert(1) brightness(2);
						}
					}
				}
			}
		}

		.filter-container {
			display: flex;
			justify-content: center;
			padding: 0px 12px;
			margin-bottom: 48px;
			margin-top: 24px;
		}

		.title-info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			.title {
				font-weight: 800;
			}

			.subtitle {
				text-align: left !important;
				margin-top: 8px;
			}
		}

		.total-info {
			gap: 17px;
			padding: 12px;
			background: linear-gradient(45deg, #5482c2 0, #353174 100%);
			border-radius: 8px;
			font-weight: 500;
			font-size: 14px;
			color: $white;

			display: flex;
			align-items: center;
			width: fit-content;
			min-height: 54px;
			margin-top: 0px;
			justify-content: center;

			@include media-breakpoint-down(md) {
				margin-top: 14px;
				gap: 8px;
			}

			.total-label {
				font-size: 14px;
			}
			.total {
				display: flex;
				font-weight: 700;
			}

			.total-available {
				font-size: 18px;
				line-height: 27px;
			}
		}

		.bat-insidersshopcard-gloit {
			padding: 0;
			background-color: #efefef;
			color: #2a2c2c;

			&-image {
				position: relative;

				.price {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 6px 8px;
					gap: 4px;
					position: absolute;
					left: 16px;
					bottom: 16px;
					background: linear-gradient(
						45deg,
						#5482c2 0,
						#353174 100%
					) !important;
					border-radius: 8px;
					margin: 0 !important;
					top: unset;
					color: white;

					@include media-breakpoint-down(md) {
						left: 8px;
						bottom: 14px;
					}

					.price-value {
						display: flex;
						align-items: center;

						@include media-breakpoint-down(md) {
							font-size: 16px;
							line-height: 100%;
						}
					}
				}

				.glass {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 10px 16px;
					gap: 10px;
					background: #ffffff;
					border-radius: 40px;
					position: absolute;
					border: 2px solid #cd4a04;
					right: 16px;
					bottom: 16px;
					pointer-events: none;

					@include media-breakpoint-down(md) {
						right: 8px;
						bottom: 14px;
						padding: 9px 14px;
					}

					img {
						width: 16px;
						height: 16px;

						@include media-breakpoint-down(md) {
							width: 12px;
							height: 12px;
						}
					}
				}
			}

			&-content {
				padding: 12px 20px 12px 20px;
				position: static;
				flex-direction: row;
				align-items: flex-end;
				gap: 15px;

				a {
					font-weight: 700;
					font-size: 22px;
					line-height: 28px;
					color: #2a2c2c;

					@include media-breakpoint-down(md) {
						font-size: 14px;
						line-height: 15px;
					}
				}

				a:hover span {
					color: #2a2c2c;
				}

				@include media-breakpoint-down(md) {
					align-items: center;
					gap: 5px;
					padding: 10px;
				}

				.product-name {
					font-weight: 700;
					font-size: 22px;
					line-height: 28px;

					@include media-breakpoint-down(md) {
						font-size: 14px;
						line-height: 15px;
					}
				}
			}

			.review-container {
				padding-bottom: 0;
				margin-bottom: 0px !important;
				padding-left: 16px;
				@include media-breakpoint-down(md) {
					padding-top: 5px;
					display: flex !important;
                    margin-bottom: 5px;
				}

				.rating {
					unicode-bidi: bidi-override;
					color: #dedede;
					font-size: 14px;
					letter-spacing: 1px;
					height: 23px;
					width: auto;
					margin: 0;
					margin-top: -4px;
					position: relative;
					padding: 0;
					font-family: FontAwesome;
				}

				.rating-upper {
					color: black;
					padding: 0;
					position: absolute;
					z-index: 1;
					display: flex;
					top: 0;
					left: 0;
					overflow: hidden;
					&::before {
						content: "\f005 \f005 \f005 \f005 \f005";
					}
				}

				.rating-lower {
					padding: 0;
					display: flex;
					z-index: 0;
					&::before {
						content: "\f005 \f005 \f005 \f005 \f005";
					}
				}

				.bat-icon {
					height: 13px;
					width: 13px;
					&::before {
						font-size: 13px;
					}
				}
				.number-review {
					margin-left: 5px;
					font-size: 12px;
					margin-top: -4px;
				}
			}

			&-options {
				display: flex;
				flex-direction: column;
				text-align: left;
				width: auto;
				padding-left: 16px;

				&-title {
					@include font-rem(12px, 16px);
					font-weight: 700;
					letter-spacing: 0;
					margin-bottom: 5px;
				}

                .bat-insidersshopcard-gloit-strengths{
                    display: flex;
                    gap: 2px;
                }

				&-variants-button {
                    @include media-breakpoint-down(sm) {
                        $button-size: 24px;
                        height: $button-size;
					    width: $button-size;
                    }
					$button-size: 35px;
					height: $button-size;
					width: $button-size;
					border-radius: 99999999px;
					position: relative;
					border: 3px solid transparent;
					&:after {
						content: "";
						background-color: var(--button-variant-color);
						display: block;
						width: 100%;
						height: 100%;
						border-radius: 99999999px;
					}
					&:active,
					&.active {
						border: 1px solid $brand-black;
					}
                    &.active:after {
						border: 3px solid $white;
					}
					&:disabled {
						background-color: var(--button-variant-color);
						border: 4px solid $white;
						&:active {
							border: 0;
						}
						&:after {
							background: linear-gradient(
								to left top,
								rgba(255, 255, 255, 0) 0%,
								rgba(255, 255, 255, 0) 42%,
								#fff 43%,
								#fff 46%,
								#ff5216 47%,
								#ff5216 53%,
								#fff 54%,
								#fff 57%,
								rgba(255, 255, 255, 0) 58%,
								rgba(255, 255, 255, 0) 100%
							);
							background: -moz-linear-gradient(
								to left top,
								rgba(255, 255, 255, 0) 0%,
								rgba(255, 255, 255, 0) 42%,
								#fff 43%,
								#fff 46%,
								#ff5216 47%,
								#ff5216 53%,
								#fff 54%,
								#fff 57%,
								rgba(255, 255, 255, 0) 58%,
								rgba(255, 255, 255, 0) 100%
							);
							background: -ms-linear-gradient(
								to left top,
								rgba(255, 255, 255, 0) 0%,
								rgba(255, 255, 255, 0) 42%,
								#fff 43%,
								#fff 46%,
								#ff5216 47%,
								#ff5216 53%,
								#fff 54%,
								#fff 57%,
								rgba(255, 255, 255, 0) 58%,
								rgba(255, 255, 255, 0) 100%
							);
							background: -o-linear-gradient(
								to left top,
								rgba(255, 255, 255, 0) 0%,
								rgba(255, 255, 255, 0) 42%,
								#fff 43%,
								#fff 46%,
								#ff5216 47%,
								#ff5216 53%,
								#fff 54%,
								#fff 57%,
								rgba(255, 255, 255, 0) 58%,
								rgba(255, 255, 255, 0) 100%
							);
							background: -webkit-gradient(
								linear,
								left top,
								right bottom,
								color-stop(0%, rgba(255, 255, 255, 0)),
								color-stop(42%, rgba(255, 255, 255, 0)),
								color-stop(43%, #fff),
								color-stop(46%, #fff),
								color-stop(47%, #ff5216),
								color-stop(53%, #ff5216),
								color-stop(54%, #fff),
								color-stop(57%, #fff),
								color-stop(58%, rgba(255, 255, 255, 0)),
								color-stop(100%, rgba(255, 255, 255, 0))
							);
							background: -webkit-linear-gradient(
								to left top,
								rgba(255, 255, 255, 0) 0%,
								rgba(255, 255, 255, 0) 42%,
								#fff 43%,
								#fff 46%,
								#ff5216 47%,
								#ff5216 53%,
								#fff 54%,
								#fff 57%,
								rgba(255, 255, 255, 0) 58%,
								rgba(255, 255, 255, 0) 100%
							);
							bottom: 0;
							content: "";

							left: 0;
							position: absolute;
							right: 0;
							top: 0;
						}
					}
				}

				p {
					display: none;
				}

				&-bundle-title {
					display: inline-block !important;
					font-size: 12px !important;
					font-weight: 600;
				}

				&-bundle-content {
					display: flex;
					flex-direction: column;
					gap: 5px;
					margin-top: 5px;
					font-size: 12px;
				}
			}

			&-button {
				padding: 0px 20px 20px;
                display: flex;
                height: 100%;
                align-items: flex-end;

				@include media-breakpoint-down(md) {
					padding: 0px 10px 10px;
				}

				.bat-cta-style {
					@include media-breakpoint-down(md) {
						padding: 8px 8px !important;
						width: fit-content;
					}
				}

				span {
					@include media-breakpoint-down(md) {
						font-size: 8px;
						line-height: 10px;
					}
				}
			}

			&.mobile-app {
				background-color: unset;
				margin: 10px 20px;

				.glass {
					right: 4px;
					bottom: 12px;
					padding: 12px 18px;
				}

				.bat-insidersshopcard-gloit {
					height: 600px;
					@include media-breakpoint-down(sm) {
						height: 400px;
					}
				}
			}
		}

		.bat-insidersshop-gloit-cards-hog-accessories {
			.bat-insidersshopcard-gloit {
				display: flex;
				flex-direction: column;
                gap: 6px;
				height: 570px;
				@media screen and (max-width: 440px) {
					height: 360px;
				}
				@media screen and (max-width: 767.98px) and (min-width: 441px) {
					height: 390px;
				}
				@media screen and (min-width: 1600px){
					height: 650px;
				}
                .bat-insidersshopcard-gloit-options-container{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    @include media-breakpoint-down(sm) {
                        flex-direction: column-reverse;
                    }
                }
			}
		}

		.bat-insidersshop-gloit-cards-hog-experiences {
			.bat-insidersshopcard-gloit {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
                justify-content: space-between;
                min-height: 420px;
				@include media-breakpoint-down(sm) {
					min-height: 255px;
				}
			}
		}
	}
}
