bat-maployaltyglopins-gloit {
    width: 100%;
    margin-bottom: 0px;
}

bat-card-gloitguidalegenda {
    margin: 0px !important;
}

.bat-maployaltyglopins-gloit {

    &-search {
        padding: 20px 20px 5px 20px;

        &-label {
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
        }

        &-input {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &-text {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                border: 0px;
                background-color: #dedede;
                padding-left: 10px;

                &::before {
                    content: "";
                }

                #glopins-search {
                    background-color: transparent;
                    width: 100%;
                    height: 48px;
                    border: none;
                    margin-left: 10px;
                    font-family: 'Mont', serif;

                    &:focus,
                    &:active {
                        outline: none;
                        border: none;
                    }

                    &::placeholder {
                        color: rgb(43, 43, 43);
                    }

                }

                #glopins-search-icon {
                    width: 30px;
                    height: 30px;
                }

            }

            &-button {
                margin-left: 15px;
                font-family: 'Mont', serif;
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                color: #3A3A3A;
            }
        }

        &-filters {
            .slider {
                padding-top: 1rem;
                border-radius: .6rem;
            }

            .slider .tabs {
                min-width: 99%;
                list-style: none;
                display: flex;
                gap: .8rem;
                overflow-x: auto;
                padding-left: 20px;
                margin-bottom: 0px;
                padding-bottom: 5px;
            }

            .slider .tabs::-webkit-scrollbar {
                display: none;
            }

            .tabs .tab {
                white-space: nowrap;
                background-color: #EFEFEF;
                color: #2A2C2C;
                padding: .5rem 1rem;
                font-size: 1rem;
                font-family: 'Mont', serif;
                font-weight: 700;
                border-radius: 1.3rem;
                transition: .2s ease-in-out;
            }

            .tabs .tab.active {
                background: linear-gradient(90deg, #E97129 0%, #C74206 100%);
                color: #fff;
            }
        }
    }

    &-results {
        &-title {
            background: #efefef;
            padding: 8px 20px;

            &-text {
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: #3a3a3a;
            }
        }

        &-list {
            display: flex;
            flex-direction: column;
            margin: 0 20px;

            &-element {
                display: flex;
                padding: 10px 0;

                &-text {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-left: 20px;

                    &-title {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 3px;
                    }

                    &-date,
                    &-location {

                        display: flex;
                        align-items: center;

                        &-text {
                            color: #6b6b6b;
                            font-size: 16px;
                            line-height: 24px;
                            margin-left: 5px;
                            margin-bottom: 2px;
                        }

                        &-image {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                &-image {
                    width: 40px;
                }

                &:not(:first-child) {
                    border-top: 1px solid #efefef;
                }
            }
        }
    }

    #map {

        width: 100%;
        height: 965px;

        @include media-breakpoint-down(md) {
            height: 580px;
        }

        #content {
            .title {
                margin: 0px 0px 10px;
                @include font-rem(18px, 20px);

                @include media-breakpoint-down(md) {
                    @include font-rem(16px, 20px);
                }
            }

            .address {
                @include font-rem(14px, 20px);
                color: #6B6B6B;

                @include media-breakpoint-down(md) {
                    @include font-rem(13px, 20px);
                }
            }

            .direction {
                -webkit-appearance: button;
                -moz-appearance: button;
                appearance: button;
                border: 1px solid #979797;
                border-radius: 30px;
                padding: 8px 15px;
                font-style: normal;
                font-weight: 700;
                font-size: 10px;
                font-family: "Mont";
                line-height: 10px;
                text-align: center;
                letter-spacing: .05em;
                text-transform: uppercase;
                color: #979797;
                background: rgba(255, 255, 255, .2);
            }
        }

        .gm-style-iw-t {

            &::after {
                display: none;
            }

            .gm-style-iw-chr {
                display: none;
            }

            .gm-style-iw-d {
                overflow: unset !important;
                background: #2A2C2C;
                padding: 10px;

                div {
                    color: #FFF;
                    font-family: 'Mont', serif;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 21px;
                    letter-spacing: 0.7pt;
                    text-align: center;
                }
            }

            .gm-style-iw-c {
                padding: 0px;
                border-radius: 4px;
                border: 0px;
            }

            .gm-style-iw-tc::after {
                background: #2A2C2C;
            }
        }

        .glopins-explore {
            background-image: url(/content/dam/glo-it/images/mobileapp/glopins-map/explore.svg);
            background-repeat: no-repeat;
            margin: 8px 0px 22px 10px;
            align-items: center;
            height: 54px;
            width: 54px;
        }

        .glopins-decrease-zoom {
            background-image: url(/content/dam/glo-it/images/mobileapp/glopins-map/decreaseZoom.svg);
            background-repeat: no-repeat;
            margin: 0px 10px 34px 0px;
            align-items: center;
            height: 54px;
            width: 54px;
            margin-left: 10px;
        }

        .glopins-increase-zoom {
            background-image: url(/content/dam/glo-it/images/mobileapp/glopins-map/increaseZoom.svg);
            background-repeat: no-repeat;
            margin: 12px 10px 0px 0px;
            align-items: center;
            height: 54px;
            width: 54px;
        }

        .glopins-center-position {
            background-image: url(/content/dam/glo-it/images/mobileapp/glopins-map/centerPosition.svg);
            background-repeat: no-repeat;
            margin: 0px 10px 0px 0px;
            align-items: center;
            height: 54px;
            width: 54px;
            margin-left: 10px;
        }

        .gm-style iframe+div {
            border: none !important;
        }
    }

    .bottom-sheet {
        position: fixed;
        z-index: 1000;
        bottom: 64px;
        left: 0;
        width: 100%;
        height: calc(100% - 64px);
        display: flex;
        opacity: 0;
        pointer-events: none;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
        transition: 0.1s linear;

        .sheet-overlay {
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            opacity: 0.2;
            background: #000;
        }

        .content {
            width: 100%;
            position: relative;
            background: #fff;
            height: 50vh;
            max-width: 1150px;
            padding: 25px 30px;
            transform: translateY(100%);
            border-radius: 12px 12px 0 0;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
            transition: 0.3s ease;
        }

        .header {
            display: flex;
            justify-content: center;
        }

        .body {
            height: 100%;
            overflow-y: auto;
            padding: 5px 0 20px;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                width: 0;
            }

            .splide {

                .splide__pagination {
                    bottom: 0;

                    button[aria-selected="true"] {
                        width: 20px;
                        background: linear-gradient(90deg, #e97129 0, #c74206 100%);
                        margin-left: 7px;
                        margin-right: 7px;
                        border-radius: 10px;
                    }
                }
            }

            .bottom-sheet-content {
                border-radius: 16px 16px 0 0;

                &-element {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: -webkit-fill-available;

                    &-top {

                        display: flex;
                        flex-direction: column;

                        &-cover {

                            position: relative;

                            &-category {
                                height: 40px;
                                position: absolute;
                                top: 15px;
                                left: 15px;
                                background: #fff;
                                border-radius: 8px;
                                display: flex;
                                flex-direction: row;
                                align-content: center;
                                flex-wrap: wrap;
                                padding: 10px;

                                &-icon {
                                    height: 24px;
                                    margin-right: 7px;
                                }

                                &-text {
                                    font-family: Mont;
                                    font-size: 14px;
                                    line-height: 20px;
                                    text-align: center;
                                    margin: auto;
                                }
                            }

                            &-event-type {
                                height: 40px;
                                position: absolute;
                                bottom: 25px;
                                right: 15px;
                                background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
                                border-radius: 8px;
                                display: flex;
                                flex-direction: row;
                                align-content: center;
                                flex-wrap: wrap;
                                padding: 10px;

                                &-text {
                                    font-family: Mont;
                                    font-size: 14px;
                                    line-height: 20px;
                                    text-align: center;
                                    color: #FFF;
                                    margin: auto;
                                }
                            }

                            &-image {
                                margin-bottom: 10px;
                                border-radius: 16px 16px 0 0;
                            }
                        }

                        &-title {
                            font-weight: 800;
                            color: #2a2c2c;
                            font-size: 18px;
                            line-height: 27px;
                            margin-bottom: 8px;
                        }

                        &-location {
                            display: flex;

                            &-icon {
                                height: 20px;
                                width: 20px;
                                margin-right: 4px;
                            }

                            &-text {
                                margin: auto 0;
                            }
                        }

                        &-date {
                            display: flex;
                            margin-bottom: 4px;

                            &-icon {
                                height: 20px;
                                width: 20px;
                                margin-right: 4px;

                            }

                            &-text {
                                margin: auto 0;
                            }
                        }

                        &-description {
                            margin-top: 10px;
                            font-size: 16px;
                            line-height: 24px;


                            :first-child {
                                display: block;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 6;
                                -webkit-box-orient: vertical;
                                text-overflow: ellipsis;
                            }

                            :not(:first-child) {
                                display: none;
                            }
                        }

                        &-description-gloclub{
                            max-height: 175px;
                            overflow-y: auto;
                            overflow-x: hidden;
                            text-overflow: ellipsis;
                            white-space: normal;
                        }
                    }

                    &-bottom {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 15px 0;

                        .bottom-sheet-content-element-bottom-open-in-map {
                            font-size: 16px;
                            margin-bottom: 10px;

                            &:hover {
                                color: #2A2C2C;
                            }

                        }

                        .bottom-sheet-content-element-bottom-go-to-details {
                            font-size: 14px;
                            margin-top: 10px;
                            margin-bottom: 20px;
                        }
                    }
                }

                .frontleBottomSheetBar {
                    padding-bottom: 0.5rem;

                    .frontleBottomSheetBarLine {
                        background: #3A3A3A;
                        height: 5px;
                    }
                }
            }

        }

        &.show {
            opacity: 1;
            pointer-events: auto;

            .content {
                transform: translateY(0%);
            }
        }

        &.dragging {
            .content {
                transition: none;
                
                .body {
                    overflow-y: hidden;
                
                }
            }
        }

        &.fullscreen {
            .content {
                border-radius: 0;
                overflow-y: hidden;

                .bottom-sheet-content-element-top {
                    overflow: scroll;
                    -webkit-overflow-scrolling: touch;
                }
            }

            .splide {

                height: 100%;

                .splide__track {
                    height: 100%;
                }
            }
        }

        &.iphone {
            .bottom-sheet-content-element {
                overflow: scroll;

                &-top {
                    overflow: unset !important;
                }
            }
            
        }

        .header {
            .drag-icon {
                cursor: grab;
                user-select: none;
                padding: 15px;
                margin-top: -15px;

                span {
                    height: 4px;
                    width: 40px;
                    display: block;
                    background: #C7D0E1;
                    border-radius: 50px;
                }
            }
        }
    }
}