.bat-editpreferences-gloit{
    width: 75%;
    margin: auto 70px;

    @include media-breakpoint-down(md){
        width: auto;
        margin: auto 20px; 
    }

    h1{
        @include font-rem(30px, 34px);
        text-transform: uppercase;
        margin-bottom: 20px;

        @include media-breakpoint-down(md){
            text-align: center;
            @include font-rem(24px, 28px);
        }
    }

    .checkbox{
        padding: 20px;
        border: 2px solid #DEDEDE;
        margin-bottom: 30px;

        .bat-form-field{
            margin: 0px;

            &.bat-form--signup-newsletter-checkbox{
                margin-bottom: 10px;

                input{
                    display: none;
                }

                span{
                    @include media-breakpoint-down(md){
                        font-size: 12px;
                    }
                }
            }

            &.bat-form--signup-contact-checkbox{
                .bat-form-field-set{
                    display: flex;

                    label{                        
                        input{
                            &::before{
                                color: #C4C4C4;
                            }
                        }

                        &:first-of-type{
                            margin-right: 15px;
                        }

                        span{
                            @include media-breakpoint-down(md){
                                font-size: 12px;
                            }
                        }
                    }
                } 
            }

            .bat-form-field-set{
                margin: 0px;

                label{
                    margin: 0px;
                }
            }
        }
    }

    .buttons{
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(md){
            display: block;
        }

        .bat-form--signup-submit,
        .bat-form--signup-back{
            width: fit-content;

            @include media-breakpoint-down(md){
                width: 100%;
            }

            button,
            a{
                width: 250px;
    
                @include media-breakpoint-down(md){
                    width: 100%;
                    max-width: 100%;
                }
            }

            a{
                font-size: 13px;

                &:hover{
                    span{
                        color: $brand-black;
                        text-decoration: none;
                    }   
                }
            }
        }
    }

    &.mobile-app {
        h1{
            @include media-breakpoint-down(md){
                text-align: left;
            }
        }

        .checkbox {
            padding: 0;
            border: unset;

            .bat-form--signup-contact-checkbox {
                margin-top: 15px;
            }

            .bat-form--signup-newsletter-checkbox + .bat-form--signup-contact-checkbox {
                margin-top: 0;
            }
        }

        .bat-form--signup-submit {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 24px 20px;
        }

        .modified-success {
            position: fixed;
            width: 100%;
            left: 0;
            bottom: 95px;
            padding: 0 20px;
            opacity: 0;
            transition: opacity 0.5s ease-out;

            &.active {
                opacity: 1;
            }

            &-content {
                width: 100%;
                padding: 16px;
                gap: 10px;
                background: #DBFFEA;
                border-radius: 8px;
                font-size: 16px;
                line-height: 24px;
                align-items: center;
            }
        }
    }
}