.bat-sliderthr-gloit {
    display: flex;
    flex-direction: column;
    position: relative;

    .hidden-mobile {
        @media (max-width: 768px) {
            display: none;
        }
    }

    .hidden-desktop {
        @media (min-width: 769px) {
            display: none;
        }
    }

    .full-animation-container {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .stepped-animation {
        height: 100%;
        width: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        .gs-image-top-resize {
            width: 50%;
            height: auto;
            will-change: transform, opacity;
        }

        .bat-video-iframe {
            width: 100%;
            height: 45%;
            position: static;
            border: 0;
            border-radius: 5px;
        }

        .description-text {
            position: absolute;
            gap: 20px;
            top: 38%;
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            will-change: transform, opacity;

            &-heading {
                color: #FFF;
                font-feature-settings: 'clig' off, 'liga' off;
                // text-shadow: 0px 4px 35px #000;
                font-size: 35px;
                font-style: normal;
                font-weight: 600;
                line-height: 40px;

                sup {
                    font-weight: 600;
                }
            }

            &-desc {
                color: #FFF;
                font-feature-settings: 'clig' off, 'liga' off;
                text-shadow: 0px 4px 35px #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }

        @include media-breakpoint-down(md) {
            .description-text {
                top: unset;
                bottom: 45%;
                left: unset;
                gap: 20px;
                width: 100%;
                padding: 26px;

                &-heading {
                    font-size: 24px;
                    line-height: 31px;
                }

                &-desc {
                    font-size: 14px;
                    line-height: 19px;
                    width: 100%;
                }
            }
        }
    }

    .gs-image-hide {
        position: absolute;
        will-change: transform, opacity;
    }

    .gs-cta-hide {
        position: fixed;
        bottom: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 999;
        will-change: transform, opacity;

        @include media-breakpoint-down(md) {
            bottom: 80px;
        }
    }

    .gs-modal-video {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        will-change: transform, opacity;

        @include media-breakpoint-down(md) {
            padding: 0 20px;
        }
    }

    .stepped-animation-two {
        height: 80%;
        width: 100%;
        overflow-y: visible;
        position: absolute;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    @include media-breakpoint-down(md) {

        .gs-image-top-resize {
            position: relative;
            top: 5%;
            width: 45%;
        }

    }

    .stepped-animation-three {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;

        .description-text {
            position: absolute;
            top: unset;
            left: unset;
            gap: 7px;
            will-change: transform, opacity;
            width: 100%;

            &.first {
                bottom: 51%;
            }

            &.second {
                bottom: 39%;
            }

            &.third {
                bottom: 27%;
            }

            &.fourth {
                bottom: 15%;
            }

            &-heading {
                color: #FFF;
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 30px;
                font-weight: bold;
                line-height: 35px;
                text-align: center;
            }
        }

        @include media-breakpoint-down(md) {

            .description-text {

                &.first {
                    bottom: 65%;
                }
    
                &.second {
                    bottom: 55%;
                }
    
                &.third {
                    bottom: 45%;
                }
    
                &.fourth {
                    bottom: 35%;
                }

                &-heading {
                    font-size: 22px;
                    line-height: 26px;
                    
                }
            }
        }
    }
}

.pin-spacer {
    background-color: #284e8a;
}

sup {
    vertical-align: super;
    font-size: 0.3em;
    font-weight: 400;
}