bat-producthero-gloit,
bat-producthero-gloitmobile,
bat-producthero-gloitgloersshop {
	.bat-producthero {
		&-gloit {
			background-color: $white;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			opacity: 0;
			padding: 0 0 20px;
			transition: opacity 0.2s ease-in;

			&.loaded {
				opacity: 1;
			}

			@include media-breakpoint-down(sm){
				.review-stars{
					align-items: center;
					margin-top: 30px;
					margin-left: 20px;

					.icon-star{
						width: 16px;
						height: 16px;
						&::before{
							font-size: 16px;
						}
					}

					.totalReviews{
						margin-left: 7px;
						@include font-rem(12px, 28px);
						color: $dark-gray
					}
				}

				.title{
					@include font-rem(26px, 32px);
					font-weight: 800;
					font-family: $primary-font;
					margin-top: 5px;
					margin-left: 20px;
				}

				.subtitle{
					@include font-rem(18px, 20px);
					font-family: $primary-font;
					font-weight: 500;
					margin-top: 5px;
			
				}
				.hyperpro-icon-container{
					margin-left: 20px;
					picture{
						width: 60%;
						padding: 10px 0 10px 0px;
					}
				}
			}

			&-sticky-banner{
				background-color: transparent !important;

				.sticky-title{
					display: none;
				}
				.add-to-cart-container{
					display: none;
				}

				.container-sticky{
					display: none;
				}
				
				&.uk-active{
					display: flex;
					top: auto !important;
					bottom: 49px;
					background-color: $concrete;
					align-items: center;

					@media (min-width: 367px) {
						bottom: 37px;
					}

					@include media-breakpoint-down(sm){
						background-color: $white;
						display: block;
					}

					.container-sticky{
						display: flex;
						flex-direction: row;
						white-space: nowrap;
						background-color: #efefef;
						width: 1300px;
						height: 87px;
						border-radius: 30px;
						bottom: 55px;
						position: fixed;
						@include media-breakpoint-down(sm){
							width: 102%;
							height: 70px;
							border-radius: 0px;
							top: 119px;;
						}
					};

					.container-title{
						margin-left: 20px;
						@include media-breakpoint-down(sm){
							display: none;
						}
					}

					.sticky-title{
						display: block;
						@include font-rem(28px, 32px);
						font-weight: 900;
						//margin-bottom: 30px;
						margin-top: 28px;
						margin-bottom: 7px;

						@include media-breakpoint-down(sm){
							display: none;
						}
					}

					.sticky-color-container{
						display: flex;
						flex-direction: row;
						.sticky-circle{
							width: 15px;
							height: 15px;
							border-radius: 99999999px;
						}
						.sticky-color{
							color: #6B6B6B;
							@include font-rem(16px, 16px);
							font-weight: 600;
							//margin-top: -3px;
							margin-left: 8px;
						}
					}

					.add-to-cart-container{
						display: flex;
						align-items: center;
						margin-left: auto;
						margin-right: 20px;
						@include media-breakpoint-down(sm){
							margin-left: 19px;
							width: 100%;
						}

						.price-container{
							display: flex;
							flex-direction: column;
							@include media-breakpoint-down(sm){
								.sticky-color-container{
									display: none;
									flex-direction: row;
									.sticky-circle{
										width: 15px;
										height: 15px;
										border-radius: 99999999px;
										margin-left: 8px;
									}
									.sticky-color{
										color: #6B6B6B;
										@include font-rem(13px, 16px);
										font-weight: 600;
										//margin-top: -3px;
										margin-left: 0px;
									}
								}
							}
							.price{
								@include font-rem(30px, 32px);
								font-weight: 800;
								font-family: $primary-font;
								margin-right: 20px;
	
								@include media-breakpoint-down(sm){
									//display: none;
								}
							}
							.total-price{
								display: none;
								@include media-breakpoint-down(sm){
									display: block;
									font-size: 13px;
									font-weight: 600;
									line-height: 17px;
									letter-spacing: 0px;
									text-align: left;
									color: #6B6B6B;
									;
								}
							}
						}
						
					
						.add-to-cart-cta{
							@include media-breakpoint-down(sm){
								margin: 10px auto;
								width: 100%;
								margin-left: 24px;
								padding: 12px;
							}
						}
					}

					.quantity-counter{
						.quantity-label{
						@include font-rem(12px, 14px);
						letter-spacing: 1.2px;
						font-weight: 800;
						font-family: $primary-font;
						max-width: fit-content;
						margin: auto;
						margin-left: 0px;
						margin-right: 35px;

						@include media-breakpoint-down(sm){
							margin-right: 60px;
						}
						}

						.counter{
							button{
								width: 28px;
								height: 28px;
							}
							.icon-plus,
							.icon-minus{
								background-color: $white;
								border-radius: 100%;
								color: $brand-black;
								font-weight: 800;
								padding: 5px;
								&:before{
									font-weight: 800;
									font-size: 18px;
								}
							}

							.counter-number{
								@include font-rem(15px, 14px);
								letter-spacing: 1.2px;
								font-weight: 800;
								font-family: $primary-font;
								margin-left: 16px;
								margin-right: 16px;
							}
						}

						@include media-breakpoint-down(sm){
							display: none;
						}
					}

					.add-to-cart-cta{
						margin-left: 20px;
						@include media-breakpoint-down(sm){
							margin-left: 0px;
						}
					}
				}
			}

            &-container{
				justify-content: space-between;
                @include media-breakpoint-down(sm){
                    display: block;
                }

                .bat-producthero-description{

					.bat-producthero-zoom-result{
						visibility: hidden;
						position: absolute;
						border: 2px solid #d4d4d4;
						/*set the size of the result div:*/
						
						background-color: white;
						background-repeat: no-repeat;
						z-index: 2;
						margin: 5%;
						margin-top:1%;

						@include media-breakpoint-down(md) {
							display: none;
						}

						@media screen and (max-width: 1024px) {
							height: 68vh;
							width: 68vh;
						}
						 
						@media screen and (min-width: 1025px) and (max-width: 1680px) {
							height: 68vh; 
							width: 68vh;
							background-size: 800px 1002px;
						}
						 
						@media screen and (min-width: 1681px) {
							height: 74vh; 
							width: 74vh;
							background-size: 800px 1400px;
						}
						 
					}

                    .descriptions{
                        margin: 100px;
					
						@include media-breakpoint-up(md){
                            width: 65%;
                        }

                        @include media-breakpoint-down(sm){
                            margin: 20px;
                        }

						.review-stars{
							align-items: center;

							.rating-summary-number{
								margin-left: 10px;
							}

							.icon-star{
								width: 16px;
								height: 16px;
								&::before{
									font-size: 16px;
								}
							}

							.totalReviews{
								margin-left: 7px;
								@include font-rem(12px, 28px);
								color: $dark-gray
							}
						}

                        .title{
                            @include font-rem(34px, 39px);
                            font-weight: 800;
                            font-family: $primary-font;
							margin-top: 20px;

                            @include media-breakpoint-down(sm){
                                @include font-rem(26px, 32px);
								margin-top: 14px;
                            }
                        }
						.hyperpro-icon-container{
							picture{
								width: 60%;
								padding: 10px 0 10px 0px;
							}
						}
						.blu-hog-container{
							width: 80%;
							height: 44px;
							background: #0103DF;
							display: flex;
							align-items: center;
							justify-content: space-around;
							margin-bottom: 5%;

							@include media-breakpoint-down(sm){
								width: auto;
								margin-left: -20px;
								margin-right: -20px;
								height: 65px;
								justify-content: flex-start;
							}

							span{
								color: #FFF;
								font-family: Mont;
								font-size: 15px;
								font-style: normal;
								font-weight: 600;
								line-height: 16px;
								padding: 0 20px;
							}
						}

                        .subtitle{
                            @include font-rem(20px, 20px);
                            font-family: $primary-font;
                            font-weight: 500;
                            margin-top: 20px;

                            @include media-breakpoint-down(sm){
                                @include font-rem(18px, 20px);
                            }
                        }

                        .listTitle{
                            @include font-rem(14px, 20px);
                            font-weight: 800;
                            font-family: $primary-font; 
                            margin-top: 30px;
                        }

                        .list{
                            margin-top: 20px;
                            .item{
                                @include font-rem(14px, 20px);
                                font-weight: 500;
                                font-family: $primary-font;
                                list-style: url('/content/dam/glo-it/images/pdp/gloitarrowright-pdp.svg');
								padding-left: 8px;
                            }
                        }

                        .engraving{
                            margin-top: 40px;
                            margin-bottom: 40px;
                            background-color: $concrete;

                            @include media-breakpoint-down(sm){
                                margin-top: 20px;
                                margin-bottom: 30px;
                            }

                            .engraving-link{
                                align-items: center;
								display: inline-flex;
    							text-align: inherit;

                                @include media-breakpoint-down(sm){
                                    display: block;
                                }

                                .container{
                                    border-right: 1px solid $light-gray;

                                    @include media-breakpoint-down(sm){
                                        border-right: none;
                                        border-bottom: 1px solid $light-gray;
                                    }
                                }

                                .text-container{
                                    @include media-breakpoint-down(sm){
                                        max-width: fit-content;
                                        margin: auto;
                                    }   
                                }

                                picture{
                                    max-width: fit-content;
                                    margin: 30px;

                                    @include media-breakpoint-down(sm){
                                        margin: 30px 20px;
                                    }
                                }

                                h3, p, span{
                                    @include font-rem(12px, 15px);
                                    font-family: $primary-font; 
                                }

                                p{
                                    color: $dark-gray;
                                }

                                h3{
                                    font-weight: 600;
                                    margin-bottom: 5px;
                                }

                                span{
                                    font-weight: 600;
                                    margin: 40px;
                                    color: $brand-black;
                                    text-decoration: none;
                                }
                            }
                        }

                        .quantity-counter{
                            .quantity-label{
                               @include font-rem(12px, 14px);
                               letter-spacing: 1.2px;
                               font-weight: 800;
                               font-family: $primary-font;
                               max-width: fit-content;
                               margin: auto;
                               margin-left: 0px;
                               margin-right: 35px;

							   @include media-breakpoint-down(sm){
								margin-right: 60px;
							   }

							   &.size{
								margin-right: 15px;
							   }

							   &.size-selected{
								   font-weight: 500;
								   font-size: 15px;
								   color: #646464;
							   }
                            }

                            .counter{
								button{
									width: 28px;
									height: 28px;
								}
                                .icon-plus,
                                .icon-minus{
                                    background-color: $concrete;
                                    border-radius: 100%;
                                    color: $brand-black;
                                    font-weight: 800;
                                    padding: 5px;
									&:before{
										font-weight: 800;
    									font-size: 18px;
									}
                                }

                                .counter-number{
                                    @include font-rem(15px, 14px);
                                    letter-spacing: 1.2px;
                                    font-weight: 800;
                                    font-family: $primary-font;
									margin-left: 16px;
									margin-right: 16px;
                                }
                            }
                        }

						.engraving-pro-container {	
							display: flex;
							margin-top: 15px;
	
							@include media-breakpoint-down(sm) {
								flex-direction: column;
								align-items: flex-start;
								gap: 8px;
							}
	
							&-text {
								width: 35%;
	
								@include media-breakpoint-down(sm) {
									width: 100%;
								}
	
								&-title {
									font-size: 12px;
									line-height: 14px;
									letter-spacing: 1.2px;
									font-weight: 800;
								}
	
								&-description {
									p {
										font-size: 12px;
										line-height: 14px;
										color: $brand-black;
									}
								}
							}
	
							&-cta {

								width: 50%;
	
								@include media-breakpoint-down(sm) {
									width: 100%;
								}
	
								.bat-cta-style {
									gap: 8px;
									padding: 10px 20px;
									max-width: 100%;
									width: 100%;
									display: flex;
									justify-content: center;
									align-items: center;
	
									&:hover {
										color: $brand-black;
										
										span {
											color: $brand-black;
										}
									}
	
									.bat-icon {
										width: 24px;
										height: 24px;
									}
								}
							}
						}

						.price-container{
							margin-top: 30px;
							align-items: center;

							@include media-breakpoint-down(sm){
								margin-top: 20px;
							}
							.price{
								@include font-rem(30px, 32px);
								font-weight: 800;
								font-family: $primary-font;
							}
							.add-to-cart-cta{
								@include media-breakpoint-down(sm){
									padding: 13px 15px;
									margin-left: 10px;
								}
							}
						}

						.insiders-container {
							margin-top: 16px;
							.insiders-intro {
								span {
									@include font-rem(14px, 20px);
								}
							}

							.insiders-title {
								margin-top: 12px;
								span {
									font-weight: 600;
									font-size: 20px;
									line-height: 28px;
								}
							}

							.insiders-amount {
								font-weight: 700;
								font-size: 12px;
								line-height: 14px;
								letter-spacing: 1.2px;
								text-transform: uppercase;
								color: #CD4A04;
							}

							.insiders-info {
								background: #D5DDEC;
								border-radius: 8px;
								padding: 10px;
								margin-top: 12px;
								font-weight: 400;
								font-size: 14px;
								line-height: 20px;
								color: #2F539F;
							}
						}
                    }

					.delivery-info-banner{
						background-color: $concrete;
						align-items: center;
						padding: 25px;
						padding-left: 100px;

						@include media-breakpoint-down(sm){
							padding-left: 25px;
						}

						.icon-delivery{
							width: 34px;
							height: 20px;
							margin-right: 16px;
						}

						.title{
							@include font-rem(12px, 14px);
							letter-spacing: 1.2px;
							font-weight: 800;
						}

						.subtitle{
							@include font-rem(12px, 17px);
							font-weight: 500;
						}
					}
                }
            }

			.bat-producthero-content {
				background-color: $white;
				margin-bottom: 5px;
				width: 100%;
				padding-bottom: 18px;
				@include media-breakpoint-down(sm) {
					padding-bottom: 0;
				}
			}

			.bat-producthero-gallery {
				background-color: $white;
				display: flex;
				flex-direction: column;
				margin-bottom: 18px;
				height: 100%;

				.slick-dots {
					display: none !important; // sass-lint:disable-line no-important
				}

				.slick-arrow {
					background-color: $dark-gray-50;
					border-radius: 30px;
					color: transparent;
					font-size: 0;
					height: 30px;
					margin-top: -15px;
					position: absolute;
					top: 50%;
					width: 30px;

					&:before {
						color: $white;
						content: "";
						display: inline-block;
						font-size: 20px;
						vertical-align: middle;
					}

					&.slick-prev {
						left: 20px;

						&:before {
							content: "\e924";
							font-family: "icomoon";
						}
					}

					&.slick-next {
						right: 20px;

						&:before {
							content: "\e928";
							font-family: "icomoon";
						}
					}
				}

				&-images {
					line-height: 0;
					min-height: 300px;
					@include media-breakpoint-up(sm) {
						min-height: 600px;
					}
					display: flex;
					flex-direction: column;
					justify-content: center;
					.large {
						background-position: calc(
								-40px + (-25 - (-40)) * ((100vw - 320px) /
											(575 - 320))
							)
							center;
						// ----------------| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
						background-repeat: no-repeat;
						background-size: contain;
						img {
							display: block;
							height: auto;
							margin: 0 auto;
							max-height: unset;
							position: relative;
							top: 2px;
							width: calc(
								225px + (345 - 225) *
									((100vw - 320px) / (575 - 320))
							);
							@include media-breakpoint-down(sm) {
								max-height: 300px;
								max-width: calc(
									225px + (345 - 225) *
										((100vw - 320px) / (575 - 320))
								);
								width: unset;
							}
						}
						iframe {
							width: 100%;
							height: 150px;
							position: relative;
							border: 0;
							border-radius: 5px;
						}
					}
				}

				&-thumbs {
					display: none;
					flex-wrap: wrap;
					margin-top: 20px;
					padding: 0;

					.thumb {
						background-color: $white;
						background-position: 0 center;

						background-repeat: no-repeat;
						background-size: auto 65%;
						border-top: 4px solid $white;
						height: 95px;
						margin: 2.5px;
						width: 95px;

						&.active {
							border-top: 4px solid $light-blue;
							outline: none;
						}

						&.hide {
							visibility: hidden;
						}

						img {
							display: block;
							margin: 0 auto;
							width: auto;
						}
					}
				}
			}

			.bat-producthero-configurator {
				display: flex;
				flex-direction: column;
				padding: 0;
				width: 100%;

				& > * {
					padding-left: 20px;
					padding-right: 20px;
				}
				.bat-producthero-strengths-indicator-line, .bat-producthero-strengths-group {
					margin-left: 20px;
					margin-right: 20px;
				}

				&-bottom {
					flex-direction: column;
					justify-content: flex-start;
					margin-bottom: 43px;
					padding: 0 20px;
					text-align: left;
				}

				&-cta-list {
					display: flex;
					flex-direction: column;
					flex: 1 1 auto;
					margin-left: auto;
					align-items: center;
					width: 100%;
					button, a {
						max-width: 300px;
						padding: 11px 50px;
						width: fit-content;
					}
					button {
						display: none;
						&.active {
							display: flex;
						}
					}
					a {
						display: flex;
					}
					button, a {
						&:not(:last-child) {
							margin-bottom: 0.5em;
						}
					}
				}

				&-price {
					flex: 0 1 auto;
					margin-top: 10px;
					margin-bottom: 10px;
					text-align: left;
				}

				&-subheading {
					@include font-rem(20px, 28px);
					font-weight: 500;
					letter-spacing: 1.5px;
					margin-bottom: 8px;
					padding-bottom: 0;
					padding-top: 0;
					text-align: left;
					text-transform: capitalize;
				}

				&-short-description {
					h6 {
						@include font-rem(14px, 14px);
						font-weight: 700;
						margin-bottom: 15px;
					}
				}

				&-description {
					@include font-rem(14px, 20px);
					color: $dark-gray;

					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						color: inherit;
						font-size: inherit;
						line-height: inherit;
						margin-bottom: 7px;
					}

					p {
						color: inherit;
						font-size: inherit;
						line-height: inherit;
						margin-bottom: 0;
						&:not(:last-of-type) {
							margin-bottom: 5px;
						}
					}

					ul {
						margin-bottom: 15px;

						li {
							color: inherit;
							font-size: inherit;
							line-height: inherit;
						}
					}
				}

				&-stock {
					display: block;
					font-weight: 700;
					margin-bottom: 20px;
				}

				&-subscription {
					background-color: $concrete;
					margin: 0 auto;
					max-width: 325px;

					&-text {
						@include font-rem(12px, 12px);
						margin-top: 2px;

						.formatted-price {
							font-weight: normal;
						}

						&-note {
							@include font-rem(12px, 12px);
							display: inline-block;
							font-weight: normal;
							margin: 7px 0 0;
							text-transform: none;
						}

						.discount-savings {
							@include font-rem(12px, 12px);
							background-color: $dark-blue;
							color: $white;
							display: inline-block;
							padding: 2px 5px;
						}
					}

					&-max-message {
						margin-top: 0;
					}

					&-details {
						button {
							color: $dark-blue;
							font-weight: 700;
						}
					}

					fieldset {
						padding: 0;

						label {
							align-content: flex-start;
							align-items: flex-start;
							height: auto;
							min-height: 29px;

							input {
								margin-top: 2px;
							}
						}
					}
				}
				&-configurable-options {
					display: flex;
					flex-direction: row;
					text-align: left;
					width: auto;

					.size-container{
						border-radius: 100%;
						margin-right: 2px;
						margin-top: 5px;
						border: 1px solid transparent;


						

						.size{
							border-radius: 100%;
							width: 30px;
							position: relative;
							height: 30px;
							background-color: $concrete;
							color: $dark-gray;
							font-weight: 600;
							font-size: 12px;
							font-family: $primary-font;
							margin: 2px;
			
							&:hover{
								border: 1px solid $brand-black;
								color: $brand-black;
							}
				
							&:active{
								background-color: $white;
								box-shadow: 0 0 3px #1d1d1d;
								border: 1px solid $white;
								background-color: $white;
								color: $brand-black;
								
							}
							&:disabled{
								&:hover{
									border: 0;
									color: $dark-gray;
								}
								&:active{
									background-color: $concrete;
									color: $brand-black;
									box-shadow: 0 0 0 #1d1d1d;
									color: $dark-gray;
								}
								&:after {
									height:23px;
									width:26px;
									background: linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -moz-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -ms-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -o-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -webkit-gradient(linear,left top,right bottom,color-stop(0%,rgba(255,255,255,0)),color-stop(42%,rgba(255,255,255,0)),color-stop(43%,#fff),color-stop(46%,#fff),color-stop(47%,#ff5216),color-stop(53%,#ff5216),color-stop(54%,#fff),color-stop(57%,#fff),color-stop(58%,rgba(255,255,255,0)),color-stop(100%,rgba(255,255,255,0)));
									background: -webkit-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									bottom: 0;
									content: '';
									
									left: 0;
									position: absolute;
									right: 0;
									top: 0;
								}
							}
						}

						&.active{
							.size{
								border: 1px solid $brand-black;
								color: $brand-black;
							}
						}

					}

					&-title {
						@include font-rem(12px, 16px);
						font-weight: 700;
						letter-spacing: 0;
						margin-top: 5px;
						margin-bottom: 5px;
					}
					
					&-variants-button {
						$button-size: 34px;
						height: $button-size;
						width: $button-size;
						border-radius: 99999999px;
						padding: 4px;
						position:relative;
						&:after {
							content: "";
							background-color: var(--button-variant-color);
							display: block;
							width: 100%;
							height: 100%;
							border-radius: 99999999px;
						}
						&:active,
						&.active {
							border: 1px solid $brand-black;
						}
						&:disabled{
							
							background-color: var(--button-variant-color);
							border: 4px solid $white;
							&:active{
								border: 0;
							}
							&:after {
								background: linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
								background: -moz-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
								background: -ms-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
								background: -o-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
								background: -webkit-gradient(linear,left top,right bottom,color-stop(0%,rgba(255,255,255,0)),color-stop(42%,rgba(255,255,255,0)),color-stop(43%,#fff),color-stop(46%,#fff),color-stop(47%,#ff5216),color-stop(53%,#ff5216),color-stop(54%,#fff),color-stop(57%,#fff),color-stop(58%,rgba(255,255,255,0)),color-stop(100%,rgba(255,255,255,0)));
								background: -webkit-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
								bottom: 0;
								content: '';
								
								left: 0;
								position: absolute;
								right: 0;
								top: 0;
							}
						}
					}

					p {
						display: none;
					}
				}

				&-title {
					@extend h3;
					@include font-rem(32px, 32px);
					letter-spacing: -1.25px;
					margin-bottom: 6px;
					max-width: unset;
					padding-bottom: 0;
					padding-top: 0;
				}

				&-options {
					z-index: 1;

					&-option {
						flex: 0 1 auto;
						margin-right: 32px;
						padding: 0 0 10px;
						text-align: center;

						p {
							@include font-rem(14px, 14px);
							font-weight: 700;
							margin-bottom: 5px;
						}

						&-input {
							.select-wrapper {
								display: inline-block;

								select {
									@include font-rem(13px, 13px);
									font-weight: 700;
								}
							}

							fieldset {
								label {
									cursor: pointer;
									display: inline-flex;
									margin-bottom: 0;
									margin-right: 5px;

									input {
										&[type="radio"] {
											display: none;

											+ span {
												@include font-rem(13px, 13px);
												background-color: $concrete;
												color: $dark-blue;
												display: block;
												padding: 14px;
											}

											&:checked {
												+ span {
													@include font-rem(
														13px,
														13px
													);
													background: $dark-blue;
													color: $white;
													display: block;
													font-weight: 700;
													padding: 14px;
												}
											}

											&:disabled {
												+ span {
													position: relative;

													&::after {
														background: $dark-blue;
														content: "";
														height: 2px;
														left: -5px;
														position: absolute;
														top: 46%;
														transform: rotate(
															-34deg
														);
														width: 114%;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.bat-quantity {
					margin-bottom: 26px;
					padding-bottom: 0;
					padding-top: 0;
					&-label {
						@extend h5;
						@extend .headline5-small;
						@include font-rem(12px, 15px);
						flex: 0 1 140px;
						letter-spacing: 1px;
					}
					.bat-quantity-button {
						flex: 0 0 32;
						height: 32px;
						min-width: 32px;
					}
				}

				.bat-producthero-deliveryinfo {
					align-items: center;
					background-color: $white;
					display: flex;
					padding: 24px 20px;
					margin-top: auto;
					margin-bottom: 23px;
					&-icon {
						padding-left: 4px;
						padding-right: 14px;
						.bat-icon {
							height: 34px;
							width: 34px;
							&::before {
								font-size: 34px;
							}
						}
					}
					&-text {
						.heading {
							@extend h5;
							@extend .headline5-small;
							@include font-rem(12px, 15px);
							display: block;
							letter-spacing: 1px;
							margin-bottom: 4px;
						}
						p {
							@include font-rem(12px, 14.4px);
							color: $dark-gray;
							letter-spacing: 0.25px;
							margin: 0;
						}
					}
				}

				.bat-producthero-desktop {
					display: none;
					padding-bottom: 0;
					padding-top: 0;
				}

				.bat-producthero-strengths {
					&-group {
						margin-left: 10%;
						margin-right: 10%;
						margin-bottom: 12px;
						padding: 0;
						&-indicators {
							display: flex;
							flex-direction: column;
							> .bat-producthero-strengths-indicator-line:not(:last-child) {
								margin-right: 40px;
							}
						}
						.bat-producthero-strengths-indicator-line {
							margin: 0;
						}
						&-top-bar {
							display: flex;
							flex-direction: row;
							align-items: center;
							margin-bottom: 12px;

							span {
								margin-right: 13px;
								font-family: $primary-font;
								@include font-rem(12px, 115%);
								letter-spacing: 1.2px;
								font-weight: bold;
								width: 14ch;
								flex-shrink: 0;
							}

							hr {
								width: 100%;
								height: 0;
								background-color: $light-gray;
								border: none;
								border-top: 2px solid $light-gray;
								margin-top: 0;
								margin-bottom: 0;
							}
						}
					}
					
					&-indicator-line {
						display: flex;
						flex-direction: row;
						align-items: center;
						flex-wrap: wrap;
						margin-bottom: 25px;
						margin-left: 10%;
						margin-right: 10%;
						padding: 0;
					}

					&-container {
						display: flex;
						flex-wrap: wrap;
						
						.bat-indicator {
							@include variable(--indicator-active-color, $brand-black);
							@include variable(--indicator-active-last-color, $brand-black);
							border: none;
							align-items: baseline;
							flex-direction: row;
							font-family: $primary-font;
							justify-content: center;
							margin: 0;
							padding: 0;
							width: auto;
							$size: 13px;
							ul {
								li {
									width: $size;
									height: $size;
									line-height: $size;
									font-size: $size;
									border-color: $brand-black;
								}
							}

							&.strength-4mg,
							&.strength-mild,
							&.strength-1 {
								li {
									&:nth-child(1) {
										background: var(--indicator-active-color);
									}
								}

								&.active {
									li {
										&:nth-child(1) {
											background: $white;
										}
									}
								}
							}

							&.strength-6mg,
							&.strength-medium,
							&.strength-2 {
								li {
									&:nth-child(-n + 2) {
										background: var(--indicator-active-color);
									}
									&:nth-child(2) {
										background: var(--indicator-active-last-color);
										border: none;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 2) {
											background: $white;
										}
									}
								}
							}

							&.strength-10mg,
							&.strength-strong,
							&.strength-3 {
								li {
									&:nth-child(-n + 3) {
										background: var(--indicator-active-color);
									}
									&:nth-child(3) {
										background: var(--indicator-active-last-color);
										border: none;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 3) {
											background: $white;
										}
									}
								}
							}

							&.strength-16mg,
							&.strength-x-strong,
							&.strength-4 {
								li {
									&:nth-child(-n + 4) {
										background: var(--indicator-active-color);
									}
									&:nth-child(4) {
										background: var(--indicator-active-last-color);
										border: none;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 4) {
											background: $white;
										}
									}
								}
							}

							&.strength-18mg,
							&.strength-ultra,
							&.strength-5 {
								li {
									&:nth-child(-n + 5) {
										background: var(--indicator-active-color);
									}
									&:nth-child(5) {
										background: var(--indicator-active-last-color);
										border: none;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 5) {
											background: $white;
										}
									}
								}
							}

							&.strength-20mg,
							&.strength-max,
							&.strength-6 {
								li {
									&:nth-child(-n + 6) {
										background: var(--indicator-active-color);
									}
									&:nth-child(6) {
										background: var(--indicator-active-color);
										border: none;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 6) {
											background: $white;
										}
									}
								}
							}
							span {
								@include font-rem(12px, 12px);
								color: $brand-black;
								font-weight: 700;
								margin-left: 10px;
								margin-bottom: 0;
							}
						}
					}
					&-label {
						display: inline-flex;
						flex-direction: row;
						align-items: center;
						margin: 0;
						margin-inline-end: 10px;
						font-weight: normal;
						@include font-rem(13px, 185%);
						width: fit-content;
						&--bold {
							font-weight: bold;
						}
						&-popover-button {
							.bat-icon:before {
								@include font-rem(19px, 100%);
							} 
						}
						&-popover-content {
							position: absolute;
							background-color: $charcoal;
							color: $white;
							padding: 15px;
							transform: translateX(50%);
							width: 200px;
							$triangle-size: 10px;
							display: none;
							margin-top: $triangle-size;
							z-index: 999;
							font-family: $primary-font;
							font-weight: normal;
							@include font-rem(10px, 150%);

							&[data-show] {
								display: block;
							}

							&-arrow {
								visibility: hidden;

								&, &::before {
									position: absolute;
									width: $triangle-size;
									height: $triangle-size;
									background: inherit;
								}

								&::before {
									visibility: visible;
									content: '';
									transform: rotate(45deg);
								}
							}

							&[data-popper-placement^='top'] > .bat-producthero-strengths-label-popover-content-arrow {
								bottom: #{-($triangle-size / 2)};
							}
						
							&[data-popper-placement^='bottom'] > .bat-producthero-strengths-label-popover-content-arrow {
								top: #{-($triangle-size / 2)};
							}
					
							&[data-popper-placement^='left'] > .bat-producthero-strengths-label-popover-content-arrow {
								right: #{-($triangle-size / 2)};
							}
					
							&[data-popper-placement^='right'] > .bat-producthero-strengths-label-popover-content-arrow {
								left: #{-($triangle-size / 2)};
							}

							p {
								display: block;
								font-size: inherit;
								line-height: inherit;
								font-family: inherit;
								font-weight: inherit;
							}
						}
					}
				}
			}

			.bat-producthero-mobile {
				display: block;
				margin-bottom: 31px;
				padding: 40px 20px 0;
				&-lower {
					display: block;
				}
			}
		}
		&-skuerror {
			@include font-rem(16px, 28px);
			margin-bottom: 20px;
		}
		.zoom-lens {
			visibility: hidden;
			position: absolute;
			border: 1px solid #d4d4d4;
			/*set the size of the lens:*/
			width: 150px;
			height: 150px;
			z-index: 1;
		}

		.rating {
			display: inline-block;
			unicode-bidi: bidi-override;
			color: #dedede;
			font-size: 15px;
			letter-spacing: 2px;
			height: 23px;
			width: auto;
			margin: 0;
			position: relative;
			padding: 0;
			font-family: FontAwesome;
		}
		  
		.rating-upper {
			color: black;
			padding: 0;
			position: absolute;
			z-index: 1;
			display: flex;
			top: 0;
			left: 0;
			overflow: hidden;
			&::before {
				content: "\f005 \f005 \f005 \f005 \f005";
			}
		}
		  
		.rating-lower {
			padding: 0;
			display: flex;
			z-index: 0;
			&::before {
				content: "\f005 \f005 \f005 \f005 \f005";
			}
		}

		.bundle-container {
			max-width: 1400px;
    		margin: 0 auto;
			padding: 20px;

			.bundle-section {
				position: relative;

				@media (max-width: 959px) {
					display: grid;
				}

				.bundle-selection {
					@media (max-width: 959px) {
						margin-bottom: 147px;
						grid-row: 1;
  						grid-column: 1;
					}
				}

				.bundle-title {
					font-size: 14px;
					line-height: 18px;
					letter-spacing: 1.2px;
					font-weight: 800;
					font-family: "Mont", sans-serif;
					margin-bottom: 10px;
				}

				.bundle-content {
					background-color: #efefef;
					padding: 20px;
					border-radius: 20px;
					gap: 10px;
					margin-top: 0;

					@include media-breakpoint-down(sm) {
						padding: 10px;
					}

					.bundle {
						label {
							gap: 15px;
							margin: 0;
							> input[type="checkbox"] {
								margin-right: 5px !important;

								&:before {
									background-color: #efefef;
								}
							}

							&.disabled-field {
								opacity: 0.5;
							}
						}

						.bundle-image {
							max-width: 12%;
							img {
								background-color: $white;
								border-radius: 10px;
							}
						}

						.bundle-name {
							font-size: 14px;
							line-height: 18px;
						}
					}

					.bundle-separator {
						border-bottom: 2px solid #dedede;
						width: 100%;
						height: 0;
					}
				}
			}

			.bundle-price {
				
				@media (max-width: 959px) {
					height: 100%;
					pointer-events: none;
					grid-row: 1;
					grid-column: 1;
					justify-content: flex-end;
				}

				.bundle-sticky-price {
					z-index: 1;
					background-color: $white;

					@media (max-width: 959px) {
						bottom: 49px;
						top: unset !important;
						pointer-events: all;
					}
				}
				.quantity-counter{
					.quantity-label{
					   @include font-rem(12px, 14px);
					   letter-spacing: 1.2px;
					   font-weight: 800;
					   font-family: $primary-font;
					   max-width: fit-content;
					   margin: auto;
					   margin-left: 0px;
					   margin-right: 35px;

					   @include media-breakpoint-down(sm){
						margin-right: 60px;
					   }

					   &.size{
						margin-right: 15px;
					   }

					   &.size-selected{
						   font-weight: 500;
						   font-size: 15px;
						   color: #646464;
					   }
					}

					.counter{
						button{
							width: 28px;
							height: 28px;
						}
						.icon-plus,
						.icon-minus{
							background-color: $concrete;
							border-radius: 100%;
							color: $brand-black;
							font-weight: 800;
							padding: 5px;
							&:before{
								font-weight: 800;
								font-size: 18px;
							}
						}

						.counter-number{
							@include font-rem(15px, 14px);
							letter-spacing: 1.2px;
							font-weight: 800;
							font-family: $primary-font;
							margin-left: 16px;
							margin-right: 16px;
						}
					}
				}

				.price-container{
					margin-top: 30px;
					align-items: center;

					@include media-breakpoint-down(sm){
						margin-top: 20px;
						justify-content: space-between;
					}
					.price{
						@include font-rem(30px, 32px);
						font-weight: 800;
						font-family: $primary-font;
					}
					.add-to-cart-cta{
						@include media-breakpoint-down(sm){
							padding: 13px 15px;
							margin-left: 10px;
						}
					}
				}

				.insiders-container {
					margin-top: 16px;
					.insiders-intro {
						span {
							@include font-rem(14px, 20px);
						}
					}

					.insiders-title {
						margin-top: 12px;
						span {
							font-weight: 600;
							font-size: 20px;
							line-height: 28px;
						}
					}

					.insiders-amount {
						font-weight: 700;
						font-size: 12px;
						line-height: 14px;
						letter-spacing: 1.2px;
						text-transform: uppercase;
						color: #CD4A04;
					}

					.insiders-info {
						background: #D5DDEC;
						border-radius: 8px;
						padding: 10px;
						margin-top: 12px;
						font-weight: 400;
						font-size: 14px;
						line-height: 20px;
						color: #2F539F;
					}
				}
			}
		}
	}

	.shop-section-sticky-placeholder {
		padding-top: 140px;
	}
	
	.shop-section-sticky {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(45deg, #5482C2 0, #353174 100%);
		box-shadow: 0px -2px 8px rgba(255, 255, 255, 0.04);
		border-radius: 20px 20px 0px 0px;
		color: $white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 20px;
		gap: 16px;

		.quantity-counter {
			width: 100%;

			.requantity-label {
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
			}
			.counter{
				.icon-minus,
				.icon-plus {
					color: white !important;
					background: unset !important;
					border: 2px solid #FFFFFF;
					display: flex;
					align-items: center;
					justify-content: center;			
				}
			}
		}

		.price-container {
			width: 100%;
			padding-top: 20px !important;
			margin-top: 0 !important;
			border-top: 1px solid #5F73BA;
		}
	}
}

@include media-breakpoint-up(sm) {
	bat-producthero-gloit,
	bat-producthero-gloitmobile {
		.bat-producthero {
			&-gloit {
				.bat-producthero-configurator {
					&-price {
						flex: 0 1 140px;
					}
					&-bottom {
						flex-direction: row;
						margin-bottom: 0;
						margin-top: 0;
					}
					&-cta-list {
						align-items: flex-end;
						width: unset;
					}
					.bat-producthero-strengths-group-indicators {
						flex-direction: row;
					}
					.bat-producthero-strengths-group-top-bar {
						flex-direction: column-reverse;
						align-items: flex-start;
						span {
							margin-top: 12px;
						}
					}
				}
				.bat-producthero-gallery {
					&-images {
						// calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
						.large {
							// ----------------| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
							background-position: calc(
									-25px + (10 - (-25)) * ((100vw - 576px) /
												(767 - 576))
								)
								center;

							/* sasslint-disable-next-line nesting-depth */
							img {
								width: calc(
									350px + (415 - 350) *
										((100vw - 576px) / (767 - 576))
								);
							}
							iframe {
								height: 160px;
							}
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	bat-producthero-gloit,
	bat-producthero-gloitmobile {
		.bat-producthero {
			&-gloit {
				background-color: $white;
				padding: 0;

				.bat-producthero-content {
					width: 50%;
				}

				.bat-producthero-description {
					width: 50%;
				}

				.bat-producthero-gallery {
					padding: 0 0 16px;

					&-images {
						.large {
							// ----------------| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
							background-position: calc(
									-60px + (-85 - (-60)) * ((100vw - 768px) /
												(991 - 768))
								)
								center;

							padding: 12px 0;
							img {
								width: calc(
									260px + (350 - 260) *
										((100vw - 768px) / (991 - 768))
								);
							}
							iframe {
								height: 180px;
							}
						}
					}

					&-thumbs {
						display: flex;
						margin-top: 0;
					}

					.slick-arrow {
						margin-top: 0px;

						&.slick-prev {
							left: 30px;
						}

						&.slick-next {
							right: 30px;
						}
					}
				}

				.bat-producthero-configurator {
					padding: 45px 0 0;
					position: relative;
					width: 48%;

					& > * {
						padding-left: 10%;
						padding-right: 10%;
					}

					&-bottom {
						margin-bottom: 0;
						.bat-cta-style {
							&.button-dark,
							&.button-dark-disabled,
							a {
								flex-basis: 50%;
								padding: 11px 50px;
							}
						}
					}

					&-title {
						@include font-rem(40px, 36px);
						letter-spacing: -0.5px;
						margin-bottom: 13px;
						padding: 0;
					}

					&-subheading {
						letter-spacing: 0.05px;
						margin-bottom: 14px;
						padding: 0;
					}

					&-price {
						@include font-rem(30px, 32px);
						flex-basis: 167px;
						letter-spacing: 0.25px;
						margin-bottom: 0;
						text-align: left;
					}

					&-short-description {
						h6 {
							@include font-rem(20px, 20px);
						}
					}

					&-description {
						font-size: 0.94rem;
						line-height: 16px;
						margin-bottom: 0;
					}

					&-subscription {
						margin: 0;
						max-width: none;
						padding: 20px;
					}

					&-options {
						&-option {
							padding: 0 0 20px;
							text-align: left;
						}
					}

					.bat-producthero-deliveryinfo {
						bottom: 23px;
						left: 0;
						padding: 26px 5.105vw;
						width: 100%;
						&-icon {
							padding-left: 0;
							padding-right: 8px;

							.bat-icon {
								height: 41px;
								width: 41px;
								&::before {
									font-size: 41px;
								}
							}
						}
						&-text {
							.heading {
								letter-spacing: 1.25px;
								margin-bottom: 5px;
							}
						}
					}

					.bat-producthero-desktop {
						display: block;
						margin-bottom: 35px;
					}

					.bat-quantity {
						margin-bottom: 34px;
						&-label {
							flex-basis: 150px;
						}
					}

					.bat-producthero-strengths {
						margin-bottom: 28px;
						&-buttons {
							margin-bottom: 0;
						}
						&-label {
							letter-spacing: 0.95px;
						}
					}
				}

				.bat-producthero-mobile {
					display: none;
					&-lower {
						display: none;
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	bat-producthero-gloit,
	bat-producthero-gloitmobile {
		.bat-producthero {
			&-gloit {
				.bat-producthero-gallery {
					&-images {
						.large {
							background-position: -85px center;
							img {
								// --| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
								width: calc(
									350px + (415 - 350) *
										((100vw - 992px) / (1199 - 992))
								);
							}
							iframe {
								height: 250px;
							}
						}
					}
				}

				.bat-producthero-configurator {
					padding: 65px 0 0;

					&-options {
						form {
							flex-direction: row;
						}
					}

					&-bottom {
						flex-direction: row;
						text-align: left;
						.bat-quantity {
							justify-content: flex-start;
							margin: 0 20px 0 0;
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	bat-producthero-gloit,
	bat-producthero-gloitmobile {
		.bat-producthero {
			&-gloit {
				.bat-producthero-gallery {
					&-images {
						//min-height: 200px; // -----| Prevent flickering when loading new images into carousel
						.large {
							background-position: calc(
									-90px + (-25 - (-90)) * ((100vw - 1200px) /
												(1439 - 1200))
								)
								center;
							// ----------------| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
							img {
								width: 300px;
							}
							iframe {
								height: 300px;
							}
						}
					}
				}

				.bat-producthero-configurator {
					padding: 65px 0 0;

					&-options {
						form {
							flex-direction: row;
						}
					}

					&-bottom {
						flex-direction: row;
						text-align: left;
						.bat-quantity {
							justify-content: flex-start;
							margin: 0 20px 0 0;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1440px) {
	bat-producthero-gloit,
	bat-producthero-gloitmobile {
		.bat-producthero {
			&-gloit {
				.bat-producthero-content {
					.bat-producthero-gallery {
						&-images {
							.large {
								background-position: -25px center;
								iframe {
									height: 330px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1700px) {
	bat-producthero-gloit,
	bat-producthero-gloitmobile {
		.bat-producthero {
			&-gloit {
				.bat-producthero-content {
					.bat-producthero-gallery {
						&-images {
							.large {
								background-position: -25px center;
								iframe {
									height: 400px;
								}
							}
						}
					}
				}
			}
		}
	}
}
