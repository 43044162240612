bat-producthero-gloitredesign{
    &.pdp-hyper-core{
        .bat-capverify-gloit{
            background-color: white;
            .bat-capverify-gloit-form{
                .bat-form--cap-verify-submit button {
                    span{
                        color: black;
                    }
                }
        
                .bat-form--cap-verify-submit button.loading:after {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    border: 8px solid lightgray;
                    border-radius: 50%;
                    border-top: 8px solid #2f539f;
                    animation: spin 1s linear infinite;
                    z-index: 10;
                }
        
                #step1{
                    #step1Form{
                        input{
                            background-color:white;
                            border: solid 1px #6B6B6B !important;
                            border-radius: 60px;
                        }
                    }
                    .bat-form-heading{
                        h1{
                            color:black;
                        }
                    }
                    
                }
    
                &-info, &-info-glovo, &-info-sameday{
                    p{
                        color: black;
                        opacity: 1;
                    }
                }
                
                &-info-sameday{
                    img{
                        width: 22%;
                        content: url("/content/dam/glo-it/images/24hDELIVEYICON.png");
                        @include media-breakpoint-down(sm){
                            width: 17% !important;
                        }
                    }
                }
            }
        }
    }
    .bat-capverify-gloit{
        background-color: #000000;
        .bat-capverify-gloit-form{
            margin: 2% 8% 10% 12%;
            @include media-breakpoint-down(sm){
                margin: 5%;
            }
    
            .bat-form--cap-verify-submit button {
                position: relative;
                background: transparent;
                border-radius: 100px;
                border: 2px solid var(--glo-2024-Accent-Orange, #FE5000);
                max-height: 36px;
                padding: 12.5px 24px;

                span{
                    color: var(--glo-2024-White, #FFF);
                    font-family: Mont;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: none;
                }
                &.loading.disabled{
                    opacity: 0.5;
                }
            }
    
    
            .bat-form--cap-verify-submit button.loading:after {
                content: "";
                position: absolute;
                width: 30px;
                height: 30px;
                border: 8px solid lightgray;
                border-radius: 50%;
                border-top: 8px solid #2f539f;
                animation: spin 1s linear infinite;
                z-index: 10;
            }
    
            #step1{
                #step1Form{
                    display: flex;
                    align-content: center;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5%;
    
                    input{
                        background-color:white;
                        border: solid 1px #6B6B6B !important;
                        border-radius: 60px;
                    }
                    .bat-form-block {
                        margin:0;
                        min-width: 350px;
                        @include media-breakpoint-down(sm){
                            min-width: 0px;
                        }
                    }
                }
                .bat-form-heading{
                    padding-top: 5%;
                    width: 100%;
                    padding-bottom: 4%;
    
                    @include media-breakpoint-down(sm){
                        width: 80%;
                    }
                    h1{
                        color:white;
                        font-family: Mont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 115%;
                        letter-spacing: 0.5px;
                    }
                }
                
            }
            .bat-capverify-gloit-form-info-success-container{
                display: flex;
                align-items: center;
                gap: 32px;
            }
            &-info-glovo{
                img{
                    width: 40% !important;
                    @include media-breakpoint-down(sm){
                        width: 30%;
                    }
                }
            }

            &-info-sameday{
                img{
                    width: 22%;
                    @include media-breakpoint-down(sm){
                        width: 17% !important;
                    }
                }
            }

            &-info, &-info-glovo, &-info-sameday{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4%;
                width: 38%;
                padding-bottom: 3%;
                @include media-breakpoint-down(sm){
                    width: 100%;
                    flex-direction: column;
                    align-items: flex-start;
                }
                p{
                    color: var(--glo-2024-Warm-Grey, #E5E1E6);
                    font-family: Mont;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%; /* 14.4px */
                    letter-spacing: 0.5px;
                    opacity: 0.6;
                    width: 100%;
                    text-transform: none;
                }
    
                img{
                    width: 30%;
                    margin-bottom: 10px;
                }
                &-success{
                    display: flex;
                    align-items: center;
                    @include media-breakpoint-down(sm){
                        gap: 3%;
                        img{
                            width: 8%;
                        }
                        p{
                            width:75%;
                        }
                    }
                    p{
                        color: #0DB14E;
                        font-size: 10px;
                        font-weight: 700;
                    }
                    img{
                        width: 6%;
                    }
                }
                &-error{
                    display: flex;
                    align-items: center;
                    @include media-breakpoint-down(sm){
                        gap: 3%;
                        img{
                            width: 8%;
                        }
                        p{
                            width:75%;
                        }
                    }
                    p{
                        color: #2F539F;
                        font-size: 10px;
                        font-weight: 700;
                    }
                    img{
                        width: 6%;
                    }
                }
            }
        }
    }
}





.bat-capverify-gloit{
    background-color: #efefef;
    .bat-capverify-gloit-form{
        margin: 10% 8% 10% 8%;
        @include media-breakpoint-down(sm){
            margin: 5%;
        }

        .bat-form--cap-verify-submit button {
            position: relative;
            &.loading.disabled{
                background: linear-gradient(267.63deg,#ba4304 .65%,#d7722d 100%);
                opacity: 0.5;
            }
        }


        .bat-form--cap-verify-submit button.loading:after {
            content: "";
            position: absolute;
            width: 30px;
            height: 30px;
            border: 8px solid lightgray;
            border-radius: 50%;
            border-top: 8px solid #2f539f;
            animation: spin 1s linear infinite;
            z-index: 10;
        }

        #step1{
            #step1Form{
                display: flex;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
                gap: 5%;

                input{
                    background-color:white;
                    border: solid 1px #6B6B6B !important;
                }
                .bat-form-block {
                    margin:0;
                    min-width: 350px;
                    @include media-breakpoint-down(sm){
                        min-width: 0px;
                    }
                }
            }
            .bat-form-heading{
                padding-top: 5%;
                width: 60%;
                padding-bottom: 4%;

                @include media-breakpoint-down(sm){
                    width: 80%;
                }
                h1{
                    color:#2A2C2C;
                    font-family: Mont;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 115%;
                    letter-spacing: 0.5px;
                }
            }
            
        }
        .bat-capverify-gloit-form-info-success-container{
            display: flex;
            align-items: center;
            gap: 32px;
        }
        &-info, &-info-glovo, &-info-sameday{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5%;
            width: 35%;
            padding-bottom: 3%;
            @include media-breakpoint-down(sm){
                width: 60%;
            }
            p{
                color: #2A2C2C;
                font-family: Mont;
                font-size: 11px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                width: 55%;
            }

            img{
                width: 15%;
            }
            &-success{
                display: flex;
                align-items: center;
                @include media-breakpoint-down(sm){
                    gap: 3%;
                    img{
                        width: 8%;
                    }
                    p{
                        width:75%;
                    }
                }
                p{
                    color: #0DB14E;
                    font-size: 10px;
                    font-weight: 700;
                }
                img{
                    width: 6%;
                }
            }
            &-error{
                display: flex;
                align-items: center;
                @include media-breakpoint-down(sm){
                    gap: 3%;
                    img{
                        width: 8%;
                    }
                    p{
                        width:75%;
                    }
                }
                p{
                    color: #2F539F;
                    font-size: 10px;
                    font-weight: 700;
                }
                img{
                    width: 6%;
                }
            }
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}