.bat-insiderstabalternative-gloit{
    .bat-insiderstab-gloit-buttons{
        justify-content: flex-start;
        border-bottom: none;

        &-button{
            margin: 0px;
            margin-right: 5px;
            margin-bottom: 5px;
            margin-top: 30px;
            padding: 20px 10px;
            justify-content: flex-start;

            .title{
                @include font-rem(16px, 14px)
            }

            &.trendsetter{
                border: 1px solid #B0B0B0;
                border-left: 8px solid #FAA61A;
            }

            &.hero{
                border: 1px solid #B0B0B0;
                border-left: 8px solid #C7006B;
            }

            &.leader{
                border: 1px solid #B0B0B0;
                border-left: 8px solid #7100C3;
            }

            &.not-selected{
                background-color: #B0B0B0;
                border-left: 8px solid #B0B0B0;

                .title{
                    color: #757575;
                }
            }

            .stars{
                &.hidden{
                    display: none;
                }
            }

            .stars-not-selected{
                padding-left: 5px;

                .star{
                    padding-bottom: 5px;
                }
                
                &.hidden{
                    display: none;
                }
            }
        }
    }

    .bat-insiderstab-gloit-table-container{
        padding: 0px;

        &.trendsetter{
            border: 1px solid #FAA61A;
        }

        &.hero{
            border: 1px solid #C7006B;
        }

        &.leader{
            border: 1px solid #7100C3;
        }

        .first-row{
            border-bottom: 1px solid #B0B0B0
        }

        .first-row,
        .second-row{
            padding-bottom: 20px;
            padding-top: 20px;
            margin-left: 20px;
            margin-right: 20px;

            div:first-of-type{
                border-right: 1px solid #B0B0B0;
            }
        }

        .table-container{
            width: 100%;
            padding: 20px;
        }
    }
}

.note-become{
    font-size: 12px;
    a{
        text-decoration: none;
    }

    @include media-breakpoint-down(md){
        padding: 0px 20px;
    }
}