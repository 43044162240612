.discover-video{
	@include media-breakpoint-down(md){
		flex-direction: column-reverse;
		padding-bottom: 150px;
		.title-video{
			display: none;
		}
		
	}
	
	@include media-breakpoint-up(md){
		.title-video-mobile{
			display:none;
		}
	}
	background-color: $brand-black;
	padding-bottom: 50px;
	.bat-video-content{
		max-width: $site-max-width;
		&-video{
			margin-top: -130px;
		}
	}
	.title-video{
		h2{
			color: $white !important;
			margin-left: 10%;
    		max-width: 370px;
		}
	}
	.content-video{
		max-width: 980px;
		margin-left: 10%;
	}
	
}
.title-video-mobile{
	padding-bottom: 100px;
	@include media-breakpoint-up(lg){
		display:none;
	}
}