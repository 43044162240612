.bat-insiderstabaccordion-gloit{
    .uk-container{
        .list{
            .list-item{
                padding: 20px 10px;

                &.trendsetter{
                    border: 1px solid #FAA61A;
                    
                    .list-item-title{
                        span{
                            color: #FAA61A;
                        }
                    }
                }

                &.hero{
                    border: 1px solid #C7006B;

                    .list-item-title{
                        span{
                            color: #C7006B
                        }
                    }
                }

                &.leader{
                    border: 1px solid #7100C3;

                    .list-item-title{
                        span{
                            color: #7100C3;
                        }
                    }
                }

                .list-item-title{
                    display: flex;
                    max-height: 20px;

                    span{
                        @include font-rem(16px, 20px);
                        letter-spacing: 1.2px;
                        align-self: center;
                        padding-top: 22px;
                    }

                    .stars{
                        .star{
                            padding-bottom: 5px;
                            padding-left: 5px;
                        }
                    }

                    .bat-icon{
                        &::before{
                            font-size: 20px;
                        }
                    }
                }

                .list-item-content{
                    .bat-insiderstab-gloit-table-container{
                        border-top: 1px solid #b0b0b0;
                        border-bottom: none;

                        table{
                            display: inline-table;

                            caption{
                                @include font-rem(14px, 16px);
                            }

                            tr{
                                td{

                                    &:first-of-type{
                                        p{
                                            @include font-rem(12px, 16px);
                                            color: #757575;
                                        }
                                    }
                                    
                                    p{
                                        @include font-rem(9px, 14px);
                                        min-height: 20px;
                                        max-height: 35px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}