bat-form-gloitfaq{
    .bat-form-gloitfaq{
        background-color: transparent;
        padding: 0px 24px;
        @include media-breakpoint-down(sm) {
            padding: 0px 0px;
        }
        &-container {
            align-items: center;
            justify-content: center;
            background: var(--glo-2024-glo-Charcoal, #2a2a2a);
        }
    
        &-title {
            color: var(--glo-2024-White, #FFF);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Mont;
            font-size: 38px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 45.6px */
            width: 100%;
            text-align: left;
    
            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
                font-size: 28px;
            }
        }
        &-text {
            gap: 78px;
            width: fit-content;
            max-width: 782px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-height: 751px;
    
            @include media-breakpoint-down(sm) {
                padding-top: 34px;
                padding-bottom: 34px;
                min-width: 0px;
                min-height: 0px;
                padding-inline: 20px;
            }
    
            .column {
                width: 100%;
                &-upper {
                    gap: 12px;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
    
                    svg{
                        opacity: 0.4;
                        transition: transform 0.5s ease-in, opacity 0.5s ease-in;
    
                        &.open{
                            transform: rotate(180deg);
                            opacity: 1;
                        }
                    }
                }
    
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    &-upper {
                        align-items: flex-start;
                        gap: 0px;
                    }
                }
            }
    
            @include media-breakpoint-down(sm) {
                display: block;
                margin: 0px;
            }
    
            .title {
                font-weight: 500;
                transition: font-weight 0.6s ease;
                font-family: $primary-font;
                color: white;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 28px */
    
                &.open{
                    font-weight: 700;
                }
    
                @include media-breakpoint-down(sm) {
                    width: 86%;
                    font-size: 18px;
                    line-height: 130%;
                }
            }
            .full-specifications-cta {
                @include media-breakpoint-up(sm) {
                    display: none;
                }
    
                .text {
                    @include font-rem(16px, 27px);
                    font-weight: 700;
                    letter-spacing: 1px;
                    font-family: $primary-font;
                    color: white;
                }
    
                &.hidden {
                    display: none;
                }
            }
    
            .border {
                border-bottom: 1px solid #e5e1e6;
                opacity: 0.4;
                margin-top: 30px;
                margin-bottom: 30px;
                width: 100%;
    
                @include media-breakpoint-down(sm) {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
    
            .subtitle {
                @include font-rem(16px, 20px);
                font-weight: 500;
                font-family: $primary-font;
                color: white;
                max-height: 0;
                opacity: 0;
                overflow: hidden;
                display: none;
                transition:
                max-height 0.5s ease,
                opacity 0.5s ease;
                margin-top: 20px;
    
                &.show {
                    display: block !important;
                    max-height: 100px;
                    opacity: 0.6 !important;
                }
    
                &-container{
                    display: block;
                    width:100%;
    
                    .up,.down{
                        width:100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .left,.right{
                           display: flex;
                           gap: 15px;
                        }
                    }
    
                    .up{
                        margin-bottom: 30px;
                        .left,.right{
                            .text{
                                font-size: 20px;
                                font-weight: 500;
                            }
                        }
                    }
    
                    .down{
                        .left,.right{
                            font-size: 33px;
                            font-weight: 300;
                            span{
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
    
            .column{
                .border:nth-last-of-type(1) {
                    display: none;
                }
            } 
    
            .column {
                .border {
                    &.show {
                        border-bottom: 2px solid $light-gray;
                    }
                }
            }
        }
        &-image {
            max-width: 80%;
        }
    }
}
