.gloit-mclaren-usp{
    @include media-breakpoint-down(md){
        display: block;
    }
    
    &-title{
        position: relative;
        max-width: fit-content;
        align-self: center;
        width: 25%;
        padding: 70px;
        background-color: $concrete;

        @include media-breakpoint-down(md){
            text-align: start;
            width: 100%;
            padding: 30px 20px;
            margin-right: 20px;
        }

        .rect{
            position: absolute;
            width: 107px;
            height: 40px;
            background-color: white;
            bottom: 0;
            right: 0; 

            @include media-breakpoint-down(md){
                width: 75px;
                height: 25px;
            }
        }
    }

    .banner{
        background-color: $white;

        .uk-container{
            .uk-container{
                @include media-breakpoint-up(sm){
                    display: flex;
                }
            }
        }

        .banner-container{
            display: block;

            @include media-breakpoint-down(sm){
                padding-top: 0px;
                padding-bottom: 0px;
            }

            .banner-number-list{
                justify-content: space-evenly;

                @include media-breakpoint-down(sm){
                    padding-right: 20px;
                    padding-bottom: 0px;
                }
            }
        }

        .uk-flex-middle{
            align-items: flex-start;
        }

        .banner-number-list{
            margin-left: 0px;
            padding-left: 40px;
            padding-right: 40px;

            @include media-breakpoint-down(sm){
                padding-left: 0px;
                padding-right: 0px;
            }

            .uk-inline{
                display: block;

                @include media-breakpoint-down(sm){
                    display: inline-block;
                }
            }

            .element-and-image{
                margin-bottom: 20px;

                picture{
                    img{
                        margin: auto;
                        max-width: 33px;
                    }
                }
            }

            .list-element{
                text-align: center;
                margin-left: 0px;
                margin-bottom: 20px;
                max-width: 300px;

                @include media-breakpoint-down(sm){
                    text-align: start;
                }
            }

            @include media-breakpoint-down(sm){
                .list-element{
                    margin-left: 15px;
                    max-width: 90%;
                }

                .list-element:last-of-type{
                    margin-left: 40px;
                }
            }
        }
        
        .title-and-subtitle{
            .big-title{
                color: $black;
            }
        }

       .list-element{
            .list-title,
            .list-description{
                color: $black;
            }

            .list-title{
                @include font-rem(22px, 28px);
                text-transform: uppercase;
                font-weight: 800;

                @include media-breakpoint-down(sm){
                    @include font-rem(20px, 28px);
                }
            }

            .list-description{
                @include font-rem(14px, 20px);
            }
        }
    }
}

.gloit-mclaren-news-card{
    margin-bottom: 50px;

    @include media-breakpoint-down(sm){
        display: block;
    }

    &-title{
        padding: 70px 50px;
        width: 50%;

        @include media-breakpoint-down(sm){
            padding: 20px 0px;
            width: 100%;
        }

        .title{
            margin-bottom: 20px;
            @include font-rem(30px, 34px);
    
            @include media-breakpoint-down(sm){
                @include font-rem(24px, 28px);
                padding: 20px;
                background-color: $light-gray;
                margin-right: 20px;
            }
        }
    
        .description{
            @include font-rem(14px, 20px);

            @include media-breakpoint-down(sm){
                padding: 0px 20px;
            }
        }
    }


    .aem-GridColumn{
        .carousel{
            background-color: $white;
        }
        
        .bat-carousel{
            .slick-track{
                left: 33%
            }
        }
    }

    .news-card-title{
        @include font-rem(34px, 34px);
        font-weight: 800;

        @include media-breakpoint-down(sm){
            @include font-rem(24px, 24px);
        }
    }

    .bat-cardnews-gloit--news{
        @include media-breakpoint-down(sm){
            padding: 40px 10px 30px 0px
        }
    }
}

.gloitmclaren-headline{
    .bat-headline{
        .bat-headline-style{
            @include font-rem(45px, 45px);
            font-weight: 800;
            @include media-breakpoint-down(sm){
                font-size: 20px;
            }
        }
    }
}

