.bat-carousel {

	.bat-card {
		.bat-card--default {
			margin-right: 20px;
			max-width: none;
			
			@include media-breakpoint-up(sm) {
				margin-right: 40px;
			}
		}
	}
	
	.bat-productcard {
		opacity: 1;
	}

	.bat-video-content-video {
		margin: 0 auto;
	}
	
	&-slides {
		opacity: 0;
		transition: opacity .2s ease-in;
		
		//AEM author mode 
		.authorEditMode & { // sass-lint:disable-line class-name-format
			opacity: 1;
		}
		
		&.loaded {
			opacity: 1;
		}
	}
	
	.slick-dots {
		justify-content: center;
		margin: 0 auto;
		li {				
			button {
				border: 1px solid $dark-gray-90;
				height: 10px;
				width: 10px;
			}

			&.slick-active {
				button {
					background-color: $dark-gray;
				}
			}
		}
	}

	&.product-carousel {

		.slick-dots {
			display: flex;
			justify-content: center;
			margin: 0 auto;
		}
	}

	

	&.card-carousel {
		.slick-dots {
			margin-left: 0;
		}
		
		.slick-list {
			
			padding: 0 20px 0 0;
			
			@include media-breakpoint-up(md) {
				padding: 0 40px 0 0;
			}
			
			@include media-breakpoint-up(lg) {
				padding: 0 80px 0 0;
			}
			
		}
	}
	
	.slick-prev {
		display: none;
		left: 0;
		position: absolute;
		top: 49%;
		z-index: 20;
	}
	
	.slick-next {
		display: none;
		position: absolute;
		right: 0;
		top: 49%;
		z-index: 20;
	}
	
	.slick-arrow {
		
		&.slick-prev {
			display: block;
			left: 0;
			position: absolute;
			top: 49%;
			z-index: 20;
			
			&.slick-hidden {
				display: none;
			}
		}
		
		&.slick-next {
			display: block;
			position: absolute;
			right: 0;
			top: 49%;
			z-index: 20;
			
			&.slick-hidden {
				display: none;
			}
		}
	}
	

	.slick-prev,
	.slick-next {
		.bat-icon {
			color: $dark-blue;
		}
	}
}
