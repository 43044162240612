.information-container{
	max-width: 980px;
	margin-bottom: 30px;
	margin-top: 2px solid $concrete;
	
	h5{
		margin-bottom: 17px;
	}
	
	.title-manageable-slot{
		margin-top:40px;
		margin-bottom: 38px;
		@include media-breakpoint-down(lg) {
			text-align: center;
			margin-bottom: 0;
		}
	}
	.info-delivery{
		font-size: 14px;
		line-height: 20px;
		max-width: 150px;
	}
	.column-info{
		max-width: 220px;
		@include media-breakpoint-down(lg) {
			max-width: 260px;
			margin-top:40px;
			padding-left: 40px;
		}
	}
	.visa{
		margin-top:10px ;
		width: 30px;
		height: 20px;
		margin-right: 5px;
	}
	.title{
		font-size: 14px;
		line-height: 20px;
	}
	.column-information{
		@include media-breakpoint-down(lg) {
			flex-direction: column;
		}
	}
}