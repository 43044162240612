.bat-hyperrange-hero {
    justify-content: space-around;
    display: flex;
    gap: 150px;
    background: black url(/content/dam/glo-it/images/hero/background-dark.svg) no-repeat left;
    padding-top: 20px;
    padding-inline: 20px;
    background-position-y: 80%;
    @include media-breakpoint-down(md) {
        gap: 40px;
    }

    .zoom-animation {
        transition: transform 2s;
        &:hover {
            transform: scale(1.1);
        }
    }

    .content {
        font-size: 55px;
        line-height: 55px;
        font-weight: 500;
        color: $white;
        padding-top: 110px;

        .logo{
            margin-bottom: 20px;
        }


        div {
            margin-bottom: 50px;

            span{
                font-size: 0.5em;
                vertical-align: super;
            }
        }
    }

    .mobile-only {
        display: none;
    }
}

@include media-breakpoint-down(md) {
    .bat-hyperrange-hero {
        flex-direction: column;
        align-items: center;

        img {
            order: 1;
            max-width: 264px;
            position: relative;
            top: -30px;
        }

        .content {
            order: 0;
            font-size: 28px;
            line-height: 28px;
            font-weight: 400;
            text-align: center;
            padding-top: 0;

            .logo{
                margin-top: 30px;
                margin-bottom: -5px;
            }

            div {
                margin-bottom: 13px;
            }
        }

        .button-dark {
            display: none;
            order: 3;
            position: relative;
            top: -190px;
            height: 40px;
            width: 172px;
            padding: 0;
          

        }
        .mobile-only {
            display: flex;
        }
    }
}