.orderhistory-content{
	
	.first-container{
		border: 1px dashed #6B6B6B;
		padding: 20px;
		max-width: 980px;
		.manageable-slot-container{
			.title-manageable-slot{
				margin-bottom: 10px;
			}
			.paragraph{
				margin-top: 10px;
			}
		}
		@include media-breakpoint-down(lg) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	
}

.section-page{
	.title-manageable-slot{
		@include media-breakpoint-down(lg) {
			text-align: center;
		}
	}
	
}