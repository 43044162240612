.bat-sectionengraving-gloit{
    padding-left: 10px;
    padding-right: 10px;

    .front-section,
    .back-section,
    .summary-section{
        &.hidden{
            display: none;
        }
        .cta-container{
            margin-top: 30px;
        }
    }

    .front-section{
        .customization-selection{
            display: contents
        }

        .input-form{
            margin-bottom: 20px
        }

        .front-title,
        .front-text {
            margin-left: 5px;
            margin-right: 5px;
        }

        .customization-selection {
            .uk-slider-items {
                margin-left: 5px;
                margin-right: 5px;
                li {
                    height: 180px;
                }
            }
        }
    }

    .cta-nav{
        padding-top: 20px;
        padding-bottom: 10px;

        .back-cta,
        .back-cta-back,
        .back-cta-summary{
           @include font-rem(14px, 28px);
           letter-spacing: 1px;
           font-weight: 700;
           font-family: $primary-font;
        }

        .cancel-engraving{
            font: inherit;
            @include font-rem(14px, 20px);
            text-decoration: underline;
        }
    }

    h3{
        @include font-rem(24px, 28px);
        font-family: $primary-font;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 10px;

        @include media-breakpoint-down(sm){
            @include font-rem(18px, 24px);
        }
    }

    p{
        @include font-rem(16px, 24px);
        font-family: $primary-font;
        font-weight: 300;

        @include media-breakpoint-down(sm){
            @include font-rem(14px, 20px);
        }
    }

    .customization-selection{
        margin-top: 20px;

        .collection-logo{
            background-color: #E5E5E5;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.selected{
                filter: invert(100%);
            }

            .image-container {
                max-width: 33%;
                max-height: 80%;
                display: flex;
                align-items: center;
                picture, img {
                    height: 100%;
                    object-fit: contain;
                }
            }

            .customization-text {
                font-weight: 600;
            }
        }

        .text-logo{
            background-color: #E5E5E5;
            margin-left: 5px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.selected{
                filter: invert(100%);
            }

            .image-container {
                max-width: 66%;
                max-height: 80%;
                display: flex;
                align-items: center;
            }

            .customization-text {
                font-weight: 600;
                padding-top: 1%;
            }
        }

        .collection-engraving{
            padding: 0px 40px;
            background-color: $light-gray;
            margin-right: 5px;
            cursor: pointer; 
            
            @include media-breakpoint-up(xl){
                width: 100%;
                margin-bottom: 5px;
                picture,img {
                    max-height: 180px;
                    object-fit: contain;
                }
            }

            @include media-breakpoint-down(lg) {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content:center;
                padding: 0;
                picture,img {
                    height: 100%;
                    width: unset;
                }
            }

            &.selected{
                filter: invert(100%);
            }
        }

        .text-engraving{
            margin-right: 5px;
            margin-bottom: 5px;
            background: #efefef;
            text-align: center;
            flex-direction: column;
            margin-bottom: 5px;
            color: #2a2a2a;
            padding: 0;
            cursor: pointer;

            &.selected{
                filter: invert(100%);
            }

            .img-wrap {
                height:50px;
                margin-bottom: 10px;
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-top: 5px;
                .font-preview {
                    font-size: 45px;
                    font-weight: 600;
                    @include media-breakpoint-down(sm) {
                        font-size: 35px;
                    }
                }
            }

            .option-title {
                font-size: 12px;
                font-weight: 500;
                display: block;
                text-transform: uppercase;
                margin-bottom: 0;
                margin-top: 4px;
                letter-spacing: 1px;
            }
        }
    }

    .text-direction{
        font-family: $primary-font;
        @include font-rem(12px, 15px);
        text-transform: uppercase;
        font-weight: 700;
    }

    .input-direction-cta{
        margin-bottom: 20px;

        .vertical-cta,
        .horizontal-cta{
            font-family: $primary-font;
            @include font-rem(12px, 14px);
            font-weight: 700;
            padding: 13px 50px;
            background-color: $concrete;
            margin-right: 5px;
            color: #6B6B6B;

            @include media-breakpoint-down(sm){
                width: 100%;
            }

            &.selected{
                background-color: $black;
                color: $white;
            }
        }

        .vertical-cta-back,
        .horizontal-cta-back{
            font-family: $primary-font;
            @include font-rem(12px, 14px);
            font-weight: 700;
            padding: 13px 50px;
            background-color: $concrete;
            margin-right: 5px;
            color: #6B6B6B;

            @include media-breakpoint-down(sm){
                width: 100%;
            }

            &.selected{
                background-color: $black;
                color: $white;
            } 
        }
    }

    .input-form{
        .input-info{
            font-family: $primary-font;
            @include font-rem(12px, 17px);

            &.hidden{
                display: none;
            }
        }
    }

    .error-msg{
        font-family: $primary-font;
        @include font-rem(12px, 17px);
        color: $red;

        &.hidden{
            display: none
        }
    }


    .continueCta,
    .continueCta-back,
    .addToBasketCta{
        margin-top: 10px;
    }

    .addToBasketCta,
    .continueCta{
        width: 100%;
    }

    .nofront-engraving,
    .noback-engraving{
        display: block;
        margin-top: 20px;
        font-family: $primary-font;
        @include font-rem(16px, 34px);
        text-decoration: underline;
    }

    .summary{
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px 0px;
        border-top: 2px solid #DEDEDE;
        &:last-of-type{
            border-bottom: 2px solid #DEDEDE;
        }

        li{
            picture{
                width: 50%;
            }
        }

        &-image {
            overflow: hidden;
            border-radius: 50%;
            min-width: 60px;
            max-width: 60px;
            min-height: 60px;
            max-height: 60px;
            display: flex;
            background-color: #efefef;
            align-items: center;
            justify-content: center;
            picture {
                width: 70%;
            }
        }

        .summary-table{
            margin: 0px;
            margin-left: 20px;

            @include media-breakpoint-down(sm){
                margin-left: 2px;
            }

            .summary-table-heading{
                @include font-rem(16px, 28px);
                font-weight: 600;
                color: black;
                padding: 0px;
            }
            .summary-table-data{
                border: none;
                padding: 0px;
                width: 40%;

                &.data-cta{
                    max-width: fit-content;
                    margin: auto;
                    width: 100%;

                    .summary-button-edit{
                        font-family: $primary-font;
                        @include font-rem(12px, 15px);
                        font-weight: 600;
                        margin-right: 5px;

                        @include media-breakpoint-down(sm){
                            margin-right: 1px;
                        }
                    }

                    .summary-button-cancel{
                        &::before{
                            background-color: $concrete;
                            border-radius: 100%;

                            @include media-breakpoint-down(sm){
                                font-size: 20px;
                            }
                        }
                    }
                }

                .data{
                    display: block;
                    font-family: $primary-font;
                    @include font-rem(14px, 15px);
                    font-weight: 300;
                    padding-top: 5px;
                    padding-bottom: 5px;

                    @include media-breakpoint-down(sm){
                        @include font-rem(12px, 14px);
                    }
                }
            }
        }
    }

    .summary-title{
        font-family: $primary-font;
        @include font-rem(24px, 28px);
        font-weight: 600;
        margin-bottom: 10px;
    }

    .summary-warning{
        margin-top: 20px;
        @include font-rem(12px, 15px);
        font-weight: 300;
    }

    .cta-container{
        @include media-breakpoint-down(sm){
            max-width: fit-content;
            margin: auto;
        }
    }
}