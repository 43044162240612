bat-card-gloitdropzone {
    margin-top: 40px;
    background: #efefef;
    height: auto;
    min-height: 400px;
    padding: 13px;
    border-radius: 40px;
    width: 45vw;
    margin-left: auto;
    margin-right: auto;
    color: #2A2C2C;
    animation: fadeIn 1s ease-in-out;

    @media (max-width: 768px) {
        width: 90vw;

        .submit-message-success {
            margin: 20px 0px 0px 0px !important;
        }

        .submit-submessage-success {
            margin: 20px 0px 20px 0px !important;

        }

    }

    @media (min-width: 769px) and (max-width: 1200px) {
        width: 70vw;
    }

    form.dropzone {
        border-radius: 20px;
        border-style: dashed !important;
        margin: 20px;
        background: #efefef;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 400px;
        justify-content: center;

        &.completed {
            border-width: 0px;

            .dz-message {
                display: none;
            }

            .dz-preview-element {
                display: none;
            }

            .submit-section {
                .dz-submit-cta {
                    display: none;
                }

                .dz-submit-picture {
                    display: block;
                }

                .submit-message {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .dz-discover-cta {
                    display: block;
                }
            }
        }

        &.ready {
            background: rgba(47, 159, 85, 0.1);
            border: 2px solid rgba(47, 159, 85, 1);
        }

        &.uploading {
            border: 2px solid rgba(243, 101, 26, 1);
        }

        &.not-loyalty {
            .dz-message {

                display: flex;
                flex-direction: column;
                align-items: center;

                .dz-message-supported-files {
                    margin-top: 15px;
                    max-width: 60%;
                }

                .dz-message-cta {
                    margin-top: 30px;
                }
            }
        }

        .dz-message {

            .dz-message-picture {
                width: 107px;
                height: 107px;
            }

            .dz-message-title {
                font-size: 22px;
                font-weight: 700;
                margin-top: 20px;
                color: #2A2C2C;
            }

            .dz-message-supported-files {
                font-size: 14px;
                color: #2A2C2C;
            }

            .dz-message-or {
                font-size: 16px;
                margin: 14px 0;
                color: #2A2C2C;
            }

            .dz-message-cta {
                height: 40px;
            }

            .dz-message-max-size {
                font-size: 12px;
                color: #6B6B6B;
                margin-top: 14px;
            }

            .dz-message-accept-disclaimer {
                font-size: 12px;
                color: #6B6B6B;
                margin-top: 8px;
            }

        }

        .submit-section {
            display: flex;
            flex-direction: column;
            align-items: center;

            .dz-submit-cta {
                display: none;
            }

            .submit-message {

                display: none;

                .submit-message-success,
                .submit-submessage-success {
                    font-size: 22px;
                    margin: 20px 60px;
                    text-align: center;
                    display: inline-block !important;
                    color: #2A2C2C;
                }

                .submit-message-success {
                    font-weight: 700;
                }

                .submit-submessage-success {
                    line-height: 25px;
                }

            }

            .uploading-section {
                display: flex;
                flex-direction: column;
                align-items: center;

                .dz-spinner {
                    color: #F3651A;
                    margin-bottom: 30px;
                    width: 126px;
                    height: 126px;
                }
            }

            .dz-submit-picture {
                display: none;
                width: 62px;
                height: 62px;

                img {
                    width: 62px;
                }
            }

            .dz-discover-cta {
                display: none;
            }
        }



        &.dz-started {

            .submit-section {
                .dz-submit-cta {
                    display: block;
                }
            }
        }

        .dz-preview-element {
            .dz-image-preview {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                animation: fadeIn 0.3s;

                img {
                    width: 150px;
                    height: 150px
                }

                label {
                    font-size: 22px;
                    font-weight: 700;
                    color: #2a2c2c;
                    max-width: 56%;
                    line-height: 26px;
                    text-align: center;
                }
            }
        }

        &.dz-drag-hover {
            border-color: rgb(243, 101, 26);
            background: rgba(243, 101, 26, 0.1);

            .dz-message {
                opacity: 1 !important;

                .dz-message-or,
                .dz-message-cta,
                .dz-message-max-size {
                    display: none !important;
                }
            }
        }
    }

    .dz-agreement {

        background: #ffffff;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        margin: 20px;

        .dz-agreement-text {
            font-size: 12px;
            color: #6B6B6B;
            text-align: center;

            a {
                color: #1d1d1b;
            }
        }

        .dz-agreement-cta {
            height: 40px;
            margin-top: 20px;
        }
    }

    .submit-section-error {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 400px;

        .submit-message {

            display: flex;
            flex-direction: column;
            align-items: center;

            .submit-message-error,
            .submit-submessage-error {
                font-size: 22px;
                margin: 20px 60px;
                text-align: center;
                display: inline-block !important;
                color: #2A2C2C;
            }

            .submit-message-error {
                font-weight: 700;
            }

            .submit-submessage-error {
                line-height: 25px;
            }

        }

        .dz-submit-picture {
            width: 62px;
            height: 62px;

            img {
                width: 62px;
            }
        }

    }

    .error-generic {
        padding: 3px 5px;
        margin: 0px 20px;
        font-size: 14px;
        font-weight: 700;
        color: #2A2C2C;
        background-color: rgba(255, 229, 234, 1);
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: rgba(224, 8, 48, 1);
    }

    .submit-section-outside-form {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 10px;
        overflow: hidden;

        .submit-or-cancel {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .dz-cancel-cta {
                background: none;
                border-color: rgba(107, 107, 107, 1);
                color: rgba(107, 107, 107, 1);
            }
        }

        .file-section {

            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            .file-section-label {
                font-size: 18px;
                margin: 5px 0;
            }

            .file-section-row {

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .file-section-row-left {

                    display: flex;
                    flex-direction: row;

                    .file-section-picture {

                        width: 48px;
                        height: 48px;

                    }

                    .file-section-row-filename-size {

                        display: flex;
                        flex-direction: column;
                        margin-left: 20px;

                        .file-section-row-filename {
                            color: rgba(42, 44, 44, 1);
                            font-size: 16px;
                            font-weight: 700;
                        }

                    }
                }


            }

        }
    }

    .hidden-mobile {
        @media (max-width: 768px){
            display: none !important;
        }
    }

    .hidden-desktop {
        @media (min-width: 769px){
            display: none !important;
        }
    }
}