.glo-it-acquistahyper{
	padding-top: 40px;
	.img-info{
		max-width:$site-max-width;
		
		.full-width & {
			max-width: unset;
		}

		@include media-breakpoint-up(lg){
			position: relative;
			&-button{
				position: absolute;
				top: 10px;
				right:10px;
			}
		}
		&-button{
			@include media-breakpoint-down(lg){
				margin-top: 10px;
			}
		}
	}

	.button-below & {
		padding-top: 0;
		.img-info{
	
			@include media-breakpoint-up(lg){
				position: relative;
				&-button{
					position: absolute;
					bottom: -22px;
					top: unset;
					right: 50%;
					left: 50%;

					.bat-cta-style {
						min-width: max-content;
					}
				}
			}
			&-button{
				@include media-breakpoint-down(lg){
					margin-top: 10px;
				}
			}
		}
	}
}