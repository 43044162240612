// File:          _sortfilter.scss
// Description:   Sort Filter

bat-sortfilter-glo {
	&.bat {
		display: block;
		// sass-lint:disable no-important
		margin-left: -3.5px !important;
		margin-right: -3.5px !important;
		max-width: unset !important;
	}
	.bat-plp-filter-container {
		margin: 0 auto;
	}

	.bat-filter {
		-moz-box-shadow: 7px 6px 11px -1px $dark-30;
		-webkit-box-shadow: 7px 6px 11px -1px $dark-30;
		background-color: $white;
		box-shadow: 7px 6px 11px -1px $dark-30;

		z-index: $modal-zindex;

		&-overlay {
			background: $charcoal;
			bottom: 0;
			left: 0;
			opacity: 0;
			pointer-events: none;
			position: fixed;
			right: 0;
			top: 0;
			transition: 0.5s;
			z-index: $modal-zindex;
			&--open {
				opacity: 0.33;
				pointer-events: all;
			}
		}

		&-wrapper {
			height: 100%;
			overflow-y: auto;
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		&-closebutton {
			right: 1rem;
		}

		&-applybutton {
			padding: 20px 0 20px 20px;
			border-top: 1px solid $gray;
			background-color: $white;
			margin-top: auto;
			flex-shrink: 0;

			@include media-breakpoint-down(md) {
				padding: 10px;
			}

			button {
				&[data-parameters="applyButton"] {
					background-color: $purple;

					&:hover {
						background-color: $purple-hover;
					}
				}

				@include media-breakpoint-down(md) {
					padding: 10px;
					width: 90%;
					margin: 0 auto;
				}
			}
		}

		&-collapse {
			border-top: 1px solid $dark-gray;
			padding: 1rem 0;

			&:hover {
				cursor: pointer;
			}

			i {
				height: 24px;
				width: 24px;

				&:before {
					font-size: 24px;
				}
			}

			.title {
				@include font-rem(18px, 18px);
				text-transform: uppercase;
				font-weight: 700;
			}

			&-container {
				flex-grow: 1;
				overflow: auto;
				padding: 20px;
				margin-top: 0;

				@include media-breakpoint-up(lg) {
					padding: 20px 40px;
				}

				.group-title {
					@include font-rem(24px, 24px);
					border-bottom: 0;
					font-weight: 700;
					padding: 1rem 0;
				}
			}

			&-items {
				border: 0;
				padding: 0 0 2rem;

				ul {
					li {
						label {
							@include font-rem(16px, 16px);
							align-content: center;
							align-items: center;
							display: flex;
							margin-bottom: 15px;

							.count {
								@include font-rem(14px, 14px);
								color: $dark-gray-50;
								margin-left: 5px;
							}

							input {
								display: inline;
								margin-right: 12px;
								position: relative;
								width: auto;

								&:before {
									background-color: $white;
									color: $black;
									content: "\e977";
									display: inline-block;
									font-family: "icomoon";
									font-size: 18px;
									height: 18px;
									margin: -4px 0 0 -4px;
									position: relative;
									width: 18px;
								}

								&:checked {
									&:before {
										color: $black;
										content: "\e979";
										display: inline-block;
									}

									+ span {
										@include font-rem(14px, 14px);
										color: $charcoal;
									}
								}

								+ span {
									@include font-rem(14px, 14px);
								}
							}
						}
					}
				}
			}
		}
	}

	.reset-button,
	.clear-button {
		align-items: center;
		color: $gray-dark;
		display: flex;
		font-weight: 700;
		text-transform: uppercase;
	}

	.reset-button {
		i {
			&:before {
				font-size: 24px;
			}
		}
	}

	.bat-plp-button-wrapper {
		align-items: center;
		display: flex;
		justify-content: center;
		margin: 0 auto -10px;
		max-width: $site-max-width;
		padding: 0 10px;
		position: relative;
	}

	.bat-filter-button {
		display: flex;
		margin: 16px;

		[data-parameters="filterButton"] {
			@include font-rem(15px, 15px);
			align-items: center;
			color: $charcoal;
			display: flex;
			font-family: $primary-font;
			font-weight: 700;

			&:hover {
				color: $orange;

				i {
					&:before {
						color: $orange;
					}
				}
			}

			i {
				margin-right: 5px;

				&:before {
					color: $charcoal;
					font-size: 24px;
				}
			}
		}
	}

	.bat-filter-count {
		display: none;
		position: absolute;
		right: 10px;
	}

	.bat-filter-closebutton {
		button {
			i {
				&:before {
					color: $charcoal;
					font-size: 30px;
				}
			}
		}
	}

	.bat-sort-button {
		display: flex;
		margin: 16px;

		[data-parameters="sortButton"] {
			@include font-rem(15px, 15px);
			align-items: center;
			color: $charcoal;
			display: flex;
			font-family: $primary-font;
			font-weight: 700;

			&:hover {
				color: $orange;

				i {
					&:before {
						color: $orange;
					}
				}
			}

			i {
				&:before {
					color: $charcoal;
				}
			}
		}

		i {
			&:before {
				color: $charcoal;
				font-size: 24px;
			}

			&[id="sortButtonIconOpen"] {
				display: none;
			}

			&[id="sortButtonIconClosed"] {
				display: flex;
			}
		}

		&.open {
			i {
				&[id="sortButtonIconOpen"] {
					display: flex;
				}

				&[id="sortButtonIconClosed"] {
					display: none;
				}
			}
		}

		#sortButtonIcon {
			// sass-lint:disable-line no-ids, id-name-format
			margin-left: 10px;
		}
	}

	.bat-sort-select {
		border: 2px solid $charcoal;
		min-width: 170px;
		z-index: 10;

		button {
			color: $charcoal;
			transition: color 0.1s ease-in;

			&:hover {
				color: $orange;
			}
		}
	}

	.bat-price-slider {
		&-output {
			label {

				input {
					@include font-rem(14px, 14px);
					background-color: $concrete;
					border: 0;
					color: $charcoal;
					padding: 10px;
				}
			}
		}

		&-range {
			input {
				&[type="range"] {
					&:focus {
						&::-webkit-slider-runnable-track {
							background: $purple-gradient;
						}
					}

					&::-webkit-slider-runnable-track {
						background: $purple-gradient;
					}

					&::-webkit-slider-thumb {
						background: $purple-gradient;
					}

					&::-moz-range-track {
						background: $purple-gradient;
					}

					&::-moz-range-thumb {
						background-color: $purple;
					}

					&::-ms-track {
						background-color: $purple;
					}

					&::-ms-thumb {
						background-color: $purple;
					}
				}
			}
		}
	}

	.sortfilter {
		padding-left: 0;
		padding-right: 0;
	}
}

@include media-breakpoint-up(sm) {
	bat-sortfilter-glo {
		.bat-filter-button {
			justify-content: flex-end;
			margin: 20px 20px 20px 0;
		}
		.bat-filter-count {
			display: block;
		}
		.bat-plp-button-wrapper {
			justify-content: flex-start;
		}
		.bat-sort-button {
			justify-content: flex-start;
			margin: 20px 20px 20px 0;
		}
	}
}

@include media-breakpoint-up(lg) {
	bat-sortfilter-glo {
		.bat-filter {
			&-wrapper {
				padding: 0;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	bat-sortfilter-glo {
		.bat-filter {
			&-wrapper {
				padding: 0;
			}
		}
	}
}

.sortfilter-category-error {
	@include font-rem(16px, 28px);
	margin-bottom: 20px;
}
