#gloit-slot-available{
	background-color: #FFF2EB;
	margin: 5%;
	padding: 3%;
}

.gloit-slot-available-image{
	float: left;
	margin-right: 3%;
	width: 10%;
}

.gloit-slot-available-text{
	font-size: 18px;
	text-align: left;
	margin-right: 8%;
	line-height: 28px;
	padding-left: 14%;
}

#gloit-slot-available-close-button{
	top: 120px;
	right: 6%;
	height: 20px;
	width: 40px;
	position: absolute;
}