bat-productlisting-globrochureware {
	.bat-productlistings {
		max-width: unset;

		&--glo-brochureware {
			background-color: #e5e5e5;
			display: flex;
			flex-direction: row;
			margin: 0 auto;
			padding: 0 0 20px;
			position: relative;
			width: 100%;

			.bat-productlistings-card {
				background-color: $white;
				display: flex;
				flex-direction: column;
				height: 100%;
				margin: 10px 0;
				max-width: 100%;
				min-width: 100vw;
				min-height: unset;
				padding: 20px;
				padding-bottom: 35px;
				position: relative;
				width: 100%;
				overflow: visible;

				&-buttons {
					margin: auto 0 0;
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					button, a {
						display: none;
						margin-bottom: 0;
						max-width: unset;
						text-transform: uppercase;
						&:not(.arrow-link-dark) {
							width: 100%;
						}
						&.active {
							display: inline-block;
						}
					}
				}

				&-description {
					margin-bottom: 12px;
					p {
						@include font-rem(13px, 125%);
						display: block;
						font-style: normal;
					}
				}

				&-details {
					align-items: stretch;
					display: flex;
					flex-direction: row;
					height: auto;
					margin: 0;
					max-height: unset;
					padding: 0;
					position: static;
					width: 100%;
					z-index: initial;

					& > div {
						display: flex;
						flex-direction: column;
						padding-top: 15px;
					}

					&-image {
						a {
							display: block;
							flex: 0 0 105px;
							picture {
								display: flex;

								> img {
									width: 100%;
									height: 100%;
									object-fit: contain;
								}
							}
							> img, > picture {
								height: auto;
								padding-right: 0;
								width: 120px;
								height: 120px;
								object-fit: contain;
							}
						}
					}

					&-text {
						padding-left: 10px;
						flex-grow: 1;
					}
				}

				&-message {
					background-color: transparent;
					color: $purple;
					display: none;
					justify-content: center;
					padding: 5px;

					button {
						background-color: transparent;
						color: $error;
					}

					.message {
						padding: 0 1rem;
					}
				}

				&-name {
					@include font-rem(16px, 22px);
					color: $brand-black;
					border-bottom: 0;
					font-weight: 400;
					margin: 0 0 8px;
					padding: 0;
					text-align: left;
					a {
						text-decoration: none;
						color: inherit;
					}
				}

				&-options {
					display: flex;
					flex: 1 1 auto;
					flex-direction: column;
					text-align: left;
					width: auto;

					&-title {
						@include font-rem(12px, 16px);
						font-weight: 700;
						letter-spacing: 0;
						margin-bottom: 5px;
					}
					
					&-variants-button {
						$button-size: 34px;
						height: $button-size;
						width: $button-size;
						border-radius: 99999999px;
						padding: 4px;
						&::after {
							content: "";
							background-color: var(--button-variant-color);
							display: block;
							width: 100%;
							height: 100%;
							border-radius: 99999999px;
						}
						&.active {
							border: 1px solid $brand-black;
						}
					}

					p {
						display: none;
					}
				}

				&-strengths {
					&-oneline-container {
						display: flex;
						flex-direction: row;
						align-items: center;
						flex-wrap: wrap;
						margin-bottom: 25px;
					}

					&-container {
						display: flex;
						flex-wrap: wrap;
	
						.bat-indicator {
							border: none;
							align-items: baseline;
							flex-direction: row;
							font-family: $primary-font;
							justify-content: center;
							margin: 0;
							padding: 0;
							width: auto;
							$size: 13px;
							ul {
								li {
									width: $size;
									height: $size;
									line-height: $size;
									font-size: $size;
									border-color: $brand-black;
								}
							}

							&.strength-4mg,
							&.strength-mild,
							&.strength-1 {
								li {
									&:nth-child(1) {
										background-color: $brand-black;
									}
								}

								&.active {
									li {
										&:nth-child(1) {
											background-color: $white;
										}
									}
								}
							}

							&.strength-6mg,
							&.strength-medium,
							&.strength-2 {
								li {
									&:nth-child(-n + 2) {
										background-color: $brand-black;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 2) {
											background-color: $white;
										}
									}
								}
							}

							&.strength-10mg,
							&.strength-strong,
							&.strength-3 {
								li {
									&:nth-child(-n + 3) {
										background-color: $brand-black;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 3) {
											background-color: $white;
										}
									}
								}
							}

							&.strength-16mg,
							&.strength-x-strong,
							&.strength-4 {
								li {
									&:nth-child(-n + 4) {
										background-color: $brand-black;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 4) {
											background-color: $white;
										}
									}
								}
							}

							&.strength-18mg,
							&.strength-ultra,
							&.strength-5 {
								li {
									&:nth-child(-n + 5) {
										background-color: $brand-black;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 5) {
											background-color: $white;
										}
									}
								}
							}

							&.strength-20mg,
							&.strength-max,
							&.strength-6 {
								li {
									&:nth-child(-n + 6) {
										background-color: $brand-black;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 6) {
											background-color: $white;
										}
									}
								}
							}
							span {
								@include font-rem(12px, 12px);
								color: $brand-black;
								font-weight: 700;
								margin-left: 10px;
								margin-bottom: 0;
							}
						}
					}
					&-label {
						display: inline-flex;
						flex-direction: row;
						align-items: center;
						margin: 0;
						margin-inline-end: 10px;
						font-weight: normal;
						@include font-rem(13px, 185%);
						width: fit-content;
						&-popover-button {
							.bat-icon:before {
								@include font-rem(19px, 100%);
							} 
						}
						&-popover-content {
							@include font-rem(10px, 150%);
							position: absolute;
							background-color: $charcoal;
							color: $white;
							padding: 15px;
							transform: translateX(50%);
							width: 200px;
							$triangle-size: 10px;
							display: none;
							margin-top: $triangle-size;
							z-index: 999;

							&[data-show] {
								display: block;
							}

							&-arrow {
								visibility: hidden;

								&, &::before {
									position: absolute;
									width: $triangle-size;
									height: $triangle-size;
									background: inherit;
								}

								&::before {
									visibility: visible;
									content: '';
									transform: rotate(45deg);
								}
							}

							&[data-popper-placement^='top'] > .bat-productlistings-card-strengths-label-popover-content-arrow {
								bottom: #{-($triangle-size / 2)};
							}
						
							&[data-popper-placement^='bottom'] > .bat-productlistings-card-strengths-label-popover-content-arrow {
								top: #{-($triangle-size / 2)};
							}
					
							&[data-popper-placement^='left'] > .bat-productlistings-card-strengths-label-popover-content-arrow {
								right: #{-($triangle-size / 2)};
							}
					
							&[data-popper-placement^='right'] > .bat-productlistings-card-strengths-label-popover-content-arrow {
								left: #{-($triangle-size / 2)};
							}


							p {
								display: block;
								font-size: inherit;
								line-height: inherit;
							}
						}
					}
				}


				&-prices {
					@include font-rem(18px, 18px);
					color: $brand-black;
					font-weight: 700;
					margin-bottom: 10px;
					text-align: left;
					vertical-align: middle;

					.base-price {
						margin-right: 0.5rem;

						&.has-sale-price {
							@include font-rem(18px, 18px);
							color: $gray;
							position: relative;
							text-decoration: none;

							&:before {
								-moz-transform: rotate(-5deg);
								-ms-transform: rotate(-5deg);
								-o-transform: rotate(-5deg);
								-webkit-transform: rotate(-5deg);
								border-color: $gray;
								border-top: 2px solid;
								content: "";
								left: 0;
								position: absolute;
								right: 0;
								top: 50%;
								transform: rotate(-5deg);
							}
						}
					}
				}

				&-quantity {
					margin: 0 0 0 auto;
					width: 90px;
				}

				&-stock {
					@include font-rem(20px, 20px);
					color: $purple;
					display: none;
					font-style: normal;
					padding: 0;
				}

				&-strengths {
					display: flex;
					flex-wrap: wrap;
					padding-bottom: 5px;
					button {
						&.bat-indicator {
							align-items: center;
							border-top-width: 0;
							flex-direction: row;
							font-family: $primary-font;
							justify-content: center;
							margin: 0;
							min-width: 95px;
							padding: 15px;
							width: 50%;
							&.full-width {
								width: 100%;
							}
							&:nth-child(even) {
								border-left: 0;
							}
							&:nth-child(1),
							&:nth-child(2) {
								border-top-width: 1px;
							}
							span {
								@include font-rem(10px, 10px);
								font-weight: 700;
								margin-bottom: 3px;
							}
						}
					}
				}

				&-variants {
					margin: 10px 0 20px auto;
					max-width: 45%;

					form {
						.select-wrapper {
							select {
								padding: 11px 30px 13px 13px;
								text-overflow: ellipsis;
							}
						}
					}
				}
			}

			.bat-productlistings-cards {
				max-width: $site-max-width;
			}

			.loading-wrapper {
				align-items: center;
				display: flex;
				height: 110px;
				position: relative;
				width: 100px;

				p {
					text-align: center;
					width: 100%;
				}

				&:after {
					-webkit-animation: spin 2s linear infinite; /* Safari */
					animation: spin 2s linear infinite;
					border: 10px solid $light-gray;
					border-radius: 50%;
					border-top: 10px solid $purple;
					bottom: 0;
					content: "";
					height: 100px;
					left: 0;
					margin: auto;
					position: absolute;
					right: 0;
					top: 0;
					width: 100px;
					z-index: 1;
				}
			}

			.errors-wrapper {
				ul {
					display: none;
				}
			}
		}
	}
}

@include media-breakpoint-up(sm) {
	bat-productlisting-globrochureware {
		.bat-productlistings {
			&--glo-brochureware {
				.bat-productlistings-card {
					margin: 10px;
					min-width: 268px;
					min-height: 490px;
					width: calc(50% - 20px);
					padding-bottom: 20px;
					&:hover {
						.bat-productlistings-card-buttons {
							a {
								display: none;
							}
							button, a {
								&.active {
									display: inline-block;
								}
							}
						}
					}
				}

				.bat-productlistings-filter-count {
					display: block;
				}

				.bat-productlistings-card-details {
					border: 0;
					flex-direction: column;
					align-items: center;
					margin-bottom: 14px;
					padding: 0;
					& > div {
						padding: 0;
						width: 100%;
						justify-content: space-between;
					}
					&-image {
						a {
							flex: 1 0 100%;
							text-align: center;

							> img, > picture {
								max-width: 75%;
								height: unset;
								width: unset;
							}

							> picture {
								margin: 0 auto;
							}
						}
					}
					.bat-productlistings-card {
						&-name {
							margin-top: 28px;
							margin-bottom: 12px;
							order: 1;
						}

						&-description {
							order: 2;
						}
		
						&-strengths {
							padding-bottom: 15px;
						}

						&-options {
							order: 3;
						}

						&-prices {
							margin-bottom: 0;
							order: 4;
						}
						&-buttons {
							padding-top: 15px;
							border-top: 2px solid $light-gray;
							margin-top: 15px;
							button, a {
								&.active {
									display: inline-flex;
								}
							}
							order: 5;
						}
					}
				}

			}
		}
	}
}

@include media-breakpoint-up(lg) {
	bat-productlisting-globrochureware {
		&.bat {
			// sass-lint:disable no-important
			max-width: unset !important;
		}
		.bat-productlistings {
			&--glo-brochureware {
				.bat-productlistings-card {
					min-width: 310px;
					width: calc(33.3% - 20px);
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	bat-productlisting-globrochureware {
		.bat-productlistings {
			&--glo-brochureware {
				.bat-productlistings-card {
					min-width: 280px;
					width: calc(25% - 20px);
					min-height: 490px;
				}
			}
		}
	}
}
/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
