.bat-quick-login-gloit{
    margin-inline: auto;
    margin-top: 30px;
    width: 500px;


    @include media-breakpoint-down(md) {
        margin-inline: 20px;
        width: auto;
    }

    &-form {

        &-loading-circle{
            &.active {
                &:after {
                    -webkit-animation: spin 2s linear infinite; /* Safari */
                    animation: spin 2s linear infinite;
                    border: 10px solid $light-gray;
                    border-radius: 50%;
                    border-top: 10px solid $purple;
                    bottom: 0;
                    content: "";
                    height: 60px;
                    left: 0;
                    margin: auto;
                    position: absolute;
                    right: 0;
                    top: -150px;
                    width: 60px;
                    z-index: 2;
                }
            }
        }

        .separatore-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom:25px;
            
            .line{
                border-top: 2px solid #dedede;
                width: 40%;
            }
            .center-text{
                width: 20%;
                text-align: center;
                text-transform: lowercase;
                font-size: 13px;
            }
        }

        .forgotpwd-container{
            margin-inline: auto;
            margin-top: 60px;
            width: 500px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 20px;

            a{
                color: rgba(42, 44, 44, 0.60);
                font-size: 14px;
                font-family: Mont;
                font-style: normal;
                font-weight: 550;
                line-height: normal;
                text-decoration-line: underline;
            }
        }

        .bat-form-step {

            &.opacity {
                opacity: 0.4;
                pointer-events: none;
            }

            .bat-form-heading {
                text-align: center;

                .bat-headline-style {
                    margin-bottom: 10px;
                    @include font-rem(30px, 34px);

                    @include media-breakpoint-down(md) {
                        @include font-rem(24px, 28px);
                    }
                }
            }
        }

        .bat-form-block {
            margin-bottom: 0px;
        }

        .bat-form--signin-email {
            input {
                border-radius: 0px;
            }
        }

        .bat-form--signin-password {
            margin-bottom: 3px;

            input {
                border-radius: 0px;
            }

            .error-msg {
                display: none;
            }

            .bat-icon {
                margin-right: 10px;
                margin-left: auto;
                margin-top: -34px;
                cursor: pointer;
            }
        }

        .bat-form--signin-submit {
            text-align: left;
            margin-top: 20px;

            .error-msg {
                display: none;
            }

            .bat-message {
                color: red;
                margin-bottom: 10px;
            }

            button {
                width: 300px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        .forgotPdw {
            text-transform: uppercase;
            margin-bottom: 30px;
            margin-top: 10px;
        }

        .uk-flex {
            flex-direction: column;
            align-items: center;

            &.social-buttons {

                &.opacity  {
                    opacity: 0.4 !important;
                    pointer-events: none;
                }
                
                &.opacity-zero {
                    opacity: 0;
                }

                &.opacity-zero.transition {
                    opacity: 1;
                    transition: all 1s;
                    -webkit-transition: all 1s;
                }

                @include media-breakpoint-down(md) {
                    max-width: 310px;
                    margin: 0 auto;
                }
            }

            .g_id_signin {
                margin-bottom: 20px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }

            .bat-button--facebook {
                width: 300px;
                margin-bottom: 20px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }

                .fa-brands {
                    position: absolute;
                    left: 15px;
                    height: 18px;
                    width: 18px;
                }
            }
           
            .bat-button--google {
                width: 300px;
                margin-bottom: 20px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }

                .fa-brands {
                    position: absolute;
                    left: 15px;
                    height: 18px;
                    width: 18px;
                }
            }

            .google-button {
                padding: 0;
                margin-bottom: 20px;
                overflow: visible;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }

            .fb-login-button {
                padding: 0;
                margin-bottom: 20px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    &-signupCta {
        text-align: center;
        border-top: 2px solid #DEDEDE;
        margin-bottom: 50px;

        .title {
            text-transform: uppercase;
            @include font-rem(22px, 28px);
            margin-bottom: 10px;
            margin-top: 40px;

            @include media-breakpoint-down(md) {
                @include font-rem(20px, 24px);
            }
        }

        .subtitle {
            @include font-rem(18px, 28px);
            margin-bottom: 15px;
            margin-top: 10px;

            @include media-breakpoint-down(md) {
                @include font-rem(16px, 24px);
            }
        }

        .info {
            >ul {
                @include font-rem(14px, 20px);
            }

            width: 300px;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: 100%;
            }

            .additional-info {
                margin-top: 10px;
                @include font-rem(11px, 28px);
            }
        }

        .cta {
            width: 300px;
            font-size: 13px;

            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: 100%;
            }

            &:hover {
                color: $brand-black;
            }
        }
    }

    &.register-device {
        margin-top: 0;

        @include media-breakpoint-down(md) {
            background: $white;
            margin: 0;
            padding: 20px;
        }
        
        .bat-form-step {
            .bat-form-heading {
                text-align: left;
            }

            .bat-form-field {
                input {
                    background-color: $white !important;
                    box-shadow: 0 0 0 30px $white inset !important;
                    -webkit-box-shadow: 0 0 0 30px $white inset !important;

                    @include media-breakpoint-down(md) {
                        background-color: #efefef !important;
                        box-shadow: 0 0 0 30px #efefef inset !important;
                        -webkit-box-shadow: 0 0 0 30px #efefef inset !important;
                    }
                }
            }
        }

        .cta-flex {
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;

            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
                justify-content: flex-end;
            }

            .bat-form--signin-submit {
                width: auto;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            .forgotPdw {
                text-transform: none;
                text-decoration: underline;
                font-size: 10px;
                line-height: 14px;
            }
        }

        .bat-signin-gloit-signupDevice {
            font-size: 14px;
            line-height: 20px;

            .orangeLink {
                color: #E35205;
                font-weight: 700;
                text-decoration: underline;
            }
        }
    }
}

.bat-quick-login-hyperpro-gloit{
    margin-inline: auto;
    margin-top: 30px;
    width: 500px;


    @include media-breakpoint-down(md) {
        margin-inline: 20px;
        width: auto;
    }

    &-form {

        &-loading-circle{
            &.active {
                &:after {
                    -webkit-animation: spin 2s linear infinite; /* Safari */
                    animation: spin 2s linear infinite;
                    border: 10px solid $light-gray;
                    border-radius: 50%;
                    border-top: 10px solid $purple;
                    bottom: 0;
                    content: "";
                    height: 60px;
                    left: 0;
                    margin: auto;
                    position: absolute;
                    right: 0;
                    top: -150px;
                    width: 60px;
                    z-index: 2;
                }
            }
        }

        .separatore-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom:25px;
            
            .line{
                border-top: 2px solid #dedede;
                width: 40%;
            }
            .center-text{
                width: 20%;
                text-align: center;
                text-transform: lowercase;
                font-size: 13px;
            }
        }

        .forgotpwd-container{
            margin-inline: auto;
            margin-top: 60px;
            width: 500px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 20px;

            a{
                color: rgba(42, 44, 44, 0.60);
                font-size: 14px;
                font-family: Mont;
                font-style: normal;
                font-weight: 550;
                line-height: normal;
                text-decoration-line: underline;
            }
        }

        .bat-form-step {

            &.opacity {
                opacity: 0.4;
                pointer-events: none;
            }

            .bat-form-heading {
                text-align: left;

                

                .bat-headline-style {
                    font-family: Mont;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    @include media-breakpoint-down(md) {
                        font-size: 22px;
                    }
                }

            }

            .bat-form-text{
                margin-bottom: 5%;
            }
        }

        .bat-form-block {
            margin-bottom: 0px;
        }

        .bat-form--signin-email {
            input {
                border-radius: 0px;
            }
        }

        .bat-form--signin-password {
            margin-bottom: 3px;

            input {
                border-radius: 0px;
            }

            .error-msg {
                display: none;
            }

            .bat-icon {
                margin-right: 10px;
                margin-left: auto;
                margin-top: -34px;
                cursor: pointer;
            }
        }

        .bat-form--signin-submit {
            text-align: left;
            margin-top: 20px;

            .error-msg {
                display: none;
            }

            .bat-message {
                color: red;
                margin-bottom: 10px;
            }

            button {
                width: 300px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        .forgotPdw {
            text-transform: uppercase;
            margin-bottom: 30px;
            margin-top: 10px;
        }

        .uk-flex {
            flex-direction: column;
            align-items: center;

            &.social-buttons {

                &.opacity  {
                    opacity: 0.4 !important;
                    pointer-events: none;
                }
                
                &.opacity-zero {
                    opacity: 0;
                }

                &.opacity-zero.transition {
                    opacity: 1;
                    transition: all 1s;
                    -webkit-transition: all 1s;
                }

                @include media-breakpoint-down(md) {
                    max-width: 310px;
                    margin: 0 auto;
                }
            }

            .g_id_signin {
                margin-bottom: 20px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }

            .bat-button--facebook {
                width: 300px;
                margin-bottom: 20px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }

                .fa-brands {
                    position: absolute;
                    left: 15px;
                    height: 18px;
                    width: 18px;
                }
            }
           
            .bat-button--google {
                width: 300px;
                margin-bottom: 20px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }

                .fa-brands {
                    position: absolute;
                    left: 15px;
                    height: 18px;
                    width: 18px;
                }
            }

            .google-button {
                padding: 0;
                margin-bottom: 20px;
                overflow: visible;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }

            .fb-login-button {
                padding: 0;
                margin-bottom: 20px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    &-signupCta {
        text-align: center;
        border-top: 2px solid #DEDEDE;
        margin-bottom: 50px;

        .title {
            text-transform: uppercase;
            @include font-rem(22px, 28px);
            margin-bottom: 10px;
            margin-top: 40px;

            @include media-breakpoint-down(md) {
                @include font-rem(20px, 24px);
            }
        }

        .subtitle {
            @include font-rem(18px, 28px);
            margin-bottom: 15px;
            margin-top: 10px;

            @include media-breakpoint-down(md) {
                @include font-rem(16px, 24px);
            }
        }

        .info {
            >ul {
                @include font-rem(14px, 20px);
            }

            width: 300px;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: 100%;
            }

            .additional-info {
                margin-top: 10px;
                @include font-rem(11px, 28px);
            }
        }

        .cta {
            width: 300px;
            font-size: 13px;

            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: 100%;
            }

            &:hover {
                color: $brand-black;
            }
        }
    }

    &.register-device {
        margin-top: 0;

        @include media-breakpoint-down(md) {
            background: $white;
            margin: 0;
            padding: 20px;
        }
        
        .bat-form-step {
            .bat-form-heading {
                text-align: left;
            }

            .bat-form-field {
                input {
                    background-color: $white !important;
                    box-shadow: 0 0 0 30px $white inset !important;
                    -webkit-box-shadow: 0 0 0 30px $white inset !important;

                    @include media-breakpoint-down(md) {
                        background-color: #efefef !important;
                        box-shadow: 0 0 0 30px #efefef inset !important;
                        -webkit-box-shadow: 0 0 0 30px #efefef inset !important;
                    }
                }
            }
        }

        .cta-flex {
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;

            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
                justify-content: flex-end;
            }

            .bat-form--signin-submit {
                width: auto;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            .forgotPdw {
                text-transform: none;
                text-decoration: underline;
                font-size: 10px;
                line-height: 14px;
            }
        }

        .bat-signin-gloit-signupDevice {
            font-size: 14px;
            line-height: 20px;

            .orangeLink {
                color: #E35205;
                font-weight: 700;
                text-decoration: underline;
            }
        }
    }
}
