.bat-addressbook-gloit{
    width: 75%;
    margin: auto 70px;

    @include media-breakpoint-down(md){
        width: auto;
        margin: auto 20px;  
    }

    &-title{
        padding-bottom: 40px;
        border-bottom: 2px solid #DEDEDE;

        @include media-breakpoint-down(md){
            padding-bottom: 30px;
        }

        .title{
            @include font-rem(30px, 34px);
            text-transform: uppercase;
            margin-bottom: 30px;

            @include media-breakpoint-down(md){
                @include font-rem(24px, 28px);
                text-align: center;
                margin-bottom: 20px;
            }
        }

        .addAddress{
            color: white;
            font-size: 13px;
            width: 250px;


            @include media-breakpoint-down(md){
                max-width: 100%;
                width: 100%;
            }
        }
    }

    &-default-addresses{
        justify-content: space-between;
        margin-bottom: 30px;
        padding-top: 40px;
        padding-bottom: 30px;
        border-bottom: 2px solid #DEDEDE;

        @include media-breakpoint-down(md){
            display: block;
            margin-bottom: 20px;
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .default-delivery-address,
        .default-billing-address{
            width: 45%;

            @include media-breakpoint-down(md){
                width: auto;
            }

            .title{
                @include font-rem(20px, 20px);
                text-transform: inherit;
                font-weight: 500;
                margin-bottom: 30px;

                @include media-breakpoint-down(md){
                    text-align: left;
                    @include font-rem(18px, 20px);
                    margin-bottom: 20px;
                }
            }

            .information{
                padding: 20px;
                border: 1px solid #DEDEDE;
                margin-bottom: 20px;

                .user{
                    font-weight: 700;
                }

                p{
                    margin-bottom: 10px;
                    @include font-rem(14px, 20px);

                    &:last-of-type{
                        margin-bottom: 0px;
                    }
                }

                a{
                    margin-top: 15px;
                    font-size: 13px;
                    
                    &:hover{
                        color: $brand-black;
                    }
                }
            }
        }

        .default-delivery-address{
            @include media-breakpoint-down(md){
                margin-bottom: 20px;
            }
        }
    }

    &-addresses{
        .title{
            @include font-rem(22px, 28px);
            text-transform: uppercase;
            margin-bottom: 30px;

            @include media-breakpoint-down(md){
                text-align: center;
                @include font-rem(20px, 24px);
            }
        }

        .text{
            @include font-rem(20px, 20px);
            font-weight: 500;
            
            @include media-breakpoint-down(md){
                text-align: center;
                @include font-rem(18px, 24px);
            }
        }

        .addresses-container{
            margin-left: 0px;
            justify-content: space-between;

            @include media-breakpoint-down(md){
                display: block;
            }

            .information{
                padding: 20px;
                border: 1px solid #DEDEDE;
                margin-bottom: 20px;
                width: 45%;

                @include media-breakpoint-down(md){
                    width: auto;
                }
    
                .user{
                    font-weight: 700;
                }
    
                p{
                    margin-bottom: 10px;
                    @include font-rem(14px, 20px);
    
                    &:last-of-type{
                        margin-bottom: 0px;
                    }
                }
    
                .cta{
                    justify-content: space-between;
    
                    a,
                    button{
                        margin-top: 15px;
                        height: 50px;
                        width: 180px;
    
                        &:hover{
                            color: $brand-black;
                        }
    
                        @include media-breakpoint-down(md){
                            padding-inline: 0px;
                            width: 135px;
                        }
                    }
    
                    a{
                        font-size: 13px;
                    }
                }
            }
        }
    }
}