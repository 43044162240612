.guida-legenda-modal-body {
	display: none;
	position: fixed;
	z-index: 1000000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0px !important;
}

.guida-legenda-modal-content {
	height: 100%;
	background: #fff;
	position: relative;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	overflow-wrap: break-word;
	&-title{
		padding-top: 7%;
		text-align: center;
		font-size: 28px;
		font-weight: 700;
		color: #000;
		line-height: 36px;
		margin-top: 5%;
	}
	
}


.guida-legenda-modal-horizontal-line{
	width: 100%; 
	height: 0.2px; 
	background-color: #cccaca; 
	margin-top: 5%;
	margin-bottom: 3%;
}

.guida-legenda-modal-pins{
	display: flex;
	align-items: flex-end;
	&-title{
		margin-left: 2%;
		font-weight: 600;
		font-size: 18px;
	}
	&-text{
		font-size: 12px;
		margin-top: 2.5%;
	}
	&-container{
		margin: 6.5%;
	 }
	&-img{
		width: 8%;
	}
}

#guida-legenda-modal-close-button {
	background: #fff;
	top: 44px;
	right: 5%;
	height: 40px;
	width: 40px;
	padding: 5px;
	position: absolute;
	border-radius: 100%;
}

.guida-legenda-modal-continue-button-container {
	text-align: center;
	width: 90%;
	height: 15%;
	margin: 5%;
	color: #ff5216;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.guida-legenda-modal-continue-button {
	background: #fff;
	border: 3px solid;
	border-radius: 100px;

	&-text {
		margin: 4%;
		font-weight: 600;
		color: black;
		font-size: 16px;
        font-family: 'Mont';
	}
}