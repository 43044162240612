.bat-videotext {
	display: flex;
	gap: 60px;
    justify-content: center;
	padding: 20px 75px;
    background-color: $black;
    color: $white;

	.text {
		font-size: 20px;
		line-height: 24px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        justify-content: center;
        max-width: 549px;

        .title {
            font-weight: 500;
            font-size: 50px;
            line-height: 60px;
        }
        .desktop-only, .mbl-only{
            span{
                font-weight: 800;
            }
        }
	}

    .video-wrapper {
        margin: auto 0;
        max-width: 55%;
        video {
            max-width: 679px;
            cursor: pointer;
        }
    }

    .mbl-only {
        display: none;
    }
    .desktop-only{
        display: block;
    }
}

@include media-breakpoint-down(lg) {
    .bat-videotext {
        .text {
            font-size: 14px;
            line-height: 19px;
    
            .title {
                font-size: 26px;
                line-height: 26px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .bat-videotext {
        margin-top: -60px;
        padding-inline: 15px;
        padding-bottom: 30px;
        justify-content: center;
        gap: 0;

        .text {
            text-align: center;
            justify-content: space-between;
            gap: 15px;
            align-items: center;
        }

        video {
            display: none;
            max-width: 400px;
            padding: 0 15px;
        }

        .mbl-only {
            display: block;
        }
        .desktop-only{
            display: none;
        }
    }
}
