.bat-riscattacoin-gloit{
    width: 75%;
    margin: auto 70px;

    @include media-breakpoint-down(md){
        width: auto;
        margin: auto;
    }

    .title{
        text-transform: uppercase;
        @include font-rem(36px, 45px);
        margin-bottom: 40px;

        @include media-breakpoint-down(md){
            @include font-rem(20px, 24px);
            margin-left: 20px;
        }
    }

    &-form{
        background-color: #EFEFEF;
        padding: 40px 60px;

        @include media-breakpoint-down(md){
            padding: 20px 30px;
        }

        .title{
            @include font-rem(20px, 25px);
            text-align: center;
            text-transform: inherit;
            font-weight: 500;
        }

        .riscatta-coin-input{
            .riscatta{
                display: flex;
                justify-content: center;
                margin: auto;

                @include media-breakpoint-down(md){
                    display: block;
                }

                .bat-form--riscatta-coin{
                    &-coin{
                        width: 50%;
                        margin-right: 10px;

                        @include media-breakpoint-down(md){
                            width: 100%;
                            margin-right: 0px;
                        }

                        input{
                            border-radius: 0px;
                            -webkit-appearance: none;
                            background-color: white;
                            height: 46px;

                            &:-webkit-autofill{
                                box-shadow: 0 0 0 30px white inset !important;
                                -webkit-box-shadow: 0 0 0 30px white inset !important;
                            }

                            &:hover{
                                &:-webkit-autofill{
                                    box-shadow: 0 0 0 30px white inset !important;
                                    -webkit-box-shadow: 0 0 0 30px white inset !important;
                                }
                            }
                        }
                    }

                    &-riscatta{
                        width: fit-content;

                        @include media-breakpoint-down(md){
                            width: 100%;
                        }

                        button{
                            border-radius: 0;
                            margin: 0;

                            @include media-breakpoint-down(md){
                                border-radius: 100px;
                                width: 100%;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    &-success-message,
    &-error-message{
        margin-top: 30px;

        @include media-breakpoint-down(md){
            margin: 20px;
        }

        &::after{
            display: none;
        }

        &:hover{
            cursor: auto;
        }

        .message{
            font-weight: 700;
            @include font-rem(14px, 20px);
            text-align: center;
        }

        &.hidden{
            display: none;
        }
    }

    &-success-message{
        background: rgba(69, 159, 47, 0.2);

        .message{
            color: $brand-black;
        }
    }

    &-not-logged{
        background-color: #EFEFEF;
        padding: 40px 60px;
        text-align: center;

        @include media-breakpoint-down(md){
            padding: 20px 30px;
        }

        .title{
            @include font-rem(20px, 25px);
            text-align: center;
            text-transform: inherit;
            font-weight: 500;
            margin-bottom: 20px;
        }

        .subtitle{
            @include font-rem(14px, 20px);
            text-align: center;
            font-weight: 500;
            margin-bottom: 30px;
        }

        .button{
            font-size: 13px;
            color: white;

            @include media-breakpoint-down(md){
                width: 100%;
                max-width: 100%;
            }
        }
    }
}