bat-card-gloitstepper {
    max-width: unset !important;
}

.bat-card-stepper-gloit {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $white;
    z-index: 1;

    &-placeholder {
		height: 106px;
		width: 100%;

        @include media-breakpoint-up(sm) {
            height: 138px;
        }
	}

    &-title {
        @include font-rem(16px, 20px);
        font-weight: 700;
        margin-bottom: 3px;

        @include media-breakpoint-up(sm) {
            @include font-rem(18px, 22px);
            margin-bottom: 13px;
        }
    }

    &-steps {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 500px;

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            &-circle {
                width: 28px;
                height: 28px;
                border-radius: 100%;
                background: #FFFFFF;
                border: 2px solid #D4D5D5;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 15px;

                @include media-breakpoint-up(sm) {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 23px;
                }

                &.active {
                    border: 2px solid #CE4B05;
                }

                &-fill {
                    display: none;
                    width: 18px;
                    height: 18px;
                    border-radius: 100%;
                    background: #CE4B05;

                    @include media-breakpoint-up(sm) {
                        width: 22px;
                        height: 22px;
                    }

                    &.active {
                        display: block;
                    }
                }
            }

            &-label {
                @include font-rem(10px, 12px);
                position: absolute;
                bottom: 0;

                @include media-breakpoint-up(sm) {
                    @include font-rem(14px, 17px);
                }

                &.active {
                    font-weight: 700;
                }
            }
        }

        &-line {
            flex-grow: 1;
            border-bottom: 2px solid #D4D5D5;
            margin: 0 -10px 16px -10px;
            z-index: -1;

            @include media-breakpoint-up(sm) {
                margin: 0 -10px 23px -10px;
            }

            &.active {
                border-bottom: 2px solid #CE4B05;
            }
        }
    }

}