.glo-it-personalizza{

	@include media-breakpoint-down(sm) {
		margin: -190px 0 0;
	}

	.uk-container{
		max-width: $site-max-width;
		@include media-breakpoint-up(sm) {
			padding: 0;
		}
	}
	.personalizza-discover{
		@include media-breakpoint-down(sm){
			flex-direction: column-reverse;
		}
		.text{
			max-width: 495px;
			@include media-breakpoint-up(sm){
				margin-right: 20px;
			}
		}
		.title{
			margin-top: 15px;
			margin-bottom: 15px;
			@include media-breakpoint-up(sm){
				font-size: 36px;
				line-height: 36px;
				font-weight: 800;
			}
			@include media-breakpoint-down(sm){
				display: none;
			}
		}
		.description{
			font-size: 16px;
			line-height: 24px;
			font-weight: 500;
		}
		.img-info-button{
			margin-top:15px;
			margin-bottom: 15px;
			@include media-breakpoint-down(lg){
				justify-content: center;
			}
		}
		.overlapping-img{
			@include media-breakpoint-up(lg){
				margin-top: -130px;
			}
		}
	}	
}
.mobile-title{
	background-color: $brand-black;
	.title{
		@include media-breakpoint-down(sm){
			font-size: 24px;
			line-height: 24px;
			color: $white;
		}
		@include media-breakpoint-up(md){
			display: none;
		}
	}
}