.bat-portaunamico-gloit{
    width: 75%;
    margin: auto 70px;

    @include media-breakpoint-down(md){
        width: auto;
        margin: auto 20px;
    }

    &-title{
        @include font-rem(34px, 39px);
        text-transform: uppercase;
        margin-bottom: 30px;

        @include media-breakpoint-down(md){
            text-align: center;
            @include font-rem(24px, 28px);
        }
    }

    &-content{
        width: 600px;
        margin-inline: auto;
        margin-top: 100px;

        @include media-breakpoint-down(md){
            width: auto;
            margin-top: 70px;
        }

        &-disclaimer{
            margin-bottom: 70px;

            @include media-breakpoint-down(md){
                margin-bottom: 70px;    
            }

            .title{
                @include font-rem(34px, 39px);
                margin-bottom: 30px;
                text-align: center;
            }

            .subtitle{
                @include font-rem(20px, 14px);
                margin-bottom: 30px;
                font-weight: 500;
                text-align: center;
            }

            .codice-amico{
                @include font-rem(34px, 39px);
                margin-bottom: 30px;
                font-weight: 500;
                text-align: center; 
            }

            .text{
                text-align: center;
                margin-top: 70px;
            }
        }

        &-links{
            display: flex;
            justify-content: space-between;

            .info-link,
            .whatsapp-link{
                display: flex;
                flex-direction: column;
                align-items: center;

                .icon{
                    margin-bottom: 5px;
                }

                .text{
                    font-size: 14px;
                }

                &:hover{
                    .text{
                        color: $brand-black;
                        text-decoration: none;
                    }
                }
            }

            .whatsapp-link{
                &.disabled{
                    pointer-events: none;

                    .icon,
                    .text{
                        opacity: .5;
                        filter: grayscale(1);
                    }
                }
            }
        }
    }
}