.gloit-highlights-image{
	aspect-ratio: 0!important;
	box-shadow: none!important;
}

.highligths-custom-padding{
	padding-right: 1%;
}

.gloit-highlights-image-category{
	height: 94%;
    position: absolute;
    width: 120px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    right: 2%;
    margin-right: 0;
}

.gloit-highlights-image-category-background{
	    background: linear-gradient(45deg, #5482c2 0, #353174 100%) !important;
    height: 38px;
    border-radius: 9px;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: .2px;
    display: flex;
    flex-direction: column;
	justify-content: center;
    width: 70%;
}

 .gloit-highlights-image-category-background p{
    	font-weight: 700;
		font-size: 12px;
		line-height: 28px;
		text-align: center;
		letter-spacing: .2px;
		display: flex;
		color: #fff;
		margin: 10px;
}

.gloit-highlights-title{
	margin-top: 5%;
}

.gloit-highlights-date{
	font-size: 13px;
}

.gloit-highlights-description{
	 display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: justify;
}