bat-carousel-gloitaudiomysterynumbers{
    .audiomystery-carousel-container{
        display: flex;
        align-items: center;
        gap: 28px;
        width: fit-content;
        margin-inline: auto;
        padding-top: 20px;
        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 320px;
            gap: 56px;
            justify-content: flex-start;
        }
        &-text{
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 111.111% */
        }
        .bat-carousel-gloitaudiomysterynumbers-container {
            width: fit-content;
            margin: 0;
            color: $white;
            @include media-breakpoint-down(md) {
                max-width: 160px;
            }

            .slides-container .navigation-arrows{

                .slick-arrow{
                    &.slick-disabled{
                        opacity: 0.2;
                    }
                    &.slick-next{
                        right: -35%;
                    }
                    &.slick-prev{
                        left: -24%;
                    }
                    i.bat-icon{
                        color: white;
                        width: 21px;
                        height: 21px;
                        ::before{
                            font-size: 21px;
                        }
                    }
                }
            }
            .slick-track {
                width: fit-content !important;
                display: flex;
                margin: 0;
            }
        
            .bat-carousel-slides {
                width: fit-content;
                visibility: visible;
                opacity: 1;
                @include media-breakpoint-down(md) {
                    width: unset;
                }
                .slick-slide {
                    height: 100%;
                    background: transparent;
                    position: relative;
                    width: fit-content !important;
                    &:last-child{
                        margin-right: 0px;
                    }
                    .number-container{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        border: 2px solid #FFF;
                        width: 36px;
                        height: 36px;
                        margin-right: 20px;
                        .number{
                            color: #FFF;
                            text-align: center;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px; /* 111.111% */
                        }
                    }
                }
            }

        }
    }

}




