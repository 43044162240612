.bat-tabs-gloit {
	border-top: solid 12px $gray;
	position: relative;
	
	&-indicator {
		border-color: $gray transparent transparent;
		border-style: solid;
		border-width: 12px 7px 0;
		height: 0;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		transition: left .25s ease-in, opacity .5s ease-in;
		width: 0;
		
		&.active {
			opacity: 1;
		}
		
	}

	&-list {
		list-style: none;
		margin: 12px 0 0;
		padding: 0;

		&-item {
			flex: 0 0 auto;
			margin: 0;
            padding-left: 0px;
	
			&-button {
				align-items: center;
				display: flex;
				padding: 10px;
                justify-content: center;
                width: 100%;
				
				i {
					height: 16px;
					margin-left: 20px;
					width: 16px;
					
					&:before {
						font-size: 16px;
					}
				}
				
				.active & {
					border-bottom: solid 1px $gray;
				}
			}
		}
	}
	
	&-panels {
		
		&-panel {
			display: none;
			overflow: hidden;
			
			.authorEditMode & { // sass-lint:disable-line class-name-format
				display: block;
			}

			&.active {
				display: block;
			}
			
			& > div {
				padding: 20px 0;
			}
		}
	}
}

body {
	.bat-wrapper {
		bat-tabs-gloit {
			padding-bottom: 40px;

			bat-sectiondefault-gloit {
				.card {
					display: none;
					&:first-child {
						display: block; // seems to fix weird Tabs bug where multiple Cards are showing
					}
				}
			}

			&.bat {
				@include media-breakpoint-up(lg) {
					height: 525px;
					margin-left: -3.5px;
					min-height: 500px;
					padding: 0px 100px
				}
			}

			.bat-tabs-gloit {
				border-top: 0;

				&-list {
					justify-content: center;
					margin-top: 0;

					&-item {
						&-button {
							border-bottom: 2px solid $light-gray;
							box-shadow: none;

							padding: 12px;

							@include media-breakpoint-up(md) {
								padding: 12px 20px;
							}

							@include media-breakpoint-up(lg) {
								padding: 12px 30px;
							}

							span {
								color: $light-gray;
								font-family: $primary-font;
								font-size: 12px;
								font-weight: 700;
								letter-spacing: 0.04rem;
								line-height: 14px;
								position: relative;
							}
						}
						&.active {
							button {
								border-bottom: 3px solid #EC7B2E;

								span {
									color: $black;

									@include media-breakpoint-up(lg) {
										color: $black;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

bat-tabs-gloitmusic {
	max-width: unset !important;
	background-image: url('/content/dam/glo-it/immagini/glo-for-music/music-back-2.png');
    background-position: center;
    background-size: cover;
	margin-top: 100px;
	margin-bottom: 100px !important;

	@include media-breakpoint-down(md) {
		background-image: url('/content/dam/glo-it/immagini/glo-for-music/music-back-2-mob.png');
		margin-top: 0;
		margin-bottom: 0 !important;
	}
}

.bat-tabs-gloitmusic {
	max-width: 1200px;
	margin: 0 auto;
	padding: 40px 0;

	@include media-breakpoint-down(md) {
		padding: 25px 20px;
		flex-direction: column;
	}

	&-list {
		list-style: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 25px;
		margin: 0;
		padding: 0;
		justify-content: space-evenly;

		@include media-breakpoint-down(md) {
			flex-direction: row;
		}
		
		&-item {
			gap: 25px;
			align-items: center;

			@include media-breakpoint-down(md) {
				flex-direction: column;
			}
			
			&-button {
				width: 75px;
				height: 75px;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				opacity: 0.6;

				.bat-icon {
					height: 100%;
					width: 100%;
				}
			}

			&-arrow {
				visibility: hidden;

				@include media-breakpoint-down(md) {
					transform: rotate(90deg);
				}
			}

			&.active {
				.bat-tabs-gloitmusic-list-item-button {
					width: 127px;
					height: 127px;
					border: 1px solid #FFFFFF;
					opacity: 1;

					@include media-breakpoint-down(md) {
						width: 118px;
						height: 118px;
					}

					.bat-icon {
						width: 110px;
						height: 110px;
						filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.6));

						@include media-breakpoint-down(md) {
							width: 100px;
							height: 100px;
						}
					}
				}

				.bat-tabs-gloitmusic-list-item-arrow {
					visibility: visible;
				}
			}
		}
	}

	&-panels {
		.bat-tabs-panels-panel > div {
			padding: 0 !important;
		}

		.bat-tabs-panels-panel.active {
			overflow: visible;
		}

		.bat-card--blurb {
			display: flex;
			height: auto;
			flex-direction: row-reverse;
			align-items: center;
			overflow: visible;

			@include media-breakpoint-down(md) {
				flex-direction: column;
			}

			&-img {
				position: static;
				width: 60%;
				margin: -40% 0;
				padding: 20px;

				@include media-breakpoint-down(md) {
					width: 100%;
					margin: 0;
					padding: 20px 0;
				}

				img {
					filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.4));
					border-radius: 30px;
				}
			}

			&-main {
				position: static;
				width: 40%;
				padding: 30px 40px;

				@include media-breakpoint-down(md) {
					width: 100%;
					padding: 20px 0;
				}
			}

			&-text {
				h3 {
					color: $white;
					font-size: 30px;
					line-height: 34px;
					margin-bottom: 25px;

					@include media-breakpoint-down(md) {
						font-size: 24px;
						line-height: 28px;
					}
				}

				p {
					font-size: 14px;
					line-height: 20px;

					@include media-breakpoint-down(md) {
						font-size: 13px;
					}
				}
			}

			&-ctalist {
				display: none;
			}
		}
	}
}
