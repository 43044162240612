.bat-editdetails-gloit{
    width: 45%;
    margin: auto 70px;

    @include media-breakpoint-down(md){
        margin: auto 20px;
        width: auto;
    }

    input{
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        border-radius: 0;
    }

    h1{
        @include font-rem(30px, 34px);
        text-transform: uppercase;
        margin-bottom: 20px;

        @include media-breakpoint-down(md){
            text-align: center;
            @include font-rem(24px, 28px);
        }
    }

    


    .bat-form--edit-details{
        &-password,
        &-new-password,
        &-confirm-password,
        &-current-password{
            .error-msg{
                display: none;
            }
            .edit-password{
                @include font-rem(12px, 15px);
                font-weight: 700;
                width: 100%;
                justify-content: flex-end;
                padding-bottom: -9px;
                position: relative;
                top: -32px;
                right: 15px;
            }

            .bat-icon{
                margin-right: 10px;
                margin-left: auto;
                margin-top: -34px;
                cursor: pointer;
            }

            input{
                border-radius: 0px;
                -webkit-appearance: none;
            }

            .password-meter{
                border: 2px solid $concrete;
                @include font-rem(14px, 20px);
                margin-top: 5px;
                padding: 5px 10px;

                &.no-password{
                    background: $white
                }

                &.weak{
                    background: -webkit-linear-gradient(left, #ffafae 25%, white 25%);
                    background: -moz-linear-gradient(left, #ffafae 25%, white 25%);
                    background: -o-linear-gradient(left, #ffafae 25%, white 25%);
                    background: -ms-linear-gradient(left, #ffafae 25%, white 25%);
                    background: linear-gradient(left, #ffafae 25%, white 25%);
                }

                &.medium{
                    background: -webkit-linear-gradient(left, #ffd6b3 50%, white 50%);
                    background: -moz-linear-gradient(left, #ffd6b3 50%, white 50%);
                    background: -o-linear-gradient(left, #ffd6b3 50%, white 50%);
                    background: -ms-linear-gradient(left, #ffd6b3 50%, white 50%);
                    background: linear-gradient(left, #ffd6b3 50%, white 50%);                    
                }

                &.strong{
                    background: -webkit-linear-gradient(left, #c5eeac 75%, white 75%);
                    background: -moz-linear-gradient(left, #c5eeac 75%, white 75%);
                    background: -o-linear-gradient(left, #c5eeac 75%, white 75%);
                    background: -ms-linear-gradient(left, #c5eeac 75%, white 75%);
                    background: linear-gradient(left, #c5eeac 75%, white 75%);
                }

                &.very-strong{
                    background: -webkit-linear-gradient(left, #81b562 100%, white 100%);
                    background: -moz-linear-gradient(left, #81b562 100%, white 100%);
                    background: -o-linear-gradient(left, #81b562 100%, white 100%);
                    background: -ms-linear-gradient(left, #81b562 100%, white 100%);
                    background: linear-gradient(left, #81b562 100%, white 100%);
                }
            }
        }

        &-new-password,&-confirm-password, &-current-password{
            display: none;
        }
        &-confirm-password, &-current-password{
            .password-meter{
                display: none;
            } 
        }


        &-current-password, &-new-password{
            .frontText-input-error{
                display: none;
            } 
        }

        &-password{
            .bat-icon{
                display: none;
            }
        }


        &-submit{
            text-align: center;

            button{
                width: 300px;

                @include media-breakpoint-down(md){
                    max-width: 100%;
                    width: 100%;
                }
            }
        }

        &-messages{
            text-align: center;
            @include font-rem(14px, 20px);
        }

    }

    .bat-form--edit-details-date{
        .error-msg{
            display: none;
        }

        .bat-icon{
            margin-right: 10px;
            margin-left: auto;
            margin-top: -38px;
            cursor: pointer;
        }

        input[type="date"]::-webkit-calendar-picker-indicator{
            display: none;
        }

        input::-webkit-date-and-time-value {
            text-align: left;
            vertical-align: middle;
        }

        .bat-icon{
            &::before{
                content: url('/content/dam/glo-it/images/icon-calendar.svg');
            }
        }

        input{
            color: transparent;
        }
    }

    .buttons{
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(md){
            display: block;
        }

        .bat-form--edit-details-submit,
        .bat-form--edit-details-back{
            width: fit-content;

            @include media-breakpoint-down(md){
                width: 100%;
            }

            button,
            a{
                width: 250px;
    
                @include media-breakpoint-down(md){
                    width: 100%;
                    max-width: 100%;
                }
            }

            a{
                font-size: 13px;

                &:hover{
                    span{
                        color: $brand-black;
                        text-decoration: none;
                    }   
                }
            }
        }
    }

    &.mobile-app {
        h1{
            @include media-breakpoint-down(md){
                text-align: left;
            }
        }

        form input:disabled {
            color: #6B6B6B !important;
        }

        form .select-wrapper select {
            color: #6B6B6B !important;
            background-color: #dedede !important;
            opacity: 1 !important;
        }

        .edit-password {
            width: 100%;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
        }

        .bat-form--edit-details-submit {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 24px 20px;
        }

        .modified-success {
            position: fixed;
            width: 100%;
            left: 0;
            bottom: 95px;
            padding: 0 20px;
            opacity: 0;
            transition: opacity 0.5s ease-out;

            &.active {
                opacity: 1;
            }

            &-content {
                width: 100%;
                padding: 16px;
                gap: 10px;
                background: #DBFFEA;
                border-radius: 8px;
                font-size: 16px;
                line-height: 24px;
                align-items: center;
            }
        }
    }
}