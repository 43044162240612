bat-producthero-gloitredesign{
	&.pdp-hyper-core{
		background-color: $white;
		.bat-producthero {
			&-gloit {
				background-color: $white;

				&-container{
					.bat-producthero-description{
						.descriptions{
							.review-stars{
								align-items: center;
	
								.rating-summary-number{
									color: var(--glo-2024-glo-Charcoal, #2A2A2A);
								}
	
								.totalReviews{
									color: #2A2A2A;
									border: 2px solid var(--glo-2024-glo-Charcoal, #2A2A2A);
									text-align: center;
									font-family: Mont;
									font-size: 14px;
									font-style: normal;
									font-weight: 600;
									line-height: normal;
									svg{
										path{
											fill: black;
											stroke: black;
										}
									}
								}
							}
	
							.title{
								color: black;
							}
							.hyperpro-icon-container{
								picture{
									width: 60%;
									padding: 10px 0 10px 0px;
	
								}
							}
	
							.blu-hog-container{
								width: 80%;
								height: 44px;
								background: #0103DF;
								display: flex;
								align-items: center;
								justify-content: space-around;
								margin-bottom: 5%;
	
								@include media-breakpoint-down(sm){
									width: auto;
									margin-left: -20px;
									margin-right: -20px;
									height: 65px;
									justify-content: flex-start;
								}
	
								span{
									color: #FFF;
									font-family: Mont;
									font-size: 15px;
									font-style: normal;
									font-weight: 600;
									line-height: 16px;
									padding: 0 20px;
								}
							}
	
							.subtitle{
								@include font-rem(20px, 20px);
								font-family: $primary-font;
								font-weight: 500;
								margin-top: 20px;
	
								@include media-breakpoint-down(sm){
									@include font-rem(18px, 20px);
								}
							}
	
							.listTitle{
								@include font-rem(14px, 20px);
								font-weight: 700;
								font-family: $primary-font; 
								margin-top: 30px;
							}
	
							.list{
								margin-top: 20px;
								.item{
									@include font-rem(14px, 20px);
									font-weight: 500;
									font-family: $primary-font;
									list-style: url('/content/dam/glo-it/images/pdp/gloitarrowright-pdp.svg');
									padding-left: 8px;
								}
							}
	
							.engraving{
								margin-top: 40px;
								margin-bottom: 40px;
								background-color: $concrete;
	
								@include media-breakpoint-down(sm){
									margin-top: 20px;
									margin-bottom: 30px;
								}
	
								.engraving-link{
							
									.container{
										border-right: 1px solid $light-gray;
	
										@include media-breakpoint-down(sm){
											border-right: none;
											border-bottom: 1px solid $light-gray;
										}
									}
	
									.text-container{
										@include media-breakpoint-down(sm){
											max-width: fit-content;
											margin: auto;
										}   
									}
	
									picture{
										max-width: fit-content;
										margin: 30px;
	
										@include media-breakpoint-down(sm){
											margin: 30px 20px;
										}
									}
	
									h3, p, span{
										@include font-rem(12px, 15px);
										font-family: $primary-font; 
									}
	
									p{
										color: $dark-gray;
									}
	
									h3{
										font-weight: 600;
										margin-bottom: 5px;
									}
	
									span{
										font-weight: 600;
										margin: 40px;
										color: $brand-black;
										text-decoration: none;
									}
								}
							}
	
							.quantity-counter{
								.quantity-label{
									color: black;
								}
	
								.selected-color-label{
									color: #2A2A2A;
								}
	
								.counter{
									.icon-plus,
									.icon-minus{
										background-color:  #FFF;
										color: $black;
										font-weight: 700;
										padding: 5px;
										&:before{
											font-weight: 700;
											font-size: 18px;
										}
									}
	
									.counter-number{
										color: black;
									}
								}
							}
	
							.engraving-pro-container {	
								margin-top: 15px;
								display: none !important;
								@include media-breakpoint-down(sm) {
									flex-direction: column;
									align-items: flex-start;
									gap: 8px;
								}
		
								&-text {
									width: 38%;
		
									@include media-breakpoint-down(sm) {
										width: 100%;
									}
		
									&-title {
										color: black;
									}
		
									&-description {
										p {
											color: var(--glo-2024-Warm-Grey, #2A2A2A);
											@include media-breakpoint-down(sm) {
												font-size: 12px;
											}
										}
									}
								}
		
								&-cta {
		
									.bat-cta-style {
										border: 2px solid var(--glo-2024-Accent-Orange, #FE5000);
	
										span {
											color: black;
										}
		
									}
								}
							}
	
							.gray-container{
								background: var(--glo-2024-Warm-Grey, #E5E1E6);
								.price-container{
									.price{
										color: black;
									}
								}
							}
	
							.insiders-container{
								.insiders-intro, .insiders-title{
									color: black !important;
								}
	
							}
	
	
							.insiders-container {
								margin-top: 16px;
								.insiders-intro {
									span {
										color: var(--glo-2024-Warm-Grey, #2A2A2A);
									}
								}
	
								.insiders-title {
									span {
										color: black;
									}
								}
								.insiders-info {
									border-radius: 24px;
									background: #e6e1e5;
									p{
										color: black;
									}
								}
							}
						}
					}
				}

				.bat-producthero-configurator {
	
					&-price {
	
						.formatted-price {
							color: black;
						}
					}
	
	
					&-description {
						@include font-rem(14px, 20px);
						color: #2A2A2A;
					}
	
					&-subscription {
						&-text {
							.formatted-price {
								color: black;
							}
						}
					}

					&-configurable-options {
						background: var(--glo-2024-Warm-Grey, #E5E1E6);

						.size-container{
	
							.size{
								border-radius: 100%;
								width: 30px;
								position: relative;
								height: 30px;
								background-color: $concrete;
								color: $dark-gray;
								font-weight: 600;
								font-size: 12px;
								font-family: $primary-font;
								margin: 2px;
				
								&:hover{
									border: 1px solid $brand-black;
									color: $brand-black;
								}
					
								&:active{
									background-color: $white;
									box-shadow: 0 0 3px #1d1d1d;
									border: 1px solid black;
									color: $brand-black;
									
								}
								&:disabled{
									&:hover{
										border: 0;
										color: $dark-gray;
									}
									&:active{
										background-color: $concrete;
										color: $brand-black;
										box-shadow: 0 0 0 #1d1d1d;
										color: $dark-gray;
									}
								}
							}
	
							&.active{
								.size{
									border: 1px solid $brand-black;
									color: $brand-black;
								}
							}
	
						}
	
			
						
						&-variants-button {
							$button-size: 34px;
							height: $button-size;
							width: $button-size;
							border-radius: 99999999px;
							padding: 4px;
							position:relative;
							&:after {
								content: "";
								background-color: var(--button-variant-color);
								display: block;
								width: 100%;
								height: 100%;
								border-radius: 99999999px;
							}
							&:active,
							&.active {
								background-color: white;
								border: 2px solid black;
							}
							&:disabled{
								
								background-color: var(--button-variant-color);
								border: 4px solid $white;
								&:active{
									border: 0;
								}
								&:after {
									background: linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -moz-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -ms-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -o-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -webkit-gradient(linear,left top,right bottom,color-stop(0%,rgba(255,255,255,0)),color-stop(42%,rgba(255,255,255,0)),color-stop(43%,#fff),color-stop(46%,#fff),color-stop(47%,#ff5216),color-stop(53%,#ff5216),color-stop(54%,#fff),color-stop(57%,#fff),color-stop(58%,rgba(255,255,255,0)),color-stop(100%,rgba(255,255,255,0)));
									background: -webkit-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									bottom: 0;
									content: '';
									
									left: 0;
									position: absolute;
									right: 0;
									top: 0;
								}
							}
						}
	
						p {
							display: none;
						}
					}
	
					.bat-quantity {
						margin-bottom: 26px;
						padding-bottom: 0;
						padding-top: 0;
						&-label {
							@extend h5;
							@extend .headline5-small;
							@include font-rem(12px, 15px);
							flex: 0 1 140px;
							letter-spacing: 1px;
						}
						.bat-quantity-button {
							flex: 0 0 32;
							height: 32px;
							min-width: 32px;
						}
					}
	
					.bat-producthero-deliveryinfo {
						align-items: center;
						background-color: $white;
						display: flex;
						padding: 24px 20px;
						margin-top: auto;
						margin-bottom: 23px;
						&-icon {
							padding-left: 4px;
							padding-right: 14px;
							.bat-icon {
								height: 34px;
								width: 34px;
								&::before {
									font-size: 34px;
								}
							}
						}
						&-text {
							p {
								@include font-rem(12px, 14.4px);
								color: $dark-gray;
								letter-spacing: 0.25px;
								margin: 0;
							}
						}
					}
				}

				.bat-producthero-content {
					background-color: white;
			
					.scrolling-buttons{
						.scrolling-buttons-content{
							svg{
								path{
									stroke: black;
								}
							}
							span{
								color: black;
							}
						}
					}
				}
	
				.bat-producthero-gallery {
					background-color: white;
				
	
					@include media-breakpoint-down(sm) {
						margin-top: 0px;
						padding-left: 0px;
					}
					.slick-dots {
						display: none !important; // sass-lint:disable-line no-important
					}
	
					.slick-arrow {
						background-color: $dark-gray-50;
	
						&:before {
							color: $black;
						}
					}

					&-thumbs {
						display: none;
						flex-wrap: wrap;
						margin-top: 20px;
						padding: 0;
	
					}
				}
			}
		}
	}
	background-color: $black;
    .hyperpro-icon-container{
        margin-left: 13%;
        max-width: 118px;
        margin-top: 56px;
        @include media-breakpoint-down(sm) {
            margin-top: 24px;
			margin-left: 4%;
			max-width: 98px;
        }
    }
	.bat-producthero {
		&-gloit {
			margin-inline: auto;
			max-width: 1500px !important;
			background-color: $black;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			opacity: 0;
			padding: 0 0 20px;
			transition: opacity 0.2s ease-in;

			&.loaded {
				opacity: 1;
			}

			@include media-breakpoint-down(sm){
				.review-stars{
					align-items: center;
					margin-top: 30px;

					.icon-star{
						width: 16px;
						height: 16px;
						&::before{
							font-size: 16px;
						}
					}

					.totalReviews{
						margin-left: 7px;
						@include font-rem(12px, 28px);
						color: $dark-gray
					}
				}

				.title{
					@include font-rem(26px, 32px);
					font-weight: 700;
					font-family: $primary-font;
					margin-top: 5px;

				}

				.subtitle{
					@include font-rem(18px, 20px);
					font-family: $primary-font;
					font-weight: 500;
					margin-top: 5px;
			
				}
			}

			&-sticky-banner{
				background-color: transparent !important;

				.sticky-title{
					display: none;
				}
				.add-to-cart-container{
					display: none;
				}

				.container-sticky{
					display: none !important;
					@include media-breakpoint-down(sm){
						pointer-events: all;
						display: flex;
						flex-direction: row;
						white-space: nowrap;
						background: linear-gradient(0deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0.88) 100%), #FFF;
						width: 100%;
						height: 70px;
						border-radius: 0px;
						top: 119px;
						right: 0px;
						position: sticky;
						display: flex !important;
					}
				};

				.container-title{
					margin-left: 20px;
					@include media-breakpoint-down(sm){
						display: none;
					}
				}

				.sticky-title{
					color: var(--glo-2024-White, #FFF);
					font-family: Mont;
					font-size: 30px;
					font-style: normal;
					font-weight: 700;
					line-height: 100%; /* 30px */
					letter-spacing: -1px;
					text-transform: uppercase;

					@include media-breakpoint-down(sm){
						display: none;
					}
				}

				.sticky-color-container{
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 6px;
					.sticky-circle{
						width: 15px;
						height: 15px;
						border-radius: 99999999px;
						border: 2px solid white;
					}
					.sticky-color{
						color: var(--glo-2024-Warm-Grey, #E5E1E6);
						font-family: Mont;
						font-size: 14px !important;
						font-style: normal;
						font-weight: 500 !important;
						line-height: 100% !important; /* 14px */
						opacity: 0.6;
						@include media-breakpoint-down(sm){
							color: var(--glo-2024-Warm-Grey, #E5E1E6) !important;
							font-size: 10px !important;
							font-weight: 500 !important;
							line-height: 100% !important;
						}

					}
				}

				.add-to-cart-container{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					margin-left: 20px;
					margin-right: 20px;

					.add-to-cart-cta{
							border: none;
							padding-inline: 34px;
							max-width: 190px;
							max-height: 42px;
							display: flex;
							align-items: center;
							border-radius: 100px !important;
							background: var(--glo-2024-Accent-Orange, #FE5000) !important;

							span{
								color: var(--glo-2024-Black, #000) !important;
								font-family: Mont;
								font-size: 13px !important;
								font-style: normal;
								font-weight: 700;
								line-height: normal;
								text-transform: none;
								@include media-breakpoint-down(md){
									font-size: 10px;
								}
							}
					}
					@include media-breakpoint-down(sm){
						margin-left: 19px;
						width: 100%;
					}

					.price-container{
						display: flex;
						flex-direction: column;

						@include media-breakpoint-down(sm){
							.sticky-color-container{
								display: none;
								flex-direction: row;
								.sticky-circle{
									width: 15px;
									height: 15px;
									border-radius: 99999999px;
									margin-right: 8px;
								}
								.sticky-color{
									color: var(--glo-2024-Warm-Grey, #E5E1E6);
									@include font-rem(13px, 16px);
									font-weight: 600;
									//margin-top: -3px;
									margin-left: 0px;
								}
							}
						}
						.price{
							color: var(--glo-2024-White, #FFF);
							font-family: Mont;
							font-size: 30px;
							font-style: normal;
							font-weight: 700;
							line-height: 100%; /* 30px */
							letter-spacing: -1px;
							text-transform: uppercase;

							@include media-breakpoint-down(sm){
								//display: none;
							}
						}
						.total-price{
							display: none;
							@include media-breakpoint-down(sm){
								display: block;
								font-size: 13px;
								font-weight: 600;
								line-height: 17px;
								letter-spacing: 0px;
								text-align: left;
								color: #6B6B6B;
								;
							}
						}
					}
					
				}

				.quantity-counter{
					.quantity-label{
						color: var(--glo-2024-White, #FFF);
						font-family: Mont;
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: 115%; /* 16.1px */
						letter-spacing: 0.5px;
						text-transform: uppercase;

					@include media-breakpoint-down(sm){
						margin-right: 6px;
						font-size: 12px !important;
					}
					}

					.selected-color-label{
						color: var(--glo-2024-Warm-Grey, #E5E1E6);
						font-family: Mont;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 115%; /* 16.1px */
						letter-spacing: 0.5px;
						opacity: 0.6;
						@include media-breakpoint-down(sm){
							font-size: 12px !important;
						}
					}

					.counter{
						display: none;
						button{
							width: 28px;
							height: 28px;
						}
						.icon-plus,
						.icon-minus{
							background-color:  #2A2A2A;
							border-radius: 100%;
							color: $brand-black;
							font-weight: 700;
							padding: 5px;
							&:before{
								font-weight: 700;
								font-size: 18px;
							}
						}

						.counter-number{
							@include font-rem(15px, 14px);
							letter-spacing: 1.2px;
							font-weight: 700;
							font-family: $primary-font;
							margin-left: 16px;
							margin-right: 16px;
						}
					}

					@include media-breakpoint-down(sm){
						display: none ;
					}
				}

				.add-to-cart-cta{
						border: none !important;
						padding-inline: 34px;
						max-width: 190px;
						max-height: 42px;
						display: flex;
						align-items: center;
						border-radius: 100px !important;
						background: var(--glo-2024-Accent-Orange, #FE5000) !important;

						span{
							color: var(--glo-2024-Black, #000) !important;
							font-family: Mont;
							font-size: 13px;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
							text-transform: none;
							@include media-breakpoint-down(md){
								font-size: 10px;
							}
						}
				}
				
				&.uk-active{
					display: flex;
					//top: auto !important;
					bottom: 49px;
					background-color: $concrete;
					align-items: center;

					@media (min-width: 367px) {
						bottom: 37px;
					}

					@include media-breakpoint-down(sm){
						background-color: $white;
						display: block;
					}

					&.uk-sticky-below{
						.container-sticky{
							display: flex !important;
							flex-direction: row;
							white-space: nowrap;
							background: linear-gradient(0deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0.88) 100%), #FFF;
							width: 429px;
							height: 87px;
							border-radius: 30px;
							bottom: 55px;
							right: 8%;
							position: fixed;
							@include media-breakpoint-down(sm){
								width: 102%;
								height: 70px;
								border-radius: 0px;
								top: 119px;
								right: 0px;
								position: sticky;
							}
						};
					}

				}
			}

            &-container{
				justify-content: space-between;
                @include media-breakpoint-down(sm){
                    display: block;
                }

				#stickyBar{
					z-index: 979;
				}

                .bat-producthero-description{

					.bat-producthero-zoom-result{
						visibility: hidden;
						position: absolute;
						border: 2px solid #d4d4d4;
						/*set the size of the result div:*/
						
						background-color: white;
						background-repeat: no-repeat;
						z-index: 2;
						margin: 5%;
						margin-top:1%;

						@include media-breakpoint-down(md) {
							display: none;
						}

						@media screen and (max-width: 1024px) {
							height: 68vh;
							width: 68vh;
						}
						 
						@media screen and (min-width: 1025px) and (max-width: 1680px) {
							height: 68vh; 
							width: 68vh;
							background-size: 800px 1002px;
						}
						 
						@media screen and (min-width: 1681px) {
							height: 74vh; 
							width: 74vh;
							background-size: 800px 1400px;
						}
						 
					}

                    .descriptions{
                        margin: 100px;
                        margin-bottom: 0px !important;
					
						@include media-breakpoint-up(md){
                            width: 65%;
                        }

                        @include media-breakpoint-down(sm){
                            margin: 20px;
                        }

						.review-stars{
							align-items: center;

							.rating-summary-number{
                                color: var(--glo-2024-White, #FFF);
                                font-family: Mont;
                                font-size: 14.4px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
								margin-left: 10px;
							}

							.icon-star{
								width: 16px;
								height: 16px;
								&::before{
									font-size: 16px;
								}
							}

							.totalReviews{
								margin-left: 22.6px;
                                color: var(--glo-2024-White, #FFF);
                                text-align: center;
                                font-family: Mont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                display: flex;
                                align-items: center;
                                padding-left: 20px;
                                padding-right: 12px;
                                gap: 6px;
                                border-radius: 60px;
                                border: 2px solid var(--glo-2024-White, #FFF);
                                min-height: 36px;
							}
						}

                        .title{
                            color: var(--glo-2024-White, #FFF);
                            font-feature-settings: 'liga' off, 'clig' off;
                            font-family: Mont;
                            font-size: 36px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%; /* 43.2px */

                            @include media-breakpoint-down(sm){
                                font-size: 36px;
								margin-top: 14px;
                            }
                        }
						.hyperpro-icon-container{
							picture{
								width: 60%;
								padding: 10px 0 10px 0px;

							}
						}

						.blu-hog-container{
							width: 80%;
							height: 44px;
							background: #0103DF;
							display: flex;
							align-items: center;
							justify-content: space-around;
							margin-bottom: 5%;

							@include media-breakpoint-down(sm){
								width: auto;
								margin-left: -20px;
								margin-right: -20px;
								height: 65px;
								justify-content: flex-start;
							}

							span{
								color: #FFF;
								font-family: Mont;
								font-size: 15px;
								font-style: normal;
								font-weight: 600;
								line-height: 16px;
								padding: 0 20px;
							}
						}

                        .subtitle{
                            @include font-rem(20px, 20px);
                            font-family: $primary-font;
                            font-weight: 500;
                            margin-top: 20px;

                            @include media-breakpoint-down(sm){
                                @include font-rem(18px, 20px);
                            }
                        }

                        .listTitle{
                            @include font-rem(14px, 20px);
                            font-weight: 700;
                            font-family: $primary-font; 
                            margin-top: 30px;
                        }

                        .list{
                            margin-top: 20px;
                            .item{
                                @include font-rem(14px, 20px);
                                font-weight: 500;
                                font-family: $primary-font;
                                list-style: url('/content/dam/glo-it/images/pdp/gloitarrowright-pdp.svg');
								padding-left: 8px;
                            }
                        }

                        .engraving{
                            margin-top: 40px;
                            margin-bottom: 40px;
                            background-color: $concrete;

                            @include media-breakpoint-down(sm){
                                margin-top: 20px;
                                margin-bottom: 30px;
                            }

                            .engraving-link{
                                align-items: center;
								display: inline-flex;
    							text-align: inherit;

                                @include media-breakpoint-down(sm){
                                    display: block;
                                }

                                .container{
                                    border-right: 1px solid $light-gray;

                                    @include media-breakpoint-down(sm){
                                        border-right: none;
                                        border-bottom: 1px solid $light-gray;
                                    }
                                }

                                .text-container{
                                    @include media-breakpoint-down(sm){
                                        max-width: fit-content;
                                        margin: auto;
                                    }   
                                }

                                picture{
                                    max-width: fit-content;
                                    margin: 30px;

                                    @include media-breakpoint-down(sm){
                                        margin: 30px 20px;
                                    }
                                }

                                h3, p, span{
                                    @include font-rem(12px, 15px);
                                    font-family: $primary-font; 
                                }

                                p{
                                    color: $dark-gray;
                                }

                                h3{
                                    font-weight: 600;
                                    margin-bottom: 5px;
                                }

                                span{
                                    font-weight: 600;
                                    margin: 40px;
                                    color: $brand-black;
                                    text-decoration: none;
                                }
                            }
                        }

                        .quantity-counter{
							margin-top: 5px;
                            .quantity-label{
                                color: var(--glo-2024-White, #FFF);
                                font-family: Mont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 115%; /* 16.1px */
                                letter-spacing: 0.5px;
                                text-transform: uppercase;

							   @include media-breakpoint-down(sm){
								margin-right: 0px;
								font-size: 12px;
							   }

							   &.size{
								margin-right: 15px;
							   }

							   &.size-selected{
								   font-weight: 500;
								   font-size: 15px;
								   color: #646464;
							   }
                            }

                            .selected-color-label{
                                color: var(--glo-2024-Warm-Grey, #E5E1E6);
                                font-family: Mont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 115%; /* 16.1px */
                                letter-spacing: 0.5px;
                                opacity: 0.6;
								@include media-breakpoint-down(sm){
									font-size: 12px;
								}
                            }

                            .counter{
                                display: flex;
                                align-items: center;
								button{
									width: 28px;
									height: 28px;
								}
                                .icon-plus,
                                .icon-minus{
                                    background-color:  #2A2A2A;
                                    border-radius: 100%;
                                    color: $white;
                                    font-weight: 700;
                                    padding: 5px;
									&:before{
										font-weight: 700;
    									font-size: 18px;
									}
                                }

                                .counter-number{
                                    color: var(--glo-2024-White, #FFF);
                                    padding-inline:14px;
                                    text-align: center;
                                    font-family: Mont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 115%; /* 18.4px */
                                    text-transform: uppercase;
                                }
                            }
                        }

						.engraving-pro-container {	
							margin-top: 15px;
							display: none !important;
							@include media-breakpoint-down(sm) {
								flex-direction: column;
								align-items: flex-start;
								gap: 8px;
							}
	
							&-text {
								width: 38%;
	
								@include media-breakpoint-down(sm) {
									width: 100%;
								}
	
								&-title {
									color: var(--glo-2024-White, #FFF);
                                    font-family: Mont;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 115%; /* 16.1px */
                                    letter-spacing: 0.5px;
                                    text-transform: uppercase;
									@include media-breakpoint-down(sm) {
										font-size: 12px;
									}
								}
	
								&-description {
									p {
										color: var(--glo-2024-Warm-Grey, #E5E1E6);
                                        font-family: Mont;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 140%; /* 19.6px */
                                        opacity: 0.6;
										@include media-breakpoint-down(sm) {
											font-size: 12px;
										}
									}
								}
							}
	
							&-cta {

								width: 50%;
	
								@include media-breakpoint-down(sm) {
									width: 100%;
								}
	
								.bat-cta-style {
									gap: 8px;
									padding: 19px;
									max-width: 212px;
									width: 100%;
									max-height: 42px;
									display: flex;
									align-items: center;
									justify-content: center;
									border-radius: 100px;
									border: 2px solid var(--glo-2024-Accent-Orange, #FE5000);

                                    @include media-breakpoint-down(sm) {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        max-width: none;
                                        min-width: 100%;
                                    }

                                    span {
                                        color: var(--glo-2024-White, #FFF);
                                        text-align: center;
                                        font-family: Mont;
                                        font-size: 13px;
                                        font-style: normal;
                                        font-weight: 700;
                                        line-height: 120%; /* 15.6px */
                                        text-transform: none;
										@include media-breakpoint-down(md){
											font-size: 10px;
										}
                                    }
	
									&:hover {
										opacity: 0.8;
										
									}
	
									.bat-icon {
										width: 24px;
										height: 24px;
									}
								}
							}
						}

                        .gray-container{
                            padding: 17px;
                            border-radius: 30px;
                            margin-top: 30px;
                            background: linear-gradient(0deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0.88) 100%), #FFF;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;
                            @include media-breakpoint-down(sm){
                                display: none !important;
                            }
                            .price-container{
                                align-items: flex-start;
                                width: 98%;
                                .price{
                                    color: var(--glo-2024-White, #FFF);
                                    font-family: Mont;
                                    font-size: 30px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 100%; /* 30px */
                                    letter-spacing: -1px;
                                    text-transform: uppercase;
                                }
                                .bat-cta-style.add-to-cart-cta{
										border: none !important;
                                        padding-inline: 34px;
                                        margin-left: 10px;
                                        max-height: 42px;
                                        display: flex;
                                        align-items: center;
                                        border-radius: 100px !important;
                                        background: var(--glo-2024-Accent-Orange, #FE5000) !important;
    
                                        span{
                                            color: var(--glo-2024-Black, #000) !important;
                                            font-family: Mont;
                                            font-size: 13px;
                                            font-style: normal;
                                            font-weight: 700;
                                            line-height: normal;
											text-transform: none;
											@include media-breakpoint-down(md){
												font-size: 10px;
											}
                                        }
                                }
                            }
                        }

						.insiders-container{
							.insiders-intro, .insiders-title{
								color: white !important;
							}

						}


						.insiders-container {
							margin-top: 16px;
							.insiders-intro {
								span {
									color: var(--glo-2024-Warm-Grey, #E5E1E6);
									font-size: 12px;
									font-style: normal;
									font-weight: 600;
									line-height: 140%; 
									opacity: 0.6;
								}
							}

							.insiders-title {
								span {
									color: var(--glo-2024-White, #FFF);
									font-size: 14px;
									font-style: normal;
									font-weight: 700;
									line-height: 140%; /* 19.6px */
									letter-spacing: 0.5px;
								}
							}

							.insiders-amount {
								margin-top: 8px;
								font-weight: 700;
								font-size: 12px;
								line-height: 14px;
								letter-spacing: 1.2px;
								text-transform: uppercase;
								color: #CD4A04;
							}

							.insiders-info {
								border-radius: 24px;
								background: var(--glo-2024-glo-Charcoal, #2A2A2A);
								padding: 20px;
								margin-top: 18px;
								p{
									color: var(--glo-2024-White, #FFF);
									font-size: 14px;
									font-style: normal;
									font-weight: 400;
									line-height: 140%;
									letter-spacing: 0.5px;
								}
							}
						}
                    }

					.delivery-info-banner{
						background-color: $concrete;
						align-items: center;
						padding: 25px;
						padding-left: 100px;

						@include media-breakpoint-down(sm){
							padding-left: 25px;
						}

						.icon-delivery{
							width: 34px;
							height: 20px;
							margin-right: 16px;
						}

						.title{
							@include font-rem(12px, 14px);
							letter-spacing: 1.2px;
							font-weight: 700;
						}

						.subtitle{
							@include font-rem(12px, 17px);
							font-weight: 500;
						}
					}
                }
            }

			.bat-producthero-content {
				background-color: black;
				margin-bottom: 0px;
				width: 100%;
				padding-bottom: 0px;
				@include media-breakpoint-down(sm) {
					padding-bottom: 0;
				}


                .scrolling-buttons{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    padding-inline: 13%;
                    @include media-breakpoint-down(sm) {
                        padding-inline: 6%;
                    }

                    .scrolling-buttons-content{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        .scrolling-buttons-content {
                        pointer-events: all;
                        cursor: pointer; 
                        z-index: 1000; 
                        }

                        span{
                            text-decoration-line: underline;
                            color: var(--glo-2024-White, #FFF);
                            font-family: Mont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }
                }
			}

			.bat-producthero-gallery {
				background-color: black;
				display: flex;
				flex-direction: column;
				margin-bottom: 0px;
				padding-left: 40px;

				@include media-breakpoint-down(sm) {
					margin-top: -42px;
					padding-left: 0px;
				}
				.slick-dots {
					display: none !important; // sass-lint:disable-line no-important
				}

				.slick-arrow {
					background-color: $dark-gray-50;
					border-radius: 30px;
					color: transparent;
					font-size: 0;
					height: 30px;
					margin-top: -15px;
					position: absolute;
					top: 50%;
					width: 30px;

					&:before {
						color: $white;
						content: "";
						display: inline-block;
						font-size: 20px;
						vertical-align: middle;
					}

					&.slick-prev {
						left: 100px;
						@include media-breakpoint-down(sm) {
							left: 30px;
						}

						&:before {
							content: "\e924";
							font-family: "icomoon";
						}
					}

					&.slick-next {
						right: 100px;
						@include media-breakpoint-down(sm) {
							right: 30px;
						}
						&:before {
							content: "\e928";
							font-family: "icomoon";
						}
					}
				}

				&-images {
					line-height: 0;
					min-height: 300px;
					@include media-breakpoint-up(sm) {
						min-height: 600px;
					}
					display: flex;
					flex-direction: column;
					justify-content: center;
					.large {
						background-position: calc(
								-40px + (-25 - (-40)) * ((100vw - 320px) /
											(575 - 320))
							)
							center;
						// ----------------| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
						background-repeat: no-repeat;
						background-size: contain;
						img {
							display: block;
							height: auto;
							margin: 0 auto;
							max-height: unset;
							position: relative;
							top: 2px;
							width: calc(
								225px + (345 - 225) *
									((100vw - 320px) / (575 - 320))
							);
							@include media-breakpoint-down(sm) {
								max-height: 300px;
								max-width: calc(
									225px + (345 - 225) *
										((100vw - 320px) / (575 - 320))
								);
								width: 318px;
							}
						}
						iframe {
							width: 100%;
							height: 150px;
							position: relative;
							border: 0;
							border-radius: 5px;
						}
					}
				}

				&-thumbs {
					display: none;
					flex-wrap: wrap;
					margin-top: 20px;
					padding: 0;

					.thumb {
						background-color: $white;
						background-position: 0 center;

						background-repeat: no-repeat;
						background-size: auto 65%;
						border-top: 4px solid $white;
						height: 95px;
						margin: 2.5px;
						width: 95px;

						&.active {
							border-top: 4px solid $light-blue;
							outline: none;
						}

						&.hide {
							visibility: hidden;
						}

						img {
							display: block;
							margin: 0 auto;
							width: auto;
						}
					}
				}
			}

			.bat-producthero-configurator {
				display: flex;
				flex-direction: column;
				padding: 0;
				width: 100%;

				& > * {
					padding-left: 20px;
					padding-right: 20px;
				}
				.bat-producthero-strengths-indicator-line, .bat-producthero-strengths-group {
					margin-left: 20px;
					margin-right: 20px;
				}

				&-bottom {
					flex-direction: column;
					justify-content: flex-start;
					margin-bottom: 43px;
					padding: 0 20px;
					text-align: left;
				}

				&-cta-list {
					display: flex;
					flex-direction: column;
					flex: 1 1 auto;
					margin-left: auto;
					align-items: center;
					width: 100%;
					button, a {
						max-width: 300px;
						padding: 11px 50px;
						width: fit-content;
					}
					button {
						display: none;
						&.active {
							display: flex;
						}
					}
					a {
						display: flex;
					}
					button, a {
						&:not(:last-child) {
							margin-bottom: 0.5em;
						}
					}
				}

				&-price {
					flex: 0 1 auto;
					margin-top: 10px;
					margin-bottom: 10px;
					text-align: left;

                    .formatted-price {
                        color: var(--glo-2024-White, #FFF);
                        font-family: Mont;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%; /* 30px */
                        letter-spacing: -1px;
                        text-transform: uppercase;
                    }
				}

				&-subheading {
					@include font-rem(20px, 28px);
					font-weight: 500;
					letter-spacing: 1.5px;
					margin-bottom: 8px;
					padding-bottom: 0;
					padding-top: 0;
					text-align: left;
					text-transform: capitalize;
				}

				&-short-description {
					h6 {
						@include font-rem(14px, 14px);
						font-weight: 700;
						margin-bottom: 15px;
					}
				}

				&-description {
					@include font-rem(14px, 20px);
					color: $dark-gray;

					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						color: inherit;
						font-size: inherit;
						line-height: inherit;
						margin-bottom: 7px;
					}

					p {
						color: inherit;
						font-size: inherit;
						line-height: inherit;
						margin-bottom: 0;
						&:not(:last-of-type) {
							margin-bottom: 5px;
						}
					}

					ul {
						margin-bottom: 15px;

						li {
							color: inherit;
							font-size: inherit;
							line-height: inherit;
						}
					}
				}

				&-stock {
					display: block;
					font-weight: 700;
					margin-bottom: 20px;
				}

				&-subscription {
					background-color: $concrete;
					margin: 0 auto;
					max-width: 325px;

					&-text {
						@include font-rem(12px, 12px);
						margin-top: 2px;

						.formatted-price {
							color: var(--glo-2024-White, #FFF);
                            font-family: Mont;
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%; /* 30px */
                            letter-spacing: -1px;
                            text-transform: uppercase;
						}

						&-note {
							@include font-rem(12px, 12px);
							display: inline-block;
							font-weight: normal;
							margin: 7px 0 0;
							text-transform: none;
						}

						.discount-savings {
							@include font-rem(12px, 12px);
							background-color: $dark-blue;
							color: $white;
							display: inline-block;
							padding: 2px 5px;
						}
					}

					&-max-message {
						margin-top: 0;
					}

					&-details {
						button {
							color: $dark-blue;
							font-weight: 700;
						}
					}

					fieldset {
						padding: 0;

						label {
							align-content: flex-start;
							align-items: flex-start;
							height: auto;
							min-height: 29px;

							input {
								margin-top: 2px;
							}
						}
					}
				}
				&-configurable-options {
					display: flex;
					flex-direction: row;
					text-align: left;
                    align-items: center;
					width: fit-content;
                    min-height: 48px;
                    padding-inline: 11.5px;
					border-radius: 80px;
					background: #4D4D4D;
                    gap: 17px;
                    margin-top: 34px;
                    @include media-breakpoint-down(sm) {
                        gap: 2px;
                    }


					.size-container{
						border-radius: 100%;
						margin-right: 2px;
						margin-top: 5px;
						border: 1px solid transparent;


						

						.size{
							border-radius: 100%;
							width: 30px;
							position: relative;
							height: 30px;
							background-color: $concrete;
							color: $dark-gray;
							font-weight: 600;
							font-size: 12px;
							font-family: $primary-font;
							margin: 2px;
			
							&:hover{
								border: 1px solid $brand-black;
								color: $brand-black;
							}
				
							&:active{
								background-color: $white;
								box-shadow: 0 0 3px #1d1d1d;
								border: 1px solid $white;
								background-color: $white;
								color: $brand-black;
								
							}
							&:disabled{
								&:hover{
									border: 0;
									color: $dark-gray;
								}
								&:active{
									background-color: $concrete;
									color: $brand-black;
									box-shadow: 0 0 0 #1d1d1d;
									color: $dark-gray;
								}
								&:after {
									height:23px;
									width:26px;
									background: linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -moz-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -ms-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -o-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									background: -webkit-gradient(linear,left top,right bottom,color-stop(0%,rgba(255,255,255,0)),color-stop(42%,rgba(255,255,255,0)),color-stop(43%,#fff),color-stop(46%,#fff),color-stop(47%,#ff5216),color-stop(53%,#ff5216),color-stop(54%,#fff),color-stop(57%,#fff),color-stop(58%,rgba(255,255,255,0)),color-stop(100%,rgba(255,255,255,0)));
									background: -webkit-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
									bottom: 0;
									content: '';
									
									left: 0;
									position: absolute;
									right: 0;
									top: 0;
								}
							}
						}

						&.active{
							.size{
								border: 1px solid $brand-black;
								color: $brand-black;
							}
						}

					}

					&-title {
						@include font-rem(12px, 16px);
						font-weight: 700;
						letter-spacing: 0;
						margin-top: 5px;
						margin-bottom: 5px;
					}
					
					&-variants-button {
						$button-size: 34px;
						height: $button-size;
						width: $button-size;
						border-radius: 99999999px;
						padding: 4px;
						position:relative;
						&:after {
							content: "";
							background-color: var(--button-variant-color);
							display: block;
							width: 100%;
							height: 100%;
							border-radius: 99999999px;
						}
						&:active,
						&.active {
							background-color: white;
						}
						&:disabled{
							
							background-color: var(--button-variant-color);
							border: 4px solid $white;
							&:active{
								border: 0;
							}
							&:after {
								background: linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
								background: -moz-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
								background: -ms-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
								background: -o-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
								background: -webkit-gradient(linear,left top,right bottom,color-stop(0%,rgba(255,255,255,0)),color-stop(42%,rgba(255,255,255,0)),color-stop(43%,#fff),color-stop(46%,#fff),color-stop(47%,#ff5216),color-stop(53%,#ff5216),color-stop(54%,#fff),color-stop(57%,#fff),color-stop(58%,rgba(255,255,255,0)),color-stop(100%,rgba(255,255,255,0)));
								background: -webkit-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
								bottom: 0;
								content: '';
								
								left: 0;
								position: absolute;
								right: 0;
								top: 0;
							}
						}
					}

					p {
						display: none;
					}
				}

				&-title {
					@extend h3;
					@include font-rem(32px, 32px);
					letter-spacing: -1.25px;
					margin-bottom: 6px;
					max-width: unset;
					padding-bottom: 0;
					padding-top: 0;
				}

				&-options {
					z-index: 1;

					&-option {
						flex: 0 1 auto;
						margin-right: 32px;
						padding: 0 0 10px;
						text-align: center;

						p {
							@include font-rem(14px, 14px);
							font-weight: 700;
							margin-bottom: 5px;
						}

						&-input {
							.select-wrapper {
								display: inline-block;

								select {
									@include font-rem(13px, 13px);
									font-weight: 700;
								}
							}

							fieldset {
								label {
									cursor: pointer;
									display: inline-flex;
									margin-bottom: 0;
									margin-right: 5px;

									input {
										&[type="radio"] {
											display: none;

											+ span {
												@include font-rem(13px, 13px);
												background-color: $concrete;
												color: $dark-blue;
												display: block;
												padding: 14px;
											}

											&:checked {
												+ span {
													@include font-rem(
														13px,
														13px
													);
													background: $dark-blue;
													color: $white;
													display: block;
													font-weight: 700;
													padding: 14px;
												}
											}

											&:disabled {
												+ span {
													position: relative;

													&::after {
														background: $dark-blue;
														content: "";
														height: 2px;
														left: -5px;
														position: absolute;
														top: 46%;
														transform: rotate(
															-34deg
														);
														width: 114%;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.bat-quantity {
					margin-bottom: 26px;
					padding-bottom: 0;
					padding-top: 0;
					&-label {
						@extend h5;
						@extend .headline5-small;
						@include font-rem(12px, 15px);
						flex: 0 1 140px;
						letter-spacing: 1px;
					}
					.bat-quantity-button {
						flex: 0 0 32;
						height: 32px;
						min-width: 32px;
					}
				}

				.bat-producthero-deliveryinfo {
					align-items: center;
					background-color: $white;
					display: flex;
					padding: 24px 20px;
					margin-top: auto;
					margin-bottom: 23px;
					&-icon {
						padding-left: 4px;
						padding-right: 14px;
						.bat-icon {
							height: 34px;
							width: 34px;
							&::before {
								font-size: 34px;
							}
						}
					}
					&-text {
						.heading {
							@extend h5;
							@extend .headline5-small;
							@include font-rem(12px, 15px);
							display: block;
							letter-spacing: 1px;
							margin-bottom: 4px;
						}
						p {
							@include font-rem(12px, 14.4px);
							color: $dark-gray;
							letter-spacing: 0.25px;
							margin: 0;
						}
					}
				}

				.bat-producthero-desktop {
					display: none;
					padding-bottom: 0;
					padding-top: 0;
				}

				.bat-producthero-strengths {
					&-group {
						margin-left: 10%;
						margin-right: 10%;
						margin-bottom: 12px;
						padding: 0;
						&-indicators {
							display: flex;
							flex-direction: column;
							> .bat-producthero-strengths-indicator-line:not(:last-child) {
								margin-right: 40px;
							}
						}
						.bat-producthero-strengths-indicator-line {
							margin: 0;
						}
						&-top-bar {
							display: flex;
							flex-direction: row;
							align-items: center;
							margin-bottom: 12px;

							span {
								margin-right: 13px;
								font-family: $primary-font;
								@include font-rem(12px, 115%);
								letter-spacing: 1.2px;
								font-weight: bold;
								width: 14ch;
								flex-shrink: 0;
							}

							hr {
								width: 100%;
								height: 0;
								background-color: $light-gray;
								border: none;
								border-top: 2px solid $light-gray;
								margin-top: 0;
								margin-bottom: 0;
							}
						}
					}
					
					&-indicator-line {
						display: flex;
						flex-direction: row;
						align-items: center;
						flex-wrap: wrap;
						margin-bottom: 25px;
						margin-left: 10%;
						margin-right: 10%;
						padding: 0;
					}

					&-container {
						display: flex;
						flex-wrap: wrap;
						
						.bat-indicator {
							@include variable(--indicator-active-color, $brand-black);
							@include variable(--indicator-active-last-color, $brand-black);
							border: none;
							align-items: baseline;
							flex-direction: row;
							font-family: $primary-font;
							justify-content: center;
							margin: 0;
							padding: 0;
							width: auto;
							$size: 13px;
							ul {
								li {
									width: $size;
									height: $size;
									line-height: $size;
									font-size: $size;
									border-color: $brand-black;
								}
							}

							&.strength-4mg,
							&.strength-mild,
							&.strength-1 {
								li {
									&:nth-child(1) {
										background: var(--indicator-active-color);
									}
								}

								&.active {
									li {
										&:nth-child(1) {
											background: $white;
										}
									}
								}
							}

							&.strength-6mg,
							&.strength-medium,
							&.strength-2 {
								li {
									&:nth-child(-n + 2) {
										background: var(--indicator-active-color);
									}
									&:nth-child(2) {
										background: var(--indicator-active-last-color);
										border: none;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 2) {
											background: $white;
										}
									}
								}
							}

							&.strength-10mg,
							&.strength-strong,
							&.strength-3 {
								li {
									&:nth-child(-n + 3) {
										background: var(--indicator-active-color);
									}
									&:nth-child(3) {
										background: var(--indicator-active-last-color);
										border: none;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 3) {
											background: $white;
										}
									}
								}
							}

							&.strength-16mg,
							&.strength-x-strong,
							&.strength-4 {
								li {
									&:nth-child(-n + 4) {
										background: var(--indicator-active-color);
									}
									&:nth-child(4) {
										background: var(--indicator-active-last-color);
										border: none;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 4) {
											background: $white;
										}
									}
								}
							}

							&.strength-18mg,
							&.strength-ultra,
							&.strength-5 {
								li {
									&:nth-child(-n + 5) {
										background: var(--indicator-active-color);
									}
									&:nth-child(5) {
										background: var(--indicator-active-last-color);
										border: none;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 5) {
											background: $white;
										}
									}
								}
							}

							&.strength-20mg,
							&.strength-max,
							&.strength-6 {
								li {
									&:nth-child(-n + 6) {
										background: var(--indicator-active-color);
									}
									&:nth-child(6) {
										background: var(--indicator-active-color);
										border: none;
									}
								}

								&.active {
									li {
										&:nth-child(-n + 6) {
											background: $white;
										}
									}
								}
							}
							span {
								@include font-rem(12px, 12px);
								color: $brand-black;
								font-weight: 700;
								margin-left: 10px;
								margin-bottom: 0;
							}
						}
					}
					&-label {
						display: inline-flex;
						flex-direction: row;
						align-items: center;
						margin: 0;
						margin-inline-end: 10px;
						font-weight: normal;
						@include font-rem(13px, 185%);
						width: fit-content;
						&--bold {
							font-weight: bold;
						}
						&-popover-button {
							.bat-icon:before {
								@include font-rem(19px, 100%);
							} 
						}
						&-popover-content {
							position: absolute;
							background-color: $charcoal;
							color: $white;
							padding: 15px;
							transform: translateX(50%);
							width: 200px;
							$triangle-size: 10px;
							display: none;
							margin-top: $triangle-size;
							z-index: 999;
							font-family: $primary-font;
							font-weight: normal;
							@include font-rem(10px, 150%);

							&[data-show] {
								display: block;
							}

							&-arrow {
								visibility: hidden;

								&, &::before {
									position: absolute;
									width: $triangle-size;
									height: $triangle-size;
									background: inherit;
								}

								&::before {
									visibility: visible;
									content: '';
									transform: rotate(45deg);
								}
							}

							&[data-popper-placement^='top'] > .bat-producthero-strengths-label-popover-content-arrow {
								bottom: #{-($triangle-size / 2)};
							}
						
							&[data-popper-placement^='bottom'] > .bat-producthero-strengths-label-popover-content-arrow {
								top: #{-($triangle-size / 2)};
							}
					
							&[data-popper-placement^='left'] > .bat-producthero-strengths-label-popover-content-arrow {
								right: #{-($triangle-size / 2)};
							}
					
							&[data-popper-placement^='right'] > .bat-producthero-strengths-label-popover-content-arrow {
								left: #{-($triangle-size / 2)};
							}

							p {
								display: block;
								font-size: inherit;
								line-height: inherit;
								font-family: inherit;
								font-weight: inherit;
							}
						}
					}
				}
			}

			.bat-producthero-mobile {
				display: block;
				margin-bottom: 31px;
				padding: 40px 20px 0;
				&-lower {
					display: block;
				}
			}
		}
		&-skuerror {
			@include font-rem(16px, 28px);
			margin-bottom: 20px;
		}
		.zoom-lens {
			visibility: hidden;
			position: absolute;
			border: 1px solid #d4d4d4;
			/*set the size of the lens:*/
			width: 150px;
			height: 150px;
			z-index: 1;
		}

		.rating {
			display: inline-block;
			unicode-bidi: bidi-override;
			color: #dedede;
			font-size: 15px;
			letter-spacing: 2px;
			height: 23px;
			width: auto;
			margin: 0;
			position: relative;
			padding: 0;
			font-family: FontAwesome;
		}
		  
		.rating-upper {
			color: #ffbe0c;
			padding: 0;
			position: absolute;
			z-index: 1;
			display: flex;
			top: 0;
			left: 0;
			overflow: hidden;
			&::before {
				content: "\f005 \f005 \f005 \f005 \f005";
			}
		}
		  
		.rating-lower {
			padding: 0;
			display: flex;
			z-index: 0;
			&::before {
				content: "\f005 \f005 \f005 \f005 \f005";
			}
		}

		.bundle-container {
			max-width: 1400px;
    		margin: 0 auto;
			padding: 20px;

			.bundle-section {
				position: relative;

				@media (max-width: 959px) {
					display: grid;
				}

				.bundle-selection {
					@media (max-width: 959px) {
						margin-bottom: 147px;
						grid-row: 1;
  						grid-column: 1;
					}
				}

				.bundle-title {
					font-size: 14px;
					line-height: 18px;
					letter-spacing: 1.2px;
					font-weight: 700;
					font-family: "Mont", sans-serif;
					margin-bottom: 10px;
				}

				.bundle-content {
					background-color: #efefef;
					padding: 20px;
					border-radius: 20px;
					gap: 10px;
					margin-top: 0;

					@include media-breakpoint-down(sm) {
						padding: 10px;
					}

					.bundle {
						label {
							gap: 15px;
							margin: 0;
							> input[type="checkbox"] {
								margin-right: 5px !important;

								&:before {
									background-color: #efefef;
								}
							}

							&.disabled-field {
								opacity: 0.5;
							}
						}

						.bundle-image {
							max-width: 12%;
							img {
								background-color: $white;
								border-radius: 10px;
							}
						}

						.bundle-name {
							font-size: 14px;
							line-height: 18px;
						}
					}

					.bundle-separator {
						border-bottom: 2px solid #dedede;
						width: 100%;
						height: 0;
					}
				}
			}

			.bundle-price {
				
				@media (max-width: 959px) {
					height: 100%;
					pointer-events: none;
					grid-row: 1;
					grid-column: 1;
					justify-content: flex-end;
				}

				.bundle-sticky-price {
					z-index: 1;
					background-color: $white;

					@media (max-width: 959px) {
						bottom: 49px;
						top: unset !important;
						pointer-events: all;
					}
				}
				.quantity-counter{
					.quantity-label{
                        color: var(--glo-2024-White, #FFF);
                        font-family: Mont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 115%; /* 16.1px */
                        letter-spacing: 0.5px;
                        text-transform: uppercase;

					   @include media-breakpoint-down(sm){
						margin-right: 0px;
					   }

					   &.size{
						margin-right: 15px;
					   }

					   &.size-selected{
						   font-weight: 500;
						   font-size: 15px;
						   color: #646464;
					   }
					}

					.counter{
						button{
							width: 28px;
							height: 28px;
						}
						.icon-plus,
						.icon-minus{
							background-color: #2A2A2A;
							border-radius: 100%;
							color: $white;
							font-weight: 700;
							padding: 5px;
							&:before{
								font-weight: 700;
								font-size: 18px;
							}
						}

						.counter-number{
							@include font-rem(15px, 14px);
							letter-spacing: 1.2px;
							font-weight: 700;
							font-family: $primary-font;
							margin-left: 16px;
							margin-right: 16px;
						}
					}
				}

				.price-container{
					margin-top: 30px;
					align-items: center;

					@include media-breakpoint-down(sm){
						margin-top: 20px;
						justify-content: space-between;
					}
					.price{
						@include font-rem(30px, 32px);
						font-weight: 700;
						font-family: $primary-font;
					}
                    .add-to-cart-cta{
                        @include media-breakpoint-down(sm){
                            padding-inline: 34px;
                            margin-left: 10px;
                            max-height: 42px;
                            display: flex;
                            align-items: center;
                            border-radius: 100px !important;
                            background: var(--glo-2024-Accent-Orange, #FE5000) !important;

                            span{
                                color: var(--glo-2024-Black, #000) !important;
                                font-family: Mont;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
								text-transform: none;
                            }

                        }
                    }
				}

				.insiders-container {
					margin-top: 16px;
					.insiders-intro {
						span {
							@include font-rem(14px, 20px);
						}
					}

					.insiders-title {
						margin-top: 12px;
						span {
							font-weight: 600;
							font-size: 20px;
							line-height: 28px;
						}
					}

					.insiders-amount {
						font-weight: 700;
						font-size: 12px;
						line-height: 14px;
						letter-spacing: 1.2px;
						text-transform: uppercase;
						color: #CD4A04;
					}

					.insiders-info {
						background: #D5DDEC;
						border-radius: 8px;
						padding: 10px;
						margin-top: 12px;
						font-weight: 400;
						font-size: 14px;
						line-height: 20px;
						color: #2F539F;
					}
				}
			}
		}
	}

	.shop-section-sticky-placeholder {
		padding-top: 140px;
	}
	
	.shop-section-sticky {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(45deg, #5482C2 0, #353174 100%);
		box-shadow: 0px -2px 8px rgba(255, 255, 255, 0.04);
		border-radius: 20px 20px 0px 0px;
		color: $white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 20px;
		gap: 16px;

		.quantity-counter {
			width: 100%;

			.requantity-label {
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
			}
			.counter{
				.icon-minus,
				.icon-plus {
					color: white !important;
					background: unset !important;
					border: 2px solid #FFFFFF;
					display: flex;
					align-items: center;
					justify-content: center;			
				}
			}
		}

		.price-container {
			width: 100%;
			padding-top: 20px !important;
			margin-top: 0 !important;
			border-top: 1px solid #5F73BA;
		}
	}


.mobile-popup-cart {
	border-radius: 40px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0.88) 100%), #FFF;
	margin-inline: auto;
	max-width: 335px;
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    transition: bottom 0.3s ease;
}

.mobile-popup-cart-content {
    display: flex;
    flex-direction: column;
}

.mobile-popup-cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

	h3{
		color: var(--glo-2024-Warm-Grey, #E5E1E6);
		font-family: Mont;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 120%; /* 16.8px */
	}
}

.mobile-popup-cart-items{
	border-radius: 10px;
	background: var(--glo-2024-glo-Charcoal, #2A2A2A);
	padding: 10px;
	gap: 6px;
	align-items: center;

	.mobile-popup-product-image{
		max-width: 50px;
	}
}

.mobile-popup-cart-item-details{
	.title-price-container{
		margin-bottom: 4px;
		display: flex;
		align-items: center;
		gap: 22px;
		color: var(--glo-2024-White, #FFF);
		font-family: Mont;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 14px */
	}

	.mobile-popup-cart-item-color, .mobile-popup-cart-item-quantity{
		color: var(--glo-2024-Warm-Grey, #E5E1E6);
		font-family: Mont;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%; /* 18px */
		opacity: 0.8;
	}
}

.mobile-popup-close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.mobile-popup-cart-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

	.mobile-popup-view-cart{
		margin-bottom: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-inline: 34px;
		min-width: 100%;
		min-height: 42px;
		border-radius: 100px;
		background: var(--glo-2024-Accent-Orange, #FE5000);
		color: var(--glo-2024-Black, #000);
		font-family: Mont;
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
}

.icons-container{
	display: flex;
	gap: 6px;
	width: fit-content;
	margin-inline: auto;
}
}

@include media-breakpoint-up(sm) {
	bat-producthero-gloitredesign{
		.bat-producthero {
			&-gloit {
				.bat-producthero-configurator {
					&-price {
						flex: 0 1 140px;
					}
					&-bottom {
						flex-direction: row;
						margin-bottom: 0;
						margin-top: 0;
					}
					&-cta-list {
						align-items: flex-end;
						width: unset;
					}
					.bat-producthero-strengths-group-indicators {
						flex-direction: row;
					}
					.bat-producthero-strengths-group-top-bar {
						flex-direction: column-reverse;
						align-items: flex-start;
						span {
							margin-top: 12px;
						}
					}
				}
				.bat-producthero-gallery {
					&-images {
						// calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
						.large {
							// ----------------| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
							background-position: calc(
									-25px + (10 - (-25)) * ((100vw - 576px) /
												(767 - 576))
								)
								center;

							/* sasslint-disable-next-line nesting-depth */
							img {
								width: calc(
									350px + (415 - 350) *
										((100vw - 576px) / (767 - 576))
								);
							}
							iframe {
								height: 160px;
							}
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	bat-producthero-gloitredesign{
		.bat-producthero {
			&-gloit {
				background-color: $black;
				padding: 0;

				.bat-producthero-content {
					width: 50%;
				}

				.bat-producthero-description {
					width: 50%;
				}

				.bat-producthero-gallery {
					padding: 0 0 16px;

					&-images {
						.large {
							// ----------------| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
							background-position: calc(
									-60px + (-85 - (-60)) * ((100vw - 768px) /
												(991 - 768))
								)
								center;

							padding: 12px 0;
							img {
								width: calc(
									260px + (350 - 260) *
										((100vw - 768px) / (991 - 768))
								);
							}
							iframe {
								height: 180px;
							}
						}
					}

					&-thumbs {
						display: flex;
						margin-top: 0;
					}

					.slick-arrow {
						margin-top: 0px;

						&.slick-prev {
							left: 100px;
						}

						&.slick-next {
							right: 100px;
						}
					}
				}

				.bat-producthero-configurator {
					padding: 45px 0 0;
					position: relative;
					width: 48%;

					& > * {
						padding-left: 10%;
						padding-right: 10%;
					}

					&-bottom {
						margin-bottom: 0;
						.bat-cta-style {
							&.button-dark,
							&.button-dark-disabled,
							a {
								flex-basis: 50%;
								padding: 11px 50px;
							}
						}
					}

					&-title {
						@include font-rem(40px, 36px);
						letter-spacing: -0.5px;
						margin-bottom: 13px;
						padding: 0;
					}

					&-subheading {
						letter-spacing: 0.05px;
						margin-bottom: 14px;
						padding: 0;
					}

					&-price {
						@include font-rem(30px, 32px);
						flex-basis: 167px;
						letter-spacing: 0.25px;
						margin-bottom: 0;
						text-align: left;
						margin-top: 0px
					}

					&-short-description {
						h6 {
							@include font-rem(20px, 20px);
						}
					}

					&-description {
						font-size: 0.94rem;
						line-height: 16px;
						margin-bottom: 0;
					}

					&-subscription {
						margin: 0;
						max-width: none;
						padding: 20px;
					}

					&-options {
						&-option {
							padding: 0 0 20px;
							text-align: left;
						}
					}

					.bat-producthero-deliveryinfo {
						bottom: 23px;
						left: 0;
						padding: 26px 5.105vw;
						width: 100%;
						&-icon {
							padding-left: 0;
							padding-right: 8px;

							.bat-icon {
								height: 41px;
								width: 41px;
								&::before {
									font-size: 41px;
								}
							}
						}
						&-text {
							.heading {
								letter-spacing: 1.25px;
								margin-bottom: 5px;
							}
						}
					}

					.bat-producthero-desktop {
						display: block;
						margin-bottom: 35px;
					}

					.bat-quantity {
						margin-bottom: 34px;
						&-label {
							flex-basis: 150px;
						}
					}

					.bat-producthero-strengths {
						margin-bottom: 28px;
						&-buttons {
							margin-bottom: 0;
						}
						&-label {
							letter-spacing: 0.95px;
						}
					}
				}

				.bat-producthero-mobile {
					display: none;
					&-lower {
						display: none;
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	bat-producthero-gloitredesign{
		.bat-producthero {
			&-gloit {
				.bat-producthero-gallery {
					&-images {
						.large {
							background-position: -85px center;
							img {
								// --| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
								width: calc(
									350px + (415 - 350) *
										((100vw - 576px) / (767 - 576))
								);
							}
							iframe {
								height: 250px;
							}
						}
					}
				}

				.bat-producthero-configurator {
					padding: 65px 0 0;

					&-options {
						form {
							flex-direction: row;
						}
					}

					&-bottom {
						flex-direction: row;
						text-align: left;
						.bat-quantity {
							justify-content: flex-start;
							margin: 0 20px 0 0;
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {
    bat-producthero-gloitredesign{
		.bat-producthero {
			&-gloit {
				.bat-producthero-gallery {
					&-images {
						//min-height: 200px; // -----| Prevent flickering when loading new images into carousel
						.large {
							background-position: calc(
									-90px + (-25 - (-90)) * ((100vw - 1200px) /
												(1439 - 1200))
								)
								center;
							// ----------------| calc(minSize + (maxSize - minSize) * ((100vw - minViewport) / (maxViewport - minViewport)));
							img {
								width: 500px;
							}
							iframe {
								height: 300px;
							}
						}
					}
				}

				.bat-producthero-configurator {
					padding: 65px 0 0;

					&-options {
						form {
							flex-direction: row;
						}
					}

					&-bottom {
						flex-direction: row;
						text-align: left;
						.bat-quantity {
							justify-content: flex-start;
							margin: 0 20px 0 0;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1440px) {
    bat-producthero-gloitredesign{
		.bat-producthero {
			&-gloit {
				.bat-producthero-content {
					.bat-producthero-gallery {
						&-images {
							.large {
								background-position: -25px center;
								iframe {
									height: 330px;
								}
								img{
									width: 440px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1700px) {
    bat-producthero-gloitredesign{
		.bat-producthero {
			&-gloit {
				.bat-producthero-content {
					.bat-producthero-gallery {
						&-images {
							.large {
								background-position: -25px center;
								iframe {
									height: 400px;
								}
								img{
									width: 500px;
								}
							}
						}
					}
				}
			}
		}
	}
}
