bat-text-default.health-warning{
	.bat-text{
		z-index: 10001;
	}
}

bat-form-gloithyperproanimation{
	&.pdp-hyper-core{
		.pin-spacer {
			background-color: white;
		}
		.bat-hyperproanimation-gloit {
			.full-animation-container {
				max-height: none !important;
				height: 100% !important;
			}
			.popup-overlay {
				width: 100%;
				height: 100vh;
			}
			.popup-container {
				height: 450px;
				@include media-breakpoint-down(md) {
					height: 550px;
					.popup-content {
						p {
							margin-bottom: 48px;
						}
					}
				}
			}
		
			.popup-container.show-popup {
				bottom: 0% !important;
			}
			.scroll-icon-container{
				.scroll-title{
					color: black;
					font-weight: 600;
				}
				.scroll-icon{
					path{
						fill: black;
					}
				}
			}
			
			.text-animated {
				color: var(--glo-2024-Black, #000);
				font-family: Mont;
				font-size: 28px;
				font-style: normal;
				font-weight: 600;
				line-height: 120%; 
				@include media-breakpoint-down(md) {
					font-size: 20px;
				}
			}
			.stepped-animation {
				gap: 60px;
				bottom: 6% !important;
				@include media-breakpoint-down(md) {
					font-size: 19px;
					bottom: 9% !important;
				}
				.orange-button {
					background: var(--glo-2024-Accent-Orange, #fe5000);
					color: black;
				}
		
				.ligh-button {
					background: transparent;
					border: 2px solid var(--glo-2024-White, #000);
					color: black;
				}
			}

			.bat-flex-slides {
				.slide {	
					&.one {
						background-image: url("/content/dam/glo-it/images/hyper-core-pdp/glo-hyper-specifiche-1.webp");
						background-size: cover;
						background-repeat: no-repeat;
					}
		
					&.two {
						background-image: url("/content/dam/glo-it/images/hyper-core-pdp/glo-hyper-specifiche-2.webp");
						background-size: cover;
						background-repeat: no-repeat;
					}
		
					&.three {
						background-image: url("/content/dam/glo-it/images/hyper-core-pdp/glo-hyper-specifiche-3.webp");
						background-size: cover;
						background-repeat: no-repeat;
					}
		
					&.four {
						background-image: url("/content/dam/glo-it/images/hyper-core-pdp/glo-hyper-specifiche-4.webp");
						background-size: cover;
						background-repeat: no-repeat;
					}
		
					&.five {
						background-image: url("/content/dam/glo-it/images/hyper-core-pdp/glo-hyper-specifiche-5.webp");
						background-size: cover;
						background-repeat: no-repeat;
					}
		
					&.six {
						background-image: url("/content/dam/glo-it/images/hyper-core-pdp/glo-hyper-specifiche-6.webp");
						background-size: cover;
						background-repeat: no-repeat;
					}
				}
			}
		
			.stepped-animation-two {
				bottom: 30%;	
				@include media-breakpoint-down(md) {
					left: 48%;
					bottom: 17%;
				}
			}
		
			.stepped-animation-three {
				bottom: 42%;
				left: 52%;
				@include media-breakpoint-down(md) {
					bottom: 58%;
					left: 68%;
				}
			}
		
			.stepped-animation-four {
				width: 390px;
				bottom: 55%;
				left: 52%;
				@include media-breakpoint-down(md) {
					bottom: 47%;
					left: 46%;
				}
			}
		
			.stepped-animation-five {
				bottom: 19%;
				//bottom: 45%;
				left: 58%;
				@include media-breakpoint-down(md) {
					bottom: 23%;
					left: 63%;
				}
			}
		
			.stepped-animation-six {
				width: 346px;
				bottom: 50%;
				left: 58%;
				@include media-breakpoint-down(md) {
					bottom: 62%;
					left: 50%;
				}
			}

			.popup-container-carousel {
				max-height: 400px;
				@media (min-width: 1366px) and (max-width: 1919px){
					max-height: 470px;
				}
				@include media-breakpoint-down(md) {
					max-height: 490px;
				}

				.popup-content-carousel {
					border-radius: 40px 40px 0px 0px;	
					@include media-breakpoint-down(md) {
						border-radius: 40px 40px 40px 40px;	
					}			
				}
			}

			.popup-container-carousel.show-popup {
				bottom: 2% !important;
				@media (min-width: 1366px) and (max-width: 1919px){
					bottom: 3% !important;
				}
				@include media-breakpoint-down(md) {
					bottom: 6% !important;
				}	
			}

			#popup-0,
			#popup-4 {
				.popup-content-carousel {
					img {
						position: absolute;
						bottom: -78%;
						right: 12%;
		
						@include media-breakpoint-down(md) {
							bottom: -68%;
							left: -5%;
						}
					}
				}
			}
		
			#popup-4 {
				img {
					position: absolute;
					top: -12%;
					left: 28%;
					bottom: unset;
					right: unset;
					max-width: 70%;
					width: 700px;
					@include media-breakpoint-down(md) {
						rotate: 12deg;
						top: unset !important;
						left: 5% !important;
						bottom: -53% !important;
						right: unset !important;
						width: 260px !important;
					}
				}
			}
		
			#popup-1 {
				.popup-content-carousel {
					img {
						bottom: -68%;
						right: 8%;
						@include media-breakpoint-down(md) {
							max-width: none;
							bottom: -70%;
							width: 400px;
							rotate: -15deg;
							left: -20%;
						}
					}
				}
			}
		
			#popup-2 {
				.popup-content-carousel {
					img {
						position: absolute;
						max-width: 200px;
						right: 18%;
						@include media-breakpoint-down(md) {
							max-width: 164px;
							bottom: -12%;
							left: -4%;
							right: unset;
						}
					}
				}
			}
		
			#popup-3 {
				.popup-content-carousel {
					img {
						position: absolute;
						bottom: -34%;
						right: 14%;
						max-width: 280px;
						@include media-breakpoint-down(md) {
							rotate: -10deg;
							bottom: -28%;
							right: -12%;
						}
					}
				}
			}
		
			#popup-5 {
				.popup-content-carousel {
		
					img {
						position: absolute;
						bottom: -38%;
						left: 60%;
						width: 25%;
						@include media-breakpoint-down(md) {
							bottom: -14%;
							left: 55%;
							width: 36%;
						}
					}
				}
			}
		}
	}
	.bat-hyperproanimation-gloit {
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 10000;
	
		.scroll-icon-container{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: fit-content;
			position: absolute;
			bottom: 5% !important;
			left: 50%;
			transform: translateX(-50%);
			@media (max-width: 768px) {
				bottom: 6%;
			}
			.scroll-title{
				color: var(--glo-2024-White, #FFF);
				text-align: center;
				font-family: Mont;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: 120%; /* 15.6px */
				letter-spacing: 1px;
				text-transform: uppercase;
			}
	
			.scroll-icon {
				display: block;
				animation: scrollDown 2s infinite ease-in-out;
			}
	
			@keyframes scrollDown {
				0% {
				  transform: translateY(0);
				}
				50% {
				  transform: translateY(10px);
				}
				100% {
				  transform: translateY(0);
				}
			  }
		}
	
	
	
		.hidden-mobile {
			@media (max-width: 768px) {
				display: none;
			}
		}
	
		.hidden-desktop {
			@media (min-width: 769px) {
				display: none;
			}
		}
	
		.full-animation-container {
			display: flex;
			flex-direction: column;
			position: relative;
			/*@media (min-width: 1200px) {
				margin-top: -32px !important;
			}*/
		}
	
		.stepped-animation {
			width: 100%;
			overflow-y: hidden;
			overflow-x: hidden;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;
			@include media-breakpoint-down(md) {
				top: unset;
				bottom: 60px;
			}
			@include media-breakpoint-up(md) {
				bottom: 19%;
				@media (min-width: 1366px) and (max-width: 1919px){
					bottom: 24%;
				}
			}
	
	
	
	
			.orange-button,
			.ligh-button {
				opacity: 0;
				transition: opacity 0.5s;
				min-height: 42px;
				max-width: 128px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-block: 12.5px;
				padding-inline: 34px;
				border-radius: 100px;
				flex-shrink: 0;
				font-family: Mont;
				font-size: 13px;
				font-style: normal;
				font-weight: 800;
				line-height: normal;
			}
	
			.orange-button {
				background: var(--glo-2024-Accent-Orange, #fe5000);
				color: black;
			}
	
			.ligh-button {
				background: transparent;
				border: 2px solid var(--glo-2024-White, #fff);
				color: white;
			}
		}
	
		.gs-image-hide {
			position: absolute;
			will-change: transform, opacity;
		}
	
		.gs-cta-hide {
			position: fixed;
			bottom: 60px;
			width: 100%;
			display: flex;
			justify-content: center;
			z-index: 999;
			will-change: transform, opacity;
	
			@include media-breakpoint-down(md) {
				bottom: 80px;
			}
		}
	
		.gs-modal-video {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 1;
			will-change: transform, opacity;
	
			@include media-breakpoint-down(md) {
				padding: 0 20px;
			}
		}
	
		.text-animated {
			color: var(--glo-2024-White, #fff);
			font-family: Mont;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 120%; /* 28.8px */
		}
	
		.stepped-animation-two {
			width: fit-content;
			overflow-y: visible;
			position: absolute;
			//bottom: 23%;
			bottom: 42%;
			left: 62%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 27px;
			opacity: 0;
			transform: translateX(100px);
			transition: opacity 0.5s ease, transform 0.5s ease;
			@include media-breakpoint-down(md) {
				left: 44%;
				bottom: 24%;
			}
		}
	
		.stepped-animation-three {
			width: 243px;
			overflow-y: visible;
			position: absolute;
			bottom: 42%;
			//bottom: 52%;
			left: 55%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 27px;
			opacity: 0;
			transform: translateX(100px);
			transition: opacity 0.5s ease, transform 0.5s ease;
			@include media-breakpoint-down(md) {
				bottom: 50%;
				left: 48%;
			}
		}
	
		.stepped-animation-four {
			width: 260px;
			overflow-y: visible;
			position: absolute;
			bottom: 53%;
			//bottom: 63%;
			left: 54%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 27px;
			opacity: 0;
			transform: translateX(100px);
			transition: opacity 0.5s ease, transform 0.5s ease;
			@include media-breakpoint-down(md) {
				bottom: 62%;
				left: 46%;
			}
		}
	
		.stepped-animation-five {
			width: 280px;
			overflow-y: visible;
			position: absolute;
			bottom: 32%;
			//bottom: 45%;
			left: 57%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 27px;
			opacity: 0;
			transform: translateX(100px);
			transition: opacity 0.5s ease, transform 0.5s ease;
			@include media-breakpoint-down(md) {
				bottom: 26%;
				left: 45%;
			}
		}
	
		.stepped-animation-six {
			width: 314px;
			overflow-y: visible;
			position: absolute;
			bottom: 48%;
			//bottom: 63%;
			left: 60%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 27px;
			opacity: 0;
			transform: translateX(100px);
			transition: opacity 0.5s ease, transform 0.5s ease;
			@include media-breakpoint-down(md) {
				bottom: 63%;
				left: 36%;
			}
		}
	
		@include media-breakpoint-down(md) {
			.gs-image-top-resize {
				position: relative;
				top: 5%;
				width: 45%;
			}
		}
	
		#popup-0,
		#popup-4 {
			.popup-content-carousel {
				h3.title {
					margin-bottom: 22px;
					@include media-breakpoint-down(md) {
						margin-top: 30px;
					}
				
				}
	
				.description {
					color: var(--glo-2024-White, #fff);
					font-feature-settings: "liga" off, "clig" off;
					font-family: Mont;
					font-size: 27px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%;
					text-align: left;
					span {
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 130%; /* 20.8px */
					}
				}
	
				img {
					position: absolute;
					bottom: -26%;
					right: 12%;
	
					@include media-breakpoint-down(md) {
						top: unset;
						bottom: -74%;
						left: -14%;
						max-width: none;
						width: 327px;
					}
				}
			}
		}
	
		#popup-4 {
			img {
				position: absolute;
				top: 14%;
				left: 28%;
				bottom: unset;
				right: unset;
				max-width: 140%;
				width: 700px;
				@include media-breakpoint-down(md) {
					top: unset !important;
					left: 24% !important;
					bottom: -10% !important;
					right: unset !important;
					width: 600px !important;
				}
			}
		}
	
		#popup-1 {
			.popup-content-carousel {
				color: var(--glo-2024-Warm-Grey, #e5e1e6);
				font-feature-settings: "liga" off, "clig" off;
				font-family: Mont;
				h3.title {
					margin-bottom: 18px;
					@include media-breakpoint-down(md) {
						margin-top: 30px;
					}
				}
	
				.upper-appendix {
					font-size: 26px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 76.923% */
				}
	
				.appendix {
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: 120%; /* 15.6px */
					opacity: 0.6;
					text-align: left;
				}
	
				img {
					position: absolute;
					bottom: -40%;
					right: 4%;
					@include media-breakpoint-down(md) {
						max-width: none;
						bottom: -44%;
						width: 400px;
						rotate: 35deg;
						left: -57%;
					}
				}
			}
		}
	
		#popup-2 {
			.popup-content-carousel {
				color: white;
				font-feature-settings: "liga" off, "clig" off;
				font-family: Mont;
	
				h3.title {
					margin-bottom: 40px;
					margin-top: 0px;
					@include media-breakpoint-down(md) {
						margin-bottom: 60px;
						margin-top: 30px;
					}
				}
				.mod-container {
					display: flex;
					flex-direction: column;
					text-align: left;
					justify-content: flex-end;
					align-items: flex-start;
					width: 100%;
					gap: 20px;
					@include media-breakpoint-down(md) {
						align-items: flex-end;
						padding-right: 14px;
					}
	
					.boost,
					.standard {
						display: flex;
						align-items: flex-start;
						justify-content: space-between;
						width: 110px;
	
						p {
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 125% */
							text-transform: uppercase;
						}
					}
	
					.celsius {
						margin-bottom: 33px;
						font-size: 32px;
						font-style: normal;
						font-weight: 600;
						line-height: 20px; /* 62.5% */
						width: 110px;
						text-align: left;
						@include media-breakpoint-down(md) {
							margin-bottom: 33px;
						}
	
						span{
							font-weight: 400;
						}
					}
				}
	
				img {
					position: absolute;
					max-width: 280px;
					bottom: -28%;
					right: 10%;
					@include media-breakpoint-down(md) {
						max-width: 200px;
						bottom: -12%;
						left: -10%;
						right: unset;
					}
				}
			}
		}
	
		#popup-3 {
			.popup-content-carousel {
				color: white;
				font-feature-settings: "liga" off, "clig" off;
				font-family: Mont;
	
				h3.title {
					margin-bottom: 40px;
					margin-top: 0px;
					@include media-breakpoint-down(md) {
						margin-bottom: 90px;
						margin-top: 30px;
					}
				}
				.mod-container {
					display: flex;
					flex-direction: column;
					text-align: left;
					justify-content: flex-start;
					align-items: flex-start;
					width: 100%;
					gap: 0px;
	
					.boost,
					.standard {
						display: flex;
						align-items: flex-start;
						justify-content: space-between;
						width: 110px;
	
						p {
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 125% */
							text-transform: uppercase;
							margin-bottom: 20px;
						}
					}
	
					.celsius {
						margin-bottom: 53px;
						font-size: 32px;
						font-style: normal;
						font-weight: 600;
						line-height: 20px; /* 62.5% */
						width: 110px;
						text-align: left;
						@include media-breakpoint-down(md) {
							margin-bottom: 53px;
						}
	
						span{
							font-weight: 400;
						}
					}
				}
	
				img {
					position: absolute;
					bottom: -38%;
					right: 14%;
					max-width: 280px;
					@include media-breakpoint-down(md) {
						bottom: -35%;
						right: -16%;
					}
				}
			}
		}
	
		#popup-5 {
			.popup-content-carousel {
				color: var(--glo-2024-Warm-Grey, #e5e1e6);
				font-feature-settings: "liga" off, "clig" off;
				font-family: Mont;
	
				h3.title {
					margin-bottom: 37px;
					margin-top: 0px;
					@include media-breakpoint-down(md) {
						margin-top: 30px;
					}
				}
				.mod-container {
					display: flex;
					flex-direction: column;
					text-align: left;
					justify-content: flex-start;
					align-items: flex-start;
					width: 100%;
					gap: 20px;
	
					div {
						color: var(--glo-2024-White, #FFF);
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 125% */
						text-transform: uppercase;
						opacity: 0.6;
					}
	
					div.charge {
						font-size: 32px;
						font-style: normal;
						font-weight: 500;
						line-height: 20px; /* 62.5% */
						text-transform: none;
						opacity: 1;
						margin-bottom: 22px;
	
						span {
							font-size: 32px;
							font-style: normal;
							font-weight: 300;
							line-height: 20px;
						}
					}
				}
	
				img {
					position: absolute;
					bottom: -26%;
					left: 60%;
					@include media-breakpoint-down(md) {
						bottom: -20%;
						left: 60%;
					}
				}
			}
		}
	
		.popup-container, .popup-container-carousel{
			display: none;
		}
	
		.popup-container-carousel {
			position: fixed;
			bottom: -100%;
			left: 0;
			width: 100%;
			height: 50%;
			max-height: 490px;
			color: white;
			text-align: center;
			padding-inline: 20px;
			transition: bottom 0.5s ease;
			z-index: 999;
			@include media-breakpoint-down(md) {
				height: 70%;
			}
	
			.popup-content-carousel {
				overflow: hidden;
				position: relative;
				display: flex;
				gap: 13px;
				flex-direction: column;
				align-items: flex-start;
				max-width: 648px;
				height: 100%;
				margin: 0 auto;
				background-color: #333;
				padding: 45px;
				border-radius: 40px 40px 40px 40px;
				@include media-breakpoint-down(md) {
					max-width: 335px;
					padding: 24px;
				}
	
				h3.title {
					margin-top: 20px;
					color: var(--glo-2024-White, #fff);
					font-feature-settings: "liga" off, "clig" off;
					font-family: Mont;
					font-size: 28px;
					font-style: normal;
					font-weight: 700;
					line-height: 130%; /* 36.4px */
					text-align: left;
				}
			}
	
			.close-btn-carousel {
				position: absolute;
				top: 2.5%;
				right: 2%;
				cursor: pointer;
				z-index: 2;
			}
		}
	
		.show-popup {
			bottom: 19% !important;
		}
	
		.popup-overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(
				0,
				0,
				0,
				0.5
			); 
			z-index: 998; 
			opacity: 0;
			visibility: hidden; 
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
	
		.popup-overlay.active {
			opacity: 1;
			visibility: visible; /* Quando il popup è attivo, l'overlay diventa visibile */
		}
	
		.popup-container {
			position: fixed;
			bottom: -100%;
			left: 0;
			width: 100%;
			height: 38%;
			color: white;
			text-align: center;
			padding-inline: 20px;
			transition: bottom 0.5s ease;
			z-index: 999;
			@include media-breakpoint-down(md) {
				height: 74%;
			}
	
			.popup-content {
				display: flex;
				gap: 13px;
				flex-direction: column;
				align-items: flex-start;
				max-width: 648px;
				height: 100%;
				margin: 0 auto;
				background-color: #333;
				padding: 20px 20px 20px 25px;
				border-radius: 40px 40px 0px 0px;
				@include media-breakpoint-down(md) {
					max-width: 355px;
				}
	
				h3 {
					color: var(--glo-2024-Warm-Grey, #e5e1e6);
					font-feature-settings: "liga" off, "clig" off;
					font-family: Mont;
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 111.111% */
					text-transform: uppercase;
					text-align: left;
					opacity: 0.4;
					margin-top: 0px !important;
				}
	
				h2 {
					margin-top: 0;
					color: var(--glo-2024-White, #fff);
					font-family: Mont;
					font-size: 28px;
					font-style: normal;
					font-weight: 600;
					line-height: 120%; /* 33.6px */
					margin-bottom: 17px;
					text-align: left;
				}
	
				p {
					color: var(--glo-2024-Warm-Grey, #e5e1e6);
					font-family: Mont;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 140%; /* 22.4px */
					margin-bottom: 41px;
					text-align: left;
					opacity: 0.8;
				}
	
				p.appendice {
					color: var(--glo-2024-Warm-Grey, #e5e1e6);
					font-family: Mont;
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: 140%; /* 18.2px */
					text-align: left;
					opacity: 0.6;
				}
			}
	
			.close-btn {
				position: relative;
				margin-left:auto;
				cursor: pointer;
				z-index: 2;
			}
		}

	
		.bat-flex-slides {
			display: none;
			position: fixed;
			bottom: -100px;
			left: 100px;
			overflow: visible !important;
			gap: 20px;
			.slide {
				min-width: 230px !important;
				padding: 22px 16px 16px 20px;
				border-radius: 40px;
				height: 360px;
				position: relative;
	
				@media only screen and (max-width: 1380px) and (min-width: 1229px) {
					width: 235px !important;
					height: 330px;
				}
	
				@media only screen and (max-width: 1228px) and (min-width: 1077px) {
					width: 205px !important;
					height: 300px;
				}
	
				@media only screen and (max-width: 1076px) and (min-width: 1001px) {
					width: 185px !important;
					height: 280px;
				}
	
				&.one {
					background-image: url("/content/dam/glo-it/animations/hyper-pro/slides/spec_13_1_5x.webp");
					background-size: cover;
					background-repeat: no-repeat;
				}
	
				&.two {
					background-image: url("/content/dam/glo-it/animations/hyper-pro/slides/spec_12_1_5x.webp");
					background-size: cover;
					background-repeat: no-repeat;
				}
	
				&.three {
					background-image: url("/content/dam/glo-it/animations/hyper-pro/slides/spec_11_1_5x.webp");
					background-size: cover;
					background-repeat: no-repeat;
				}
	
				&.four {
					background-image: url("/content/dam/glo-it/animations/hyper-pro/slides/spec_10_1_5x.webp");
					background-size: cover;
					background-repeat: no-repeat;
				}
	
				&.five {
					background-image: url("/content/dam/glo-it/animations/hyper-pro/slides/spec_9_1_5x.webp");
					background-size: cover;
					background-repeat: no-repeat;
				}
	
				&.six {
					background-image: url("/content/dam/glo-it/animations/hyper-pro/slides/ksp_6_1_5x.webp");
					background-size: cover;
					background-repeat: no-repeat;
				}
				.slide-title {
					font-size: 12px;
					font-weight: 500;
					line-height: 20px;
					text-transform: uppercase;
					padding-bottom: 5px;
				}
	
				.slide-text {
					font-size: 20px;
					font-weight: 600;
					line-height: 24px;
					color: white;
				}
	
				.plus-icon-carousel {
					position: absolute;
					bottom: 5px;
					right: 11px;
					pointer-events: all;
					cursor: pointer;
					z-index: 1000;
					img {
						border-radius: 50%;
					}
				}
			}
	
			@media (max-width: 850px) {
				.bat-carousel-popup-gloit {
					.slide {
						margin-left: 20px;
					}
	
					.slick-dots {
						position: initial;
						height: fit-content !important;
						padding-top: 39px;
	
						li {
							margin-top: 0 !important;
							padding-top: 0 !important;
						}
	
						button {
							background-color: #e5e1e6 !important;
						}
	
						.slick-active {
							button {
								width: 7px !important;
							}
						}
					}
	
					.arrows-container {
						position: absolute;
						bottom: 0;
						right: 5%;
	
						button {
							position: relative !important;
							transform: none !important;
						}
					}
	
					.navigation-arrows {
						position: relative;
					}
				}
			}
		}
	
		sup {
			vertical-align: middle !important;
			font-size: 0.5em !important;
			font-weight: 1000 !important;
		}
	}

	.pin-spacer {
		background-color: black;
	}
}

.tfsx__popup__overlay{
	z-index: 10002 !important;
}
