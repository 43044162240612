.bat-ctastick-gloit {
	padding-top: 20px;
	padding-bottom: 50px;
	display: flex;
    justify-content: center;
	z-index: 2 !important;

	.buttons {

		@include media-breakpoint-down(md) {
			display: flex;
			margin: auto;
			margin-left: 25%;
		}

		.button-container {
			@include media-breakpoint-down(md) {
				width: 44%;
			}

			.button {
				width: 230px;
				

				&.neo{
					height: 60px;
					width: 150px;
					picture{
						width: 80%;
					}
					&.unselected{
						margin-top: 1%;
						height: 55px;
					}
				}

				&.lucky{
					height: 60px;
					width: 150px;
					padding: 14px;
					padding-left: 25px;
					picture{
						width: 100%;
						margin-left: 22px;

					}
					&.unselected{
						margin-top: 1%;
						height: 54.5px;
						
					}
				}

				&.unselected{
					background-color: #efefef;
					border-radius: 20px;	
					border: 2px #efefef solid;
    				height: 55px;
				}


				&.bat-button--dark {
					z-index: 1;
					pointer-events: none;
					&:focus{
						color: white;
					}
				}

				&.bat-button--secondary--dark {
					z-index: 0;
					background-color: white;
					border-radius: 20px;
					border: solid 4px #ed7d30;
					height: 57px;
					z-index: 10 !important;
					&:hover{
						color: $brand-black;
					}
				}

				&.neo {
					margin-inline: -32px;
				}

				@include media-breakpoint-down(md) {
					display: block;
					width: 180px;

					&.neo {
						margin-inline: -30px;
					}
					&.lucky {
						display: flex;
						margin-inline: -15px;
					}
				}
			}
		}
	}

}
.section-full-border-radius{
	border-radius: 30px;
}
.section-border-radius-bottom{
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}
.image-border-radius{
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}
.image-border-radius-bottom-left{
	border-bottom-left-radius: 30px;
}
.image-border-radius-bottom-right{
	border-bottom-right-radius: 30px;
}
.image-border-radius-top-left{
	border-top-left-radius: 30px;
}
.image-border-radius-top-right{
	border-top-right-radius: 30px;
}
.section-margin-top{
	padding-top: 10px;
	background-color: white;
}

#unyco_container1c423qh01v{
	.circle{
		width: 6.4em !important;
    	height: 6.4em !important;
		right: 0;
	}
}

.margin-bottom-extra-mobile{
	@include media-breakpoint-down(md) {
		margin-bottom: 75px;
	}
}