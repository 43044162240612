bat-expander-gloitworldwide{
    .bat-expander{
    
        border-bottom: 0px;

        @include media-breakpoint-down(sm) {
            .bat-listaccordion-gloit{

                .list{
                    .list-item.uk-open{
                        .list-item-title::before{
                            background-image: url("/content/dam/glo-it/images/Glo-Chevron-up.png") !important;
                        }
                    } 

                    .list-item{
                        .list-item-title::before{
                            background-image: url("/content/dam/glo-it/images/Glo-Chevron-down.png") !important;
                        }
                    } 
                }
            }
        }

        @include media-breakpoint-up(md) {
            .bat-listaccordion-gloit{

                .list{
                    pointer-events:none;

                    .list-item{
                        border-bottom:0px;
                        &-title{
                            &::before{
                                background-image: none !important;
                            }
                        }
                        
                        
                    }
                
                } 
            } 
        }
    }
}

.worldwide-image{
    
    picture{
        @include media-breakpoint-up(lg) {
            padding:25px;
        }
    }
}