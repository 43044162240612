.video-section{
    .bat{
        width: 750px;

        @include media-breakpoint-down(sm){
            .bat-video-content{
                padding: 20px;
            }
        }
    }

    @include media-breakpoint-down(sm){
        display: block;
    }

    &-title{
        width: 25%;
        margin: auto;

        @include media-breakpoint-down(sm){
            width: 90%;
            margin: 20px 20px 0px;
        }

        .title{
            @include font-rem(30px, 34px);
            font-weight: 800;
            margin-bottom: 20px;

            @include media-breakpoint-down(sm){
                @include font-rem(24px, 28px);
            }
        }

        .description{
            @include font-rem(14px, 20px);
        }
    }
}