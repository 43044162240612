.bat-blogdetail-gloit{
    background-color: #E5E5E5;

    @include media-breakpoint-down(lg){
        background-color: white;
    }

    &.alternative{
        padding-top: 50px;

        @include media-breakpoint-down(lg){
            padding-top: 0px;
        }
    }

    &-picture{
        picture{
            width: 50%;
            margin: auto;

            @include media-breakpoint-down(lg){
                width: 100%;
            }
        }
    }

    &-blog,
    &-blog-alternative{
        max-width: 50%;
        margin: auto;
        padding: 60px 100px;
        background-color: white;

        @include media-breakpoint-down(lg){
            max-width: 100%;
            margin: 0px;
            padding: 30px 20px 0px;
        }

        .bat-video-content{
            padding: 0;
        }

        .title,.headline1{
            text-transform: uppercase;
            @include font-rem(34px, 39px);
            font-weight: 800;
            margin-bottom: 90px;

            @include media-breakpoint-down(lg){
                @include font-rem(26px, 32px);
                margin-bottom: 15px;
            }
        }


        .category-info{
            @include font-rem(14px, 20px);

            .category{
                text-transform: uppercase;
                @include font-rem(12px, 15px);
                font-weight: 700;
                padding: 7px 15px;
                border-radius: 40px;
                background-color: #E35205;
                color: white;
                margin-inline: 5px;
            }

            .date{
                @include font-rem(14px, 20px);
            }
        }

        .share{
            display: flex;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 30px;
            @include font-rem(12px, 15px);
            text-transform: uppercase;
            font-weight: 700;

            .icon-facebook{
                margin-left: 20px;
                margin-right: 10px;
            }
        }

        .paragraph, .bat-text{
            @include font-rem(14px, 20px);
            color: #6B6B6B;
            margin-bottom: 30px;

            @include media-breakpoint-down(lg){
                @include font-rem(13px, 20px);

            }
        }

        .image{
            display: flex;
            margin-bottom: 30px;

            picture{
                width: 100%;
            }

            .paragraph{
                width: 50%;
                margin: auto;
                padding-left: 40px;
            }

            @include media-breakpoint-down(lg){
                flex-direction: column;

                picture{
                    width: 100%;
                }
    
                .paragraph{
                    width: 100%;
                    margin-top: 20px;
                    padding-left: 0px;
                }
            }
        }

        .subtitle, .headline3{
            @include font-rem(22px, 28px);
            text-transform: uppercase;
            margin-bottom: 30px;

            @include media-breakpoint-down(lg){
                @include font-rem(20px, 24px);
            }
        }

        .fullImage{
            margin-bottom: 30px;
        }

        .paragraph-alternative{
            @include font-rem(20px, 20px);
            padding: 30px 40px;
            background-color: #EFEFEF;
            margin-bottom: 30px;

            @include media-breakpoint-down(lg){
                @include font-rem(18px, 24px);
                padding: 20px;
            }
        }

        .list{
            @include font-rem(14px, 20px);
            color: #6B6B6B;
            margin-bottom: 30px;
            padding-left: 40px;

            li{
                padding-left: 15px;
                list-style: url('/content/dam/glo-it/images/blog/arrow.svg')
            }

            @include media-breakpoint-down(lg){
                @include font-rem(13px, 20px);
            }
        }

        .border{
            border-bottom: 2px solid #DEDEDE;
            margin-top: 60px;

            @include media-breakpoint-down(lg){
                margin-top: 30px;
            }
        }

        .post-lc-success {
            box-sizing: border-box;
            margin-top: 24px;
            padding: 15px;
            width: 100%;
            background: rgba(47,83,159,.2);
            color: #2a2c2c;
            text-align: center;
        }

        .bat-cta-style.button-secondary-dark:hover {
            background: #ed7d30;
        }
    }

    &-blog{
        position: relative;
    }

    &-raccomanded{
        padding: 60px;

        @include media-breakpoint-down(lg){
            padding: 30px 20px;
            background-color: #E5E5E5;
        }

        .more-from-us{
            padding-left: 3px;
            padding-right: 25px;
            margin-bottom: 20px;

            @include media-breakpoint-down(lg){
                flex-direction: column;
                padding-left: 0px;
                padding-right: 0px;
                margin-bottom: 0px;
            }

            .text{
                @include font-rem(22px, 28px);
                font-weight: 700;
                text-transform: uppercase;
    
                @include media-breakpoint-down(lg){
                    @include font-rem(20px, 24px);
                    text-align: center
                }
            }
    
            .arrow-link-dark{
                @include font-rem(14px, 28px);
                text-transform: uppercase;

                @include media-breakpoint-down(lg){
                    align-self: center;
                }
            }
        }

        .raccomanded-blogs{
            .aem-GridColumn--default--12{
                padding: 0px;
                background-color: #E5E5E5;
                
                .bat-cardnews-gloit--news{
                    @include media-breakpoint-down(lg){
                        padding: 40px 0px;
                        border-bottom: 2px solid #DEDEDE;
                    }

                    .bat-cardnews-gloit--news-title{
                        margin-top: 0px;
                        font-weight: 700
                    }

                    .bat-cardnews-gloit--news-date{
                        @include font-rem(14px, 20px);

                        @include media-breakpoint-down(lg){
                            @include font-rem(13px, 20px);
                        }
                    }

                    .bat-cardnews-gloit--news-content{
                        p{
                            @include font-rem(14px, 20px);

                            @include media-breakpoint-down(lg){
                                @include font-rem(13px, 20px);
                            }
                        }
                    }

                    .bat-cta-style{
                        span{
                            font-weight: 700
                        }
                    }
                }
            }
        }
    }

    .like-container {
        display: flex;
        align-items: center;
        gap: 15px;
        margin: auto;
        max-width: 50%;
        background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
        box-shadow: 6px 10px 20px rgba(0, 0, 0, 0.14);
        padding: 10px;

        @include media-breakpoint-down(md) {
            max-width: unset;
            flex-direction: column;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 50px;
            gap: 8px;
        }

        &-image {
            max-width: 75px;
            min-width: 50px;
            margin-left: -35px;

            @include media-breakpoint-down(md) {
                margin-left: 0;
                align-self: flex-start;
                margin-top: -45px;
            }
        }

        .only-insiders {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 12px 8px 8px;
            gap: 8px;
            background: #0B1047;
            border-radius: 40px;
            width: max-content;
            margin-bottom: 10px;

            span {
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                color: #FFFFFF;
            }
        }

        &-text {

            flex: auto;

            h3 {
                color: $white;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                margin-top: 0;
            }

            p {
                color: $white;
                font-weight: 300;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .like-ctas {
            display: flex;
            gap: 8px;
        }

        .cta-likes {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 32px 10px 29px;
            gap: 8px;
            background: $white;
            border-radius: 24px;
            font-family: inherit;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: #2A2C2C;

            @include media-breakpoint-down(md) {
                padding: 10px 27px 10px 22px;

                span {
                    width: max-content;
                }
            }

            &:hover {
                opacity: 0.9;
            }

            &:disabled {
                opacity: 0.8;
                pointer-events: none;
            }

            &.previous-selection {
                pointer-events: none;
            }
        }

        .icon-like {
            &::before {
                content: url('/content/dam/glo-it/images/newloyalty/Like.svg');
            }
        }

        .icon-locked {
            height: 22px;
            &::before {
                content: url('/content/dam/glo-it/images/newloyalty/Lock.svg');
                font-size: 14px;
            }
        }

        .icon-dislike {
            &::before {
                content: url('/content/dam/glo-it/images/newloyalty/Smileys.svg');
            }
        }
    }


    
    .subscribe-insidersclub-container {
        display: flex;
        align-items: center;
        gap: 15px;
        margin: auto;
        max-width: 50%;
        background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
        box-shadow: 6px 10px 20px rgba(0, 0, 0, 0.14);
        padding: 10px;

        @include media-breakpoint-down(md) {
            max-width: unset;
            flex-direction: column;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 50px;
            gap: 8px;
        }

        &-image {
            max-width: 75px;
            min-width: 50px;
            margin-left: -35px;

            @include media-breakpoint-down(md) {
                margin-left: 0;
                align-self: flex-start;
                margin-top: -45px;
            }
        }

        .only-insiders {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 12px 8px 8px;
            gap: 8px;
            background: #0B1047;
            border-radius: 40px;
            width: max-content;
            margin-bottom: 10px;

            span {
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                color: #FFFFFF;
            }
        }

        &-text {

            flex: auto;

            h3 {
                color: $white;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                margin-top: 0;
            }

            p {
                color: $white;
                font-weight: 300;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .submit {
            display: flex;
            gap: 16px;
            flex-direction: column;
            
            @include media-breakpoint-down(md) {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            h3{
                font-weight: 700;
                font-size: 17px;
                line-height: 15px;
                text-transform: uppercase;
                text-transform: uppercase;
                color: #fff;
                text-align: center;
            }
        }

        .cta-submit {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 32px 10px 29px;
            gap: 8px;
            background: $white;
            border-radius: 24px;
            font-family: inherit;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: #2A2C2C;

            @include media-breakpoint-down(md) {
                padding: 10px 27px 10px 22px;

                span {
                    width: max-content;
                }
            }

            &:hover {
                opacity: 0.9;
                 span {
                    text-decoration: none;
                }
            }

            &.previous-selection {
                pointer-events: none;
            }
        }

        .icon-like {
            &::before {
                content: url('/content/dam/glo-it/images/newloyalty/Like.svg');
            }
        }

        .icon-locked {
            height: 22px;
            &::before {
                content: url('/content/dam/glo-it/images/newloyalty/Lock.svg');
                font-size: 14px;
            }
        }

        .icon-dislike {
            &::before {
                content: url('/content/dam/glo-it/images/newloyalty/Smileys.svg');
            }
        }
    }

    .esclusiva-insidersclub-container {
        display: flex;
        align-items: center;
        margin: auto;
        max-width: 50%;
        background: #D5DDEC;
        box-shadow: 6px 10px 20px rgba(0, 0, 0, 0.14);
        padding: 10px;
        margin-bottom: 30px;
        border-radius: 8px;

        @include media-breakpoint-down(md) {
            max-width: unset;
            flex-direction: column;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 50px;
            gap: 8px;
        }

        .esclusiva-insidersclub-content{
            display: flex;
            align-content: space-between;
            flex-direction: row;
            flex: auto;
            gap: 8px;
            @include media-breakpoint-down(md) {
                gap: 0px;
                margin-left: -30px;
            }
        }

        &-image {
            max-width: 75px;
            min-width: 50px;
            margin-left: 0px;

            picture{
                margin-top: 10px;
            }

            @include media-breakpoint-down(md) {
                picture{
                    margin-left: -10px;
                    margin-top: 10px;
                }
            }
        }

        &-text {
            margin-top: 10px;
            flex: auto;

            h3 {
                font-weight: 700;
                font-size: 17px;
                line-height: 15px;
                text-transform: uppercase;
                text-transform: uppercase;

                /* Loyalty/Dark loyalty blue */

                color: #0B1047;
            }

            p {
                
                margin-top: 10px;

                color: #0B1047;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
        }


        .cta-subscribe{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 32px;
            font-family: inherit;

            width: 137px;
            height: 37px;

            background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
            border-radius: 24px;

            flex: none;
            order: 2;
            flex-grow: 0;

            span{
                color: white;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.7px;
                text-transform: uppercase;
            }

            @include media-breakpoint-down(md) {
                width: 314px;
                padding: 10px 27px 10px 22px;

                span {
                    width: max-content;
                }
            }

            &:hover {
                opacity: 0.9;
                 span {
                    text-decoration: none;
                }
            }
         
            &.previous-selection {
                pointer-events: none;
            }
        }
        

    }




    bat-video-gloitblog {
        max-width: unset !important;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
            margin-bottom: 80px;
        }
    }

    .video-container {
        display: flex;
        align-items: center;
        margin: auto;
        max-width: 50%;
        background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
        box-shadow: 6px 10px 20px rgba(0, 0, 0, 0.14);
        padding: 10px;

        @include media-breakpoint-down(md) {
            max-width: unset;
            flex-direction: column;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 50px;
            gap: 8px;
        }

        &-image {
            max-width: 95px;
            min-width: 50px;
            margin-left: -35px;
            align-self: flex-start;

            @include media-breakpoint-down(md) {
                margin-left: 0;
                align-self: flex-start;
                margin-top: -45px;
            }
        }

        .only-insiders,
        .video-value {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 12px 8px 8px;
            gap: 8px;
            background: #0B1047;
            border-radius: 40px;
            width: max-content;
            margin-bottom: 10px;

            span {
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                color: #FFFFFF;
            }
        }

        &-text {

            flex: auto;
            h3 {
                color: $white;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                margin-top: 0;
            }

            span {
                color: $white;
                font-weight: 300;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .video-watched {
            color: white;
            margin-top: 5px;
        }

        .due-date-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            color: $white;

            i::before {
                font-size: 20px;
            }
        }

        .cta-view-video,
        .cta-not-insiders {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 32px 10px 29px;
            gap: 8px;
            background: $white;
            border-radius: 24px;
            font-family: inherit;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: #2A2C2C;

            @include media-breakpoint-down(md) {
                padding: 10px 27px 10px 22px;

                span {
                    width: max-content;
                }
            }

            &:hover {
                opacity: 0.9;

                span {
                    color: #2A2C2C;
                    text-decoration: none;
                }
            }
        }

        .video-thumbnail {
            position: relative;
            width: 300px;
            height: 160px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            .thumb-button-container {
                position: relative;
                color: $white;
                text-align: center;

                @include media-breakpoint-down(md) {
                    margin-top: 50px;
                }
            }

            .thumb-image-container {
                position: absolute;
                top: -15%;
                bottom: -15%;

                @include media-breakpoint-down(md) {
                    top: 0;
                    bottom: -30%;
                }

                picture{
                    height: 100%;
                }

                img {
                    object-fit: cover;
                    height: 100%;
                    border-radius: 8px;
                }
            }
        }

        .icon-watch-video {
            &::before {
                content: url('/content/dam/glo-it/images/newloyalty/Watch.svg');
            }
        }

        .icon-clock-time {
            &::before {
                content: url('/content/dam/glo-it/images/newloyalty/clock-time.svg');
            }
        }

        .icon-locked {
            height: 22px;
            &::before {
                content: url('/content/dam/glo-it/images/newloyalty/Lock.svg');
                font-size: 14px;
            }
        }
    }
}

.video-blog-modal{
	width: 1000px;
    background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
    box-shadow: 16px 16px 0px #D5DDEC;
    display: flex;
    flex-direction: column;
	
	.uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

    &-title {
        padding: 20px;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: $white;
    }

	.first-section-modal{
		position: relative;
		text-align: center;
        width: 98%;
        margin: auto;
        padding-top: 56.25%;
	}
	.second-section-modal{
		padding: 16px 20px 20px 20px;
        display: flex;
        justify-content: flex-end;
		.button-container{
			.cta-viewed-continue {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 29px 10px 32px;
                gap: 8px;
                background: $white;
                border-radius: 24px;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                font-family: 'Mont';

                &:hover {
                    opacity: 0.9;
                }
    
                &:disabled {
                    opacity: 0.8;
                    pointer-events: none;
                }
            }
		}
	}

    .mobile-app & {
        .video-blog-modal-title {
            margin-top: 50px !important;
        }
    }
}