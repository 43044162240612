.qrcode-modal{
	width: 585px;
    background: linear-gradient(45deg, #5482c2 0, #353174 100%);
    box-shadow: 16px 16px 0px #D5DDEC;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
	
	.uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

	.first-section-modal {		
        margin-top: 60px;
        width: max-content;

        @include media-breakpoint-down(md) {
            margin-top: 80px;
        }
        
        .redeem-code-box {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 24px;
            gap: 8px;
            background: #0B1047;
            border-radius: 24px;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: $white;

            .icon-qrcode {
                height: 48px;
                width: 48px;

                &::before {
                    height: 50px;
                    transform: unset;
                }
            }
        }
	}

	.second-section-modal {
		display: flex;
        flex-direction: column;
        justify-content: center;

        .modal-title {
            font-weight: 700;
            font-size: 26px;
            line-height: 30px;
            text-align: center;
            color: $white;
            padding: 0 20px;;
        }

        .description-modal {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: $white;
        }

	}

    .third-section-modal {

        width: 100%;
        padding-left: 24px;
        padding-right: 24px;

        @include media-breakpoint-down(md) {
            padding-left: 20px;
            padding-right: 20px;
        }
        
        .qr-field {
            padding: 8px 8px 8px 16px;
            background: #EFEFEF;

            .qr-field-input {
                flex: 1;
                background: transparent;
                border: none;
            }
        }
    }

    .fourth-section-modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 24px;
        margin-right: 24px;

        .cta-verify {
            padding: 10px 32px;
            background: $white;
            border-radius: 24px;
            font-family: inherit;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: #2A2C2C;
            margin-bottom: 40px;

            @include media-breakpoint-down(md) {

                margin-bottom: 0;
                span {
                    width: max-content;
                }
            }

            &:hover {
                opacity: 0.9;
            }

            &:disabled {
                opacity: 0.8;
                pointer-events: none;
            }
        }

        .redeem-error-message {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 24px;
            gap: 16px;
            background: #FDF3F5;
            border-radius: 8px;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.2px;
            color: #E00830;
            margin-bottom: 40px;
            max-width: 420px;

            @include media-breakpoint-down(md) {
                margin-bottom: 24px;
                width: 100%;
            }

            .icon-warning {
                &::before {
                    content: url('/content/dam/glo-it/images/newloyalty/Warning.svg');
                }
            }
        }

        .description-modal {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: $white;
        }
    }

    .qr-code-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        z-index:99999;
        background: $black;

        .qr-code-below {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
            border-radius: 24px 24px 0px 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 24px 16px 32px;
            gap: 8px;

            .qr-code-title {
                color: $white;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.2px;
            }

            .qr-code-revert-button {
                padding: 10px 32px;
                background: $white;
                border-radius: 24px;
                font-family: inherit;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.7px;
                text-transform: uppercase;
                color: #2A2C2C;
                margin-bottom: 40px;
                width: 100%;
    
                @include media-breakpoint-down(md) {
    
                    margin-bottom: 0;
                    span {
                        width: max-content;
                    }
                }
    
                &:hover {
                    opacity: 0.9;
                }
    
                &:disabled {
                    opacity: 0.8;
                    pointer-events: none;
                }
            }
        }
    }
}