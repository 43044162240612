@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

.bat-section-gloitmodal{
    .bat-modal-content{
        background-color: $charcoal;
		top: 0px;

		@include media-breakpoint-down(sm) {
			width: inherit;
			padding-right: 20px;
			padding-left: 20px;
		}


        .bat-modal-close i::before{
            color: $white;
        }
    }
}
.bat-reviewsform-gloit {
    background-color: $charcoal;

    legend,
    label{
        color: $white;
    }

    .product-label{
        @include font-rem(12px, 16px);
        letter-spacing: 1.2px;
        font-family: $primary-font;
        color: $white
    }

    .product-name{
        @include font-rem(20px, 24px);
        font-family: $primary-font;
        font-weight: 800;
        color: $white;
        margin-top: 0px;
    }

	.success-title{
		@include font-rem(20px, 24px);
		font-family: $primary-font;
		color: $white;
		font-weight: 800;
		margin-top: 20px;
		margin-bottom: 20px
	}

	.success-text{
		@include font-rem(14px, 20px);
		font-family: $primary-font;
		color: $white;
		margin-bottom: 50px;
	}

	&-form {
		display: flex;
		flex-direction: column;
		@include media-breakpoint-up(sm){
			width: 75%;
		}
		label, 
		legend {
			display: block;
			font-size: .8rem;
			font-weight: bold;
			margin-bottom: 1rem;
			input, 
			textarea {
				margin: .5rem 0;
			}
		}
        .name-container{
			@include media-breakpoint-down(sm){
				display: block;
			} 

			.title{
				margin-left: 20px;
				@include media-breakpoint-down(sm){
					display: block;
					margin-left: 0px;
				} 
			}	
			
			@include media-breakpoint-up(sm){
				.name,
				.title{
					width: 100%;
				}
			}
        }
		.textarea-wrapper {
			margin-bottom: 1rem;
			label {
				margin-bottom: 0;
			}
            textarea{
				resize: none;
				width: 100%;
            }
		}
		.cta-container{
			@include media-breakpoint-down(sm){
				display: contents;
			}

			.submit{
				@include media-breakpoint-down(sm){
					margin: auto;
					padding-left: 80px;
					padding-right: 80px;
				}
			}

			.cancel-cta{
				@include media-breakpoint-down(sm){
					margin-top: 30px
				}

				.cancel-label{
					@include font-rem(14px, 18px);
					letter-spacing: 1px;
					color: $white;
	
					@include media-breakpoint-down(sm){
						display: contents;
					}
				}
			}
		}
		.form-error {
			color: red;
			display: none;
			font-size: .8rem;
			font-style: italic;
			font-weight: 400;
		}
        .name-input{
            margin-right: 10px;
			@include media-breakpoint-down(sm){
                margin-right: 0px;
            }
        }
        .bat-cta-style{
            @include media-breakpoint-up(sm){
                max-width: 200px;
            }
        }
	}
	&-ratings {
		max-width: fit-content;
		display: inline-block;
		border: 0;
		input:checked ~ label, 
		label:hover, label:hover ~ label { color:  $yellow;  }
		
		input:checked + label:hover, input:checked ~ label:hover, 
		input:checked ~ label:hover ~ label,
		label:hover ~ input:checked ~ label { color:  $yellow;  }

		.rating-label{
			padding-top: 20px;
		}
	}

	&-ratings > input{
		display: none;
	}

	&-ratings > label{
		float: right;
	}

	&-ratings > label:before{
		display: inline-block;
		font-size: 1rem;
		padding: .3rem .1rem;
		margin: 0;
		cursor: pointer;
		font-family: FontAwesome;
		content: "\f005 ";
	}

	&-ratings .half:before{
		content: "\f089 ";
		position: absolute;
		padding-right: 0;
	}
	&-comments {
		position: relative;
		.counter {
			bottom: 1rem;
			font-size: .8rem;
			font-weight: normal;
			position: absolute;
			right: 1rem;
			z-index: 1;
		}
	}
}

.bat-reviewslist {
	width: 100%;
	h5 {
		line-height: 1;
		margin-bottom: .75rem;
	}
	&-slot {
		margin-bottom: .75rem;
		width: 100%;
	}
	&-summary {
		color: $gray;
		font-size: .9rem;
		margin-bottom: .75rem;
	}
	&-stars {
		color: $gray;
		margin-bottom: .75rem;
		.star {
			&.active {
				color: $yellow;
			}
		}
	}
	&-list {
		background-color: $off-white;
		padding: 2rem 3rem;
	}
	&-item-wrapper {
		hr {
			background-color: $gray;
			border: 0;
			height: 1px;
			margin: 2rem 0;
		}
	}
	&-title {
		font-size: 1.25rem;
		font-weight: 600;
		margin-bottom: .75rem;
	}
	&-user {
		color: $gray;
		font-size: .9rem;
		margin-bottom: .75rem;
	}
	&-loadmore {
		align-items: center;
		display: flex;
		justify-content: center;
		text-align: center;
		button {
			align-items: center;
			display: flex;
			flex-direction: column;
			font-size: 1rem;
			justify-content: center;
		}
	}
}
