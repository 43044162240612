.bat-blackfriday-gloit {
	padding-top: 20px;
	display: flex;
    justify-content: center;
	z-index: 2 !important;

	.buttons {

		@include media-breakpoint-down(md) {
			display: flex;
			margin: auto;
		}

		.button-container {
			@include media-breakpoint-down(md) {
				width: 50%;
			}

			.button {
				width: 230px;

				&.bat-button--dark {
					z-index: 1;
					pointer-events: none;
					&:focus{
						color: white;
					}
				}

				&.bat-button--secondary--dark {
					z-index: 0;
					background-color: white;
					&:hover{
						color: $brand-black;
					}
				}

				&.boost {
					margin-inline: -50px
				}

				@include media-breakpoint-down(md) {
					display: block;
					width: 180px;

					&.boost {
						margin-inline: -15px;
					}
					&.standard {
						margin-inline: -15px
					}
				}
			}
		}

		.item{
			display: flex;
			align-items: center;
			flex-direction: column;
			text-align: center;

			@include media-breakpoint-down(sm) {
				flex-direction:row;
				gap: 20px;
				text-align: left;
			}
			
			picture{
				width: 80px;
				height: 80px;
				margin-bottom: 10%;
			}
		}
	}
}
.section-full-border-radius{
	border-radius: 30px;
}
.section-border-radius-bottom{
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}
.image-border-radius{
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}
.image-border-radius-bottom-left{
	border-bottom-left-radius: 30px;
}
.image-border-radius-bottom-right{
	border-bottom-right-radius: 30px;
}
.image-border-radius-top-left{
	border-top-left-radius: 30px;
}
.image-border-radius-top-right{
	border-top-right-radius: 30px;
}
.section-margin-top{
	padding-top: 10px;
	background-color: white;
}