.bat-hyperrange-device {
    position: relative;
    overflow: hidden;
    padding: 150px 0;

    .gold-device & {
        padding-top: 111px;
        padding-bottom: 200px;

        @media (max-width: 768px) {
            padding-top: 40px;
            padding-bottom: 60px; 
        }


        .pro {
            display: none;
        }

        .gold {
            margin-top: 60px;
            display: block;
            right: -535px;
        }

        .line {
            height: 1px;
            background-color: $white;
            width: 80%;
            margin: 0 auto;
        }

        .content {
            margin-right: auto;
            margin-left: 6.5vw;
            padding-top: 150px;
            @media (max-width: 768px) {
                height: 650px;
                padding-top: 60px;
                margin-left: 0px;
            }
        }
    }

    .device-img {
        position: absolute;
        box-sizing: content-box;
    }

    .pro {
        left: -440px;
    }

    .gold {
        display: none;
    }

    .content {
        font-size: 50px;
        line-height: 60px;
        font-weight: 500;
        color: $white;
        padding-top: 110px;
        width: fit-content;
        margin-left: auto;
        margin-right: 9vw;

        .text-content {
            margin-bottom: 45px;
            margin-top: 25px;

            sup{
                font-size: 0.3em;
                vertical-align: super;
            }
        }

        .buttons-container {
            display: flex;
            gap: 30px;
            .bat-cta-style.button-secondary-light{
                border: solid 2px white;
            }
        }
    }
}

@media only screen and (max-width: 1300px) and (min-width: 769px) {
    .gold-device .bat-hyperrange-device{
        .gold{
            left: 60%;
        }
        .content {
            .title {
                width: 200px;
            }

            .buttons-container {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
    }
    .bat-hyperrange-device {
        padding: 50px 0;

        .device-img {
            min-width: 500px;
            left: -35%;
            top: 28%;
        }

        .content {
            display: flex;
            flex-direction: column;
            height: 750px;
            justify-content: space-between;
            padding: 0;
            text-align: center;
            align-items: center;
            font-size: 23px;
            line-height: 23px;
            width: 100%;
            margin: 0;

            .title {
                width: 303px;
            }

            .text-content {
                margin-top: 485px;
            }

            .buttons-container {
                display: none;
            }
        }
    }
}

@media (max-width: 768px) {
    .gold-device .bat-hyperrange-device{
        .gold{
            left: 20%;
        }
        .content {
            .title {
                width: 200px;
            }

            .buttons-container {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
    }
    .bat-hyperrange-device {
        padding: 50px 0;

        .device-img {
            min-width: 500px;
            left: -52%;
            top: 25%;
        }

        .content {
            display: flex;
            flex-direction: column;
            height: 450px;
            justify-content: space-between;
            padding: 0;
            text-align: center;
            align-items: center;
            font-size: 23px;
            line-height: 23px;
            width: 100%;
            margin: 0;

            .title {
                width: 303px;
            }

            .text-content {
                margin-top: 315px;
            }

            .buttons-container {
                display: none;
            }
        }
    }
}

@media (max-width: 376px) {
    .device-img {
        left: -65%;
    }
}
