.picture-modal{
	width: 585px;
    background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
    box-shadow: 16px 16px 0px #D5DDEC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    &.first-step {
        height: 60% !important;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        border-radius: 40px 40px 0px 0px;

        .first-section-modal {
            margin-top: 20px !important;
        }
    }
	
	.uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

	.first-section-modal {		
        margin-top: 60px;
        width: max-content;

        @include media-breakpoint-down(md) {
            margin-top: 80px;
        }
        
        .survey-code-box {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 24px;
            gap: 8px;
            background: #0B1047;
            border-radius: 24px;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: $white;

            .icon-picture {
                height: 48px;
                width: 48px;

                &::before {
                    height: 32px;
                }
            }

            .icon-picture-modal::before{
                height: 50px!important;
                transform: scale(1);
            }

            .icon-fancam {
                height: 48px;
                width: 48px;

                &::before {
                    height: 32px;
                }
            }

            .icon-fancam-modal::before{
                height: 50px!important;
                transform: scale(1);
            }
        }
	}

    .survey-step-form {
        overflow-y: visible !important;
        padding-bottom: 0 !important;

        &[data-tab="1"] {
            height: 80%;
        }
    }

    .scrolling-container {
        max-height: 100%;
        display: block;
        width: 100%;
        overflow-y: scroll;
        padding-bottom: 100px;
    }

	.third-section-modal {
		display: flex;
        flex-direction: column;
        justify-content: center;

        .image-uploaded {
            border-radius: 16px;
            max-height: 260px;
            width: auto;
            margin: 0 auto;
        }

        .modal-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            color: $white;
            margin-top: 20px;
            padding-bottom: 10px;
        }

        .description-modal {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: $white;
            margin-bottom: 24px;
        }

	}

    .survey-step-form {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        display: none;

        &.active {
            display: block;
        }
    }

    .fourth-section-modal {
        margin: 0;

        .bat-form--survey-question {
            margin: 0;
            overflow: visible;
            position: relative;
        }

        .bat-form-field-set {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            width: 100%;

            &.fixed-bottom {
                position: fixed;
                bottom: 0;
                left: 0;
                padding: 20px;
                margin: 0;
            }

            label {
                display: flex;
                flex-direction: row;
                padding: 16px 24px;
                gap: 16px;
                width: 457px;
                min-height: 60px;
                background: #D5DDEC;
                align-items: center;
                margin: 0;
                justify-content: space-between;


                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                &.selected {
                    background: $white;
                    //border: 4px solid #B4C7E7
                }
            }

            .file-input {
                width: 0.1px;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                z-index: -1;
            }

            .file-input-button {
                width: 100%;
                background: transparent;
                padding: 0;
                min-height: unset;

                button {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 32px;
                    background: $white;
                    border-radius: 100px;
                    min-height: unset;
                    width: 100%;
                    pointer-events: none;
                    font: inherit;
                }

                span {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                    color: $brand-black;
                }
            }

            .text-area {
                max-width: 457px;
                height: 110px;
                resize: none;
            }

            .number-field {
                max-width: 100px;
                font-size: 24px;
                font-weight: 600;
                height: 60px;
            }

            .date-field {
                max-width: 457px;
                height: 60px;
            }
        }
    } 

    .fifth-section-modal {
        display: flex;
        flex-direction: column;
        align-items: center;

        #modal-picture & {
            position: fixed;
            padding: 0 20px;
            left: 0;
            bottom: 20px;
            width: 100%;

            .mgm-error-message {
                margin-bottom: 10px !important;
            }

            .bat.button-container {
                position: static !important;
                padding: 0 !important;
            }
        }

        .cta-proceed,
        .cta-send {
            padding: 10px 32px;
            background: $white;
            border-radius: 24px;
            font-family: inherit;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: #2A2C2C;
            margin-bottom: 40px;

            @include media-breakpoint-down(md) {

                margin-bottom: 24px;
                span {
                    width: max-content;
                }
            }

            &:hover {
                opacity: 0.9;
            }

            &:disabled {
                opacity: 0.8;
                pointer-events: none;
            }
        }

        .mgm-error-message {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 24px;
            gap: 16px;
            background: #FDF3F5;
            border-radius: 8px;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.2px;
            color: #E00830;
            margin-bottom: 40px;
            width: 420px;

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-bottom: 24px;
            }

            .icon-warning {
                &::before {
                    content: url('/content/dam/glo-it/images/newloyalty/Warning.svg');
                }
            }
        }
    }

    .picture-container {
        height: 100%;
        width: 100%;
        background-color: $brand-black;

        .stickers-section {
            width: 100%;
            height: 145px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
            border-radius: 24px 24px 0px 0px;

            &-list {
                margin-top: -40px;

                &-item {
                    width: 80px;
                    height: 80px;
                    background: #3A3A3A;
                    border: 2px solid #DEDEDE;
                    border-radius: 8px;
                    margin-left: 8px;

                    &:last-child {
                        margin-right: 8px;
                    }

                    &.active {
                        border: 2px solid #E97129;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                &.disabled {
                    display: none;
                }
            }

            &-cta {
                display: none;
                justify-content: center;
                align-items: center;
                padding: 0px;
                gap: 8px;
                width: 48px;
                height: 48px;
                background: #FFFFFF;
                border-radius: 100%;
                position: absolute;
                bottom: 40px;
                right: 24px;

                &.active {
                    display: flex;
                }

                .bat-icon.icon-arrow-right::before {
                    margin-top: 0;
                }
            }

            &-bin {
                display: none;
                width: 100%;
                height: 100%;

                .cta-remove-sticker {
                    height: 80px;
                    width: 80px;
                }

                &.active {
                    display: flex;
                }
            }
        }

        #editor {
            display: none;
            width: 100%;
            height: 100%;
        }

        .ctx-parent {
            width: 100%;
            height: 85%;
        }

        .ctx-parent .layer {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            display: flex;
        } 
        
        .ctx-parent .layer img {
            max-width: 100%;
            object-fit: contain;
        }

        .ctx-parent .layer .rotater {
            width: 20px;
            height: 20px;
            background: rgba(133,133,133,.5);
            border: solid 1px #fff;
            position: absolute;
            display: none;
            margin-top: -10px;
            margin-right: -10px;
        }
                  
        .ctx-parent .layer .rotater.right-top{
            top: 0;
            right: 0;
        }
        
        .ctx-parent .layer .rotater.right-bottom {
            bottom: 0;
            right: 0;
            margin-bottom: -10px;
        }

        .ctx-parent .layer .rotater.left-top {
            left: 0;
            margin-left:  -10px;
        }
        
        .ctx-parent .layer .rotater.left-bottom {
            left: 0;
            bottom: 0;
            margin-bottom: -10px;
            margin-left:  -10px;
        }
        
        .ctx-parent .layer.active {
            outline: dashed 3px #E1E1E1;
            z-index: 1000;
        }
        
        .ctx-parent .layer.active .rotater {
            display: block;
        }
    }

    .send-picture-section {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
        border-radius: 40px 40px 0px 0px;
        z-index: 99999;

        &.active {
            display: block;
        }

        .first-section-modal {
            margin: 0 auto;
            margin-top: 80px;
        }

        .third-section-modal {
            padding: 0 20px;
        }

        .fourth-section-modal {
            .bat.button-container {
                position: static !important;
                display: grid;
                padding: 30px 20px !important;
                gap: 20px;

                .cta-send {
                    padding: 10px 32px;
                    background: white;
                    border-radius: 24px;
                    font-family: inherit;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                    color: #2A2C2C;
                }

                .cta-exit {
                    width: auto !important;
                    margin: 0 auto;
                    font-size: 14px;
                }
            }
        }
    }
}