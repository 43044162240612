// sass-lint:disable class-name-format, no-qualifying-elements, force-element-nesting, force-pseudo-nesting, force-attribute-nesting

.bat-quiz-question {
	left: -15px;
	margin-bottom: 104px;
	position: relative;
	@include media-breakpoint-down(md) {
		left: 0;
		margin-bottom: 60px;
	}

	&__question {
		margin-bottom: 41px;
		@include media-breakpoint-down(md) {
			margin-bottom: 30px;
		}
		p {
			color: $dark-blue;
			font-size: 28px;
			font-weight: 900;
			letter-spacing: -1px;
			line-height: 1.3;
			text-transform: uppercase;
		}
	}

	&__option {
		align-items: center;
		display: flex;
		margin-bottom: 35px;
		margin-left: 20px;
		@include media-breakpoint-down(md) {
			margin-bottom: 32px;
			margin-left: 0;
		}

		input[type="radio"] {
			-webkit-appearance: inherit;
			background-color: $light-gray;
			border: 1px solid $light-gray;
			border-radius: 50%;
			box-shadow: inset 0 5px $gray;
			cursor: pointer;
			display: inline-block;
			height: 30px;
			margin-right: 20px;
			width: 30px;
			&:checked {
				background: $dark-gray;
				box-shadow: none;
			}
		}

		label {
			align-items: center;
			color: $dark-blue;
			cursor: pointer;
			font-size: 20px;
			font-weight: 500;
			vertical-align: middle;
		}
	}
}
