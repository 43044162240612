.bat-bloglist-gloit{

    &-title{
        @include media-breakpoint-down(md){
            display: block
        }

        .title{
            @include font-rem(34px, 39px);

            @include media-breakpoint-down(md){
                @include font-rem(26px, 32px);
                text-align: center;
            }
        }

        .filter{
            @include media-breakpoint-down(md){
               justify-content: center;
               margin-top: 30px;
            }

            .label{
                @include font-rem(12px, 15px);
                margin-bottom: 0px;
                margin-right: 20px;
                align-self: center;
                text-transform: uppercase;

                @include media-breakpoint-down(md){
                    width: 25%; 
                    margin-right: 0px;   
                }
            }

            .select-wrapper{
                width: 300px;

                @include media-breakpoint-down(md){
                    width: 75%;    
                }

                .select{
                    border-radius: 0px;
                    -webkit-border-radius: 0px;
                }
            }
        }
    }


    .bat-cardnews-gloit--news{    
        
        @include media-breakpoint-down(md){
            padding-top: 40px;
            padding-bottom: 40px;
            padding-inline: 0px;
            border-bottom: 2px solid #DEDEDE;
        }
    }

    .bat-cardnews-gloit{
        padding-top: 50px;

        @include media-breakpoint-down(md){
            padding-top: 0px;
        }
    }
    
    .bat-cardnews-gloit--news-img{
        max-height: none;
        height: 363px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1399px) {
            max-height: none;
            height: auto;
            display: block;
        }
    }

    .bat-cardnews-gloit--news-main{
        .bat-cardnews-gloit--news-text{
            h3{
                margin-top: 0px;
            }
        }
    } 



    &-blogs{
        .aem-GridColumn{
            &.aem-GridColumn--default--12 {
                &.news-card{
                    padding-top: 0px;
                    background-color: white;
                }
            }
        }

        .aem-GridColumn--default--12{
            padding-inline: 0px;
        }

        .bat-cardnews-gloit--news{    
            a{
                picture{
                   /*img{
                        max-height: 363px;
                        height: 363px;

                        @media screen and (max-width: 1399px) {
                            max-height: none;
                            height: auto;
                        }
                    }*/
                }
            }        
            @include media-breakpoint-down(md){
                padding-top: 40px;
                padding-bottom: 40px;
                padding-inline: 0px;
                border-bottom: 2px solid #DEDEDE;
            }
        }

        .bat-cardnews-gloit{
            padding-top: 50px;

            @include media-breakpoint-down(md){
                padding-top: 0px;
            }
        }
        
        .bat-cardnews-gloit--news-img{
            max-height: none;
            height: 363px;
            display: flex;
            align-items: center;

            @media screen and (max-width: 1399px) {
                max-height: none;
                height: auto;
                display: block;
            }
        }

        .bat-cardnews-gloit--news-main{
            .bat-cardnews-gloit--news-text{
                h3{
                    margin-top: 0px;
                }
            }
        }   
    }

    &-pagination{
        margin-top: 40px;

        &.hidden{
            display: none;
        }

        .bat-pagination{
            .bat-pagination-buttons{
                .bat-pagination-button-wrapper{
                    button{
                        &.active{
                            background-color: #2F539F;

                            &:hover{
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}