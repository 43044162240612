.bat-carousel-cardnew-gloit {
	.slides-container{
		//padding: 20px;
		@include media-breakpoint-up(md) {
			//padding:60px;
		}

		.title-section{
			@include media-breakpoint-down(sm){
				display: block;
				text-align: center;
				padding-top: 30px;
			}
			padding-bottom: 30px;
			.product-section-title{
				font-weight: 800;
				font-size: 26px;
				line-height: 30px;
			}
			.product-section-link{
				span{
					margin-right: 10px;
				}
				
				font-weight: 800;
				font-size: 16px;
				line-height: 28px;
			}
		}
	}
	
	max-width: 100%;
	//margin-bottom: 50px;
	@include media-breakpoint-down(sm){
		margin-bottom: 0px;
	}

	.slick-initialized {
		.slick-slide {
			padding-left: 20px;
		}
	}
	.arrows-container-new{
        position: absolute;
        display: flex;
        margin-top: -2%;
        left: 77%;
        .next-arrow{
            margin-left: 50px; 
            @include media-breakpoint-down(sm){
                margin-left: 20px; 
            }
            .icon-chevron-right{
                margin-left: 15px;
            }
            .bat-icon::before{
                color: white;
                content: url('/content/dam/glo-it/images/Glo-Arrow-right.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }  
        }
        .prev-arrow{
            .bat-icon::before{
                color: white;
                content: url('/content/dam/glo-it/images/Glo-Arrow-left.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }  
        }
        @include media-breakpoint-down(sm){
            left: 74%;
			margin-top: 4%;
        }


        
    }

	.slick-dots{
		display: flex;
		justify-content: left;
		margin-left: 7%;
		top: 103% !important;
		@include media-breakpoint-up(md) {
			display: none !important;
		}
   
        li{
            display: block !important;
            @include media-breakpoint-down(sm){
                margin-top: 0% !important;
            }
            button{
                background-color: #7e9c9f !important;
                border: 1px solid #7e9c9f!important;
                height: 6px;
                width: 6px;
            }
        }
        li.slick-active{
            button{
                width: 6px;
                height: 6px;
                background-color: #3A3A3A!important;
                border: 1px solid #3A3A3A !important;
            }
        }
       
    }	
	.slick-dots {
		display: flex;
		justify-content: left;
		margin: 0 auto;
		@include media-breakpoint-up(md) {
			display: none !important;
		}
	}

	.navigation-arrows {
		position: absolute;
    	width: calc(100% - 42px);
		@include media-breakpoint-up(md) {
			width: calc(100% - 122px);
		}
    	margin-top: 5px;

		
		.slick-next, 
		.slick-prev {
			.bat-icon:before {
				font-size: 18px !important;
			}
		}
	}


	bat-productcard-gloithome {
		padding-left: 17px;
		padding-right: 17px;
	}
}

.bat-carousel .slick-next{
	display:block;
	right: -60px;
	@include media-breakpoint-up(md) {
		display: none !important;
	}
}

.bat-carousel .slick-prev {
	display: block;
	top: -3px;
	@include media-breakpoint-up(md) {
		display: none !important;
	}
}