bat-productcard-globrochureware {
	.bat-productcard {
		&--glo-brochureware {
			display: flex;
			flex-direction: column;
			height: 100%;
			margin-bottom: 20px;
			max-width: 375px;
			opacity: 0;
			padding: 20px 16px;
			position: relative;
			transition: opacity 0.2s ease-in;
			width: 100%;

			&.loaded {
				opacity: 1;
			}

			.bat-productcard-tile {
				flex: 1 0 140px;
				height: auto;
				margin: 0;
				margin-bottom: 10px;
				text-align: center;

				&-image {
					padding-left: 8px;
				}

				img {
					height: auto;
					padding-right: 0;
					width: 100%;
				}
			}

			.bat-productcard-content {
				display: flex;
				flex-direction: column;
				padding-left: 26px;
				width: auto;

				&-cta-list {
					display: flex;
					flex-direction: column;
					margin: auto 0 0 auto;
					width: 100%;

					.bat-cta-style {
						margin: 0;
						display: flex;
						width: 100%;
						&.arrow-link-dark,
						&.arrow-link-light {
							width: fit-content;
						}
						span {
							text-transform: uppercase;
						}
					}	

					.bat-button {
						padding: 11px 0;
					}
				}

				&-name {
					@include font-rem(16px, 125%);
					font-weight: 400;
					margin-bottom: 10px;
					text-align: left;
					text-transform: capitalize;
					color: $brand-black;
				}
			}
		}
	}
}

bat-section-default {
	&.recommended-products {
		bat-productcard-globrochureware {
			.bat-productcard--glo-brochureware {
				flex-direction: column;
				padding: 3px;

				.bat-productcard-content {
					padding: 0;

					& > * {
						order: 1;
					}

					&-name {
						font-weight: 700;
						margin-bottom: 5px;
					}
				}
				.bat-productcard-tile {
					margin-bottom: 0;
					&-image {
						padding: 0;
						text-align: center;
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(sm) {
	bat-productcard-globrochureware {
		.bat-productcard {
			&--glo-brochureware {
				max-width: 339px;

				.bat-productcard-content {
					padding-left: 4px;
					&-cta-list {
						margin-bottom: 15px;
						padding-bottom: 0;
						padding-top: 15px;
					}
				}
			}
		}
	}
}
