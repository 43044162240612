.bat-cardentraincontatto-gloit{
    &--news {
		margin-top: 8px;

		@include media-breakpoint-down(xs) {
			margin-bottom: 0;
			margin-top: 0;
			padding: 40px 10px 30px;
		}

        &-main{
            display: flex;
            flex-direction: column;
            width: auto;
            max-width: 423px;
            border: 3px solid #000;
            background: #E1ECE5;
            padding: 23px;
            margin: auto;
            @include media-breakpoint-down(sm) {
                width: auto;
                height: 335px;
            }
        }
        &-reviews {
            display: flex;
            flex-direction: row-reverse;
            p{
                font-size: 13px;
            }
		}

		&-img {
			height: 100%;
			max-height: 240px;
			overflow: hidden;

			a {
				display: block;
			}
		}

        &-title{
            h3{
                font-size: 18px;
                font-weight: 800;
            }
            
        }

		&-text {
			margin: 15px 0;

			@include media-breakpoint-down(xs) {
				margin: 8px 0 5px;
			}

			h3 {
				font-size: 20px;
				line-height: 150%;
				padding-top: 15px;

				@include media-breakpoint-down(xs) {
					padding-top: 4px;
				}
			}
			p {
				font-size: 15px;
				line-height: 150%;
				margin-top: 5px;

				@include media-breakpoint-down(xs) {
					color: $dark-gray;
				}
			}
		}

        &-content{
            p{
                font-size: 14px;
            }
        }
		
	}
}


.bat-entraincontattoexpander-gloit{
    width: 640px;

    @include media-breakpoint-down(md) {
        width: auto;
    }

    .uk-accordion{
        li{
            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: column;
                align-content: flex-end;
                align-items: center;
            }
        }
        .uk-accordion-title{
            align-items: center;
            height: 47px;
            align-content: center;
            padding: 20px 12px;
            border-radius: 51px;
            background: linear-gradient(180deg, #FAA61A 0%, #F16121 100%);
            margin: 10px;
        }
        .uk-accordion-title::before{
            background-image: url("/content/dam/glo-it/images/plusExpander.png");
            background-repeat: no-repeat;
            margin: 0;
            
        }
        .uk-open{
            border-radius: 16px;
            background: rgba(255, 255, 255, 0.30);
            .uk-accordion-title::before{
                background-image: url("/content/dam/glo-it/images/minusExpander.png");
                background-repeat: no-repeat;
            }
            .uk-accordion-content{
                text-align: center;
                padding-left: 35px;
                padding-right: 35px;
                padding-bottom: 35px;
            }


            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: column;
                align-content: flex-end;
                align-items: center;
            }
        }
        
    }
}

.whiteSpan{
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-family: Mont;
    font-style: italic;
    font-weight: 950;
    line-height: 120%;
    @include media-breakpoint-down(sm) {
        font-size: 23px;
    }
}

.spanWithBorder{
    color: black;
    text-align: center;
    font-size: 40px;
    font-family: Mont;
    font-weight: 950;
    line-height: 120%;
    border: 6px solid;
    padding-left: 15px;
    padding-right: 15px;
    display: inline-block;
    transform: skewX(-10deg);
    -webkit-transform: skewX(-10deg);
    background: #E1ECE5;
    @include media-breakpoint-down(sm) {
        font-size: 23px;
    }
}

bat-carousel-gloitentraincontatto{
	&.thr-centered-dots{
		.slick-dots {
		margin-left:0;
		justify-content: center;
		}
		.slick-dots li button {
            border: none;
            background-color: #E0E0E0 !important;
        }
		.bat-carousel .slick-dots li.slick-active button{
			width: 7px;
			background-color: #000 !important;
			border: 1px solid #000 !important;
		}
		.bat-carousel-entraincontatto-card-gloit .bat-carousel-slides .slick-list{
			padding: 0px 30px !important;
		}
	}
	&.hide-white-space{
		.d-sm-block .d-block{
			display: contents;
		}
	}
}