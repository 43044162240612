bat-reviewslist-gloitnew {
    .bat-reviewslist-gloit-new {
        background-color: transparent;
        padding-inline: 24px;
        @include media-breakpoint-down(sm) {
            padding-inline: 0px;
            padding-block: 0px;
        }
        &-container {
            border-radius: 40px;
            background: var(--glo-2024-glo-Charcoal, #2a2a2a);
            gap: 150px;
            @include media-breakpoint-down(sm) {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
            @include media-breakpoint-up(sm) {
                .bat-modal {
                    &.active {
                        display: contents;
                    }

                    .bat-modal-content {
                        width: inherit;
                        background-color: $charcoal;
                        top: 0px;

                        i::before {
                            color: $white;
                        }
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                display: block;
                padding: 30px 20px;

                .add-review-cta {
                    display: flex;
                    align-items: center;
                    min-width: 100%;
                    min-height: 48px;
                    margin-top: 0px;
                    margin-bottom: 35px;
                    border: solid 2px #fff;
                    color: var(--glo-2024-White, #fff);
                    text-align: center;
                    font-family: Mont;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 120%; /* 15.6px */
                    letter-spacing: 0.7px;
                    padding: 0px;
                    &-flex {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        text-transform: none;
                    }
                }

                .bat-message {
                    display: none;

                    &.show {
                        display: block;
                        background-color: $red;
                        margin: 20px 0px;

                        .md {
                            color: $white;
                            font-weight: 600;
                            a {
                                color: $white;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .container-survey {
                display: flex;
                flex-direction: column;
                width: 30%;
                @include media-breakpoint-down(md) {
                    width: 40%;
                }

                @include media-breakpoint-down(sm) {
                    width: 60%;
                }

                .bat-message {
                    @include media-breakpoint-up(sm) {
                        padding: 20px 40px;
                    }

                    @include media-breakpoint-down(sm) {
                        width: auto;
                        text-align: center;
                        margin-bottom: 40px;
                    }

                    display: none;

                    &.show {
                        display: block;
                        background-color: $red;
                        margin-top: 0;
                        margin-right: 40px;
                        margin-left: 60px;
                        margin-bottom: 55px;
                        @include media-breakpoint-down(sm) {
                            margin-right: 0px;
                            margin-top: 45px;
                            margin-bottom: 20px;
                        }

                        &::after {
                            content: "";
                        }

                        .md {
                            color: $white;
                            @include font-rem(12px, 18px);
                            font-weight: 600;
                            a {
                                color: $white;
                                @include font-rem(12px, 18px);
                                font-weight: 600;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                .bat-reviewslist-gloit-coin {
                    @include media-breakpoint-down(sm) {
                        position: relative;
                        margin-top: 25px;
                        margin-bottom: 30px;
                        margin-left: 0px;
                    }
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 8px;
                    gap: 8px;

                    position: absolute;
                    width: 87px;
                    height: 30px;
                    margin-left: 60px;
                    margin-top: 25px;

                    /* Primary/Light blue */

                    background: #d5ddec;
                    border-radius: 40px;
                    .number-of-coin {
                        width: 71px;
                        height: 14px;

                        /* Desktop/Heading 5 */

                        //font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 14px;
                        /* identical to box height, or 117% */

                        letter-spacing: 1.2px;
                        text-transform: uppercase;

                        /* Loyalty/Dark loyalty blue */

                        color: #0b1047;

                        /* Inside auto layout */

                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }
                }

                .bat-reviewslist-gloit-container-title {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                    @include media-breakpoint-up(sm) {
                        margin-top: 20px;
                        padding: 60px 13px;
                        padding-right: 0px;
                    }

                    .title {
                        color: var(--glo-2024-White, #fff);
                        font-family: Mont;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 20px; /* 66.667% */
                        letter-spacing: 1.2px;
                        margin-bottom: 20px;
                    }

                    .reviewsNumber {
                        color: var(--glo-2024-Warm-Grey, #e5e1e6);
                        font-family: Mont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%; /* 16.8px */
                        letter-spacing: 0.7px;
                        text-transform: uppercase;
                        opacity: 0.6;
                    }

                    .add-review-cta {
                        display: flex;
                        align-items: center;
                        min-height: 48px;
                        padding-left: 29px;
                        padding-right: 36px;
                        margin-top: 40px;
                        border: solid 2px #fff;
                        color: var(--glo-2024-White, #fff);
                        text-align: center;
                        font-family: Mont;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 120%; /* 15.6px */
                        letter-spacing: 0.7px;

                        &-flex {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            text-transform: none;
                        }
                    }
                }
                .container-coin {
                    width: 335px;
                    display: flex;
                    flex-direction: column;
                    .image-banner {
                        align-self: center;
                        margin-top: 10px;
                        margin-left: -245px;
                        position: relative;
                        min-width: 60px;
                        top: -145px;
                    }

                    .coin {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-right: -37px;
                        padding: 12px 0px 12px 91px;
                        gap: 8px;
                        text-align: center;

                        //position: absolute;
                        //width: 62px;
                        //height: 280px;
                        left: 0px;
                        //top: 1321px;

                        /* Gradient/Secondary */

                        border-radius: 24px;
                        background: var(--glo-2024-Hog-2024-HoG-Gradient, linear-gradient(103deg, #3980BE 19.09%, #30338C 80.91%));

                        .text-survey {
                            color: var(--glo-2024-White, #FFF);
                            font-feature-settings: 'liga' off, 'clig' off;
                            font-family: Mont;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%; /* 21.6px */
                            text-transform: uppercase;
                            text-align: left;
                            /* Inside auto layout */
                            flex: none;
                            order: 0;
                            flex-grow: 0;
                        }

                        .second-text-survey {
                            //height: 15px;
                            opacity: 0.8;
                            text-align: left;
                            color: var(--glo-2024-Cool-Grey, #DDE5ED);
                            font-feature-settings: 'liga' off, 'clig' off;
                            font-family: Mont;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 130%; /* 18.2px */
                            /* Inside auto layout */
                            flex: none;
                            order: 1;
                            flex-grow: 0;
                        }

                        .sign-up-insiders-club {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            //padding: 10px 32px;

                            width: 265px;
                            height: 37px;

                            /* Neutral/White */

                            background: #ffffff;
                            border-radius: 24px;

                            /* Inside auto layout */

                            margin-top: 8px;
                            flex: none;
                            order: 1;
                            align-self: stretch;
                            flex-grow: 0;
                            z-index: 1;
                        }

                        .sign-up-insiders-club-text {
                            width: 260px;
                            height: 40px;

                            /* Button/CTA */

                            //font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 17px;
                            /* identical to box height, or 120% */

                            text-align: center;
                            letter-spacing: 0.7px;
                            text-transform: uppercase;

                            /* Neutral/Brand black */

                            color: #2a2c2c;

                            /* Inside auto layout */

                            flex: none;
                            //order: 0;
                            flex-grow: 0;
                        }
                    }
                }
            }

            &-reviews {
                @include media-breakpoint-up(sm) {
                    padding: 30px 60px;
                    padding-left: 0px;
                }

                &.hidden {
                    display: none;
                }

                width: 50%;
                @include media-breakpoint-down(sm) {
                    width: auto;
                }

                .review-container {
                    background: var(--glo-2024-Warm-Grey, #393939);
                    border-radius: 32px;
                    padding-inline: 34px;
                    padding-block: 34px;
                    margin-bottom: 16px;

                    @include media-breakpoint-down(sm) {
                        padding: 20px;
                    }

                    &.hidden {
                        display: none;
                    }

                    &-upper {
                        align-items: baseline;
                        @include media-breakpoint-down(sm) {
                            justify-content: space-between;
                        }
                        .date-review {
                            color: var(--glo-2024-Warm-Grey, #e5e1e6);
                            font-feature-settings:
                                "liga" off,
                                "clig" off;
                            font-family: Mont;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px; /* 142.857% */
                            opacity: 0.6;
                            margin-left: 60px;
                        }
                    }
                }

                .review-by {
					color: #E5E1E6;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; 
                    font-family: Mont;
                    margin-left: 0;
                    opacity: 0.6;

                    span.image-gloers {
                        margin-left: 12px;
                    }

                    img {
                        height: 18px;
                    }
                }

                .title {
                    font-size: 18px;
                    line-height: normal;
                    font-family: Mont;
                    color: $white;
                    font-weight: 700;
                    margin-top: 15px;
                    margin-bottom: 11px;
                    @include media-breakpoint-down(sm) {
                        margin-top: 10px;
                        margin-bottom: 5px;
                    }
                }

                .text {
                    margin-top: 20px;
                    margin-bottom: 0;
                    color: var(--glo-2024-Warm-Grey, #e5e1e6);
                    font-feature-settings:
                        "liga" off,
                        "clig" off;
                    font-family: Mont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; 
                    }
                }

                .page-selector {
                    color: $white;

                    .pages {
                        list-style: none;
                        padding-right: 16px;
                    }

                    .page {
                        padding: 4px 11px;
                        background: orangered;
                        border-radius: 100%;
                        font-size: 14px;
                        width: 27px;
                        height: 25px;
                    }
                }

                .bat-pagination {
                    margin-top: 35px;
                    align-items: flex-start;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 50px;
                        align-items: center;
                    }

                    &-label {
                        @include font-rem(14px, 14px);
                        color: $brand-black;
                        margin: 10px 0;
                    }

                    &-buttons {
                        align-items: center;
                        display: flex;
                        flex-wrap: wrap;
                    }

                    &-button-wrapper {
                        button {
                            @include font-rem(14px, 14px);
                            border-radius: 52px;
                            color: $white;
                            height: 30px;
                            transition: color 0.1s ease-in;
                            width: 30px;

                            &.active {
                                background-color: #ffffff;
                                color: black;
                                font-weight: 700;
                            }

                            &:hover {
                                color: $black;

                                i {
                                    &:before {
                                        color: $white;
                                    }
                                }
                            }

                            i {
                                height: 30px;
                                width: 30px;

                                &:before {
                                    color: $white;
                                    font-size: 20px;
                                    transition: color 0.1s ease-in;
                                }
                            }
                        }
                    }

                    .button-pages-container {
                        display: flex;

                        .bat-pagination-button-wrapper-page {
                            button {
                                @include font-rem(14px, 14px);
                                border-radius: 52px;
                                color: $white;
                                height: 30px;
                                transition: color 0.1s ease-in;
                                width: 30px;
                                margin-left: 5px;
                                margin-right: 5px;
                                font-family: $primary-font;

                                &.active {
                                    background-color: #ffffff;
                                    color: black;
                                    font-weight: 700;
                                }

                                &:hover {
                                    color: $black;

                                    i {
                                        &:before {
                                            color: $white;
                                        }
                                    }
                                }

                                i {
                                    height: 30px;
                                    width: 30px;

                                    &:before {
                                        color: $white;
                                        font-size: 20px;
                                        transition: color 0.1s ease-in;
                                    }
                                }
                            }

                            &.hidden {
                                display: none;
                            }
                        }

                        .more-pages {
                            font-family: $primary-font;
                            color: $white;
                            font-size: 16px;
                            letter-spacing: 3px;
                            margin-left: 10px;

                            &.hidden {
                                display: none;
                            }
                        }
                    }

                    &.hidden {
                        display: none;
                    }
                }

                .noReviewMessage {
                    @include font-rem(20px, 20px);
                    font-family: $primary-font;
                    color: $white;
                }
            }
        }

        .rating-white {
            display: inline-block;
            unicode-bidi: bidi-override;
            color: #ffca43;
            font-size: 13px;
            letter-spacing: 5px;
            height: 23px;
            width: auto;
            margin: 0;
            position: relative;
            padding: 0;
            font-family: FontAwesome;
        }

        .rating-upper {
            color: #ffca43;
            padding: 0;
            position: absolute;
            z-index: 1;
            display: flex;
            top: 0;
            left: 0;
            overflow: hidden;
            &::before {
                content: "\f005 \f005 \f005 \f005 \f005";
            }
        }

        .rating-lower {
            padding: 0;
            display: flex;
            z-index: 0;
            &::before {
                content: "\f006 \f006 \f006 \f006 \f006";
            }
        }
    }
}
