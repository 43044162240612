.bat-card-gloitroundedvideo {
    display: inline-block;
    position: relative;
    border-radius: 40px;
    overflow: hidden;
    width: 430px;
    height: 303px;
    background-color: black;
    margin-bottom: 65px;
    margin-top: 20px;
    @include media-breakpoint-up(md) {
      box-shadow: 0px 0px 25.183px 0px rgba(0, 0, 0, 0.40);
  }

    @media only screen and (max-width: 1328px) and (min-width: 1196px){
      width: 390px !important;
      height: 290px;
    }

    @media only screen and (max-width: 1198px) and (min-width: 1066px){
      width: 350px !important;
      height: 250px;
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 0px;
  }

    @include media-breakpoint-down(sm) {
        width: 335px;
        height: 236px;
        margin-bottom: 0px;
        margin-top: 0px;
        background-color: #37beeb;
    }
  
    .video-container {
      position: relative;
      width: 100%;
      height: 100%;
    }
  
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  
    .play-button {
      position: absolute;
      bottom: 42%;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
  
    }
  }
  