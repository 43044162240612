.bat-discoverfeature-gloit {
    display: flex;
    flex-direction: column;
    position: relative;

    .stepped-animation {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
    }

    .big-text {
        color: #FFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 240px;
        font-style: normal;
        font-weight: 700;
        line-height: 106px;
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
    }

    .description-text {
        position: absolute;
        top: 50%;
        left: 10%;
        gap: 7px;
        width: 35%;

        &-heading {
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 64px;
            font-style: normal;
            font-weight: 600;
            line-height: 76px;

            
        }

        &-desc {
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
    }

    @include media-breakpoint-down(md) {
        .big-text {
            font-size: 70px;
            line-height: 106px;
        }
    
        .description-text {
            top: unset;
            bottom: 15%;
            left: unset;
            gap: 7px;
            width: 100%;
            padding: 20px;

    
            &-heading {
                font-size: 37px;
                line-height: 40px;
            }
    
            &-desc {
                font-size: 14px;
                line-height: 19px;
            }
        }
    }
}

.bat-discoverintro-gloit {
    display: flex;
    flex-direction: column;
    position: relative;
    @include media-breakpoint-down(sm) {
        margin-top: -90px;
    }

    .full-animation-container {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .stepped-animation {
        height: 100%;
        width: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        .gs-image-top-resize {
            width: 50%;
            height: auto;
            will-change: transform, opacity;
            @include media-breakpoint-down(sm) {
                width: 85%;
            }
        }

        .bat-video-iframe {
            width:100%;
            height:45%;
            position:static;
            border:0;
            border-radius:5px;
        }

        .description-text {
            position: absolute;
            gap: 20px;
            top: 55%;
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            will-change: transform, opacity;
    
            &-heading {
                color: #FFF;
                font-feature-settings: 'clig' off, 'liga' off;
                text-shadow: 0px 4px 35px #000;
                font-size: 50px;
                font-style: normal;
                font-weight: 500;
                line-height: 54px;

                sup{
                    font-size: 0.3em;
                    vertical-align: super;
                }
            }
    
            &-desc {
                color: #FFF;
                font-feature-settings: 'clig' off, 'liga' off;
                text-shadow: 0px 4px 35px #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }

        @include media-breakpoint-down(md) {
            .description-text {
                top: unset;
                bottom: 28%;
                left: unset;
                gap: 20px;
                width: 100%;
                padding: 26px;
        
                &-heading {
                    font-size: 24px;
                    line-height: 31px;
                }
        
                &-desc {
                    font-size: 14px;
                    line-height: 19px;
                    width: 100%;
                }
            }
        }
    }
    
    .gs-image-hide {
        position: absolute;
        will-change: transform, opacity;
    }

    .gs-cta-hide {
        position: fixed;
        bottom: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 999;
        will-change: transform, opacity;

        @include media-breakpoint-down(md) {
            bottom: 80px;
        }
    }

    .gs-cta-hide.index-back {
        z-index: 1;
    }

    .gs-modal-video {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        will-change: transform, opacity;

        @include media-breakpoint-down(md) {
            padding: 0 20px;
        }
    }

    .stepped-animation-two {
        height: 80%;
        width: 100%;
        overflow-y: visible;
        position: absolute;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    @include media-breakpoint-down(md) {   

        .gs-image-top-resize {
            position: relative;
            top: 5%;
            width: 45%;
        }

    }

    .stepped-animation-three {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;

        .big-text {
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 240px;
            font-style: normal;
            font-weight: 500;
            line-height: 106px;
            position: absolute;
            top: 45%;
            width: 100%;
            text-align: center;
            will-change: transform, opacity;
        }
    
        .description-text {
            position: absolute;
            top: 40%;
            left: 10%;
            gap: 7px;
            will-change: transform, opacity;
    
            &-heading {
                color: #FFF;
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 64px;
                font-style: normal;
                font-weight: 500;
                line-height: 76px;
            }
    
            &-desc {
                color: #FFF;
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }
    
        @include media-breakpoint-down(md) {
            .big-text {
                font-size: 70px;
                line-height: 106px;
            }
        
            .description-text {
                top: unset;
                bottom: 35%;
                left: unset;
                gap: 7px;
                width: 100%;
                padding: 20px;
    
        
                &-heading {
                    font-size: 34px;
                    line-height: 40px;
                }
        
                &-desc {
                    font-size: 14px;
                    line-height: 19px;
                }
            }
        }
    }
}

.pin-spacer {
    background-color: #3f675e;
}
