bat-productfeatures-gloitspecificationshyperpro{
    &.pdp-hyper-core{
        background-color: white;

        .bat-productfeatures-gloitspecificationshyperpro {
            background-color: white;

            &-title {
                color: black;
                font-weight: 700;
            }

        }
    }
    background-color: black;
    padding-bottom: 74px;
    @include media-breakpoint-down(sm) {
        padding-inline: 20px;
        padding-bottom: 59px;
        padding-top: 46px;
    }
    .bat-productfeatures-gloitspecificationshyperpro {
        background-color: black;
        max-width: 1400px;
        margin-inline: auto;
        &-container {
            align-items: center;
            justify-content: space-around;
        }
    
        &-title {
            color: white;
            margin-bottom: 30px;
            font-size: 30px;
            @include media-breakpoint-down(sm) {
                font-size: 24px;
            }
        }
        &-text {
            margin-left: 70px;
            gap: 78px;
            width: 100%;
            justify-content: space-around;
            background: var(--glo-2024-glo-Charcoal, #2a2a2a);
            border-radius: 40px;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.24);
            padding-block: 101px;
            padding-inline: 38px;
            min-height: 415px;
            max-width: 864px;
    
            @include media-breakpoint-down(sm) {
                padding-top: 34px;
                padding-bottom: 34px;
                padding-inline: 24px !important;
            }
    
            .first-column,
            .second-column {
                width: 100%;
                &-upper {
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
    
                    svg{
                        opacity: 0.4;
                        transition: transform 0.5s ease-in, opacity 0.5s ease-in;
    
                        &.open{
                            transform: rotate(180deg);
                            opacity: 1;
                        }
                    }
                }
    
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    &-upper {
                        align-items: flex-start;
                    }
                }
            }
    
            @include media-breakpoint-down(sm) {
                display: block;
                margin: 0px;
            }
    
            .title {
                @include font-rem(18px, 23.4px);
                font-weight: 500;
                transition: font-weight 0.6s ease;
                font-family: $primary-font;
                color: white;
    
                &.open{
                    font-weight: 700;
                }
    
                @include media-breakpoint-down(sm) {
                    width: 57%;
                }
            }
            .full-specifications-cta {
                @include media-breakpoint-up(sm) {
                    display: none;
                }
    
                .text {
                    @include font-rem(16px, 27px);
                    font-weight: 700;
                    letter-spacing: 1px;
                    font-family: $primary-font;
                    color: white;
                }
    
                &.hidden {
                    display: none;
                }
            }
    
            .border {
                border-bottom: 1px solid #e5e1e6;
                opacity: 0.4;
                margin-top: 22px;
                margin-bottom: 22px;
                width: 100%;
            }
    
            .subtitle {
                @include font-rem(16px, 20px);
                font-weight: 500;
                font-family: $primary-font;
                color: white;
                max-height: 0;
                opacity: 0;
                overflow: hidden;
                display: none;
                transition:
                max-height 0.5s ease,
                opacity 0.5s ease;
                margin-top: 10px;
    
                &.show {
                    display: block !important;
                    max-height: 100px;
                    opacity: 1;
                }
    
                &-container{
                    display: block;
                    width:100%;
    
                    .up,.down{
                        width:100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .left,.right{
                           display: flex;
                           gap: 15px;
                        }
                    }
    
                    .up{
                        margin-bottom: 30px;
                        .left,.right{
                            .text{
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 20px; 
                            }
                        }
                    }
    
                    .down{
                        .left,.right{
                            font-size: 32px;
                            font-weight: 300;
                            line-height: 20px;
                            span{
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
    
            .second-column{
                .border:nth-last-of-type(1) {
                    display: none;
                }
            } 

            .first-column{
                .border:nth-last-of-type(1) {
                    display: none;
                    @include media-breakpoint-down(sm) {
                        display: block;
                    }
                }
            } 
    
            .first-column {
                .border {
                    &.show {
                        border-bottom: 2px solid $light-gray;
                    }
                }
            }
        }
        &-image {
            max-width: 80%;
            img{
                width: 338px;
                height: auto;
            }
        }
    }
}

