.gloit-event-qrcode-modal-body {
	display: none;
	position: fixed;
	z-index: 1000000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0px !important;
}

.gloit-event-qrcode-modal-content {
	height: 100%;
	background: #fff;
	position: relative;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	overflow-wrap: break-word;

	#qr-code-container{
		display: flex;
		align-items: center;
		flex-direction: column;

		img{
			width: 45%;
		}
	}
	
	.event-date, .event-location{
		line-height: 28px;
	}
}

#gloit-event-qrcode-modal-close-button {
	background: #fff;
	top: 24px;
	right: 6%;
	height: 40px;
	width: 40px;
	padding: 5px;
	position: absolute;
	border-radius: 100%;
}

.gloit-event-qrcode-title-container{
	margin: 5%;
}

.gloit-event-qrcode-title {
	text-align: center;
	font-size: 30px;
	font-weight: bold;
	color: #000;
	line-height: 36px;
	margin-top: 20%;
}

.gloit-event-qrcode-text {
	text-align: center;
	margin: 12%;
	margin-top: 0;
	font-size: 18px;
	line-height: 26px;
}

.gloit-event-qrcode-image {
	width: 70%;
	margin: auto;
	margin-top: 35%;
	margin-bottom: 0;
}



.gloit-event-qrcode-confirm-button-container {
	text-align: center;
	width: 90%;
	margin: 5%;
	color: #fff;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.gloit-event-qrcode-confirm-button {
	border-radius: 100px;
	background: linear-gradient(to right, #fd612c 0, #ce3a07 100%);

	&-text {
		padding: 4%;
		padding-bottom: 2%;
		font-weight: bold;
	}
}

.gloit-event-qrcode-cancel-button-container {
	text-align: center;
	width: 90%;
	margin: 5%;
	height: 100% !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}

.gloit-event-qrcode-button{
	width: 100%;
}
.gloit-event-qrcode-cancel-button{
 	width: 100%;
 }

.gloit-event-qrcode-cancel-button-text {
		color: black;
}



.gloit-event-qrcode-dettagli-evento {
	display: flex;
	flex-direction: column;
	margin: 7%;
	height: 10%;
	margin-bottom: 25%;

	&-pretitle {
		font-size: 12px;
	}

	&-title {
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 5%;
		line-height: 25px;
	}
	&-info-details{
    		display: flex;
    		flex-direction: row;
    		margin-bottom: 1%;
    	}
}
.gloit-registrazione-completata-continue-button-container{
	text-align: center;
        width: 90%;
        height: 20%;
        margin: 5%;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
}

.gloit-aggiungi-al-calendario {
	display: none !important;
}