
 .bat-form-simplebanner-container{
    display: flex;
    max-width: 1440px;
    height: 506px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: auto;

    &-content{
        display: flex;
        border-radius: 16px;
        background-size: cover;
        background-repeat: no-repeat;
        width: 95%;
        height: 90%;

        &-text{
            position: relative;
            align-items: center;
            display: flex;
            flex-direction: column;
            align-self: center;
            justify-content: center;
            width: 25%;
            margin: auto;
            left: 10%;

            h2{
                color: var(--glo-2024-White, #FFF);
                font-size: 32px;
                font-style: normal;
                font-weight: 800;
                line-height: 140%; 
                margin-bottom: 0px;
                margin-right: auto;
            }

            p{
                color: var(--glo-2024-White, #FFF);
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }

            button{
                align-items: start;
                background: transparent;
                border-radius: 100px;
                border: 2px solid var(--glo-2024-White, #FFF);
                display: inline-flex;
                height: 42px;
                padding: 12.5px 34px;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;
                color: var(--glo-2024-White, #FFF);
                font-size: 13px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                margin-right: auto;
                margin-top: 42px;
            }
        }
    }
}




