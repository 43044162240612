.bat-loyaltyhistory-gloit{
    width: 75%;
    margin: auto;

    @include media-breakpoint-down(md){
        width: auto;
        margin: auto 20px;
    }

    &-title{
        @include font-rem(34px, 39px);
        text-transform: uppercase;
        margin-bottom: 30px;

        @include media-breakpoint-down(md){
            @include font-rem(24px, 28px);
        }
    }

    &-subtitle{
        @include font-rem(20px, 28px);
        margin-bottom: 20px;

        @include media-breakpoint-down(md){
            @include font-rem(18px, 20px);
        }
    }

    &-filters {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
            justify-content: unset;
        }

        .bat-loyaltyhistory-gloit-user-balance {
            background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
            border-radius: 8px;
            padding: 16px;
            width: fit-content;
            color: $white;

            .user-balance-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin-right: 16px;
            }

            .user-balance {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &-content{
        margin-top: 20px;

        &-element {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 24px;
            border: 2px solid #DEDEDE;
            margin-bottom: 15px;
            @include font-rem(14px, 20px);

            &-left {
                .element-date {
                    font-weight: 400;
                    color: #6B6B6B;
                    margin-bottom: 16px;
                }

                .element-title {
                    font-weight: 700;
                    margin-bottom: 8px;
                }

                .element-mission {   
                    width: fit-content;                 
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 4px 8px;
                    gap: 8px;
                    background: rgba(249, 220, 205, 0.5);
                    border: 1px solid #CD4A04;
                    border-radius: 8px;
                    color: #CD4A04;

                    .icon-mission {
                        &::after {
                            content: url('/content/dam/glo-it/images/newloyalty/Rocket.svg');
                            display: block;
                            height: 20px;
                            width: 20px;
                        }
                    }
                }

                .element-shop {   
                    width: fit-content;                 
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 4px 8px;
                    gap: 8px;
                    background: rgba(213, 221, 236, 0.5);
                    border: 1px solid #2F539F;
                    border-radius: 8px;
                    color: #2F539F;

                    .icon-shop {
                        &::after {
                            content: url('/content/dam/glo-it/images/newloyalty/Wrapped-gift.svg');
                            display: block;
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
            }

            &-right {
                .element-price {
                    font-weight: 700;
                    @include font-rem(26px, 30px);
                    text-align: right;
                    text-transform: uppercase;
                    width: max-content;

                    &.price-positive {
                        color: #000AE3;
                    }

                    &.price-negative {
                        color: #E00830;
                    }
                }

                .element-subprice {
                    font-weight: 700;
                    @include font-rem(12px, 14px);
                    text-align: right;
                    text-transform: uppercase;
                    color: #6B6B6B;
                }

            }
        }
    }

    &.mobile-app {

        .bat-loyaltyhistory-gloit-subtitle {
            @include media-breakpoint-down(md){
                @include font-rem(16px, 24px);
                font-weight: 500;
                margin-top: 0;
            }
        }

        .bat-loyaltyhistory-gloit-user-balance {
            width: 100%;

            .user-balance {
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
            }
        }

        .bat-loyaltyhistory-gloit-content-element-right {
            padding: 8px;
            gap: 4px;
            background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
            border-radius: 8px;

            .element-price {
                font-weight: 700;
                @include font-rem(16px, 24px);
                text-align: left;

                &.price-positive {
                    color: $white;
                }

                &.price-negative {
                    color: $white;
                }
            }

            .element-subprice {
                font-weight: 500;
                @include font-rem(14px, 20px);
                text-align: left;
                color: #DEDEDE;
            }
        }
    }
}