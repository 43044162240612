bat-section-gloitscrolllep{
    .bat-section-scrolllep-gloit {
        height: 84vh !important;    
		@include media-breakpoint-up(md) {
			min-height: 750px;
		}  
    }

    bat-card-gloitlepcarousel {
        position: absolute; 
        width: 100%;
        clip-path: inset(100% 0% 0% 0%); 
        z-index: 0;
    
        &.first{
            clip-path: inset(0% 0% 0% 0%); 
            z-index: 1; 
            img{
                left: 12.04% !important;
                @include media-breakpoint-down(md) {
                    left: 0px !important;
                } 
            }
        }
    }

    .dots-container {
        z-index: 4;
        position: absolute;
        bottom: 50%;
        right: 6%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include media-breakpoint-down(sm) {
            display: none;
        } 

        .dot {
            width: 13px;
            height: 13px;
           border: 3px solid white;
            border-radius: 50%;
            cursor: pointer;
        }

        .dot.active {
            background-color: white; 
        }
    }
    
}
