.mobile-view{
	@include media-breakpoint-down(md){
		width: 90%;
		margin-left: 20px;
		height: 90%;
	}
	@include media-breakpoint-up(md){
		height: 90%;
	}
}

.image-resize{
	@include media-breakpoint-up(md){
		min-width: 300px;
	}
}

.section-resize{
	@include media-breakpoint-up(md){
		width: 75%;
	}
}

.margin-bottom{
	margin-bottom: 60px;
}

.navigation-center{
	bottom: 45px;
	@include media-breakpoint-up(md){
		left: 16%;
	}
	@include media-breakpoint-down(md){
		background-color: rgba(0,0,0,0);
	}

}


bat-cta-gloitcontactusnav {
	//height: 100%;
	position: fixed;
    width: 100%;
    z-index: 11;

	@include media-breakpoint-down(md) {
		border-radius: unset;
		box-shadow: unset;
		background-color: $white;
	}

	.display-mobile-none{
		@include media-breakpoint-down(md){
			display: none;
		}
	}
	.display-desktop-none{
		display: none;
		@include media-breakpoint-down(md){
			display: flex;
		}

		.uk-slider-items {
			margin-left: 0;
		}

		.cta-list-container:last-child {
			padding-right: 15px;
		}
	}
	.bat-cta-list--vertical {
		flex-direction: row;
		flex-wrap: nowrap;
		@include media-breakpoint-up(md){
			background-color: #F5F5F5;
			box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
			border-radius: 30px;
			width: 75%;
			font-size: 2rem;
		}
		ul {
			@include media-breakpoint-down(md){
				border-radius: 30px;
			}
			list-style: none;
			padding: 20px 60px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			position: relative;

			&::after {
				content: '';
				height: 138px;
				width: 2px;
				display: flex;
				background: #DEDEDE;
				position: absolute;
				right: 0;
				top: 15%;
			}

			&:last-child::after {
				display: none;
			}

			@include media-breakpoint-down(md){
				padding: 15px 20px;
				margin-bottom: 0px;
				background: #F5F5F5;
				box-shadow: 0px 2.7651px 10px rgba(0, 0, 0, 0.1);
				border-radius: 15px;

				&::after {
					display: none;
				}
			}

			li {
				text-transform: unset;
				align-items: center;
				justify-content: space-between;
				margin: 0;
				min-height: 50px;
				float: left;
				display: flex;
				flex-direction: column;
				position: relative;
				padding: 0 10px 50px 10px;

				@include media-breakpoint-down(md){
					/* justify-content: center; */
					padding: 0 10px 60px 10px;
					margin-right: 0px;
				}


				span{
					@include media-breakpoint-down(md){
						@include font-rem(10px, 15px);
						text-align: center;
					}
					@include font-rem(12px, 15px);
					text-align: center;
				}
				
				@include media-breakpoint-up(lg) {
					display: flex;
					flex-direction: column;
					padding-left: 0;
				}

				&:last-child {
					border-bottom: 0;
				}

				&.selected {
					display: flex;

					@include media-breakpoint-up(lg) {
						background-color: $brand-black;

						span{
							color: $white;
						}

						a:hover{
							span{
								color: $white;
							}
						}
					}
				}

				a {
					@extend .cta-link;
					margin-top: 15px;
					//align-items: center;
					height: 100%;
					margin-bottom: 0;
					color: #6B6B6B;

					&:nth-child(2) {
						position: absolute;
						width: 100%;
						top: 56%;
						justify-content: center;

						@include media-breakpoint-down(md) {
							top: 41%;
						}
					}
					
					&:last-child {
						position: absolute;
						top: 72%;
						width: 100%;
    					justify-content: center;
					}
				}

				img{
					width: 35px;
					height: 35px;
					object-fit: contain;
					@include media-breakpoint-up(md){
						width: 50px;
						height: 50px;
					}
				}

				.icon-chevron-up {
					cursor: pointer;
					height: 14px;

					@include media-breakpoint-up(lg) {
						display: none;
					}

					&::before {
						font-size: 14px;
					}
				}

				.icon-chevron-down {
					margin-top: 15px;
					cursor: pointer;
					height: 14px;
					@include media-breakpoint-down(md) {
						margin-top: 10px;
					}

					@include media-breakpoint-up(lg) {
						
					}

					&::before {
						font-size: 14px;
					}
				}

				&.index-element {
					padding-left: 0;
					border-bottom: 1px solid #2A2C2C;

					span {
						font-weight: 600;
						font-size: 20px;
						line-height: 28px;
						display: flex;
						align-items: center;
						color: #CD4A04;
						text-transform: none;

						@include media-breakpoint-down(md) {
							@include font-rem(12px, 15px);
						}
					}
				}
			}
			div {
				
				align-items: center;
				justify-content: space-between;
				margin: 0;
				min-height: 50px;
				padding-left: 25px;
				padding-right: 25px;
				display: flex;
				
				@include media-breakpoint-down(md){
					padding: 0px;
					min-height: unset;
				}

				span{
					@include font-rem(12px, 15px);
				}
				
				@include media-breakpoint-up(lg) {
					display: flex;
				}

				&:last-child {
					border-bottom: 0;
				}

				&.selected {
					display: flex;

					@include media-breakpoint-up(lg) {
						background-color: $brand-black;

						span{
							color: $white;
						}

						a:hover{
							span{
								color: $white;
							}
						}
					}
				}

				a {
					@extend .cta-link;
					align-items: center;
					height: 100%;
					margin-bottom: 0;
					
				}

				.icon-chevron-up {
					cursor: pointer;
					height: 14px;

					@include media-breakpoint-up(lg) {
						display: none;
					}

					&::before {
						font-size: 14px;
					}
				}

				.icon-chevron-down {
					cursor: pointer;
					height: 14px;

					@include media-breakpoint-up(lg) {
						display: none;
					}
					@include media-breakpoint-down(md) {
						display: none;
					}


					&::before {
						font-size: 14px;
					}
				}

				&.index-element {
					justify-content: center;
					flex-basis: 100%;

					span {
						font-weight: 600;
						line-height: 28px;
						display: flex;
						align-items: center;
						text-transform: uppercase;
						

						@include media-breakpoint-down(md) {
							@include font-rem(12px, 15px);
						}
					}
				}
			}
		}
	}

	// when plus is clicked all items become visible in mobile mode
	&.expanded {
		@include media-breakpoint-down(md) {
			.bat-cta-list--vertical {
				ul {
					li {
						display: flex;

						.icon-chevron-down{
							display: none;
						}

						.icon-chevron-up{
							display: none;
						}

					}

					li:first-child{
						.icon-chevron-down{
							display: flex;
						}
						.icon-chevron-up {
							display: flex;
						}
					}
				}
			}
		}
	}
}


