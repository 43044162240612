.bat-cardeditpreferences-gloit{
    padding: 10px 10px 10px 10px;
    background: #F5F5F5;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 15px;

    &--news {
		margin-top: 8px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
			margin-top: 0;
			//padding: 40px 10px 30px;
		}
        
        &-main {
			display: flex;
            flex-direction: row;
            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }

            
		}

		&-img {
			height: 100%;
			max-height: 240px;
			overflow: hidden;

			a {
				display: block;
			}
		}

        &-title{
            //@include font-rem(20px, 28px);
            font-weight: 800;
            font-size: 24px;
            line-height: 30px;
            /* or 125% */
            text-transform: uppercase;

            /* Glo/Brand Black */
            color: #2A2C2C;


        }

        &-content{
            //@include font-rem(14px, 20px);
            /* Desidero ricevere newsletter, promozioni, concorsi, inviti a eventi. */
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */

            /* Glo/Brand Black */
            color: #2A2C2C;

            display: flex;
            margin-top: 25px;


        }

		&-text {
			//margin: 15px 0;
            width: 50%;
            padding: 20px;

			@include media-breakpoint-down(sm) {
				//margin: 8px 0 5px;
                width: 100%;
                padding: 20px 0px 0px 20px;
			}

			h3 {
				font-size: 20px;
				line-height: 150%;
				//padding-top: 15px;

				@include media-breakpoint-down(xs) {
					padding-top: 4px;
				}
			}
			p {
				font-size: 15px;
				line-height: 150%;
				//margin-top: 5px;

				@include media-breakpoint-down(xs) {
					color: $dark-gray;
				}
			}

            .bat-form--signup-messages{
                @include media-breakpoint-down(xs) {
					margin-bottom: 10px;
				}
            }

		}

		&-ctalist {
            padding: 20px 20px 20px 60px;
            width: 50%;
            @include media-breakpoint-down(xs) {
                padding: 20px 20px 20px 20px;
                width: 100%;
            }

            p{
                /* Selezionando la casella presti il tuo consenso a ricevere comunicazioni attraverso il canale indicato: */
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #6B6B6B;

            }

			.bat-cta-style {
				font-size: 15px;
				line-height: 150%;
				margin-top: 5px;
                text-transform: uppercase;
                @include font-rem(15px, 28px);
                font-weight: 800;
                color: $charcoal;

				@include media-breakpoint-down(sm) {
					margin-top: 0;
				}
				
			}
			
			.button-news{
				@include media-breakpoint-up(sm){
                    /*
					&:hover {
						transform: translateX(50px);
					}
					transition: all .5s ease-out;
                    */
                    display: flex;
                    flex-direction: column;
				}
                .toggle-switch{
                    display: flex;
                    flex-direction: row;

                    .toggle-switch-text{
                        margin-top: 25px;
                        width: 60%;
                        @include media-breakpoint-down(sm) {
                            width: 70%;
                        }

                        .textlabel{
                            font-weight: 800;
                            font-size: 14px;
                            line-height: 20px;
                            /* identical to box height, or 143% */
                            text-transform: uppercase;

                            /* Glo/Brand Black */
                            color: #2A2C2C;


                        }
                    }

                    .switch {
                        margin-top: 20px;
                        position: relative;
                        display: inline-block;
                        width: 60px;
                        height: 34px;
                        margin-left: 20px
                      }
                      
                      .switch input { 
                        opacity: 0;
                        width: 0;
                        height: 0;
                      }
                      
                      .slider {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #ccc;
                        -webkit-transition: .4s;
                        transition: .4s;
                      }
                      
                      .slider:before {
                        position: absolute;
                        content: "";
                        height: 26px;
                        width: 26px;
                        left: 4px;
                        bottom: 4px;
                        background-color: white;
                        -webkit-transition: .4s;
                        transition: .4s;
                      }
                      
                      input:checked + .slider {
                        background-color: #2196F3;
                      }
                      
                      input:focus + .slider {
                        box-shadow: 0 0 1px #2196F3;
                      }
                      
                      input:checked + .slider:before {
                        -webkit-transform: translateX(26px);
                        -ms-transform: translateX(26px);
                        transform: translateX(26px);
                      }
                      
                      /* Rounded sliders */
                      .slider.round {
                        border-radius: 34px;
                      }
                      
                      .slider.round:before {
                        border-radius: 50%;
                      }
                }
				
			}

            a{
                text-decoration: none;
                color: $charcoal;
            
                @include font-rem(14px, 28px);
                font-weight: 800;
        
                i{
                    margin-top: 2px;
                    margin-left: 10px;
                }
            }
            a:hover{
                text-decoration: none;
                color: $charcoal;

                span{
                    text-decoration: none;
                    color: $charcoal;
                }
            }

            span{
                font-weight: 800;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 10px;
            }
		}
            
        &-slash {
			content: '';
				//height: 166px;
				width: 2px;
				display: flex;
				background: #AAAAAA;
				position: relative;
				right: 0;
				//top: 3px;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
		}

        
	}
}

.margin-left-text{
    margin-left: 20px !important;
}

.set-text-card{
    width: 70% !important;
    margin-left: 50px !important;
}

.buttons-consensus{
    display: flex;
    flex-direction: row;
    margin-top: 25px;

    @include media-breakpoint-down(md){
        display: block;
    }

    .bat-form--signup-messages{
        margin-left: 35px;
        align-items: center;
        display: flex;
        @include media-breakpoint-down(sm){
            margin-top: 10px;
        }
    }

    .bat-form--signup-submit,
    .bat-form--signup-back{
        width: fit-content;
        margin-left: 40px;

        @include media-breakpoint-down(sm){
            width: 90%;
            margin-left: 19px;
        }

        button,
        a{
            width: 250px;

            @include media-breakpoint-down(md){
                width: 100%;
                max-width: 100%;
            }
        }

        a{
            font-size: 13px;

            &:hover{
                span{
                    color: $brand-black;
                    text-decoration: none;
                }   
            }
        }
    }
}

