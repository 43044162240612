.gloit-confirm-participation-modal-body {
	display: none;
	position: fixed;
	z-index: 1000000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0px !important;
	
	.loading-wrapper{
		height: 100% !important;
	}
}

.gloit-confirm-participation-modal-content {
	height: 100%;
	background: #fff;
	position: relative;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	overflow-wrap: break-word;
}

#gloit-confirm-participation-modal-close-button {
	background: #fff;
	top: 24px;
	right: 6%;
	height: 40px;
	width: 40px;
	padding: 5px;
	position: absolute;
	border-radius: 100%;
}

.gloit-confirm-participation-title-container{
	margin: 5%;
}

.gloit-confirm-participation-title {
	text-align: center;
	font-size: 30px;
	font-weight: bold;
	color: #000;
	line-height: 25px;
}

.gloit-confirm-participation-text {
	text-align: center;
	margin: 5%;
	margin-top: 0;
	font-size: 18px;
}

.gloit-confirm-participation-image {
	width: 70%;
	margin: auto;
	margin-top: 35%;
	margin-bottom: 0;
}



.gloit-confirm-participation-continue-button-container {
	text-align: center;
	width: 90%;
	margin: 5%;
	color: #fff;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.gloit-confirm-participation-continue-button-text {
		width: 100%;
	}


.gloit-confirm-participation-dettagli-evento {
	display: flex;
	flex-direction: column;
	margin: 7%;
	height: 10%;
	margin-bottom: 25%;

	&-pretitle {
		font-size: 12px;
	}

	&-title {
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 5%;
		line-height: 25px;
	}
	&-info-details{
    		display: flex;
    		flex-direction: row;
    		margin-bottom:1%;
    	}
}