.gloit-nextevent-card{
	position: relative;
	.loading-wrapper {
    			margin: 50px auto;
    			display: flex;
    			width: 100px;
    			position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
    
    			p {
    				text-align: center;
    				width: 100%;
    				font-size: 14px;
    				margin: 0;
    			}
    
    			&:after {
    				-webkit-animation: spin 2s linear infinite; /* Safari */
    				animation: spin 2s linear infinite;
    				border: 10px solid $light-gray;
    				border-radius: 50%;
    				border-top: 10px solid $purple;
    				bottom: 0;
    				content: "";
    				height: 60px;
    				left: 0;
    				margin: auto;
    				position: absolute;
    				right: 0;
    				top: 0;
    				width: 60px;
    				z-index: 1;
    			}
    		}
    		.event-date, .event-location{
				line-height: 28px;
			}
}

.gloit-card-container{
	border: 1px;
	border-radius: 15px;
	border-color: #000;
	border-style: solid;
	background: #fff;
	margin: 5%;
}

 .gloit-card-subtitle {
    margin-bottom: 0.5%; 
	margin-top: 3%;
	font-size: 12px;
}

.gloit-card-eventName {
    margin-top: -0.5%; 
	margin-bottom: 5%;
	font-size: 20px;
}

.gloit-card-detail-data {
   display: flex;
}

.gloit-card-detail-data-image{
	width: 7%;
	margin-right: 1%;
	height: 5%;
}

.gloit-card-detail-luogo {
   display: flex;
    gap: 2%; 
}

.gloit-card-detail-luogo-image{
	width: 5%;
	margin-left: 1%;
	height: 5%;
}

.gloit-warning-image{
	width: 10%;
}

.title-container {
	margin-left: 5%;
	margin-top: 3%;
}

.details-container {
	display: flex;
    flex-direction: column;
	margin: 5%;
}
.button-qr-gloit {
	display: flex;
	flex-direction: column;
	align-items: center;
	button{
		height: 45px;
		padding: 0px;
		width: 100%;
	}
}

.gloit-warning {
	background-color: #e5e5e5;
	margin:5%;
	padding: 3%;
}

.gloit-warning p{
	overflow: hidden;
}

.gloit-warning-image {
	float: left;
	margin-right: 3%;
}

.gloit-detail-image{
	margin-right: 3%
}

.button-annulla-partecipazione-gloit {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
	margin-bottom: 5%;
    margin-top: 2%;
}

.personalized-color{
	color: black !important;
	border: #fff !important;
}

.button-annulla-partecipazione-gloit .button-secondary-light:hover,
.button-annulla-partecipazione-gloit .button-secondary-light:hover span {
    color: black !important;
}

.gloit-card-margin{
	margin-left: 5%;
}
