bat-card-gloitaudiomisterylastcardcarousel {
	.card {
		width: 100%;
		height: 540px;
		position: relative;
		max-width: none;

		@include media-breakpoint-down(md) {
			height: 522px;
		}
		.image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			z-index: 1;

			@include media-breakpoint-down(md) {
			}
		}
		.second-section {
			position: relative;
			z-index: 2;
			color: #fff;
			padding: 220px 0 0 51.5%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			gap: 4%;

			@include media-breakpoint-down(md) {
				padding: 0 5% 11% 7%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				width: 100%;
				gap: 2%;
			}
			.title-second-section {
				font-size: 34px;
				font-weight: 600;
				line-height: 39px;

				@include media-breakpoint-down(md) {
					font-size: 23px;
					line-height: 25px;
				}
			}
			.text-second-section {
				p {
					font-size: 15.6px;
					font-weight: 400;
					line-height: 24px;
				}
				@include media-breakpoint-down(md) {
					p {
						font-size: 12px;
						line-height: 19px;
						margin-bottom: 0;
					}
				}
			}
			.cta-second-section {
				padding-top: 1%;
				@include media-breakpoint-down(md) {
					padding-top: 0;
				}
				.button-secondary-dark {
					@include media-breakpoint-down(md) {
						height: 33px;
						padding: 2% 9%;
						width: fit-content;
					}
					text-transform: none;
					height: 37px;
					font-size: 13px;
					background-color: #fe5000;
					border: 0;
					border-radius: 50px;
					width: fit-content;
					padding: 0% 5%;
					color: #000;
					font-weight: 600;
					text-align: center;
					cursor: pointer;
					transition: all 0.3s ease;

					&:hover {
						background-color: #fe5000;
						color: black;
					}
				}
			}
		}
	}
}
