bat-section-gloittogglecomponents{
    &.pdp-hyper-core{
        position: relative;
        z-index: 1;
        .bat-section {
            .toggle-component{
                box-shadow: none;
                .toggle-container{
                    @include media-breakpoint-up(sm) {  
                        width: 433px;
                    }
                }
            } 
            @include media-breakpoint-down(sm) {  
                .toggle-component{

                    .toggle-button{
                        color: rgba(42,42,42,0.6) !important;
                        .icon svg{
                            path{
                                stroke: #2A2A2A;
                            }
                        }
                    }

                    #show-review-btn{
                        .bat-reviewslist-gloit-container-title{
                            .title{
                                color: var(--glo-2024-glo-Charcoal, #2A2A2A);
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px; /* 125% */
                                letter-spacing: 0.2px;
                                opacity: 0.6;
                            }
                            .reviewsNumber{
                                color: var(--glo-2024-glo-Charcoal, #2A2A2A);
                                font-family: Mont;
                                font-size: 9px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 120%; /* 10.8px */
                                letter-spacing: 0.7px;
                                text-transform: uppercase;
                                opacity: 0.6;
                            }
                        }
                        .text{
                            display: none !important;
                        }
                    }
                
                    .toggle-container::before {
                        display: none; /* Nascondi l'animazione della parte bianca */
                    }
                
                    /* Gestione del bottone attivo in mobile (cambia solo il colore del testo) */
                    .toggle-button.active {
                        color: rgb(255, 255, 255);
                        background: var(--glo-2024-glo-Charcoal, #2a2a2a);
                        border-radius: 20px 20px 0px 0px;
                        min-height: 61px;
                        margin-bottom: 0px;
                        padding: 15px 20px;
                        align-items: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; /* 125% */
                        letter-spacing: 0.2px;
                        .icon svg{
                            path{
                                stroke: white;
                            }
                        }
                        .title{
                            opacity: 1 !important;
                            color: var(--glo-2024-White, #FFF) !important;
                        }
                        .reviewsNumber{
                            opacity: 0.6 !important;
                            color: var(--glo-2024-White, #FFF) !important;
                        }
                    }
                }          
        
            }
        }
    }
    .bat-section{
        padding-top: 71px;
        padding-bottom: 92px;
        @include media-breakpoint-down(sm) {
            padding-top: 0px;
        }
        .toggle-component{
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.24);
            border-radius: 40px;
            background: var(--glo-2024-glo-Charcoal, #2a2a2a);
            padding-top: 40px;
            padding-bottom: 60px;
            @include media-breakpoint-down(sm) {
                padding-block: 0px;
            }
            .toggle-container {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #333;
                border-radius: 50px;
                padding: 5px;
                width: 315px;
                margin: 20px auto;
                overflow: hidden;
                border: 2px solid white;
                @include media-breakpoint-down(sm) {
                    border: none;
                }
            }
            
            .toggle-button {
                position: relative;
                z-index: 1;
                border: none;
                border-radius: 50px;
                padding: 10px 20px;
                flex: 1;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                color: white !important;
                transition: color 0.3s ease;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 125% */
                letter-spacing: 0.2px;
            }
            
            .toggle-button.active {
                color: #2A2A2A !important;
                @include media-breakpoint-down(sm) {  
                    color: white !important;
                }
                .icon svg{
                        path{
                            stroke: #2A2A2A;
                            opacity: 1;
                            @include media-breakpoint-down(sm) {  
                                stroke: white;
                            }
                        }
                    }
            }
            
            .toggle-button .icon {
                margin-right: 8px;
            }
            
            .toggle-container::before {
                content: '';
                position: absolute;
                left: 0px; 
                width: 50%; 
                height: calc(100%); 
                background-color: white;
                border-radius: 50px;
                z-index: 0;
                transition: transform 0.3s ease;
            }
            
            .faq-active::before {
                transform: translateX(100%);
            }
            
        }


        @include media-breakpoint-down(sm) {  
        .toggle-component{
            background-color: transparent;
            .toggle-container {
            margin: 0px;
            width: 100%;
            background-color: transparent;
            padding: 0px;
            border-radius: 0px;
            }
            .toggle-button {
                align-items: center;
                justify-content: center;
                color: rgba(255, 255, 255, 0.6);
                padding: 15px 20px;
                width: 100%;
                margin-bottom: 0px;
                background: transparent;
                border-radius: 20px 20px 0px 0px;
                min-height: 60px;
            }

            #show-review-btn{
                .bat-reviewslist-gloit-container-title{
                    display: flex !important;
                    flex-direction: column;
                    align-items: flex-start;
                    font-family: Mont;
                    .title{
                        color: var(--glo-2024-White, #FFF);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; /* 125% */
                        letter-spacing: 0.2px;
                        opacity: 0.6;
                    }
                    .reviewsNumber{
                        color: var(--glo-2024-Warm-Grey, #E5E1E6);
                        font-size: 9px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%; /* 10.8px */
                        letter-spacing: 0.7px;
                        text-transform: uppercase;
                        opacity: 0.4;
                    }
                }
                .text{
                    display: none !important;
                }
            }
        
            .toggle-container::before {
                display: none; /* Nascondi l'animazione della parte bianca */
            }
        
            /* Gestione del bottone attivo in mobile (cambia solo il colore del testo) */
            .toggle-button.active {
                color: rgb(255, 255, 255);
                background: var(--glo-2024-glo-Charcoal, #2a2a2a);
                border-radius: 20px 20px 0px 0px;
                min-height: 61px;
                margin-bottom: 0px;
                padding: 15px 20px;
                align-items: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 125% */
                letter-spacing: 0.2px;

                .title{
                    opacity: 1 !important;
                }
                .reviewsNumber{
                    opacity: 0.6 !important;
                }
            }
        }          

        }
    }

}


