.aem-Grid{
	&.infoglocard{
		padding: 0px 60px;

		@include media-breakpoint-down(sm){
			padding: 0px;
		}
	}
}

bat-card-gloitbackground {
    max-width: unset !important;
    position: relative;

    .bat-background-gloit {
        background-color: #E5E5E5;
        max-width: 1310px;
        align-items: center;
        margin: 0 auto 80px auto;
        z-index: 1;
        position: relative;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            margin: 0 20px 35px 20px;
        }

        &-back {
            position: absolute;
            width: 100%;
            height: 50%;
            height: calc(50% + 80px);
            bottom: -80px;
            background: #FFC000;

            @include media-breakpoint-down(md) {
                height: calc(50% + 35px);
                bottom: -35px;
            }
        }

        &-image{
            padding: 0;
        }

        &-container {
            padding: 0 75px;

            @include media-breakpoint-down(md) {
                padding: 20px;
            }

            &-text {
                font-family: $primary-font;
                color: $brand-black;
                p {
                    font-size: 22px;
                    line-height: 28px;

                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }

            .background-cta {
                margin-top: 25px;

                @include media-breakpoint-down(md) {
                    margin-top: 15px;
                }
            }
        }
    }
}

.bat-infoglocard-gloit{
    
	.uk-grid {
		 margin-left: 0px;
	}
	&-text{
		background-color: $light-gray;
		@include media-breakpoint-down(lg){
			padding: 40px;
            padding-left: 20px;
            padding-right: 20px;
			margin-top: 0 !important;
		}
	}
	&-image{
		padding:0;
	}
    .infoglocard{
		
        font-family: $primary-font;
        color: $brand-black;

        &-title,
		&-text,
        &-infolist{
            margin: 0px;
        }

        &-text,
        &-infolist{
            @include font-rem(14px, 20px);
            width: 75%;
        }

        &-title{
            padding-top: 50px;
            padding-left: 50px;
            padding-bottom: 20px;
            @include font-rem(30px, 34px);
            font-weight: 800;
        }

        &-text{
            padding-left: 50px;
            padding-bottom: 25px;
            width: 90%;
        }

        &-infolist{
            padding-left: 70px;

            li{
                padding-bottom: 17px;
                list-style: url('/content/dam/glo-it/images/infoglo-card/icon-tick.svg');
            }
        }

        &-cta{
            margin-top: 10%;
            margin-bottom: 7%;
            padding-left: 45px;
            @include media-breakpoint-down(sm){
                padding-left: 3% !important;
                margin: 0px;
            }
        }
    }

    @media (max-width: 1270px) {

        .uk-flex{
            flex-direction: column-reverse;
        }

        .uk-width-1-2{
            width: 100%;

            .infoglocard{
                &-title{
                    @include font-rem(24px, 28px);
                    padding-left: 20px;
                    padding-top: 0px;
                }

                &-text{
                    padding-top: 0px;
                    padding-left: 20px;
                    padding-right: 20px;
                    width: 95%;
                }

                &-infolist{
                    padding: 0px 0px 30px 45px;
                    width: 95%;
                }

                &-cta{
                    padding-left: 0;
                }
            }
        }
        
    }

    h1{
        font-size: 20px;
        font-family: $primary-font;
    }
}