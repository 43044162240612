bat-card-gloitnewsletterblack{
    &.pdp-hyper-core{
        .bat-card-gloitnewsletterblack{
            background-color: #FFF;
        }
    }
    .bat-card-gloitnewsletterblack{
        background-color: $black;
        padding: 54px 10px 64px 10px;
        @include media-breakpoint-down(sm){
            padding: 20px 10px 32px 10px;
         }
        .uk-flex{
            display: block;
            background-color: #2A2A2A;
            border-radius: 40px;
            max-width: 668px;
            margin-inline: auto;
            padding: 38px 56px 30px;
            @include media-breakpoint-down(sm){
                display: block;
                padding: 30px;
                max-width: 355px;
            }
        }
        
    
         &-title{
             @include font-rem(28px, 24px);
             font-family: $primary-font;
             font-weight: 700;
             color: white;
             text-align: center;
             text-transform:none;
    
             @include media-breakpoint-down(sm){
                @include font-rem(20px, 24px);
                margin-bottom: 7px;
             }
         }
    
         &-subtitle{
            p{
                @include font-rem(18px, 25.2px);
                margin-block: 20px;
                opacity: 0.8 !important;
                color: var(--glo-2024-Warm-Grey, #E5E1E6);
                text-align: center;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Mont;
                font-style: normal;
                font-weight: 400;
    
                @include media-breakpoint-down(sm){
                    @include font-rem(13px, 20px);
                 }
            }
         }
    
         &-cta{
            min-width: 100%;
            text-align: center;
    
            @include media-breakpoint-down(sm){
                max-width: 301px;
             }
            .bat-cta-style{
                &.button-secondary--dark:hover span{
                    color: $white;
                }
            }
    
            .bat-cta-style{
                &.button-secondary-dark{
                    width: 100%;
                    color:$white;  
                    border: solid 2px #fff;   
                    max-height: 42px;
                    text-transform: none;      
                }
            }
    
             span{
                color: $white;
            }
    
            @include media-breakpoint-down(sm){
                margin-top: 30px;
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
            }
    
        }
    }
}
