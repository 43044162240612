.bat-worldwide-gloit{
    .bat-worldwide-gloit--container{
        display: flex;
        flex-direction: column;
        gap:15px;

        &-items{
            display: flex;
            align-items: center;
            @include media-breakpoint-down(sm) {
                gap: 10px;
            }
    
            a{
                pointer-events: all;
                font-family: Mont;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding-left: 10px;
                @include media-breakpoint-down(sm) {
                    padding-left: 5px;
                }
            }
        }
       
    }
}