
.bat-carousel .slick-dots{
    justify-content: flex-start;
    margin: 0 auto;
    display: flex;
    margin-left: 5%;

    li{
        display: block !important;
        @include media-breakpoint-down(sm){
            margin-top:4%
        }
        button{
            background-color: #acd3f2 !important;
            border: 1px solid #acd3f2 !important;
            height: 7px;
            width: 7px;
        }
    }
    li.slick-active{
        button{
            width: 25px;
            height: 7px;
            background-color: white !important;
            border: 1px solid white !important;
        }
    }
    
}



.bat-thr-slider-card-gloit{
    color: white;
    .container{
        margin-left: 5%;

        &-image{

        }
        &-text-with-image{
            color: white;
            text-align: left;
            display: flex;
            flex-direction: column;
            gap:8px;
            margin-left: 5%;
            margin-top: 2%;
            h3{
                color: white;
            }
            
        }
        &-text-without-image{
            width: 65%;
            color: white;
            text-align: left;
            display: flex;
            h3{
                color: white;
            }

            @include media-breakpoint-down(sm){
                width: 100%;
            }
        }

        
    } 
    .container-slider-dots{
        margin-top: 30px;
        width: 12%; 
        margin-left: 5%;
    }

    

}

.bat-infoglo-slider-card-gloit{
    margin-bottom: 4%;
    margin-top:2%;
    width: 86%;
    height: fit-content;
    border-radius: 24px;
    background: var(--glo-white, #FFF);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    margin-left: 5%;

    @include media-breakpoint-down(sm){
        width: 91%;
        margin-left: 4%;
    }

    p{
        color: black;
    }
    .container{
        margin-left: 5%;

        &-image{

        }
        &-info{
            
            width: 95%;
            color: white;
            text-align: left;
            display: flex;
            flex-direction: column;

            h3{
                color: var(--glo-brand-black, #2A2C2C);
                font-family: Mont;
                font-size: 17px;
                font-style: normal;
                font-weight: 800;
                line-height: 120%;
                @include media-breakpoint-down(sm){
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 130%;
                }
            }

            @include media-breakpoint-down(sm){
                width: 100%;
            }

            img{
                height: 60%;
                @include media-breakpoint-down(sm){
                    height: auto;
                }
            }

            p{
                font-family: Mont;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 0px;
            }

            &-card{
                margin-top: 5.0%;
                display: flex;
                flex-direction: row;
                gap: 17px;
                margin-bottom: 5%;
                @include media-breakpoint-down(sm){
                    flex-direction: column;
                }
                .left{
                    display: flex;
                    flex-direction: column; 
                    gap: 20px;
                    width: 680px;
                    @include media-breakpoint-down(sm){
                        width: 100%;
                    }
                    h3{
                        margin-right: 2%;
                        min-height: 3.5em;
                        
                        @include media-breakpoint-down(sm){
                            margin: o;
                            margin-right: 2%;
                            min-height: 4em;
                        }
                    }
                    .info-text{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        @include media-breakpoint-down(sm){
                            margin-top: -2%;
                            
                            p{
                                font-size: 12px;
                            }
                        }


                        @media (max-width: 375px) {
                            p{
                                font-size: 11px;
                                }
                            }

                        
                        .p-image{
                            width: 22%;
                        }

                        p:not(.p-image) { 
                            margin-bottom: -2px;
                            font-size: 11px;
                        } 
                    }
                }

                .right{
                    height: 100%;
                    width: 35%;
                    .source-review{
                        height: auto;
                        width: 100px;
                        @include media-breakpoint-down(sm){
                            margin: auto;
                        }
                        @media (max-width: 360px) {
                            margin-top: 2%;
                        }
                    }
                }

                
            }
            
        }
    } 

    .container-slider-dots{
        margin-top: 30px;
        width: 12%; 
        margin-left: 5%;
    }



}

.bat-carousel-thr-slider-gloit{
    @include media-breakpoint-down(sm){
        height: 230px;
    }

    @media (min-width: 577px) and (max-width: 767px) {
        height: unset !important;
    }

    h2{
        text-align: left;
        margin-top: 6%;
        margin-left: 5%;
        color: white;
        padding-bottom: 35px;
        font-family: Mont;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        text-transform: uppercase;
        @include media-breakpoint-down(sm){
            margin-top:10%;
        }
    }
    .arrows-container{
        position: absolute;
        display: flex;
        margin-top: 2%;
        left: 77%;
        .next-arrow{
            margin-left: 50px; 
            @include media-breakpoint-down(sm){
                margin-left: 20px; 
            }
            .bat-icon::before{
                color: white;
                content: url('/content/dam/glo-it/images/Glo-Chevron-right.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }  
        }
        .prev-arrow{
            .bat-icon::before{
                color: white;
                content: url('/content/dam/glo-it/images/Glo-Chevron-left.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }  
        }
        @include media-breakpoint-down(sm){
            left: 74%;
        }


        
    }

    
}

.bat-carousel-infoglo-gloit{
    @include media-breakpoint-down(sm){
        height: 240px;
    }

    h2{
        text-align: left;
        margin-top: 6%;
        margin-left: 5%;
        color: white;
        padding-bottom: 35px;
        font-family: Mont;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        text-transform: uppercase;
        @include media-breakpoint-down(sm){
            margin-top:10%;
        }
    }
    .arrows-container{
        position: absolute;
        display: flex;
        left: 75%;
        margin-top: -5.5px;

        @include media-breakpoint-down(sm) {
            margin-top: -1%;
        }

        .next-arrow{
            margin-left: 50px; 
            @include media-breakpoint-down(sm){
                margin-left: 20px; 
            }
            .bat-icon::before{
                color: white;
                content: url('/content/dam/glo-it/images/Glo-Arrow-right.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                height: 28px;
            }  
        }
        .prev-arrow{
            .bat-icon::before{
                color: white;
                content: url('/content/dam/glo-it/images/Glo-Arrow-left.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                height: 28px;
            }  
        }
        @include media-breakpoint-down(sm){
            left: 69%;
        }
        
    }

    .slick-track{
        @include media-breakpoint-down(sm){
            height: 200px;
        }
    }
    .slick-dots{
        justify-content: flex-start;
        margin: 0 auto;
        display: flex;
        margin-left: 8%;
        align-content: center;
        margin-bottom: 3%;

        li{
            display: block !important;
            overflow: hidden;
            @include media-breakpoint-down(sm){
                margin-top:4%
            }
            button{
                background-color: #ADADAD !important;
                border: 1px solid #ADADAD  !important;
                width: 6px;
                height: 6px;    
            }
        }
        li.slick-active{
            button{
                width: 6px;
                height: 6px;
                background-color: #3A3A3A !important;
                border: 1px solid #3A3A3A !important;
            }
        }

        @include media-breakpoint-down(sm){
            margin-left: 8%;
        }
        
    }
}





.image-thr{
    height: 100%;
    margin-top: 0 !important;
    padding-top: 0 !important;
    @include media-breakpoint-down(sm){
        padding: 25px;
        margin-top: 15%;
        picture{
            transform: translateY(110px);
        }
    }
    .bat-image{
        height: 100%;
        picture{
            height: 100%;
            img{
                height: 100%;
                object-fit: cover;
            }
        }
    }
    
}

.section-thr{
    position: relative;
    z-index: 2;

    .video.aem-GridColumn--default--none{
        padding: 0;
    }
    .video-thr{

        margin-bottom: 0px !important;
        
        .bat-video-content{
            padding: 0;
            @include media-breakpoint-down(sm){
                padding: 20px;
            }
        }
        @include media-breakpoint-down(sm){
            transform: translateY(110px); 
        }
        
    }
}

.section-highlights{
    top: -24%;
    background-color: #E5E5E5;
    position: relative;
}

#sort-filter-alternativa{
    margin-top:-11%;
    @include media-breakpoint-down(sm){
        margin-top: -50%;
    }
}

bat-carousel-gloitthrslider{
	&.thr-round-dots-mobile,&.thr-round-dots-desktop{
		.slick-dots li button {
            background-color: #E0E0E0 !important;
            border: 1px solid #E0E0E0 !important;
        }
		.bat-carousel .slick-dots li.slick-active button{
			width: 7px;
			background-color: #000 !important;
			border: 1px solid #000 !important;
		}
		.bat-carousel .arrows-container{
			display: none;
		}
        .bat-quotes-hero{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
        }
	}
    &.thr-round-dots-desktop{
        .slick-dots{
            margin-left:1%;
        }
    }
}