.bat-headline{
	.register-device{
		margin-top: 20px;
		margin-bottom: 30px;
	}
}
.order-table{
	margin-top: 20px;
	.pagination{
		margin-bottom: 20px;
		@include media-breakpoint-down(lg) {
			justify-content: center;
		}
	}

	.bat-pagination-buttons.pagination{
		margin-top:30px;
	}
	
	.bat-pagination-button-wrapper{
		&.numbers{
			button.active {
				background-color: $blue;
				color: #fff;
			}
		}
		
	} 
	.uk-table{
		max-width: 980px;
		th{
			background-color: $concrete;
			font-family: 'Mont';
			font-style: normal;
			font-weight: 800;
			font-size: 15px;
			line-height: 20px;
			color: $brand-black;
			border-bottom: 2px solid $orange;
			&:first-child{
				padding-left: 12px;
			}
			&:last-child{
				padding-right: 12px;
			}
		}
		td{
			border: none;
			text-align: center;
		}
	}
	.button-secondary-light {
		color: $brand-black;
		padding: 10px 16px;
	}

	.mobile-my-devices{
		padding: 20px 20px 30px 20px;
		min-width: 310px;
		.button-details{
			margin-left: auto;
			margin-right: auto;
			margin-top: 10px;
		}
		.button-secondary-light {
			padding: 10px 30px;
		}
		.device-item{
			display: flex;
			flex-direction:column;
			&-title{
				font-family: 'Mont';
				font-style: normal;
				font-weight: 900;
				font-size: 14px;
				line-height: 18px;
				text-transform: uppercase;
			}
			&-subtitle{
				font-family: 'Mont';
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
				display: flex;
				align-items: center;
			}
		}
		
		&.mobile-app {
			.devices {
				gap: 16px;
				.device-item {
					gap: 10px;
				}
			}
		}
	}

	.no-orders{
		margin-bottom: 20px;
		@include media-breakpoint-down(lg) {
			text-align: center;
		}
		.button-no-orders{
			margin-top: 10px;
			
		}
		.button-light{
			@include media-breakpoint-down(lg) {
				padding: 10px 80px;
			}
		}
		.message{
			font-weight: 500;
			font-size: 20px;
			line-height: 20px;
		}
	}
	
}