bat-card-gloitaudiomisterypersonages {
	max-width: fit-content !important;
    margin: 0 !important;
	@include media-breakpoint-down(md) {
		max-width: 100% !important; 
	}
	.bat-card-gloitaudiomisterypersonages {
		max-width: fit-content;
		@include media-breakpoint-down(md) {
			max-width: 100% !important;
			width: 100%;
			height: 400px;
		}
	}
	.card {
		width: 246px;
		height: 283px;
		position: relative;
		max-width: none;
		padding: 0 2% 2% 4%;
		display: flex;
		flex-direction: row;

		@include media-breakpoint-down(md) {
			width: 100%;
			height: 330px;
			padding: 0;
		}
		.image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			z-index: 1;
			border-radius: 2%;

			@include media-breakpoint-down(md) {
				border-radius: 0;
			}
		}
		.second-section {
			position: relative;
			z-index: 2;
			color: #fff;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			gap: 5%;

			@include media-breakpoint-down(md) {
				padding: 0 0 0 0;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				width: 100%;
				gap: 2%;
			}
			.title-second-section {
				font-size: 23px;
				font-weight: 700;
				line-height: 23px;

				@include media-breakpoint-down(md) {
					font-size: 27px;
					font-weight: 700;
					text-align: center;
				}
			}
			.text-second-section {
				p {
					font-size: 11px;
					font-weight: 400;
					line-height: 19px;
					margin-bottom: 5%;
				}

				@include media-breakpoint-down(md) {
					p {
						font-size: 12px;
						text-align: center;
						line-height: 20px;
						margin-bottom: 0%;
					}
				}
			}
		}
	}
}
