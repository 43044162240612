.bat-deleteaccount-gloit{
    width: 75%;
    margin: auto 70px;

    @include media-breakpoint-down(md){
        width: auto;
        margin: auto 20px;
    }

    &-delete{
        &.hidden{
            display: none;
        }

        h1{
            @include font-rem(30px, 34px);
            text-transform: uppercase;
            margin-bottom: 20px;
     
            @include media-breakpoint-down(md){
                @include font-rem(24px, 28px);
                text-align: center;
            }
        }

        .bat-form-text{
            font-family: "Mont", sans-serif;
            @include font-rem(14px, 20px);
            margin-bottom: 30px;
            
            @include media-breakpoint-down(md){
                text-align: center;
            }
        }

        .bat-form-field-set{
            margin-bottom: 0px;
            border: 1px solid #DEDEDE;
            padding: 20px 20px 10px;

            label{
                margin-bottom: 0px;

                input{
                    -webkit-appearance: none;
                    background: white;
                    padding-right: 0px;

                    &:focus{
                        border: none
                    }
                }

                span{
                    padding: 0px;
                    color: $brand-black;
                }
            }
        }

        .bat-form--delete-account-delete{
            button{
                @include media-breakpoint-down(md){
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }

    &-delete-confirm{
        &.hidden{
            display: none;
        }

        text-align: center;

        .title{
            @include font-rem(20px, 28px);
            margin-bottom: 20px;

            @include media-breakpoint-down(md){
                @include font-rem(18px, 24px);
            }
        }

        .subtitle{
            @include font-rem(14px, 20px);
            font-weight: 500;
            margin-bottom: 20px;
        }

        .homeCta{
            width: 250px;
            font-size: 13px;
            color: $white;

            @include media-breakpoint-down(md){
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

bat-account-gloitoptoutfanize {
    .bat-form-heading {
        h1 {
            font-size: 20px;
            margin-bottom: 10px;
            padding-top: 30px;
            border-top: 1px solid black;
        }
    }

    .bat-form-text {
        font-size: 12px !important;
        line-height: 14px !important;
        margin-bottom: 15px !important;
    }

    .bat-form-block {
        margin: 0;

        input {
            height: 0 !important;
        }

        input::before {
            font-size: 15px !important;
        }

        input + span {
            font-size: 12px !important;
            line-height: 12px !important;
        }

        .bat-form--delete-account-delete {
            display: flex;
        }
    }
}