.glo-it-getloyaltyapp {
    max-width: 987px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 0;

    @include media-breakpoint-down(md) {
        padding: 60px 12px 140px 12px;
    }

    &-container {
        @include media-breakpoint-down(md) {
            border-radius: 20px;
            background: linear-gradient(180deg, #3880BE 0%, #345BA6 35%, #2F328C 100%);
            padding-top: 30px;
        }
    }

    &-title {
        color: #2A2C2C;
        font-size: 34px;
        font-weight: 500;
        line-height: 39px;
        margin-bottom: 25px;
        margin-left: 40px;

        @include media-breakpoint-down(md) {
            color: $white;
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 15px;
            margin-left: 0;
            padding: 0 150px 0 26px;
        }
    }
    

    &-inner-container {
        border-radius: 40px;
        background: linear-gradient(90deg, #3880BE 0%, #345BA6 35%, #2F328C 100%);
        padding: 40px;
        width: 100%;
        align-items: center;
        position: relative;

        @include media-breakpoint-down(md) {
            border-radius: unset;
            background: unset;
            padding: 0 26px;
            flex-direction: column;
        }
    }

    &-description {
        flex-direction: column;

        &-title {
            color: #2A2C2C;
            font-size: 34px;
            font-weight: 500;
            line-height: 39px;
            margin-bottom: 25px;
            margin-left: 40px;
    
            @include media-breakpoint-down(md) {
                color: $white;
                font-size: 28px;
                line-height: 36px;
                margin-bottom: 15px;
                margin-left: 0;
                padding: 0px;
            }
        }
        &-subtitle {
            color: $white;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            margin-bottom: 40px;
            
            p {
                color: $white;
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
            }

            @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 23px;

                p {
                    font-size: 16px;
                    line-height: 23px;
                }
            }
        }

        &-cta {
            gap: 15px;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                margin-bottom: 40px;
            }

            .bat-cta-style {
                padding: 8px 20px 8px 12px !important;
                max-width: 190px !important;

                .icon-playstore {
                    height: 30px !important;
                    width: 30px !important;
                    flex: none;
                }
            }
        }
    }

    &-image {
        width: 50%;
        picture{
            width: 70%;
            margin-left: 10%;      
            @include media-breakpoint-down(md) {  
                margin-left: 0px;
                margin-bottom: 10%;
            }
        }
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        
    }

    &-icon {
        width: 161px;
        height: 161px;
        position: absolute;
        right: -75px;

        @include media-breakpoint-down(md) {
            right: 0;
            top: -215px;
        }
    }

    &-video {

        .glo-it-getloyaltyapp-container {
            @include media-breakpoint-down(md) {
                padding-top: 0;
            }
        }

        .glo-it-getloyaltyapp-inner-container {
            @include media-breakpoint-down(md) {
                padding: 26px;
            }
        }

        .glo-it-getloyaltyapp-description {
            &-heading {
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 30px;
                font-weight: 500;
                line-height: normal;
                color: $white;
                margin-bottom: 10px;

                @include media-breakpoint-down(md) {
                    font-size: 28px;
                    line-height: 36px;
                }
            }

            &-title {
                font-size: 16px;
                line-height: 20px;
                p {
                    font-size: 16px;
                    line-height: 20px;
                }
    
                @include media-breakpoint-down(md) {
                    font-size: 16px;
                    line-height: 23px;
    
                    p {
                        font-size: 16px;
                        line-height: 23px;
                    }
                }
            }
        }

        &-embed {
            min-width: 50%;
            width: 50%;
            border-radius: 16px;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            video {
                text-align: center;
                margin: 0 auto;
                height: auto;
                clip-path: inset(0.1px 0.1px);
                -webkit-clip-path: inset(0.1px 0.1px);
            }
        }
    }
}