.bat-discoverglo-gloit {
	padding-top: 20px;
	padding-bottom: 50px;
	display: flex;
    justify-content: center;
	z-index: 2 !important;

	.buttons {

		@include media-breakpoint-down(md) {
			display: flex;
			margin: auto;
		}

		.button-container {
			@include media-breakpoint-down(md) {
				width: 50%;
			}

			.button {
				width: 230px;

				&.bat-button--dark {
					z-index: 1;
					pointer-events: none;
					&:focus{
						color: white;
					}
				}

				&.bat-button--secondary--dark {
					z-index: 0;
					background-color: white;
					&:hover{
						color: $brand-black;
					}
				}

				&.boost {
					margin-inline: -50px
				}

				@include media-breakpoint-down(md) {
					display: block;
					width: 180px;

					&.boost {
						margin-inline: -15px;
					}
					&.standard {
						margin-inline: -15px
					}
				}
			}
		}
	}
}
.section-full-border-radius{
	border-radius: 30px;
}
.section-border-radius-bottom{
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}
.image-border-radius{
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}
.image-border-radius-bottom-left{
	border-bottom-left-radius: 30px;
}
.image-border-radius-bottom-right{
	border-bottom-right-radius: 30px;
}
.image-border-radius-top-left{
	border-top-left-radius: 30px;
}
.image-border-radius-top-right{
	border-top-right-radius: 30px;
}
.section-margin-top{
	padding-top: 10px;
	background-color: white;
}

bat-cta-gloitdiscoverglo{
	&.thr-blue-cta{
		.bat-button--secondary--dark, .bat-cta-style.button-secondary-dark{
			border: solid 3px #1B65AC;
		}
		.bat-button--secondary--dark, .bat-cta-style.button-secondary-dark span{
			color: #1B65AC;
		}
		.bat-button, .bat-cta-style.button-dark, .bat-cta-style.button-dark-disabled, .bat-cta-style.button-light, .bat-cta-style.button-secondary-dark, .bat-cta-style.button-secondary-light{
			padding: 8px;
			font-size: 11px;
		}
	}
	
}