.coins-container{
	padding-top: 30px;
	padding-bottom: 20px;
	margin-top:20px;
	max-width: 980px;
	background-color: $concrete;

	.title-container{
		padding: 0px 120px;

		@include media-breakpoint-down(sm){
			padding: 0px 35px;
		}
	}

	.title{
		@include font-rem(36px, 45px);
		margin-bottom: 0;

		@include media-breakpoint-down(sm){
			@include font-rem(20px, 24px);
		}
	}

	.subtitle{
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.ins-description{
		margin-top:10px;
		@include font-rem(16px, 27px);
	}

	.list-container{
		
		.element-list{
			@include media-breakpoint-down(md){
				padding-top: 10px;
				padding-bottom: 10px;
				flex-direction: column;
			}
			.single-element{
				max-width: 250px;

				@include media-breakpoint-down(md){
					flex-direction: row;
					margin-top: 20px;
					padding: 0px;
					padding-left: 25px;
					max-width: 300px;

					.text-element{
						margin-left: 30px;

						.titolo{
							p{
								text-align: left;
							}
						}

						.descrizione{
							p{
								text-align: left;
							}
						}
					}
				}

				&.coin-card{
					max-width: none;
					.icona{
						max-width: none;

						picture{
							max-width: none;
						}
					}
				}
			}
			.icona{
				max-width: 75px;
				margin-bottom: 10px;
			}
			.titolo{
				margin-bottom: 10px;
			}
			.descrizione{
				p{
					font-weight: 700;
				}
				font-size: 14px;
				
			}
		}
	}
}
.totale-coins{
	margin-top: 25px;
	margin-bottom: 10px;
	padding: 30px 200px;

	@include media-breakpoint-down(sm){
		padding: 0px 35px;
	}
}

.btn-iscriviti{
	max-width: fit-content;
	margin: auto;
	margin-top: 30px;
    margin-bottom: 30px;

	.bat-button{
		color: $white;
	}
}