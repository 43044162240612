bat-carousel-gloitlepinfinity{
    max-width: none;
    .bat-carousel.bat-carousel-lepinfinity-gloit {
        position: relative;
        max-width: none;
        margin-inline: auto;

    
        .slick-play-stop {
            rotate: 90deg;
            background-color: rgba(42, 42, 42, 0.6); 
            color: white; 
            border: none;
            border-radius: 50%; 
            padding: 7px;
            cursor: pointer; 
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 780px) {
                rotate: none;
            }
            
            .icon-play, .icon-stop {
                width: 26px;
                height: 26px;
                fill: rgba(255, 255, 255, 1)
            }
    
            .icon-play {
                display: none;
            }
    
            &.paused {
                .icon-stop {
                    display: none;
                }
    
                .icon-play {
                    display: block;
                }
            }
        }
    
        .slick-dots {
            rotate: 180deg;
            background-color: rgba(42, 42, 42, 0.6); 
            border-radius: 30px; 
            padding: 10px; 
            width: fit-content;
            margin: 0;
            bottom: 0;
            position: relative;
            left: unset;
            right: unset;
            
            li {
                width: 10px; 
                height: 10px; 
                display: inline-block;
                background: rgba(229, 225, 230, 1);
                margin: 0 5px;
                cursor: pointer;
                -webkit-border-radius: 50%;
                        border-radius: 50%;
                -webkit-transition: opacity .5s, background-color .5s, width .5s;
                -o-transition: opacity .5s, background-color .5s, width .5s;
                transition: opacity .5s, background-color .5s, width .5s;
                -webkit-transition-delay: .5s, .5s, 0s;
                     -o-transition-delay: .5s, .5s, 0s;
                        transition-delay: .5s, .5s, 0s;
                
                button {
                  display: none;
                }
    
                &.slick-active {
                    background: rgba(255, 255, 255, 1);
                    width: 40px;
                    border-radius: 30px;
                    -webkit-transition-delay: 0s;
                     -o-transition-delay: 0s;
                        transition-delay: 0s;
                }
            }
        }
    
        .slick-dots-container {
            position: absolute;
            gap: 20px;
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;
            rotate: 270deg;
            top: 50%;
            right: 0px;
            @media screen and (max-width: 780px) {
                rotate: none;
                flex-direction: row;
                top: unset;
                right: 6%;
                bottom: 3%;
            }
        }.slick-play-stop{
			display: none !important;
		}
    
        }

        .slick-slide {
            max-height: 829px;
            border: none !important;
            @media screen and (max-width: 780px) {
                max-height: 616px !important;
            }
            .slick-current {
                z-index: 1;
            }
            bat-card-gloitlepcarousel{
                .slide{
                    height: 828px !important;
                    @media screen and (max-width: 780px) {
                        height: 616px !important;
                    }
					@media screen and (max-width: 380px) {
                        height: 460px !important;
                    }
                }
            }
        }

        .bat-card {
            .bat-card--default {
                margin-right: 20px;
                max-width: none;
                
                @include media-breakpoint-up(sm) {
                    margin-right: 40px;
                }
            }
        }

        
        .slick-arrow {
            
            &.slick-prev {
                
                &.slick-hidden {
                    display: none;
                }
            }
            
            &.slick-next {
                
                &.slick-hidden {
                    display: none;
                }
            }
        }
        
    
        .slick-prev,
        .slick-next {
            z-index: 1;
            width: 44px;
            height: 44px;
            display: inline-block !important;
            .bat-icon {
                color: $dark-blue;
                width: 44px;
                height: 44px;
            }
        }
}

