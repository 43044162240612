.quick-login-modal{
	width: 585px;
    display: flex;
    flex-direction: column;
    justify-content: center;
	

    
	.uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

	.first-section-modal{
		position: relative;
		text-align: center;
        width: 210px;
        margin: 0 auto;
        margin-top: 60px;
        margin-bottom: 30px;
	}
	.second-section-modal{
        margin-inline: auto;
        margin-top: 30px;
        width: 500px;
		display: flex;
        flex-direction: column;
        justify-content: center;


        @include media-breakpoint-down(sm) {
            margin-inline: 20px;
            margin-top: 60px;
            width: auto;
        }

        .modal-title {
            font-size: 24px;
            font-family: Mont;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-align: left;
        }

        .description-modal {
            font-size: 15px;
            line-height: 28px;
            text-align: left;
            padding: 16px;
            padding-left: 0px;
            
            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }
        }

        .coin-gained-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px 32px;
            background: #0B1047;
            border-radius: 40px;
            width: max-content;
            margin: auto;
            margin-bottom: 40px;

            .coin-gain {
                font-weight: 700;
                font-size: 26px;
                line-height: 30px;
                text-transform: uppercase;
                margin: 0;
            }

            .euro-gain {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                text-transform: uppercase;
                color: #D5DDEC;
                margin: 0;
            }
        }

		.button-container{

            text-align: left;
            margin-bottom: 35px;
            
            p {
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
            }
		}
	}
}

.quick-login-hyperpro-modal{
	width: 585px;
    display: flex;
    flex-direction: column;
    justify-content: center;
	

    
	.uk-close{
		background: white;
        color: #2A2C2C !important;
        top: 24px;
        right: 24px;
        height: 40px;
        width: 40px;

        svg {
            height: 10px;
            width: 10px;
        }
	}

	.first-section-modal{
		position: relative;
		text-align: center;
        width: 210px;
        margin: 0 auto;
        margin-top: 60px;
        margin-bottom: 30px;
	}
	.second-section-modal{
        margin-inline: auto;
        margin-top: 30px;
        width: 500px;
		display: flex;
        flex-direction: column;
        justify-content: center;


        @include media-breakpoint-down(sm) {
            margin-inline: 20px;
            margin-top: 60px;
            width: auto;
        }

        .modal-title {
            font-size: 24px;
            font-family: Mont;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-align: left;
        }

        .description-modal {
            font-size: 15px;
            line-height: 28px;
            text-align: left;
            padding: 16px;
            padding-left: 0px;
            padding-top: 0px;
            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }
        }

        .coin-gained-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px 32px;
            background: #0B1047;
            border-radius: 40px;
            width: max-content;
            margin: auto;
            margin-bottom: 40px;

            .coin-gain {
                font-weight: 700;
                font-size: 26px;
                line-height: 30px;
                text-transform: uppercase;
                margin: 0;
            }

            .euro-gain {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                text-transform: uppercase;
                color: #D5DDEC;
                margin: 0;
            }
        }

		.button-container{

            text-align: left;
            margin-bottom: 35px;
            
            p {
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
            }
		}
	}
}