.section-page{
    background-color: $white;
    padding-top: 70px;
    padding-bottom: 70px;

    @include media-breakpoint-down(sm){
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .signout{
        .textlist-title{
            @include font-rem(30px, 34px);
            margin-bottom: 20px;

            @include media-breakpoint-down(md){
                @include font-rem(24px, 28px);
            }
        }

        .textlist-subtitle{
            @include font-rem(14px, 20px);
            font-weight: 500;
        }

        .bat-textlist-gloit{
            width: 75%;
            margin: auto 70px;

            @include media-breakpoint-down(md){
                width: auto;
                margin: auto 20px;
            }
        }
    }
}

.bat-textlist-gloit{

    .textlist-title{
        font-family: $primary-font;
        font-weight: 800;
        @include font-rem(30px, 34px);
        
        @include media-breakpoint-down(sm){
            @include font-rem(24px, 28px);
            text-align: center;
        }
    }

    .textlist-subtitle{
        font-family: $primary-font;
        @include font-rem(20px, 20px);
        margin-top: 0px;
        margin-bottom: 0px;
        
        @include media-breakpoint-down(sm){
            @include font-rem(18px, 20px);
            text-align: center;
        }
    }

    .textlist-title-container{
        border-bottom: 1px solid $light-gray;

        @include media-breakpoint-down(sm){
            margin-left: 20px;
            margin-right: 20px;
        }     
        
        
    }

    .textlist-list-container{

        padding-bottom: 40px;
        padding-top: 40px;

        @include media-breakpoint-down(sm){
            margin-left: 5px;
            margin-right: 20px;
        }
      
        .textlist-list-title{
            font-family: $primary-font;
            font-weight: 800;
            @include font-rem(22px, 28px);
            
            @include media-breakpoint-down(sm){
                @include font-rem(20px, 24px);
                text-align: center;
            }
        }

        .textlist-list{
            margin-left: 20px;
            @include media-breakpoint-down(sm){
                margin-left: 0px;
            }
            .textlist-list-item{
                list-style: none;
                .item-text{
                    font-family: $primary-font;
                    @include font-rem(14px, 20px);
                }
            }
        }
    }

    .update-date{
        font-family: $primary-font;
        @include font-rem(14px, 20px);
        font-weight: 300;
    }
}

.update-date{
    p {
        font-family: $primary-font;
        @include font-rem(14px, 20px);
        font-weight: 300;
    }
}