.bat-configuratorepannello-gloit{
    &-product-container{
        background-image: url('/content/dam/glo-it/images/configuratore-background.png');
        background-size: 100%;

        @include media-breakpoint-down(md){
            flex-direction: column;
            background-image: none;
        }

        .product-image-container{
            width: 60%;
            width: fit-content;
            margin: auto;
            padding-top: 20px;

            @include media-breakpoint-down(md){
                width: 100%;
                background-image: url('/content/dam/glo-it/images/configuratore-background.svg');
            }
        }
        .product-info-container{
            width: 40%;
            background: rgba(white, 0.8);
            padding: 60px;

            @include media-breakpoint-down(md){
                width: 100%;
                padding: 20px;
                padding: 0px;
            }

            .product-title{
                font-weight: 700;
                margin-bottom: 20px;
                font-size: 21px;
                line-height: 39px;

                @include media-breakpoint-down(md){
                    font-size: 14px;
                    line-height: 18px;
                    margin: 20px;
                }
            }

            .product-name{
                margin-top: 0px;
                margin-bottom: 20px;
                font-size: 34px;
                line-height: 39px;

                @include media-breakpoint-down(md){
                    font-size: 24px;
                    line-height: 39px;
                    margin: 20px;
                }
            }

            .product-description{
                margin-bottom: 20px;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 1.2px;
                color: #6B6B6B;

                @include media-breakpoint-down(md){
                    margin: 20px;
                }
            }


            .colors-container{
                padding-bottom: 20px;
                border-bottom: 0.5px solid #2A2C2C;

                @include media-breakpoint-down(md){
                    margin: 20px;
                }

                .device-colors-label{
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 1.2px;
                    font-weight: 700;
                }

                .device-colors{
                    display: flex;

                    .device-color-container{
                        margin-inline: 3px;
                        padding: 3px;
                        display: flex;

                        &.selected{
                            border: 1px solid black;
                            border-radius: 100%;    
                        }

                        .color-button{
                            width: 26px;
                            height: 26px;
                            border-radius: 100%;
                        }
                    }
                }
            }

            .panels-container{
                padding-top: 20px;
                padding-bottom: 20px;
                border-bottom: 0.5px solid #2A2C2C;

                @include media-breakpoint-down(md){
                    margin: 20px;
                }

                .panels-colors-label{
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 1.2px;
                    font-weight: 700;
                }

                .panels-colors{
                    display: flex;

                    &.hidden{
                        display: none;
                    }

                    .panel-color-container{
                        margin-inline: 3px;
                        padding: 3px;
                        display: flex;

                        &.selected{
                            border: 1px solid black;
                            border-radius: 100%;    
                        }

                        .color-button{
                            width: 26px;
                            height: 26px;
                            border-radius: 100%;
                        }
                    }
                }

                .select-panel-button{
                    &.hidden{
                        display: none;
                    }

                    margin-top: 20px;
                    
                    &:hover{
                        color: black;
                    }
                }

                .panel-color-added-container{
                    margin-top: 20px;

                    &.hidden{
                        display: none;
                    }
                    
                    .panel-color-added-label{
                        text-transform: uppercase;
                        font-size: 12px;
                        line-height: 15px;
                        letter-spacing: 1.2px;
                        font-weight: 700;
                    }

                    .color-added-container{
                        .label{
                            font-size: 12px;
                            font-weight: 800;
                            line-height: 14px;
                            letter-spacing: 1.2px;
                            color: #AAAAAA;
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }

                        .color-added-info-container{
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 5px;

                            @include media-breakpoint-down(md){
                                margin-bottom: 20px;
                                align-items: flex-start;
                            }

                            .color-added-info{
                                display: flex;
                                align-items: center;

                                .color{
                                    border-radius: 100%;
                                    margin-right: 10px;
                                }

                                .label{
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 14px;
                                    letter-spacing: 1.2px;
                                    color: #2A2C2C;
                                    text-transform: capitalize;
                                }
                            }

                            .color-added-info-actions{
                                display: flex;
                                align-items: center;

                                .counter{
                                    margin-right: 15px;

                                    .decrement,
                                    .increment{
                                        margin-inline: 15px;
                                        background-color: #EFEFEF;
                                        padding: 10px;
                                        border-radius: 100%;

                                       span{
                                            display: flex;
                                            width: 10px;
                                            height: 10px;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: 20px;
                                            color: #2A2C2C;
                                       }
                                    }

                                    .counter-number{
                                        font-size: 14px;
                                        font-weight: 800;
                                        line-height: 16px;
                                        letter-spacing: 1.2px;
                                        color: #2A2C2C;
                                    }
                                }

                                .delete{
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 14px;
                                    letter-spacing: 1.2px;
                                    text-decoration: underline;
                                    color: #2A2C2C;
                                }

                                @include media-breakpoint-down(md){
                                    flex-direction: column;
                                    align-items: flex-end;

                                    .counter{
                                        margin-right: 0px;
                                        margin-bottom: 20px;

                                        .increment{
                                            margin-right: 0px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .select-panels {
                position: relative;
                margin-top: 20px;
                margin-bottom: 20px;
    
                select {
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    appearance: none;
    
                    @include font-rem(14px, 20px);
                    background-color: $concrete;
                    border: 0;
                    color: $blackish;
                    font-family: $primary-font;
                    height: 40px;
                    padding: 8px 20px;
                    width: 100%;
    
                    &.error {
                        border: 0;
                        border-bottom: solid 2px $red;
                    }
    
                    &.active {
                        border: 0;
                        border-bottom: solid 2px $light-blue;
                    }
    
                    &.complete {
                        border: 0;
                        border-bottom: solid 2px $light-blue;
                    }
    
                    &::-ms-expand {
                        display: none;
                    }

                    &:focus{
                        outline: none;
                    }
                }
    
                & + .error-msg {
                    visibility: hidden;
                }
    
                &.complete {
                    border: 0;
                    border-bottom: solid 2px $light-blue;
                }
    
                &.disabled {
                    color: $blackish;
                    opacity: 1;
                    pointer-events: none;
    
                    select {
                        background-color: $light-gray;
                    }
    
                    &::-webkit-input-placeholder {
                        color: $blackish;
                    }
    
                    &:-moz-placeholder {
                        /* FF 4-18 */
                        color: $blackish;
                    }
    
                    &::-moz-placeholder {
                        /* FF 19+ */
                        color: $blackish;
                    }
    
                    &:-ms-input-placeholder {
                        /* IE 10+ */
                        color: $blackish;
                    }
    
                    &::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: $blackish;
                    }
    
                    &::placeholder {
                        /* modern browser */
                        color: $blackish;
                    }
                }
    
                &.error {
                    border: 0;
    
                    & + .error-msg {
                        visibility: visible;
                    }
                }
    
                &:after {
                    color: $brand-black;
                    content: "\e920";
                    font-family: "icomoon";
                    font-size: 24px;
                    height: 22px;
                    pointer-events: none;
                    position: absolute;
                    right: 8px;
                    top: 9px;
                    width: 24px;
                }
            }

            .add-to-cart-container{
                padding-top: 25px;
                display: flex;
                justify-content: space-between;

                @include media-breakpoint-down(md){
                    flex-direction: column;
                    margin: 20px;
                }

                .price{
                    color: black;
                    background-color: transparent;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 32px;
                    padding: 0px;
                }
            }

            .add-to-cart-button{
                margin-top: 25px;

                @include media-breakpoint-down(md){
                    display: flex;
                    margin: 25px auto auto; 
                }
            }

            .delivery-info-container{
                display: flex;
                margin-top: 25px;
                align-items: center;

                @include media-breakpoint-down(md){
                    flex-direction: column;
                    justify-content: center;

                    .delivery-info{
                        margin-top: 10px;
                        margin-left: 0px;
                    }
                }

                .icon-delivery{
                    &::before{
                        font-size: 40px;
                    }
                }

                .delivery-info{
                    font-size: 12px;
                    font-weight: 800;
                    line-height: 14px;
                    letter-spacing: 1.2px;
                    margin-left: 15px;
                }
            }
        }
    }

    &-user-info{
        margin-top: 10px;
        background-color: #EFEFEF;
        padding: 20px 60px;
        font-size: 12px;
        line-height: 17px;

        @include media-breakpoint-down(md){
            padding: 20px;
        }

        .user-info{
            p{
                color: #6B6B6B;
                font-size: 12px;
                line-height: 17px;
            }
        }
    }

    &-product-info{
        margin: 40px 60px;

        @include media-breakpoint-down(md){
            margin: 20px; 
        }

        .product-info-tab{
            margin-right: 10px;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 900;
            line-height: 39px;
            border-bottom: 3px solid black;
            margin-bottom: -3px;
            width: fit-content;

            @include media-breakpoint-down(md){
                margin: auto;
            }
        }

        .product-info-contents{
            border-top: 0.5px solid #2A2C2C;

            @include media-breakpoint-down(md){
                border-top: 0px;
                width: fit-content;
                margin: auto;
            }

            .product-info{
                padding: 20px 20px 0px;
                display: flex;
                justify-content: space-between;

                .info{
                    list-style: none;
                    width: 250px;

                    &:first-of-type{
                        list-style: inherit;
                    }
                }

                @include media-breakpoint-down(md){
                    display: block;

                    .info{
                        width: auto;
                    }

                    .info + .info{
                        list-style: inside;

                        &::marker{
                            content: '- ';
                        }
                    }
                }
                
                &.hidden{
                    display: none;
                }
            }
        }
    }
}