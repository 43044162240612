.gloitbreakers-top-content{
    background-color: $concrete;

    .bat,&.bat{
        background-color: $white;
        max-width: none !important;
        padding: 40px 100px 0px;
        @include media-breakpoint-down(sm){
            background-color: $concrete;
            padding: 0px 10px;
        }

        .bat-cardnews-gloit--news{
            display: flex;
            justify-content: space-evenly;

            @include media-breakpoint-down(sm){
                display: block;
            }

            .bat-cardnews-gloit--news-main{
                width: 40%;
                margin: auto 0px;

                .bat-cardnews-gloit--news-text{
                    margin-bottom: 20px;

                    .bat-cardnews-gloit--news-title{
                        @include font-rem(22px, 28px);
                        margin-bottom: 20px;
    
                        @include media-breakpoint-down(sm){
                            @include font-rem(20px, 24px);
                        }
                    }
                }
               

                @include media-breakpoint-down(sm){
                    width: 100%;
                    padding-left: 0px;
                }

            }

            .bat-cardnews-gloit--news-img{
                max-height: none;
            }   
        }
    }

    &-text{
        padding: 60px;
        justify-content: space-evenly;

        @include media-breakpoint-down(sm){
           display: block;
           padding: 30px 20px 0px;
        }

        .title{
            @include font-rem(30px, 34px);
            font-weight: 800;
            font-family: $primary-font;
            width: 25%;

            @include media-breakpoint-down(sm){
                @include font-rem(24px, 28px);
                width: 100%;
                margin-bottom: 20px;
            }
        }

        .text{
            @include font-rem(14px, 20px);
            font-family: $primary-font;
            width: 24%;

            @include media-breakpoint-down(sm){
                width: 100%;
            }
        }
    }
}

.gloitbreakers-news{
    margin-bottom: 50px;

    .gloitbreakers-news-section{

        @include media-breakpoint-down(sm){
            .news-card{
                padding-bottom: 60px;
            } 
        }

        &-title{
            @include font-rem(30px, 34px);
            font-weight: 800;
            margin-bottom: 20px;
            padding-left: 15px;

            @include media-breakpoint-down(sm){
                @include font-rem(24px, 28px);
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 20px
            }
        }

        &-paragraph{
            @include font-rem(14px, 20px);
            margin-bottom: 20px;
            padding-left: 15px;
            width: 50%;

            @include media-breakpoint-down(sm){
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        .bat-cardnews-gloit--news{
            padding: 20px 10px;
        }

        .bat-cardnews-gloit--news-img{
            max-height: none;
        }

        .bat-carousel{
            margin: 0px 10px;

            .slick-track{
                left: 34%;
            }

            i{
                display: none;
            }
        }
    }

    .gloitbreakers-news-section:nth-child(odd){
        .aem-GridColumn.news-card{
            background-color: $white;

            .carousel{
                background-color: $white;
            }
        }
        
    }
}