.aem-GridColumn{
    

	&.news-card{
		padding: 70px 50px;
        background-color: $concrete;

		@include media-breakpoint-down(sm){
			padding: 0px;
		}
    }

    .title-section{
        @include media-breakpoint-down(sm){
            display: block;
			text-align: center;
            padding-top: 30px;
		}
        
    }

    @include media-breakpoint-down(xl){
        .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--tablet--6 {
            width: 100%;
        }
    }

    .news-section-title{
        @include font-rem(22px, 28px);
        font-weight: 800;

        @include media-breakpoint-down(sm){
            margin-bottom: 10px;
		}
    }

    .news-section-link{
        text-decoration: none;
        color: $charcoal;
    
        @include font-rem(14px, 28px);
        font-weight: 800;

        i{
            margin-top: 2px;
            margin-left: 10px;
        }
    }
}
.bat-loyaltylanding-gloit{
    height: 274px !important;
    @include media-breakpoint-down(sm){
        height: 172px !important;
    }
    &--news {

        &-main {
            margin-bottom: 10px;
            padding: 16px 24px;
            gap: 12px;
            background: $white;
            border: 2px solid #5F73BA;
            box-shadow: 8px 8px 0px #D5DDEC;
            display: flex;
            flex-direction: row;
            width: 630px;
            height: 264px;
            @include media-breakpoint-down(sm){
                width: 100%;
                height: 172px;
            }
        }
        &-event{
            top: -197px;
            position: relative;
            /* padding-top: 23px; */
            margin-left: 77px;
            @include media-breakpoint-down(sm){
                top: -123px;
                margin-left: 59px;
            }
        }


        &-image {
            width: 100%;
            @include media-breakpoint-down(sm){
                min-width: 120px;            }
            img {
                //width: 40px;
                //height: 40px;
                //object-fit: contain;
            }
        }

        &-box-coins {
            padding: 6px 10px;
            text-align: center;
            background: #18207F;
            border-radius: 40px;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: $white;
        }

        &-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include media-breakpoint-down(sm){
                margin-left: -13px;
            }
        }

        &-title{
            @include font-rem(32px, 38px);
            font-weight: bold;
            color: #2A2C2C;
            margin-top: 0;
            margin-bottom: 12px;
            @include media-breakpoint-down(sm){
                margin-bottom: 0px;	
                font-size: 22px;
                line-height: 26.4px;
                margin-top: -7px;
            }
        }
        &-subtitle-first{
            @include font-rem(20px, 28px);
            display: flex;
            flex-direction: row;
            font-weight: 400;
            color: #18207F;
            margin-top: 20px;
            margin-bottom: 12px;
            @include media-breakpoint-down(sm){
                margin-top: 10px;
                margin-bottom: 0px;	
                font-size: 14px;
                line-height: 18px;
                .bat-icon{
                    @include media-breakpoint-down(sm){
                        height: 21px;;
                    }
                }
            }
        }

        &-subtitle-second{
            @include font-rem(20px, 28px);
            display: flex;
            flex-direction: row;
            font-weight: 400;
            color: #18207F;
            margin-top: 0;
            margin-bottom: 12px;
            @include media-breakpoint-down(sm){
                margin-top: 0px;
                margin-bottom: 16px;	
                font-size: 14px;
                line-height: 18px;
                .bat-icon{
                    @include media-breakpoint-down(sm){
                        height: 21px;;
                    }
                }
            }
        }
        &-body {
            margin-left: 15px;
            @include media-breakpoint-down(sm){
                margin-left: 0px;         
            }
            .container-button{
                //margin-top: 69px;
                display: flex;
                flex-direction: row;
                position: relative;
                top: 50px;
                @include media-breakpoint-down(sm){
                    top: 23px;  
                }

                .landing-button{
                    height: 37px;
                    @include media-breakpoint-down(sm){
                        height: 30px;
                        width: 100px;
                        padding: 15px;                 
                    }
                }
                .landing-button-info{
                    height: 37px;
                    width: 50px;
                    margin-left: 25px;
                    padding: 18px;
                    &.disabled {
                        border-radius: 40px;
                        border: 2px solid var(--Glo-Grey, #AAA);
                        background: var(--Glo-White, #FFF);
                    }
                    @include media-breakpoint-down(sm){
                        height: 30px;
                        width: 44px;
                        margin-left: 17px;
                        padding: 15px;                    
                    }
 
                    .icon-container{
                        margin-left: -5.5px;
                        margin-top: -12.5px;
                    }
                
                }
            }
            }

            

        &-content{
            p {
                @include font-rem(14px, 20px);
                font-weight: 400;
                color: #2A2C2C;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
	}
}

.icon-stores {
    &::before {
        content: url('/content/dam/glo-it/immagini/stores.png');
        height: 35px;
		transform: scale(0.7);
    }
}

.icon-date {
    &::before {
        content: url('/content/dam/glo-it/immagini/icondate.png');
        height: 35px;
		transform: scale(0.7);
    }
}

.icon-error {
    &::before {
        content: url('/content/dam/glo-it/immagini/iconerror.png');
    }
}
.icon-error-disabled {
    &::before {
        content: url('/content/dam/glo-it/immagini/iconerrordisabled.png');
    }
}

.icon-event{
    &::before {
        content: url('/content/dam/glo-it/immagini/eventonondisponibile.png');
        transform: scale(0.5);
        @include media-breakpoint-down(sm){
            transform: scale(0.4);		
        }
    }
}

