.bat-portaunamico-gloit{

    &-section{

        &-first,
        &-second{

            @include media-breakpoint-down(sm){
                display: block;
            }

            .toptitle{
                font-family: $primary-font;
                @include font-rem(20px, 34px);
                font-weight: 800;

                @include media-breakpoint-down(sm){
                    display: none;
                }
            }

            .title{
                font-family: $primary-font;
                @include font-rem(30px, 34px);
    
                @include media-breakpoint-down(sm){
                    @include font-rem(24px, 28px);
                }
            }

            .subtitle{
                font-family: $primary-font;
                font-weight: 800;
                @include font-rem(18px, 20px);
    
                @include media-breakpoint-down(sm){
                    @include font-rem(14px, 20px);
                }
            }

            .description{
                font-family: $primary-font;
                @include font-rem(14px, 20px);
            }

            .list{
                list-style: none;

                &-item{
                    font-family: $primary-font;
                    @include font-rem(14px, 20px);
                }
            }

            .disclaimers{
                &-disclaimer{
                    font-family: $primary-font;
                    @include font-rem(12px, 17px); 
                }
            }

            .goToCta{
                @include font-rem(14px, 18px);
            }
        }
        
        &-first{
            margin: 70px;
            justify-content: space-around;

            @include media-breakpoint-down(sm){
                margin: 30px 20px;
            }

            .text-content{
                max-width: fit-content;
                width: 50%;

                @include media-breakpoint-down(sm){
                    max-width: none;
                    width: 100%;
                }
            }

            .title{
                margin-top: 20px;
                margin-bottom: 20px;

                @include media-breakpoint-down(sm){
                    margin-bottom: 15px;
                }
            }

            .list{
                margin-top: 40px;
                column-count: 2;
                border-bottom: 1px solid #DEDEDE;
                padding-left: 40px;
                padding-bottom: 20px;

                @include media-breakpoint-down(sm){
                    column-count: 1;
                    margin-top: 15px;
                    padding-left: 25px;
                }

                &-item{
                    list-style: url("/content/dam/glo-it/images/infoglo-card/icon-tick.svg");
                    width: 90%;
                    padding-left: 5px;
                }
            }

            picture{
                max-width: fit-content;
                img{
                    width: 300px;
                }

                @include media-breakpoint-down(sm){
                    display: none;
                }
            }

            .disclaimers{
                @include media-breakpoint-down(sm){
                    margin-top: 10px;
                }
                
                &-disclaimer{
                    display: block;
                }
            }
        }

        &-second{
            picture{
                max-width: fit-content;
                img{
                    width: 600px;
                }
            }

            .toptitle{
                max-width: fit-content;
                width: 15%;
                padding-bottom: 20px;
                border-bottom: 1px solid #DEDEDE;

                @include media-breakpoint-down(sm){
                    width: 45%;
                    border-bottom: none;
                    text-align: left;
                }
            }

            .text-content{
                margin: 100px;
                padding-left: 60px;

                @include media-breakpoint-down(sm){
                    margin: 30px 15px;
                    text-align: center;
                    padding-left: 0px;
                }
            }

            .title{
                margin-top: 20px;
                margin-bottom: 20px;

                @include media-breakpoint-down(sm){
                    text-align: left;
                }
            }

            .description{
                width: 50%;
                margin-bottom: 20px;

                @include media-breakpoint-down(sm){
                    width: 100%;
                    text-align: left;
                }
            }

            .goToCta{
                padding: 10px 25px;

                &:hover{
                    color: $black;
                }
            }
        }
    }

    .backtotop{
        text-align: center;
        margin-bottom: 40px;
        margin-top: 40px;

        &-title{
            font-family: $primary-font;
            @include font-rem(30px, 34px);
            max-width: fit-content;
            margin: auto;
            margin-bottom: 20px;
            width: 30%;
    
            @include media-breakpoint-down(sm){
                @include font-rem(24px, 28px);
                width: 70%;
            }
        }
    
        &-cta{
            @include font-rem(12px, 14px);
            svg{
                display: none;
            }
        }
    }
}