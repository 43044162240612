.glo-it-infotabacco{
	background-color: $concrete;
	.img-info{
		
		max-width:980px;
		@include media-breakpoint-up(md){
			position: relative;
			&-button{
				position: absolute;
				right:20%;
				bottom: 20%;
			}
		}
		@include media-breakpoint-down(sm){
			background: url(https://content-eu-live-global.prod.marketing.bat.net/media/wysiwyg/about-glo-discover-more-mobile-bg.png) no-repeat center top;
		}
		&-button{
			border-color: $brand-black;
			@include media-breakpoint-down(sm){
				margin-top: 10px;
				padding-top: 30px;
				padding-bottom:30px;
			}
		}
		.image-banner{
			margin-top: 32px;
		}
	}
	.title{
		@include media-breakpoint-down(md){
			margin-top: 15px;
			margin-bottom: 15px;
			font-size: 24px;
			line-height: 24px;
			justify-content: center;
			max-width: 300px;
			padding-bottom: 0;
		}
		@include media-breakpoint-up(md){
			display: none;
		}
	}
	.button-secondary-dark {
		background: transparent;
		border: solid 2px #2a2a2c;
	}
}