bat-form-gloitdisclaimer{
    &.pdp-hyper-core{
        .bat-form-gloitdisclaimer{
        position: relative;
        background: rgba(229, 225, 230, 0.6);;
        &::after {
            content: "";
            position: absolute;
            bottom: -220px; 
            left: 0;
            right: 0;
            height: 220px; 
            background: rgba(229, 225, 230, 0.6);
            z-index: 0; 
          }
        &-title {
            color: black;
        }

        &-text {
            .title {
                color: #2A2A2A;
            }

            .subtitle {
                color: #2A2A2A;
            }

            svg{
                g path{
                    fill: #2A2A2A !important;
                    stroke: #2A2A2A !important;
                }
            }
            .border {
                opacity: 0.4;
                border-bottom: 1px solid #2A2A2A;
            }
        }
    }
    }
    .bat-form-gloitdisclaimer{
        background-color: black;
        padding: 24px 13px;
        padding-top: 51px;
        max-width: none !important;
        @include media-breakpoint-down(sm) {
            padding: 24px 0px;
        }
        &-container {
            align-items: center;
            justify-content: center;
        }
    
        &-title {
            color: var(--glo-2024-White, #FFF);
            font-family: Mont;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 66.667% */
            letter-spacing: 0.2px;
            width: fit-content;
            text-align: left;
    
            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
                color: var(--glo-2024-White, #FFF);
                font-family: Mont;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%; /* 26.4px */
                width: 100%;
            }
        }
        &-text {
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            max-width: 1400px;
            width: 100%;
            gap: 220px;
            padding-inline: 20px;
    
            @include media-breakpoint-down(sm) {
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding-top: 34px;
                padding-bottom: 34px;
                min-width: 0px;
                min-height: 0px;
                padding-inline: 25px;
                gap: 78px;
                width: 100%;
            }
    
            .column {
                width: 100%;
                max-width: 667px;
                &-upper {
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
    
                    svg{
                        opacity: 0.4;
                        transition: transform 0.5s ease-in, opacity 0.5s ease-in;
    
                        &.open{
                            transform: rotate(180deg);
                            opacity: 1;
                        }
                    }
                }
    
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    &-upper {
                        align-items: flex-start;
                    }
                }
            }
    
            @include media-breakpoint-down(sm) {
                display: block;
                margin: 0px;
            }
    
            .title {
                color: var(--glo-2024-White, #FFF);
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Mont;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%; /* 20.8px */
    
                &.open{
                    font-weight: 700;
                }
    
                @include media-breakpoint-down(sm) {
                    width: 86%;
                    font-size: 16px;
                    line-height: 130%;
                }
            }
            .full-specifications-cta {
                @include media-breakpoint-up(sm) {
                    display: none;
                }
    
                .text {
                    @include font-rem(16px, 27px);
                    font-weight: 800;
                    letter-spacing: 1px;
                    font-family: $primary-font;
                    color: white;
                }
    
                &.hidden {
                    display: none;
                }
            }
    
            .border {
                border-bottom: 1px solid #e5e1e6;
                opacity: 0.4;
                margin-top: 16px;
                margin-bottom: 16px;
                width: 100%;
    
                @include media-breakpoint-down(sm) {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
    
            .subtitle {
                @include font-rem(16px, 20px);
                font-weight: 500;
                font-family: $primary-font;
                color: white;
                max-height: 0;
                opacity: 0;
                overflow: hidden;
                display: none;
                transition:
                max-height 0.5s ease,
                opacity 0.5s ease;
                margin-top: 20px;
    
                &.show {
                    display: block !important;
                    max-height: 100px;
                    opacity: 0.6 !important;
                }
    
                &-container{
                    display: block;
                    width:100%;
    
                    .up,.down{
                        width:100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .left,.right{
                           display: flex;
                           gap: 15px;
                        }
                    }
    
                    .up{
                        margin-bottom: 30px;
                        .left,.right{
                            .text{
                                font-size: 20px;
                                font-weight: 500;
                            }
                        }
                    }
    
                    .down{
                        .left,.right{
                            font-size: 33px;
                            font-weight: 300;
                            span{
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
    
            .column {
                .border {
                    &.show {
                        border-bottom: 2px solid $light-gray;
                    }
                }
            }
        }
        &-image {
            max-width: 80%;
        }
    }
}

